export const learningCenterCardStyles = {
  borderRadius: '5px',
  padding: '10px',
  margin: 0,
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'flex',
  alignItems: 'flex-end',
  color: 'white',
  boxShadow: 0,
  '&:hover': {
    cursor: 'pointer',
    boxShadow: 3,
  },
};

export const learningCenterCardAspectRatio = '176 / 100';

export const LINEAR_BACKGROUND =
  'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))';

export const WHATS_NEW_TITLE_COPY = "What's new";
