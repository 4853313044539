/* eslint-disable no-shadow */
import { SORT_DIRECTION } from 'portal-common-library/constants/table';
import { IAtomTableHeaderColumn } from 'portal-common-library/interfaces/table';

import {
  IGameAssignmentDropdownDefaults,
  IUserTableData,
  IUserTableState,
  TUserGameRoleValues,
} from './interfaces';

export const DEFAULT_USER_PAGE_SIZE = 10;

export enum TABLE_SORT_ATTRIBUTES {
  ID = 'id',
  EMAIL = 'email',
  SKILLZ_SUPPORT = 'skillz_support',
}

export const INITIAL_TABLE_STATE: IUserTableState = {
  page: 0,
  offset: 0,
  sortAttribute: TABLE_SORT_ATTRIBUTES.EMAIL,
  sortDirection: SORT_DIRECTION.ASC,
};

export const EMAIL_HEAD_ROW: IAtomTableHeaderColumn<TABLE_SORT_ATTRIBUTES> = {
  id: 'header-email',
  cellContent: 'User',
  property: TABLE_SORT_ATTRIBUTES.EMAIL,
};

export const ACTION_HEAD_ROW: IAtomTableHeaderColumn<TABLE_SORT_ATTRIBUTES> = {
  id: 'header-action',
  cellContent: '',
};

export const USER_ROLE_HEAD_ROW: IAtomTableHeaderColumn<TABLE_SORT_ATTRIBUTES> = {
  id: 'header-user-role',
  cellContent: '',
};

export const GAME_ROLE_HEAD_ROW: IAtomTableHeaderColumn<TABLE_SORT_ATTRIBUTES> = {
  id: 'header-game-role',
  cellContent: 'Role',
};

export const GAMES_HEAD_ROW: IAtomTableHeaderColumn<TABLE_SORT_ATTRIBUTES> = {
  id: 'header-games',
  cellContent: 'Game',
};

export const SKILLZ_SUPPORT_HEAD_ROW: IAtomTableHeaderColumn<TABLE_SORT_ATTRIBUTES> = {
  id: 'header-is-skillz-support',
  cellContent: 'Skillz Support',
  property: TABLE_SORT_ATTRIBUTES.SKILLZ_SUPPORT,
};

export const TABLE_DATA_ORDER: (keyof IUserTableData)[] = [
  'user',
  'skillzSupport',
  'userRole',
  'gameRole',
  'games',
  'action',
];

export enum MENU_ITEM_ATTRIBUTES {
  REMOVE = 'remove',
  CANCEL = 'cancel',
  TRANSFER = 'transfer',
  EDIT = 'edit',
}

export enum MENU_ITEM_COPY {
  REMOVE = 'Remove User',
  CANCEL = 'Cancel Invite',
  TRANSFER = 'Transfer Ownership',
  EDIT = 'Edit User',
}

export enum TABLES {
  ACTIVE_USERS = 'activeUsersTable',
  INVITED_USERS = 'invitedUsersTable',
}

export const INITIAL_USER_GAME_ROLES: TUserGameRoleValues = {
  analytics: false,
  billing: false,
  developer: false,
  publisher: false,
};

export enum ROLE_NAMES {
  analytics = 'Analytics',
  billing = 'Billing',
  developer = 'Developer',
  publisher = 'Publisher',
}

export const GAME_ASSIGNMENT_DROPDOWN_DEFAULTS: IGameAssignmentDropdownDefaults = {
  shouldSelectAllGames: true,
  selectAllGamesText: 'All Games',
  dropdownPlaceholder: 'Select Games',
  limitTags: 2,
};

export const TOOLTIP_TYPOGRAPHY_STYLE = { fontSize: '14px', padding: '16px' };

export const TOOLTIP_BOLD_TEXT_STYLE = { fontSize: '14px', fontWeight: 'bold' };
