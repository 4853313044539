import * as React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import withStyles from '@mui/styles/withStyles';

import { TooltipNoAccess } from '@app/common/components';
import variables from '@app/core/variables.scss';

import { TMouseEventDiv } from '../interfaces';
import './styles/atomMenuStyles.scss';

export interface IMenuItem {
  attributeName: string;
  itemBody: string | JSX.Element;
  handleItemClick: () => void;
  hasAccess?: boolean;
}

export interface IAtomMenuProps {
  id: string;
  menuItems: IMenuItem[];
  anchorEl: Element | null;
  isOpen: boolean;
  handleCloseMenu: (event: TMouseEventDiv) => void;
  anchorPosition?: 'left' | 'right' | 'center' | number;
  transformPosition?: 'left' | 'right' | 'center' | number;
}

type TPositionsOptions = 'left' | 'right' | 'center' | number;
type TDefaultProps = Required<Pick<IAtomMenuProps, 'anchorPosition' | 'transformPosition'>>;

interface IPosition {
  vertical: 'top' | 'bottom';
  horizontal: TPositionsOptions;
}

const StyledMenuItem = withStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: `${variables.colorBlueHoverLight}`,
    },
    '&:active': {
      backgroundColor: `${variables.colorInfoBorder}`,
    },
  },
}))(MenuItem);

class AtomMenu extends React.PureComponent<IAtomMenuProps> {
  public static defaultProps: TDefaultProps = {
    anchorPosition: 'right',
    transformPosition: 'right',
  };

  public render(): JSX.Element {
    let {
      id,
      anchorEl,
      menuItems,
      isOpen,
      handleCloseMenu,
      transformPosition,
      anchorPosition,
    } = this.props;

    return (
      <Menu
        id={id}
        className="atom-menu__menu"
        classes={{
          paper: 'atom-component__menu-paper',
        }}
        anchorEl={anchorEl}
        open={isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: transformPosition } as IPosition}
        transformOrigin={{ vertical: 'top', horizontal: anchorPosition } as IPosition}
        onClose={handleCloseMenu}
      >
        {menuItems.map((item: IMenuItem) => {
          // default is true
          const hasAccess = item.hasAccess !== false;

          return (
            <div key={item.attributeName}>
              <TooltipNoAccess active={!hasAccess}>
                <StyledMenuItem
                  id={`${id}-menu-item-${item.attributeName}`}
                  className="atom-menu__menu-item"
                  onClick={item.handleItemClick}
                  disabled={!hasAccess}
                >
                  {item.itemBody}
                </StyledMenuItem>
              </TooltipNoAccess>
            </div>
          );
        })}
      </Menu>
    );
  }
}

export { AtomMenu };
