import { DISCORD_INVITE_LOCATION } from '../components/DiscordInvite';

const determineActionPrefix = (actionString: string) => {
  const isStaging = window.location.host !== 'developers.skillz.com';

  return isStaging ? `staging_${actionString}` : actionString;
};

// google analytics event examples are all in snake case with all lower cases
export const GOOGLE_ANALYTICS_4 = {
  LANDING_PAGE: {
    CATEGORY: 'landing_page',
    ACTIONS: {
      VIEW: determineActionPrefix('view_landing_page'),
    },
  },
  PUBLISHER: {
    CATEGORY: 'publisher',
    ACTIONS: {
      SIGN_UP: determineActionPrefix('sign_up'),
      FIRST_START_INTEGRATION: determineActionPrefix('first_start_integration'),
      FIRST_SDK_DOWNLOAD: determineActionPrefix('first_sdk_download'),
      FIRST_INTEGRATION_COMPLETE: determineActionPrefix('first_integration_complete'),
      FIRST_UPLOAD_BINARY: determineActionPrefix('first_upload_binary'),
      FIRST_DISTRIBUTION_COMPLETE: determineActionPrefix('first_distribution_complete'),
    },
  },
  USER: {
    CATEGORY: 'user',
    ACTIONS: {
      LEARNING_CENTER_CARD_CLICK: (category: string, name: string) => {
        return determineActionPrefix(
          `lc_${category.replace(/ /g, '_')}_${name.replace(/ /g, '_')}`
        );
      },
      DISCORD_INVITE_CLICK: (location: DISCORD_INVITE_LOCATION) => {
        return determineActionPrefix(`discord_${location}_invite`);
      },
      QUANTUM_PACKAGE_DOWNLOAD: (location: string) => {
        return determineActionPrefix(`quantum_package_${location}`);
      },
    },
  },
};
