import { Dispatch } from 'redux';
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import axios, { AxiosResponse, AxiosError } from '@app/common/setup/axiosWithHeader';
import {
  TDispatch,
  IAction,
  IVerification,
  IVerificationParams,
  IErrors,
  TErrorGeneral,
  TAxiosPromise
} from '@app/common/interfaces';
import { GAME_STATES } from '../constants';
import { snakeCaseConverter } from '@app/common/helpers';

export enum VERIFICATION {
  RECEIVE_DATA = 'verification/RECEIVE_DATA'
}

const verifiedFairness: IVerificationParams = {
  fairnessVerification: true,
  stateName: GAME_STATES.FAIRNESS
};

export function receiveVerification(data: IVerification): IAction<IVerification> {
  return {
    type: VERIFICATION.RECEIVE_DATA,
    data
  };
}

export function fetchVerification(gameId: number): TDispatch {
  return (dispatch: Dispatch<IAction<IVerification>>): TAxiosPromise<void> => {
    return axios
      .get(`/api/v1/games/${gameId}/verification`)
      .then(camelize)
      .then((response: AxiosResponse<IVerification>) => {
        dispatch(receiveVerification(response.data));
      })
      .catch(
        (error: AxiosError<IErrors>): TErrorGeneral => {
          return error.response ? error.response.data : error;
        }
      );
  };
}

export function updateVerification(gameId: number, data: IVerificationParams): TDispatch {
  return (dispatch: Dispatch<IAction<IVerification>>): TAxiosPromise<void> => {
    return axios
      .put(`/api/v1/games/${gameId}/verification`, { verification: snakeCaseConverter(data) })
      .then(camelize)
      .then((response: AxiosResponse<IVerification>) => {
        dispatch(receiveVerification(response.data));
      })
      .catch(
        (error: AxiosError<IErrors>): TErrorGeneral => {
          return error.response ? error.response.data : error;
        }
      );
  };
}

export function verifyFairness(gameId: number): TDispatch {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: Dispatch<any>): Promise<any> => {
    /* eslint-enable @typescript-eslint/no-explicit-any */
    // TODO REPLACE [WS-10403] remove the below disable and fix error
    // eslint-disable-next-line @typescript-eslint/await-thenable
    return await dispatch(updateVerification(gameId, verifiedFairness));
  };
}
