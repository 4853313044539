export const GAMES_SKILLZ_URL = 'https://games.skillz.com/game/';
export const GAMES_SKILLZ_URL_GAMES = 'https://games.skillz.com/games';

export const SKILLZ_URL = (): string => window.appConfig?.environment.skillzUrl ?? '';

export const NFL_CHALLENGE_URL = () => `${SKILLZ_URL()}/nflchallenge/`;
export const NFL_TERMS_CONDITIONS_URL = () => `${SKILLZ_URL()}/nflchallenge-official-rules/`;

export const UFC_GAME_URL = () => `${SKILLZ_URL()}/ufcgame/`;

const determineSkillzDocsLink = (docSubPage: string): string => {
  // index page for Skillz doc is just SKILLZ_DOC_BASE_URL
  // url ending with `/docs/` is a 404 page
  const subUrl = docSubPage ? `docs/${docSubPage}` : '';

  return `${window.appConfig.environment.skillzDocBaseUrl}/${subUrl}`;
};

export const SKILLZ_DOCS_RELEASE_NOTES_URL = () => determineSkillzDocsLink('release-notes/');

export const SKILLZ_DOCS_PLAYER_DATA_API_URL = () =>
  determineSkillzDocsLink('prog-overview/#progression-namespaces');

export const SKILLZ_DOCS_STANDARD_GAMEPLAY_URL = () =>
  determineSkillzDocsLink('play-and-compare-gameplay/');

export const SKILLZ_DOCS_IN_GAME_ITEM_URL = () =>
  determineSkillzDocsLink('virtual-rewards-in-game-items');

export const SKILLZ_DOCS_PROGRESSION_API_URL = () =>
  determineSkillzDocsLink('api-unity/#progression-methods');

export const SKILLZ_DOCS_REAL_TIME_FTUE_URL = () =>
  determineSkillzDocsLink('next/first-time-user-experience');

export const SKILLZ_DOCS_BASE_URL = () => determineSkillzDocsLink('');

export const GDWC_BACKGROUND_COLOR = '#184c6c';
export const GDWC_BLOG_URL = 'https://www.skillz.com/exciting-new-skillz-categories-for-gdwc-2023/';

export const enum DC_RELATIVE_URLS {
  HOME = '/',
  PROGRESSION_AND_PLAYER_DATA_CASE_STUDY = '/learning_center/progression_and_player_data_case_study',
}

export const PLAY_AND_COMPARE_TOURNAMENT_DOCS =
  'https://docs.skillz.com/docs/tournaments-and-gameplay-parameters/';

export const REAL_TIME_TOURNAMENT_DOCS = 'https://docs.skillz.com/docs/real-time-setup/';
