import * as React from 'react';

import PhotoSizeSelectActual from '@mui/icons-material/PhotoSizeSelectActual';

import grayCheckMark from '@app/imageAssets/checkGrayIconWhiteFill.svg';
import greenCheckMark from '@app/imageAssets/checkGreenIconWhiteFill.svg';
import giftIcon from '@app/imageAssets/Overview/giftColorIcon.svg';
import giftShadedIcon from '@app/imageAssets/Overview/giftShadedIcon.svg';
import errorIcon from '@app/imageAssets/Overview/PrizeEnablementProgram/error.svg';

import { PRIZE_ENABLEMENT_STEPS_STATE } from '../constants';
import { IPathIconProps } from '../interfaces';
import './styles/pathIconStyles.scss';

class PathIcon extends React.PureComponent<IPathIconProps> {
  public renderIcon = (): JSX.Element => {
    const { state, icon } = this.props;

    switch (state) {
      case PRIZE_ENABLEMENT_STEPS_STATE.PENDING:
        return <img className="icon" src={grayCheckMark} />;
      case PRIZE_ENABLEMENT_STEPS_STATE.PASSED:
        return <img className="icon" src={greenCheckMark} />;
      case PRIZE_ENABLEMENT_STEPS_STATE.ACTION_REQUIRED:
        return <img className="icon" src={errorIcon} />;
      case PRIZE_ENABLEMENT_STEPS_STATE.ICON_GIFT_COLOR:
        return <img className="icon-gift" src={giftIcon} />;
      case PRIZE_ENABLEMENT_STEPS_STATE.ICON_GIFT_SHADE:
        return <img className="icon-gift" src={giftShadedIcon} />;
      default:
        if (icon) {
          return <img className="icon-game" src={icon} />;
        }

        return <PhotoSizeSelectActual className="icon-game-default" />;
    }
  };

  public render = (): JSX.Element => {
    return (
      <div className={'overview-prize-enablement__path-icon-container'}>{this.renderIcon()}</div>
    );
  };
}

export { PathIcon };
