export enum META_TAGS_ATTRIBUTES {
  IOS = 'ios',
  CROSS_PLATFORM = 'cross_platform',
  ANDROID = 'android',
  UNITY = 'unity',
  CORDOVA = 'cordova',
  X_CODE = 'xcode',
  ANDROID_STUDIO = 'android_studio',
  CASH_AND_VIRTUAL_CURRENCY = 'cash_and_virtual_currency',
  VIRTUAL_CURRENCY_ONLY = 'virtual_currency_only',
  PLAY_AND_COMPARE = 'play_and_compare',
  HYBRID = 'hybrid',
  REAL_TIME_ONLY = 'real_time_only',
}
