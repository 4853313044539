import * as React from 'react';

import debounce from 'lodash/debounce';
import { IBaseProps } from 'portal-common-library/interfaces/componentBase';

import {
  hasAtLeastOneNumber, hasAtLeastOneSpecialCharacter, hasBothUpperAndLowerCaseLetters,
  hasMinimumEightCharacters, hasOnlyAsciiCharacters, hasSubstring
} from '@app/common/helpers';
import { PasswordTextField } from '@app/common/molecules';

import { PASSWORD_CHECKLIST_ORDER } from './constants';
import { IPasswordChecklist, IPasswordChecklistOrder } from './interfaces';
import './styles.scss';
import {
  failedChecklistItem, passingChecklistItem, pendingChecklistItem
} from './subcomponents/passwordChecklistItems';

export interface IPasswordWrapperProps extends IBaseProps {
  attributeName: string;
  autoComplete?: string;
  errorText?: string;
  InputProps?: { [key: string]: JSX.Element };
  hasError?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isMultiline?: boolean;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
  isRequired?: boolean;
  rows?: number;
  value: string;
  variant?: 'outlined' | 'standard' | 'filled';
  checklistProps?: {
    handleChecklistUpdate: (isChecklistDone: boolean, shouldRenderChecklist: boolean) => void;
    hasSubmitted: boolean;
    shouldRenderChecklist: boolean;
  } | null;
}

export interface IPasswordWrapperState {
  checklist: IPasswordChecklist;
}

type TDefaultPropKeys = 'checklistProps';

type TDefaultProps = Required<Pick<IPasswordWrapperProps, TDefaultPropKeys>>;

class PasswordWrapper extends React.Component<IPasswordWrapperProps, IPasswordWrapperState> {
  public static defaultProps: TDefaultProps = {
    checklistProps: null
  };

  constructor(props: IPasswordWrapperProps) {
    super(props);
    this.state = {
      checklist: {
        hasBothUpperAndLowerCaseLetters: false,
        hasAtLeastOneNumber: false,
        hasMinimumEightCharacters: false,
        hasAtLeastOneSpecialCharacter: false
      }
    };
  }

  public static extraValidation = (password: string, email: string): string => {
    let emailPrefix: string = email.split('@')[0];
    // need to check if the emailPrefix exist or will see this error if email is empty
    if (!!emailPrefix && hasSubstring(password, emailPrefix)) {
      return 'Should not include email';
    }

    if (!hasOnlyAsciiCharacters(password)) {
      return 'Should only have valid characters (ASCII)';
    }

    return '';
  };

  public onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.onChange(e);

    debounce(() => {
      if (!!this.props.checklistProps) {
        // changing the state of checklist AND if the checklist should render
        this.props.checklistProps.handleChecklistUpdate(this.isChecklistDone(), true);
      }
    }, 100)();
  };

  // validates the checklist and returns if checklist is done
  public isChecklistDone = (): boolean => {
    let password = this.props.value;
    let isPassingBothUpperLowerCase: boolean = hasBothUpperAndLowerCaseLetters(password);
    let isPassingHasAtLeastOneNum: boolean = hasAtLeastOneNumber(password);
    let isPassingMinimumEightCharacters: boolean = hasMinimumEightCharacters(password);
    let isPassingAtLeastOneSpecialCharacter: boolean = hasAtLeastOneSpecialCharacter(password);

    this.setState({
      checklist: {
        hasBothUpperAndLowerCaseLetters: isPassingBothUpperLowerCase,
        hasAtLeastOneNumber: isPassingHasAtLeastOneNum,
        hasMinimumEightCharacters: isPassingMinimumEightCharacters,
        hasAtLeastOneSpecialCharacter: isPassingAtLeastOneSpecialCharacter
      }
    });

    return (
      isPassingBothUpperLowerCase &&
      isPassingHasAtLeastOneNum &&
      isPassingMinimumEightCharacters &&
      isPassingAtLeastOneSpecialCharacter
    );
  };

  public renderChecklist = (): JSX.Element[] => {
    return PASSWORD_CHECKLIST_ORDER.map((item: IPasswordChecklistOrder, index: number) => {
      let isPassing: boolean = this.state.checklist[item.checklistItem];
      let last: string = index === PASSWORD_CHECKLIST_ORDER.length - 1 ? 'last' : '';

      let checklistItem = pendingChecklistItem(item.text);

      if (isPassing) {
        checklistItem = passingChecklistItem(item.text);
      }
      if (!isPassing && this.props.checklistProps?.hasSubmitted) {
        checklistItem = failedChecklistItem(item.text);
      }

      return (
        <div
          key={`password-text-field__${item.checklistItem}`}
          className={`password-text-field__checklist-item-padding ${last}`}
        >
          {checklistItem}
        </div>
      );
    });
  };

  public render(): JSX.Element {
    let shouldRenderChecklist = false;

    let propOverride = {};
    if (!!this.props.checklistProps) {
      propOverride = {
        onChange: this.onChange
      };
      shouldRenderChecklist = this.props.checklistProps.shouldRenderChecklist;
    }

    return (
      <>
        <PasswordTextField {...this.props} {...propOverride} />
        {shouldRenderChecklist && (
          <div className="password-text-field__checklist" data-qa="password-text-field__checklist">
            {this.renderChecklist()}
          </div>
        )}
      </>
    );
  }
}

export default PasswordWrapper;
