import { Component } from 'react';

import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { clearGameInfo, fetchGames, openArchiveModal, openModal } from '@app/common/actions';
import { IWithSnackbarProp } from '@app/common/helpers/snackbarHookHelper';
import { IRootState } from '@app/core/rootReducer';

const YourGames = loadable(
  () => import(/* webpackChunkName: "your-games" */ './YourGames')
) as typeof Component;

const mapStateToProps = (state: IRootState) => {
  let { games, feature, permissions } = state;

  return {
    games,
    feature,
    permissions,
  };
};

const mapDispatchToProps = {
  openArchiveModal,
  clearGameInfo,
  fetchGames,
  openModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TYourGamesConnectedProps = ConnectedProps<typeof connector> &
  RouteComponentProps &
  IWithSnackbarProp;

export default connector(withRouter(YourGames));
