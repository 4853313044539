import merge from 'lodash/merge';

import { IAction } from '@app/common/interfaces';

import { CONTACT_US } from './actions';
import { SELECT_TEXT } from './constants';

export enum CONTACT_US_STORE {
  GAMES = 'games', // TODO[WS-9967] Remove games
  SDK_VERSIONS = 'sdkVersions'
}

export interface IReducerState {
  [CONTACT_US_STORE.SDK_VERSIONS]: string[];
}

let initialState: IReducerState = {
  [CONTACT_US_STORE.SDK_VERSIONS]: []
};

const contactUsReducer = (
  state: IReducerState = initialState,
  action: IAction<string[]>
): IReducerState => {
  Object.freeze(state);

  let newState: IReducerState = merge({}, state);

  switch (action.type) {
    case CONTACT_US.RECEIVE_SDK_VERSIONS:
      newState = merge({}, state);
      action.data.unshift(SELECT_TEXT + 'SDK version');
      newState[CONTACT_US_STORE.SDK_VERSIONS] = action.data;

      return newState;
    default:
      return state;
  }
};

export default contactUsReducer;
