// @ts-ignore: camelize package is not typed
import camelize from 'camelize';

import { TDispatch, TThunkAction, IUserInfo, TErrorGeneral } from '@app/common/interfaces';
import axios from "axios";

export enum USER {
  INFO = 'user/INFO',
  UPDATE = 'user/UPDATE',
}

interface IUserInfoData {
  type: USER;
  data: Record<string, unknown>;
}

export const getUserInfo = (data: Record<string, unknown>): IUserInfoData => {
  return {
    type: USER.INFO,
    data,
  } as IUserInfoData;
};

export const findUser: TThunkAction =
  (): TDispatch =>
  (dispatch: React.Dispatch<unknown>): Promise<IUserInfoData> | TErrorGeneral => {
    return $.ajax({
      url: `/user_info.json`,
      method: 'GET',
    }).then((response: IUserInfo) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(getUserInfo(camelize(response)));

      return response;
    });
  };

export const getUser = async (): Promise<IUserInfo> => {
  try {
    return (await axios.get('/user_info.json'));
  } catch (err) {
    return Promise.reject(err);
  }
};
