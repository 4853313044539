import { parseISO, startOfDay } from 'date-fns';
import { ANALYTICS } from '@app/common/actions';
import { IAction, IDateRange } from '@app/common/interfaces';

type TAction = IAction<IDateRange<string>>;

function dateRangeLimit(
  state: IDateRange<Date> = { startDate: new Date(), endDate: new Date() },
  action: TAction
): IDateRange<Date> {
  Object.freeze(state);

  switch (action.type) {
    case ANALYTICS.RECEIVE_DATE_RANGE_LIMIT:
      return {
        startDate: startOfDay(parseISO(action.data.startDate)),
        endDate: startOfDay(parseISO(action.data.endDate))
      };

    default:
      return state;
  }
}

export { dateRangeLimit };
