import {
  IProgressionRoomVersionLocaleValidation,
  IStateProgressionRoomVersionLocaleBodyImage,
} from '@appEntryPoints/common/interfaces';

import { emptyConditionValidation } from '../../modules/Accordion/constants';

export const existingConditionCounter = (validation: IProgressionRoomVersionLocaleValidation) => {
  let conditionValidationCount = 0;

  validation.bodyImagesWithCondition.forEach(
    (bodyImageValidations) => (conditionValidationCount += bodyImageValidations.length)
  );

  return conditionValidationCount;
};

export const newConditionCounterAndNewValidationObject = (
  bodyImagesWithCondition: IStateProgressionRoomVersionLocaleBodyImage[]
) => {
  let newConditionsCount = 0;

  // create validation for each condition within each image
  const bodyImagesWithConditionValidations = bodyImagesWithCondition.map((bodyImageWithCondition) =>
    (bodyImageWithCondition.condition ?? []).map(() => {
      newConditionsCount++;

      return emptyConditionValidation[0];
    })
  );

  return { newConditionsCount, bodyImagesWithConditionValidations };
};
