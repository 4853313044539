import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Close from '@mui/icons-material/Close';

import './styles/atomModalStyles.scss';

interface IProps {
  isOpen: boolean;
  id: string;
  handleClose: () => void;
  children: JSX.Element;
  shouldShowClose?: boolean;
  shouldDisableBackdropClick?: boolean;
}
type TOptionalProps = 'shouldShowClose' | 'shouldDisableBackdropClick';
type TDefaultProps = Pick<IProps, TOptionalProps>;

class AtomModal extends React.PureComponent<IProps> {
  public static defaultProps: TDefaultProps = {
    shouldShowClose: true,
    shouldDisableBackdropClick: false,
  };

  public render(): JSX.Element {
    let {
      children,
      isOpen,
      handleClose,
      id,
      shouldShowClose,
      shouldDisableBackdropClick,
    } = this.props;

    const onCloseWrapper = () => {
      return (_event: unknown, reason: string): void => {
        if (shouldDisableBackdropClick && ['backdropClick', 'escapeKeyDown'].includes(reason)) {
          return;
        }
        handleClose();
      };
    };

    return (
      <Modal open={isOpen} onClose={onCloseWrapper()}>
        <div id={`${id}__modal-container`} className="atom-component__container">
          {shouldShowClose && (
            <IconButton
              onClick={handleClose}
              id={`${id}__modal-close-button`}
              className="atom-component__close-button"
              size="large">
              <Close />
            </IconButton>
          )}
          <div id={`${id}__modal-contents`} className="atom-component__contents">
            {children}
          </div>
        </div>
      </Modal>
    );
  }
}

export { AtomModal };
