import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { LOCALE } from '@app/common/constants';
import {
  TStateProgressionRoomVersionLocale,
  TStateProgressionRoomVersionLocales,
} from '@appEntryPoints/common/interfaces';
import { initialRoomVersionLocaleState } from '@appGameProgression/modules/EntryPoints/modules/EntryPoint/constants';

export const determineLocaleCopyToUse = (
  locale: LOCALE,
  stateVersionLocales: TStateProgressionRoomVersionLocales
) => {
  return !!stateVersionLocales[locale]
    ? (stateVersionLocales[locale] as TStateProgressionRoomVersionLocale)
    : initialRoomVersionLocaleState(locale);
};

export const determineLocalesUpdateTo = (
  currentLocale: LOCALE,
  currentVersionLocale: TStateProgressionRoomVersionLocale,
  stateVersionLocales: TStateProgressionRoomVersionLocales
) => {
  const isCurrentVersionLocaleSameAsInitialState = versionLocaleMatchInitialState(
    currentLocale,
    currentVersionLocale
  );

  const isCurrentVersionLocaleSameAsCopyInState = versionLocaleMatchState(
    currentLocale,
    currentVersionLocale,
    stateVersionLocales
  );

  // it has changed if it is not same as the latest/edited copy of locale in state
  // or initial locale state
  const hasChangeInCurrentVersionLocale = !(
    isCurrentVersionLocaleSameAsInitialState || isCurrentVersionLocaleSameAsCopyInState
  );

  if (hasChangeInCurrentVersionLocale) {
    let newRoomVersionLocales = cloneDeep(stateVersionLocales);
    newRoomVersionLocales[currentLocale] = currentVersionLocale;

    return newRoomVersionLocales;
  }

  return null;
};

export const versionLocaleMatchInitialState = (
  currentLocale: LOCALE,
  currentVersionLocale: TStateProgressionRoomVersionLocale
) => {
  const emptyCopy = initialRoomVersionLocaleState(currentLocale);

  return isEqual(currentVersionLocale, emptyCopy);
};

export const versionLocaleMatchState = (
  currentLocale: LOCALE,
  currentVersionLocale: TStateProgressionRoomVersionLocale,
  stateVersionLocales: TStateProgressionRoomVersionLocales
) => {
  return (
    !!stateVersionLocales[currentLocale] &&
    isEqual(currentVersionLocale, stateVersionLocales[currentLocale])
  );
};

export const removeEmptyFromStateLocales = (
  currentLocale: LOCALE,
  currentVersionLocale: TStateProgressionRoomVersionLocale,
  stateVersionLocales: TStateProgressionRoomVersionLocales
) => {
  const isCurrentVersionLocaleSameAsInitialState = versionLocaleMatchInitialState(
    currentLocale,
    currentVersionLocale
  );

  if (
    currentLocale !== LOCALE.EN &&
    isCurrentVersionLocaleSameAsInitialState &&
    stateVersionLocales[currentLocale]
  ) {
    let newRoomVersionLocales = cloneDeep(stateVersionLocales);
    delete newRoomVersionLocales[currentLocale];

    return newRoomVersionLocales;
  }

  return null;
};
