/* eslint-disable */
import * as React from 'react';

import FormControl from '@mui/material/FormControl';

import { AtomButton, TButtonType } from '@app/common/atoms';

import './styles/materialFileUploadStyles.scss';

interface IDefaultProps {
  label: string;
  type: string;
  accept: string;
  buttonText: string;
  buttonType: TButtonType;
  classes: {
    [className: string]: string;
  };
}

export interface IFileUploadProps extends IDefaultProps {
  id: string;
  fileName: string;
  onChange: any;
}

// TODO DELETE [WS-10318] replace with AtomFileUploader
class MaterialFileUpload extends React.PureComponent<IFileUploadProps> {
  public static defaultProps: IDefaultProps = {
    label: '',
    type: 'file',
    buttonText: 'Upload',
    accept: '*',
    buttonType: 'tertiary',
    classes: {},
  };

  public onClick = (): void => {
    document.getElementById(`material-ui-file-input-${this.props.id}`)!.click();
  };

  public render(): JSX.Element {
    let { type, buttonText, fileName, onChange, id, classes, accept, label } = this.props;

    return (
      <FormControl className="material-ui-file-upload-center" id={id}>
        {label !== '' && (
          <div className="material-ui-file-upload-label" id={`${id}-label`}>
            {label}
          </div>
        )}
        <div className="material-ui-file-upload-input">
          <input
            type={type}
            accept={accept}
            style={{ display: 'none' }}
            id={`material-ui-file-input-${id}`}
            onChange={onChange}
            value=""
          />
          <AtomButton
            id={`${id}-button`}
            buttonContent={buttonText}
            buttonType="tertiary"
            onClick={this.onClick}
          />

          <br />
          <div className="material-ui-file-upload-file-name-container">
            <p id={`${id}-file-name`} className="material-ui-file-upload-file-name">
              &nbsp;{fileName}
            </p>
          </div>
        </div>
      </FormControl>
    );
  }
}

export { MaterialFileUpload };
