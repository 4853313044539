import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { ThemeProvider, Theme, createTheme } from '@mui/material/styles';

import { AtomButton } from '@app/common/atoms';
import { ISnackbarData } from '@app/common/interfaces';
import checkGreen from '@app/imageAssets/checkGreenIcon.svg';

import './styles/atomSnackbarStyles.scss';

export interface IProps extends Required<ISnackbarData> {
  handleClose: () => void;
  buttonContent?: string | JSX.Element;
  isOpen: boolean;
  id: string;
  handleClick?: () => void;
  backgroundColor?: string;
  textColor?: string;
  text: string | JSX.Element;
}

type TDefaultPropKeys = 'backgroundColor' | 'textColor';
type TDefaultProps = Required<Pick<IProps, TDefaultPropKeys>>;

class AtomSnackbar extends React.PureComponent<IProps> {
  public static defaultProps: TDefaultProps = {
    backgroundColor: '#ffffff',
    textColor: '#2e323a',
  };

  public static renderSuccessContent(title: string, description: string): JSX.Element {
    return (
      <React.Fragment>
        <div className="atom-component__snackbar-success">
          <div className="atom-component__snackbar-success-header">
            <img className="atom-component__snackbar-success-icon" src={checkGreen} />
            <h4>{title}</h4>
          </div>
          <p>{description}</p>
        </div>
      </React.Fragment>
    );
  }

  public render(): JSX.Element {
    let {
      handleClose,
      handleClick,
      buttonContent,
      isOpen,
      text,
      anchorOriginVertical,
      anchorOriginHorizontal,
      id,
      autoHideDuration,
      backgroundColor,
      textColor,
    } = this.props;

    const theme: Theme = createTheme({
      components: {
        MuiSnackbarContent: {
          styleOverrides: {
            root: {
              backgroundColor,
            },
            message: {
              color: textColor,
            },
          },
        },
      },
    });

    let snackbarContent: JSX.Element | null = null;

    if (handleClick && buttonContent) {
      snackbarContent = (
        <AtomButton
          id={`${id}-cta-button`}
          key="CTA"
          buttonType="tertiary"
          buttonContent={buttonContent}
          onClick={handleClick}
        />
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <div data-qa="atom-component__snackbar-wrapper">
          <Snackbar
            anchorOrigin={{
              vertical: anchorOriginVertical,
              horizontal: anchorOriginHorizontal,
            }}
            open={isOpen}
            onClose={handleClose}
            autoHideDuration={autoHideDuration}
            className="atom-component__snackbar"
          >
            <SnackbarContent
              className="atom-component__snackbar-content mui-override"
              message={
                <span id={`${id}-message`} className="atom-component__snackbar-content-message">
                  {text}
                </span>
              }
              action={[
                snackbarContent,
                <IconButton
                  id={`${id}-close-icon`}
                  data-qa={`${id}-close-icon`}
                  key="close"
                  onClick={handleClose}
                  className="atom-component__snackbar-icon close"
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          </Snackbar>
        </div>
      </ThemeProvider>
    );
  }
}

export { AtomSnackbar };
