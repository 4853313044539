import { IMenuItem } from '@app/common/atoms';

export enum MENU_ITEM_ATTRIBUTES {
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
  MANAGE = 'manage',
  DEFAULT = 'default'
}

export enum MENU_ITEM_COPY {
  ARCHIVE = 'Archive',
  UNARCHIVE = 'Unarchive',
  MANAGE = 'Manage game',
  DEFAULT = 'default'
}

export const DEFAULT_MENU_ITEMS: IMenuItem[] = [
  {
    attributeName: MENU_ITEM_ATTRIBUTES.DEFAULT,
    itemBody: MENU_ITEM_COPY.DEFAULT,
    handleItemClick: (): void => {
      return;
    }
  }
];
