import * as React from 'react';

import AtomButton from 'portal-common-library/atoms/AtomButton';
import PulseLoader from 'react-spinners/PulseLoader';

import variables from '@app/core/variables.scss';

import './styles/buttonWithLoader.scss';

interface IButtonWithLoaderProps {
  loading: boolean;
}

type TButtonWithLoaderProps = React.ComponentProps<typeof AtomButton> & IButtonWithLoaderProps;

class ButtonWithLoader extends React.PureComponent<TButtonWithLoaderProps> {
  public static defaultProps = {
    isDisabled: false,
    size: 'large',
    isErrorMode: false
  };

  public render(): JSX.Element {
    let {
      onClick,
      buttonContent,
      buttonType,
      classes,
      id,
      size,
      loading: isLoading,
      isDisabled,
      isErrorMode,
      dataAttributes
    } = this.props;

    if (isLoading) {
      return (
        <div className="atom-component__button-with-loader">
          <div className="atom-component__button-with-loader__loader">
            <PulseLoader size={8} color={variables.colorBrandBlue} />
          </div>
          <AtomButton
            id={`${id}-hidden`}
            size={size}
            buttonContent={buttonContent}
            buttonType="tertiary"
            isErrorMode={isErrorMode}
            isDisabled={true}
            dataAttributes={{ 'data-qa': `${dataAttributes['data-qa']}-hidden` }}
            onClick={() => null}
          />
        </div>
      );
    } else {
      return (
        <AtomButton
          id={id}
          size={size}
          buttonContent={buttonContent}
          buttonType={buttonType}
          onClick={onClick}
          classes={classes}
          isDisabled={isDisabled}
          isErrorMode={isErrorMode}
          dataAttributes={dataAttributes}
        />
      );
    }
  }
}

export { ButtonWithLoader };
