import { isString } from 'lodash';

import { IAnalyticInfo } from '@app/common/interfaces';
import {
  COMMON_ANALYTICS_METRICS,
  GAME_ANALYTICS_METRICS,
  GAME_ANALYTICS_REPLACED_METRICS,
  ESTIMATED_REVENUE_METRIC,
} from '@app/modules/Analytics/constants';

import { isEstimatedRevenueEnabled } from './chartHelper';

export function isFloat(n: string | number | null | undefined): boolean {
  if (!n || typeof n === 'string') {
    return false;
  }

  return n % 1 !== 0;
}

export function formatFileName(selectedGame: string, startDate: Date, endDate: Date): string {
  const upperSnakeCaseGame = selectedGame.toUpperCase().replace(/\s/g, '_');

  const startDateString = startDate.toISOString().split('T')[0].replace(/-/g, '_');
  const endDateString = endDate.toISOString().split('T')[0].replace(/-/g, '_');

  const upperSnakeDateRange = `${startDateString}__${endDateString}`;

  return upperSnakeCaseGame + '_' + upperSnakeDateRange + '.csv';
}

function getAnalyticsAttributes() {
  const attributes = {
    ...COMMON_ANALYTICS_METRICS,
    ...GAME_ANALYTICS_REPLACED_METRICS,
    ...GAME_ANALYTICS_METRICS,
  };

  return isEstimatedRevenueEnabled() ? { ...attributes, ...ESTIMATED_REVENUE_METRIC } : attributes;
}

export function formatAnalyticsToCsv(analytics: IAnalyticInfo[]): string[][] {
  const csvKeys: string[] = Object.values(getAnalyticsAttributes());
  const csvData: string[][] = [];
  csvData.push(csvKeys);

  analytics.forEach((analytic: IAnalyticInfo) => {
    const csvDataRow: string[] = [];

    Object.keys(getAnalyticsAttributes()).forEach((csvKey) => {
      // the value could be any IAnalytic Attribute
      let value: string | number | null | undefined = analytic[csvKey as keyof IAnalyticInfo];

      if (isString(value)) {
        value = `"${value}"`;
      }

      if (isFloat(value)) {
        value = (value as number).toFixed(2);
      }

      csvDataRow.push(value as string);
    });

    csvData.push(csvDataRow);
  });

  return csvData;
}
