import * as React from 'react';

import PhotoSizeSelectActual from '@mui/icons-material/PhotoSizeSelectActual';

import Dropzone, { DropzoneState } from 'react-dropzone';

import './styles/atomImageUploader.scss';

type TImage = File | string | null;
type TImageUploaderOptionalProps =
  | 'newIconLabel'
  | 'updateIconLabel'
  | 'containerSize'
  | 'fileTypes'
  | 'handleImageUpload'
  | 'isDisabled'
  | 'isRectangular';

export interface IImageUploaderProps {
  id: string;
  image: TImage;
  handleImageUpload: (image: File) => void;
  newIconLabel?: string;
  updateIconLabel?: string;
  containerSize?: number;
  fileTypes?: string[];
  isDisabled?: boolean;
  isRectangular?: boolean;
}

type IDefaultProps = Required<Pick<IImageUploaderProps, TImageUploaderOptionalProps>>;

class AtomImageUploader extends React.PureComponent<IImageUploaderProps & IDefaultProps> {
  public static defaultProps: IDefaultProps = {
    handleImageUpload: () => null,
    newIconLabel: 'Add Icon',
    updateIconLabel: 'Update',
    containerSize: 136,
    fileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'],
    isDisabled: false,
    isRectangular: false,
  };

  public handleImageDrop = (images: File[]): void => {
    this.props.handleImageUpload(images[0]);
  };

  public renderUploadIcon(): JSX.Element {
    let { id, newIconLabel, updateIconLabel, image, containerSize, isRectangular } = this.props;

    return (
      <div
        id={`${id}-image-uploader-default-icon-container`}
        className={`atom-component__image-uploader-default-icon-container`}
        style={{ height: containerSize, width: isRectangular ? '100%' : containerSize }}
      >
        <PhotoSizeSelectActual
          id={`${id}-image-uploader-upload-icon`}
          className="atom-component__image-uploader-upload-icon"
        />
        <p
          id={`${id}-image-uploader-upload-icon-label`}
          className="atom-component__image-uploader-upload-icon-label"
        >
          {image ? updateIconLabel : newIconLabel}
        </p>
      </div>
    );
  }

  public render(): JSX.Element {
    let { id, fileTypes, containerSize, image, isDisabled, isRectangular } = this.props;
    // if the image is a string then set src to the image url
    // if the image is null, set to undefined - this is done to match the src signature of <img>
    let src: string | undefined = image === null ? undefined : (image as string);

    return (
      <Dropzone
        onDrop={this.handleImageDrop}
        disabled={isDisabled}
        accept={fileTypes.join(',')}
        multiple={false}
      >
        {({ getRootProps, getInputProps }: DropzoneState): JSX.Element => (
          <section
            style={{ width: isRectangular ? '100%' : containerSize, height: containerSize }}
            {...getRootProps({
              className: 'atom-component__image-uploader-container',
              id: `${id}-image-uploader-container`,
            })}
          >
            <input {...getInputProps()} />
            <img
              src={src}
              className="atom-component__image-uploader-preview"
              id={`${id}-image`}
              style={{ height: containerSize, width: isRectangular ? '100%' : containerSize }}
            />
            {this.renderUploadIcon()}
          </section>
        )}
      </Dropzone>
    );
  }
}

export { AtomImageUploader };
