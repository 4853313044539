import * as React from 'react';

import kebabCase from 'lodash/kebabCase';

import checkGrayIconWhiteFill from '@app/imageAssets/checkGrayIconWhiteFill.svg';
import checkGreenIconWhiteFill from '@app/imageAssets/checkGreenIconWhiteFill.svg';
import redXCircle from '@app/imageAssets/redXCircle.svg';

import { passwordChecklistClassName } from '../constants';
import './passwordChecklistItems.scss';

export const pendingChecklistItem = (text: string): JSX.Element => {
  return (
    <div
      id={`password-checklist__${kebabCase(text)}-pending`}
      className={`${passwordChecklistClassName}__container pending`}
      data-qa="password-checklist__pending"
    >
      <img className="password-checklist__image" src={checkGrayIconWhiteFill} />
      {text}
    </div>
  );
};

export const passingChecklistItem = (text: string): JSX.Element => {
  return (
    <div
      id={`password-checklist__${kebabCase(text)}-passing`}
      className={`${passwordChecklistClassName}__container passing`}
      data-qa="password-checklist__passing"
    >
      <img className="password-checklist__image" src={checkGreenIconWhiteFill} />
      {text}
    </div>
  );
};

export const failedChecklistItem = (text: string): JSX.Element => {
  return (
    <div
      id={`password-checklist__${kebabCase(text)}-failed`}
      className={`${passwordChecklistClassName}__container failed`}
      data-qa="password-checklist__failed"
    >
      <img className="password-checklist__image" src={redXCircle} />
      {text}
    </div>
  );
};
