import axios, {
  AxiosResponse as AxiosResponseOriginal,
  AxiosError as AxiosErrorOriginal,
} from 'axios';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const token: string = document.querySelector("meta[name='csrf-token']")!.getAttribute('content')!;
axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

// this is to avoid separate imports.
export type AxiosResponse<T> = AxiosResponseOriginal<T>;
export type AxiosError<T> = AxiosErrorOriginal<T>;
export default axios;
