import { IAction } from 'portal-common-library/interfaces/redux';
import { ISnackbarData } from 'portal-common-library/interfaces/snackbar';

import { SNACK_BAR } from './snackbarActions';

export * from './gameActions';
export * from './gameChallengeActions';
export * from './gameChallengeProposalActions';
export * from './publisherActions';
export * from './userActions';
export * from './featureActions';
export * from './renderSwal';
export * from './snackbarActions';
export * from './modalManagementActions';
export * from './sdkDownloadActions';
export * from './analyticsActions';
export * from './countActions';
export * from './currentUser';
export * from './publisher';
export * from './permission';
export * from './gameAccessActions';

export function openSnackbar(options: ISnackbarData): IAction<SNACK_BAR, ISnackbarData> {
  return {
    type: SNACK_BAR.OPEN_SNACKBAR,
    data: options,
  };
}
