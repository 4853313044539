/* eslint-disable */
// IVT3 - NEED TO REMOVE CAMELIZE AND REPLACE THEME CONTROLLER WITH JBUILDER
// ONCE OLD INTEGRATION IS DEPRECATED
import camelize from 'camelize';
import merge from 'lodash/merge';

import { BASE_THEME, ADVANCED_THEME, CUSTOM_THEME } from './action';

let initialState = {
  1: {
    name: 'Dark',
    needsFetch: true,
  },
  2: {
    name: 'White',
    needsFetch: true,
  },
  3: {
    name: 'Emerald',
    needsFetch: true,
  },
  4: {
    name: 'Crimson',
    needsFetch: true,
  },
  5: {
    name: 'Cobalt',
    needsFetch: true,
  },
};

const customThemeReducer = (state = initialState, action) => {
  let newState;
  Object.freeze(state);
  switch (action.type) {
    case BASE_THEME.COLOR:
      let theme = camelize(action.theme);
      if (Object.values(action.theme)[0].json) {
        let key = Object.keys(action.theme)[0];
        let themeObject = Object.values(action.theme)[0];
        let parsedJson = JSON.parse(themeObject.json);

        // reducer to handle MERCURY prize view ticketz image and gradients
        if (!parsedJson.gradients['prizeViewTicketzGradient']) {
          let newGradient = {
            gradient: ['#f6604d', '#e05745'],
            type: 'Gradient',
            description: 'Prize view background on match select for ticketz currency matches',
          };
          parsedJson.gradients['prizeViewTicketzGradient'] = newGradient;
        }
        if (!parsedJson.images['prizeViewTicketzImage']) {
          let newImage = {
            type: 'Image',
            description:
              'Background Image used for match select ticketz prize container. Should be 240x240 jpg.',
          };
          parsedJson.images['prizeViewTicketzImage'] = newImage;
        }

        if (!parsedJson.images['prizeViewSyncImage']) {
          let newRealTimeMatchIconImage = {
            type: 'Image',
            description:
              'Custom image for the prize box tile for Real Time Matches. Should be ~200x200px png. File must be less than 500 KB.',
          };
          parsedJson.images['prizeViewSyncImage'] = newRealTimeMatchIconImage;
        }

        themeObject.jsonBody = parsedJson;
        theme[key] = themeObject;
      }
      Object.values(theme)[0].needsFetch = false;
      newState = merge({}, state, theme);
      return newState;
    case ADVANCED_THEME.UPDATE:
      newState = merge({}, state);
      newState[action.selected].jsonBody[action.mainField][action.subField][action.keyField] =
        action.data;
      if (!newState[action.selected].needsFetch) {
        newState[action.selected].needsFetch = true;
      }
      return newState;
    case ADVANCED_THEME.GRADIENT:
      newState = merge({}, state);
      newState[action.selected].jsonBody.gradients[action.subField].gradient[action.index] =
        action.data;
      if (!newState[action.selected].needsFetch) {
        newState[action.selected].needsFetch = true;
      }
      return newState;
    case ADVANCED_THEME.GRADIENT_REPLACE:
      newState = merge({}, state);
      newState[action.selected].jsonBody.gradients[action.subField].gradient = action.data;
      if (!newState[action.selected].needsFetch) {
        newState[action.selected].needsFetch = true;
      }
      return newState;
    case CUSTOM_THEME.UPDATE:
      newState = merge({}, state);
      let updateTheme = camelize(action.theme);
      if (updateTheme.json) {
        let parsedJson = JSON.parse(updateTheme.json);
        updateTheme.jsonBody = parsedJson;
      }
      newState[updateTheme.id] = updateTheme;
      return newState;
    default:
      return state;
  }
};

export default customThemeReducer;
