import * as React from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';

// import Link from '@mui/material/Link';

const DISMISSED_KEY = 'entry-point-workflow-change-alert-dismissed';
enum ALERT_TYPES {
  Upcoming = '1',
  Active = '2',
}

const alertContent = {
  [ALERT_TYPES.Upcoming]: (
    <>
      <AlertTitle>
        We are making changes to how you assign progression entry point(s) to game builds!
      </AlertTitle>
      You will no longer need to go through the trouble of assigning entry point(s) to existing game
      builds; an entry point will be assigned to all active game builds automatically after
      deployment. We will be communicating the specific timeline soon, but wanted to give you a
      heads up in advance and allow you sufficient time to prepare for the upcoming changes.
    </>
  ),
  [ALERT_TYPES.Active]: (
    <>
      <AlertTitle>We&apos;ve implemented changes for progression entry points!</AlertTitle>
      Now when you create a new entry point, it will be assigned to all active game builds
      automatically after deployment.{/* <Link href="#">Learn more about this here.</Link> */}
    </>
  ),
};

export const EntryPointWorkflowChangeAlert: React.FunctionComponent<Record<string, never>> = () => {
  const [isDismissed, setIsDismissed] = React.useState(true);

  const alertType = window.appConfig.featureFlags.progressionV3SeasonsEntryPoints
    ? ALERT_TYPES.Active
    : ALERT_TYPES.Upcoming;

  React.useEffect(() => {
    const previouslyDismissed = localStorage.getItem(DISMISSED_KEY);

    if (previouslyDismissed !== alertType) {
      setIsDismissed(false);
    }
  }, []);

  const dismiss = () => {
    setIsDismissed(true);
    localStorage.setItem(DISMISSED_KEY, alertType);
  };

  if (isDismissed) {
    return null;
  }

  return (
    <Grid item={true} sm={12} data-qa={'entry-points-workflow-change-alert'}>
      <Alert severity="info" onClose={dismiss}>
        {alertContent[alertType]}
      </Alert>
    </Grid>
  );
};
