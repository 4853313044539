import axios, { AxiosResponse } from '@app/common/setup/axiosWithHeader';
import { Dispatch } from 'redux';
import { IAction, ISdkDownloads } from '@app/common/interfaces';

export enum SDK_DOWNLOADS {
  RECEIVE = 'sdkDownloads/RECEIVE'
}

export function receiveSdkDownloads(data: ISdkDownloads): IAction<ISdkDownloads> {
  return {
    type: SDK_DOWNLOADS.RECEIVE,
    data
  };
}

export function fetchSdkDownloads() {
  return async (dispatch: Dispatch<IAction<ISdkDownloads>>): Promise<void> => {
    const response: AxiosResponse<ISdkDownloads> = await axios({
      url: `/downloads.json`,
      method: 'get'
    });

    dispatch(receiveSdkDownloads(response.data));
  };
}
