import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IRootState } from '@app/core/rootReducer';

const SignUpComponent = loadable(() => import(/* webpackChunkName: "sign-up" */ './SignUp'));

const mapStateToProps = (state: IRootState) => {
  let { feature } = state;

  return {
    feature,
  };
};

const connector = connect(mapStateToProps, {});

export type TSignUpConnectedProps = ConnectedProps<typeof connector> & RouteComponentProps;

export const SignUp = connector(withRouter(SignUpComponent));
