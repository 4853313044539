// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import { Dispatch } from 'redux';

import { IAction, TErrorGeneral } from '@app/common/interfaces';
import axios, { AxiosResponse } from '@app/common/setup/axiosWithHeader';

import { IVerificationDeepLink } from './constants';

export const DEEP_LINKING = {
  RECEIVE_VERIFICATION: 'deep_linking/RECEIVE_VERIFICATION',
};

export const sendDeepLinkTest = (email: string, gameId: number) => {
  const form = new FormData();

  form.append('verification[email]', email);

  return axios.post(`/verifications/${gameId}/send_test_deep_link`, form);
};

export const verifyDeepLinkTest = (match: string, gameId: number) => {
  const form = new FormData();

  form.append('verification[deep_link]', match);

  return axios.post(`/verifications/${gameId}/verify_deep_link.json`, form);
};

export const getVerification = (data: IVerificationDeepLink): IAction<IVerificationDeepLink> => {
  return {
    type: DEEP_LINKING.RECEIVE_VERIFICATION,
    data,
  };
};

export const getDeepLinkingVerification = (gameId: number) => {
  return (dispatch: Dispatch<IAction<IVerificationDeepLink>>): Promise<void> | TErrorGeneral => {
    return axios
      .get(`/verifications/${gameId}/get_deep_link_verification_status.json`)
      .then((response: AxiosResponse<IVerificationDeepLink>) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        dispatch(getVerification(camelize(response.data)));

        return response;
      });
  };
};
