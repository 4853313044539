import { IAtomTableHeaderColumn } from '@app/common/interfaces';
import { IGameBuildTableData } from './interfaces';

export enum TABLE_SORT_ATTRIBUTES {
  ACTIVE = 'active',
  NAME = 'name',
  SDK_VERSION = 'sdk_version',
  CREATED_AT = 'created_at',
  PLATFORM = 'platform',
  DESCRIPTION = 'description'
}

export const HEAD_ROWS: IAtomTableHeaderColumn<TABLE_SORT_ATTRIBUTES>[] = [
  {
    id: 'game-builds__table-header-status',
    cellContent: 'Status',
    property: TABLE_SORT_ATTRIBUTES.ACTIVE
  },
  {
    id: 'game-builds__table-header-name',
    cellContent: 'Name',
    property: TABLE_SORT_ATTRIBUTES.NAME
  },
  {
    id: 'game-builds__table-header-sdk-version',
    cellContent: 'SDK version',
    property: TABLE_SORT_ATTRIBUTES.SDK_VERSION
  },
  {
    id: 'game-builds__table-header-upload-date',
    cellContent: 'Upload Date',
    property: TABLE_SORT_ATTRIBUTES.CREATED_AT
  },
  {
    id: 'game-builds__table-header-platform',
    cellContent: 'Platform',
    property: TABLE_SORT_ATTRIBUTES.PLATFORM
  },
  {
    id: 'game-builds__table-header-build-notes',
    cellContent: 'Build Notes'
  },
  {
    id: 'game-builds__table-header-actions',
    cellContent: ''
  }
];

export const TABLE_DATA_ORDER: (keyof IGameBuildTableData)[] = [
  'status',
  'name',
  'sdkVersion',
  'uploadDate',
  'platform',
  'buildNotes',
  'action'
];

export enum MENU_ITEM_ATTRIBUTES {
  EDIT = 'edit',
  ENABLE = 'enable',
  DISABLE = 'disable',
  REQUEST_TO_ENABLE = 'request-to-enable',
  REQUEST_TO_DISABLE = 'request-to-disable',
  CANCEL_REQUEST = 'cancel-request',
  COPY_DOWNLOAD_LINK = 'copy-download-link'
}

export enum MENU_ITEM_COPY {
  EDIT = 'Edit',
  ENABLE = 'Enable',
  DISABLE = 'Disable',
  REQUEST_TO_ENABLE = 'Request to enable',
  REQUEST_TO_DISABLE = 'Request to disable',
  CANCEL_REQUEST = 'Cancel request',
  COPY_DOWNLOAD_LINK = 'Copy download link'
}

export const PENDING_TOOLTIP_COPY: string =
  'Because your game is prize enabled, our dev success team will provide quality assurance checks on your game. You will receive a response within the next 2 business days.';
