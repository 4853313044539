import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const CaseStudyProgression = loadable(
  () => import(/* webpackChunkName: "case-study-progression" */ './CaseStudyProgression')
);

const mapStateToProps = () => {
  return {} as Record<string, unknown>;
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TCaseStudyProgressionConnectedProps = ConnectedProps<typeof connector> &
  RouteComponentProps;

export default connector(withRouter(CaseStudyProgression));
