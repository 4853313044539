import React, { FC, useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';

import { UFC_GAME_URL } from '@app/common/constants';
import { NFL_CHALLENGE_URL } from '@app/common/constants';
import nflLogo from '@app/imageAssets/nfl/NFL_DC_Logo.svg';
import ufcLogo from '@app/imageAssets/ufc/UFC_logo_white.svg';
import redUfcLogo from '@app/imageAssets/ufc/UFC_red_circle_logo.png';

import './promotionBanner.scss';

const cssPrefix: string = 'promotion-banner';

const signupCssPrefix: string = 'signup-promotion-banner';

enum BANNER_TYPES {
  UFC_GAME_PARTNERSHIP = 'ufcGamePartnership',
  UFC_GAME_PARTNERSHIP_NEW_SIGNUP = 'ufcGamePartnershipNewSignup',
  NFL_GAME_CHALLENGE = 'nflGameChallenge',
}
interface IPromoBannerProps {
  isFullWidth: boolean;
  description?: string;
  hideDescription?: boolean;
  header?: boolean;
  isSignup?: boolean;
}

type TDismissedBanners = Partial<Record<BANNER_TYPES, boolean>>;

interface IBannerProps {
  bannerCssSuffix: string;
  url: string;
  logo: string;
  defaultDescription: string;
  topLineDescription?: string;
}

const PromotionBanner: FC<IPromoBannerProps> = ({
  isFullWidth,
  description,
  hideDescription,
  header,
  isSignup,
}): JSX.Element => {
  const bannerType = window.appConfig.featureFlags.ufcGamePartnership
    ? isSignup
      ? BANNER_TYPES.UFC_GAME_PARTNERSHIP_NEW_SIGNUP
      : BANNER_TYPES.UFC_GAME_PARTNERSHIP
    : BANNER_TYPES.NFL_GAME_CHALLENGE;

  const ufcProps: IBannerProps = {
    bannerCssSuffix: 'ufc',
    url: UFC_GAME_URL(),
    logo: ufcLogo,
    defaultDescription: 'Submit your game!',
  };

  const ufcSignupProps: IBannerProps = {
    bannerCssSuffix: 'ufc',
    url: UFC_GAME_URL(),
    logo: redUfcLogo,
    defaultDescription: 'Submit your game!',
    topLineDescription: 'UFC Branded competitive mobile games.',
  };

  const nflProps: IBannerProps = {
    bannerCssSuffix: 'nfl',
    url: NFL_CHALLENGE_URL(),
    logo: nflLogo,
    defaultDescription: '',
  };

  const [shouldDismiss, setShouldDismiss] = useState(false);

  useEffect((): void => {
    if (header && isBannerDismissed()) {
      setShouldDismiss(true);
    }
  }, []);

  function getBannerProps(): IBannerProps {
    if (window.appConfig.featureFlags.ufcGamePartnership) {
      return isSignup ? ufcSignupProps : ufcProps;
    } else {
      return nflProps;
    }
  }

  function getDismissedBanners(): TDismissedBanners {
    const dismissedBannersString: string = localStorage.getItem('dismissedBanners') ?? '{}';
    const dismissedBanners = JSON.parse(dismissedBannersString) as TDismissedBanners;

    return dismissedBanners;
  }

  function dismissBanner(): void {
    setShouldDismiss(true);
    const dismissedBanners: TDismissedBanners = getDismissedBanners();
    dismissedBanners[bannerType] = true;
    localStorage.setItem('dismissedBanners', JSON.stringify(dismissedBanners));
  }

  function isBannerDismissed(): boolean {
    const dismissedBanners: TDismissedBanners = getDismissedBanners();

    if (dismissedBanners.hasOwnProperty(bannerType)) {
      return dismissedBanners[bannerType] ?? false;
    }
    dismissedBanners[bannerType] = false;
    localStorage.setItem('dismissedBanners', JSON.stringify(dismissedBanners));

    return false;
  }

  const { bannerCssSuffix, url, logo, defaultDescription, topLineDescription } = getBannerProps();
  const descriptionText = description ?? defaultDescription;
  const headerPrefix = header ?? '' ? `${cssPrefix}__body__header` : '';

  if (header && shouldDismiss) {
    return <></>;
  } else if (isSignup) {
    return (
      <div className={`${signupCssPrefix}`}>
        <div className={`${signupCssPrefix}__content`}>
          <img src={logo} className={`${signupCssPrefix}__banner-image__${bannerCssSuffix}`} />
          <div className={`${signupCssPrefix}__left-text-content`}>
            <div className={`${signupCssPrefix}__banner-header__${bannerCssSuffix}`} />
            <div className={`${signupCssPrefix}__banner-top-description`}>{topLineDescription}</div>
            {!hideDescription && (
              <div className={`${signupCssPrefix}__banner-description`}>{descriptionText}</div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <a
        href={url}
        style={{ textDecoration: 'none' }}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        <Grid
          container={true}
          className={`${cssPrefix}__container  ${cssPrefix}__container__${bannerCssSuffix} ${
            isFullWidth ? '' : `${cssPrefix}__minimized-container`
          }`}
          justifyContent={'center'}
          data-qa={`${cssPrefix}__container`}
          sx={header ? { marginTop: '0px !important', cursor: 'pointer' } : {}}
        >
          <Grid
            item={true}
            xs={12}
            {...(isFullWidth && { lg: 8, sm: 10 })}
            className={`${cssPrefix}__body ${headerPrefix}`}
          >
            <div className={`${cssPrefix}__content`}>
              <div className={`${cssPrefix}__left-content`}>
                <img src={logo} className={`${cssPrefix}__banner-image__${bannerCssSuffix}`} />
                <div className={`${cssPrefix}__left-text-content`}>
                  <div className={`${cssPrefix}__banner-header__${bannerCssSuffix}`} />
                  {!hideDescription && (
                    <div className={`${cssPrefix}__banner-description`}>{descriptionText}</div>
                  )}
                </div>
              </div>
              {isFullWidth && (
                <div className={`${cssPrefix}__right-content`}>
                  <div
                    id={`${cssPrefix}__navigation-link`}
                    className={`${cssPrefix}__navigation-link`}
                  >
                    Learn More
                  </div>
                  {header && !isBannerDismissed() && (
                    <CloseIcon
                      className={`${cssPrefix}__close-icon`}
                      sx={{ cursor: 'pointer' }}
                      fontSize="small"
                      onClick={(event) => {
                        event.preventDefault();
                        dismissBanner();
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </a>
    );
  }
};

export { PromotionBanner };
