import * as React from 'react';

import Card from '@mui/material/Card';

import androidLogo from '@app/imageAssets/androidLogo.png';
import iosLogo from '@app/imageAssets/iosLogo.png';
import unityLogo from '@app/imageAssets/unityLogo.png';

interface IProps {
  className: string;
}

class CardContainer extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    let { className, children } = this.props;
    let isHomePage: boolean = window.location.pathname === '/';
    const isDeveloperLandingPageOverhaulEnabled =
      window.appConfig.featureFlags.developerLandingPageOverhaul;

    return (
      <>
        {(!isHomePage || !isDeveloperLandingPageOverhaulEnabled) && (
          <Card className={className} classes={{ root: 'mui card-container-elevation' }}>
            {children}
          </Card>
        )}

        {isHomePage &&
          (isDeveloperLandingPageOverhaulEnabled ? (
            <Card
              className={className}
              classes={{
                root: 'mui card-container-elevation home-page__welcome-signup-container-overhaul',
              }}
            >
              <h2 className="home-page__welcome-signup-form-header">Sign up</h2>
              {children}
              <div className="landing-page-platform-logos landing-page-platform-logos-overhaul">
                <img src={iosLogo} width="21px" height="25px" alt="ios logo" />
                <img src={androidLogo} width="25px" height="25px" alt="android logo" />
                <img src={unityLogo} width="25px" height="25px" alt="unity logo" />
              </div>
            </Card>
          ) : (
            <div className="landing-page-platform-logos">
              <img src={iosLogo} width="21px" height="25px" alt="ios logo" />
              <img src={androidLogo} width="25px" height="25px" alt="android logo" />
              <img src={unityLogo} width="25px" height="25px" alt="unity logo" />
            </div>
          ))}
      </>
    );
  }
}
export { CardContainer };
