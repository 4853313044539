import * as React from 'react';

import { IBaseProps } from 'portal-common-library/interfaces/componentBase';
import Scrollspy, { ScrollspyProps } from 'react-scrollspy';

import './styles/atomTableOfContents.scss';

export interface IToCLink {
  id: string;
  text: string;
}

interface IAtomTableOfContentsProps {
  links: IToCLink[];
}

export type TAtomTableOfContentsProps = IAtomTableOfContentsProps &
  IBaseProps &
  Omit<ScrollspyProps, 'items' | 'currentClassName'>;

class AtomTableOfContents extends React.Component<TAtomTableOfContentsProps> {
  public render(): JSX.Element {
    const { links } = this.props;

    const ids = links.map((link) => link.id);
    const contents = links.map((link, index) => {
      return (
        <li key={index}>
          <a href={`#${link.id}`}>{link.text}</a>
        </li>
      );
    });

    return (
      <span
        className="atom-component__table-of-contents"
        id={this.props.id}
        {...this.props.dataAttributes}
      >
        <Scrollspy {...this.props} items={ids} currentClassName="is-current">
          {contents}
        </Scrollspy>
      </span>
    );
  }
}

export { AtomTableOfContents };
