import { lastDayOfMonth } from 'date-fns';
import { startOfDay, endOfDay } from 'date-fns';
import { format } from 'date-fns-tz';
import cloneDeep from 'lodash/cloneDeep';

import { MONTH_NAMES_FULL, MONTH_NAMES_MMM, TIME_IN_MILLISECONDS } from '@app/common/constants';
import { IDateRange } from '@app/common/interfaces';
import { DATE_RANGE_OPTIONS } from '@app/modules/Analytics/constants';
import { GAME_SNAPSHOT_DATE_RANGE_OPTIONS } from '@appGameHome/modules/Overview/constants';

/** creates an IDateRange object with the 'startDate' daysBack number of days back before
 * the 'maxEndDate'
 * @param { Date } maxEndDate - the endDate to be used for the 'endDate' in the IDateRange object
 * @param { number } daysBack - the number of days back to subtract from 'maxEndDate' to get the 'startDate'
 *                              for the IDateRange object
 * @returns { IDateRange }
 */
export function calculateStartEndDates(maxEndDate: Date, daysBack: number): IDateRange<string> {
  let startDate: Date = cloneDeep(maxEndDate);
  startDate.setDate(startDate.getDate() - daysBack);

  let query: IDateRange<string> = {
    startDate: `${startDate.getUTCFullYear()}-${
      startDate.getUTCMonth() + 1
    }-${startDate.getUTCDate()}`,
    endDate: `${maxEndDate.getUTCFullYear()}-${
      maxEndDate.getUTCMonth() + 1
    }-${maxEndDate.getUTCDate()}`,
  };

  return query;
}

/** turns a date string YYYY-MM-DD to Month Day, Year
 * function needed in createXAxisDatesFromDateRangeOptions
 * the monthIndex is -1 because January is the first index
 * the day has 0 removed if the day is less than 10
 * @params { string } date YYYY-MM-DD
 * @params { boolean } should return month be in full month
 * @return { string } returns a month as Sept 10, 2019 or September 10, 2019
 */
export function formatDate(date: string, isFullMonth: boolean = false): string {
  let dateArr: string[] = date.split('-');

  let monthIndex: number = parseInt(dateArr[1], 10) - 1; // monthIndex 0 = Jan
  let day: string = parseInt(dateArr[2], 10) < 10 ? dateArr[2][1] : dateArr[2]; // remove 0 if less than 10

  if (isFullMonth) {
    return `${MONTH_NAMES_FULL[monthIndex]} ${day}, ${dateArr[0]}`;
  }

  return `${MONTH_NAMES_MMM[monthIndex]} ${day}, ${dateArr[0]}`;
}
/** creates a string array with all dates between and including the start/end Dates
 * @param { Date} startDate - the start date for the string array
 * @param { Date } endDate  - the end date for the string array
 * @returns { string[] }
 */
export function createDateStringArray(startDate: Date, endDate: Date): string[] {
  let dateRangeArray: string[] = [];

  for (let i: Date = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
    dateRangeArray.push(new Date(i).toISOString().slice(0, 10));
  }

  return dateRangeArray;
}

export function calculateDateRangeHelper(
  startDateInput: Date,
  endDateInput: Date,
  selectedDateRange: DATE_RANGE_OPTIONS | GAME_SNAPSHOT_DATE_RANGE_OPTIONS
): IDateRange<Date> {
  const startDate = startOfDay(startDateInput);
  const endDate = endOfDay(endDateInput);

  switch (selectedDateRange) {
    case DATE_RANGE_OPTIONS.LAST_7_DAYS:
      startDate.setDate(endDate.getDate() - 6);

      return { startDate, endDate };

    case DATE_RANGE_OPTIONS.LAST_30_DAYS:
      startDate.setDate(endDate.getDate() - 29);

      return { startDate, endDate };

    case DATE_RANGE_OPTIONS.MTD:
      startDate.setDate(1);

      return { startDate, endDate };

    case DATE_RANGE_OPTIONS.PRIOR_MONTH:
      startDate.setMonth(startDate.getMonth() - 1);
      startDate.setDate(1);

      endDate.setMonth(startDate.getMonth());
      endDate.setDate(lastDayOfMonth(endDate).getDate());
      endDate.setFullYear(startDate.getFullYear());

      return { startDate, endDate };

    case DATE_RANGE_OPTIONS.LAST_90_DAYS:
      startDate.setDate(endDate.getDate() - 89);

      return { startDate, endDate };

    case DATE_RANGE_OPTIONS.LAST_180_DAYS:
      startDate.setDate(endDate.getDate() - 179);

      return { startDate, endDate };

    default:
      startDate.setDate(1);
      startDate.setMonth(0);

      return { startDate, endDate };
  }
}

/** creates an IDateRange object with predefined date ranges for the dropdown selector
 * @param { DATE_RANGE_OPTIONS } selectedDateRange
 * @returns { IDateRange }
 */
export function calculateDateRange(
  endDate: Date,
  selectedDateRange: DATE_RANGE_OPTIONS | GAME_SNAPSHOT_DATE_RANGE_OPTIONS
): IDateRange<Date> {
  let startDate: Date = cloneDeep(endDate);

  return calculateDateRangeHelper(startDate, endDate, selectedDateRange as DATE_RANGE_OPTIONS);
}

/** creates an IDateRange object with predefined date ranges for the dropdown selector
 * @param { string } isoStringDate // the dateTime string passed in isoDate standard
 * @param { number } numberOfDays // number of days to be added
 * @returns { number }  // time in milliseconds elapsed since January 1, 1970 00:00:00 UTC.
 */
export function calculateDateNDaysAfter(isoStringDate: string, numberOfDays: number): number {
  return Date.parse(isoStringDate) + numberOfDays * TIME_IN_MILLISECONDS.DAY;
}

export function calculateUnitOfTimeFromMilliseconds(
  unit: TIME_IN_MILLISECONDS,
  milliseconds: number
): number {
  return Math.floor(milliseconds / unit);
}

export const calculatePSTTime = (isoStringDate: string): string => {
  const isoStrToDate: Date = new Date(Date.parse(isoStringDate));

  return format(isoStrToDate, 'PPp', {
    timeZone: 'America/Los_Angeles',
  });
};

/** formats the provided ISO DateTime string according to the options
 * @param { string } isoStringDate // the DateTime string in ISO format
 * @param { number } options // DateTimeFormatOptions object with the desired format
 * @returns { string }  // Formatted Date String
 */
export const formatIsoDate = (
  isoStringDate: string,
  options: Intl.DateTimeFormatOptions
): string => {
  return new Date(isoStringDate).toLocaleString('en', options);
};
