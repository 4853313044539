import { createSlice } from '@reduxjs/toolkit';
import { IIndexReducerState } from 'portal-common-library/interfaces/redux';

import { IUser2 } from '@app/common/interfaces';
import { fetchInvitedUsers } from '@app/modules/Account/modules/Teammates/actions';
import { DEFAULT_USER_PAGE_SIZE } from '@app/modules/Account/modules/Teammates/constants';

const invitedUsers = createSlice({
  name: 'invitedUsers',
  initialState: {
    totalCount: 0,
    items: {}
  } as IIndexReducerState<IUser2>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInvitedUsers.fulfilled, (state, action) => {
      let page = 0;

      if (action.meta.arg?.offset) {
        page = Math.floor(action.meta.arg.offset) / DEFAULT_USER_PAGE_SIZE;
      }

      if (page === 0) {
        state.items = { 0: action.payload.items };
      } else {
        state.items[page] = action.payload.items;
      }

      state.totalCount = action.payload.totalCount;

      return state;
    });

    builder.addCase(fetchInvitedUsers.rejected, (_, action) => {
      throw action.error;
    });
  }
});

export default invitedUsers.reducer;
