import { ONE_KB_IN_BYTES, ONE_MB_IN_BYTES } from '@app/common/helpers';
import { IStateProgressionRoomVersionLocaleBodyImage } from '@appEntryPoints/common/interfaces';

export const imageRequirements = {
  roomIcon: {
    extensions: ['png', 'jpg', 'jpeg', 'svg'],
    sizeInKb: 20,
    resolution: '40x40px',
  },
  headerBadge: {
    extensions: ['png', 'jpg', 'jpeg', 'svg'],
    sizeInKb: 20,
    resolution: '100x100px',
  },
  headerImage: {
    extensions: ['png', 'jpg', 'jpeg'],
    sizeInKb: 100,
    resolution: '700x140px',
  },
};

const getImageRegexFromExtensions = (extensions: string[]): RegExp => {
  const str = `image/(${extensions.join('|')})`;

  return new RegExp(str);
};

const generateRequirementsForImage = (imageFor: string) => {
  const result = imageFor.replace(/([A-Z])/g, ' $1');
  const imageFieldName =
    imageFor === 'roomIcon' ? 'Entry Point Icon' : result.charAt(0).toUpperCase() + result.slice(1);

  if (imageFor in imageRequirements) {
    const requirement = imageRequirements[imageFor as keyof typeof imageRequirements];

    return {
      maxSize: requirement.sizeInKb * ONE_KB_IN_BYTES,
      fileTypeRegex: getImageRegexFromExtensions(requirement.extensions),
      errorMessage: `The '${imageFieldName}' image needs to be ${requirement.extensions
        .join(', ')
        .toUpperCase()}. The image also needs to be less than or equal to ${
        requirement.sizeInKb
      }KB. Please try again with a different image.`,
    };
  }

  const regexBodyImage = /(image\/(png|jpe?g|gif))|(video\/mp4)/;

  return {
    maxSize: ONE_MB_IN_BYTES,
    fileTypeRegex: regexBodyImage,
    errorMessage: `The '${imageFieldName}' image needs to be PNG, JPG, JPEG, MP4, or GIF file types. The image also needs to be less than or equal to 1MB. Please try again with a different image.`,
  };
};

export const imageSizeValidation = (imageFor: string, image: File) => {
  const requirement = generateRequirementsForImage(imageFor);
  const isFileValid =
    image.size <= requirement.maxSize && requirement.fileTypeRegex.test(image.type);

  return {
    isFileValid,
    errorMessage: isFileValid ? '' : requirement.errorMessage,
  };
};

export const formatBodyImageDisplayName = (
  bodyImage: IStateProgressionRoomVersionLocaleBodyImage
): string => {
  return bodyImage.file ? bodyImage.file.name : extractFileNameFromS3Url(bodyImage.url);
};

export const formatEntryPointImageDisplayName = (file: File | null, s3Url: string): string => {
  return file ? file.name : extractFileNameFromS3Url(s3Url);
};

const extractFileNameFromS3Url = (url: string): string => {
  // progression s3 url - look at the tests for examples of possible URL formats
  const tempString = url?.split('/').pop() ?? '';
  const lastIndexOfDash = tempString.lastIndexOf('-');
  const extension = tempString.split('.').pop();

  // get the part before the last '-' and add the extension to get the filename
  return lastIndexOfDash > 0 && extension
    ? `${tempString.substr(0, lastIndexOfDash)}.${extension}`
    : tempString;
};
