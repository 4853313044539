import { ENVIRONMENT } from '@app/common/constants';
import {
  IGameProgressionRoomVersions,
  TGameProgressionRooms,
  TGameProgressionRoomVersionMappings,
} from '@appEntryPoints/common/interfaces';

export const extractLatestEnvRoomVersionId = (
  env: ENVIRONMENT,
  reduxProgressionRoomVersionMapping: TGameProgressionRoomVersionMappings,
  roomIdentifier: string
) => {
  const progressionRoomVersionMapping = reduxProgressionRoomVersionMapping[roomIdentifier];

  if (!progressionRoomVersionMapping) {
    return null;
  }

  const envRoomVersionIds = progressionRoomVersionMapping[env].roomVersionIds;

  return envRoomVersionIds[0] ?? null;
};

export const extractCurrentRoomId = (
  env: ENVIRONMENT,
  reduxProgressionRooms: TGameProgressionRooms,
  roomIdentifier: string
) => {
  const progressionRoom = reduxProgressionRooms[roomIdentifier];

  if (!progressionRoom) {
    return null;
  }

  return progressionRoom[env].id;
};

export const extractRoomName = (
  reduxProgressionRooms: TGameProgressionRooms,
  roomIdentifier: string
) => {
  return reduxProgressionRooms[roomIdentifier]?.production.name ?? 'N/A';
};

export const extractCurrentVersion = (
  env: ENVIRONMENT,
  progressionRoomVersionMappings: TGameProgressionRoomVersionMappings,
  progressionRoomVersions: IGameProgressionRoomVersions,
  roomIdentifier: string
): string | null => {
  const latestVersionId = extractLatestEnvRoomVersionId(
    env,
    progressionRoomVersionMappings,
    roomIdentifier
  );

  if (!latestVersionId) return null;

  return progressionRoomVersions[env][latestVersionId]?.versionNumber ?? null;
};
