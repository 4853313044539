import { Location } from 'history';

import { IAnalyticInfo, IDateRange, ISearchParams } from '@app/common/interfaces';
import {
  ALL_GAMES,
  DATE_RANGE_OPTIONS,
  DEFAULT_DATE_RANGE,
  TABS,
} from '@app/modules/Analytics/constants';

/**
 * return the index of the tab for the Analytics page
 * @param { string } tab - the string of the tab names
 * @returns { number }
 */
export function getTabIndex(tab: string): number {
  let tabIndex: number = TABS.indexOf(tab);

  if (tabIndex < 0) {
    tabIndex = 0;
  }

  return tabIndex < 0 ? 0 : tabIndex;
}

/**
 * returns the game name and the game production ID of the game, (a gameProductionId of -1 means ALL_GAMES)
 * @param { string } gameId - the string of the game option
 * @param { IAnalyticInfo[] } data - the raw analytic data
 * @returns { string }
 */
export function getGameName(gameId: number, data: IAnalyticInfo[]): string {
  let selectedGameName: string = ALL_GAMES;

  if (gameId && gameId !== -1) {
    for (const point of data) {
      if (point.gameProductionId === gameId) {
        selectedGameName = point.gameTitle;
        break;
      }
    }
  }

  return selectedGameName;
}

/**
 * returns an object containing the URL search params
 * @param { Location<any> } location - the history's location
 * @returns { URLSearchParams }
 */
export function getSearchParams(location: Location<unknown>): ISearchParams {
  let searchParams: URLSearchParams = new URLSearchParams(location.search);

  return {
    tab: searchParams.get('tab'),
    dateRange: searchParams.get('date_range'),
    gameId: searchParams.get('game_id'),
  };
}

/**
 * returns the startDate & endDate extracted from the date_range URL query
 * @param { string } dateRangeQuery - the string after date_range= in the URL
 * @returns { IDateRange }
 */
export function getDateRangeFromUrl(dateRangeQuery: string): IDateRange<string> {
  let splitQuery: string[] = dateRangeQuery.split('--');

  return {
    startDate: splitQuery[0],
    endDate: splitQuery[1],
  };
}

/**
 * returns true if the dateRangeQuery is in the format of YYYY-MM-DD--YYYY-MM-DD
 * where MM could be M | MM and DD could be D | DD
 * @param { string } dateRangeQuery - the string after date_range= in the URL
 * @returns { boolean }
 */
export function isValidCustomDateRange(dateRangeQuery: string): boolean {
  return /^\d{4}-\d{1,2}-\d{1,2}--\d{4}-\d{1,2}-\d{1,2}$/.exec(dateRangeQuery) !== null;
}

/**
 * formats the date into a YYYY-MM-DD string
 * @param { Date } date - the date object to be formatted
 * @returns { string }
 */
export function dateToUrlFormatter(date: Date): string {
  return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`;
}

/**
 * returns the dateRange to be used as the selectedDateRange to update the state
 * @param { string | null } dateRange - URL's date range
 * @returns { DATE_RANGE_OPTIONS }
 */
export function dateRangeUrlParamHelper(dateRange: string | null): DATE_RANGE_OPTIONS {
  // if the URL had no dateRange then we default it to DEFAULT_DATE_RANGE
  if (dateRange === null) {
    return DEFAULT_DATE_RANGE;
  }

  // if the URL had a dateRange that was a DATE_RANGE_OPTION (not a YYYY-MM-DD--YYYY-MM-DD format)
  if (Object.values(DATE_RANGE_OPTIONS).includes(dateRange as DATE_RANGE_OPTIONS)) {
    return dateRange as DATE_RANGE_OPTIONS;
  }

  // if the URL had a dateRange that wasn't a DATE_RANGE_OPTION make sure it is
  // in the YYYY-MM-DD--YYYY-MM-DD format
  if (isValidCustomDateRange(dateRange)) {
    return DATE_RANGE_OPTIONS.CUSTOM_DATE_RANGE;
  }

  return DEFAULT_DATE_RANGE;
}

/**
 * returns the string to be passed to the URL for the dateRange depending on the state's values
 * @param {  DATE_RANGE_OPTIONS | string  }selectedDateRange - state's selectedDateRange
 * @param { Date } startDate - state's selected startDate
 * @param { Date } endDate - state's selected endDate
 */
export function dateRangeUrlParamFormatter(
  selectedDateRange: DATE_RANGE_OPTIONS | string,
  startDate: Date,
  endDate: Date
): DATE_RANGE_OPTIONS | string {
  if (selectedDateRange === DATE_RANGE_OPTIONS.CUSTOM_DATE_RANGE) {
    let startDateQuery: string = dateToUrlFormatter(startDate);
    let endDateQuery: string = dateToUrlFormatter(endDate);

    return `${startDateQuery}--${endDateQuery}`;
  }

  return selectedDateRange;
}
