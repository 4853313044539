import { createAsyncThunk } from '@reduxjs/toolkit';

import { IRootState } from '../../core/rootReducer';
import { fetchGameAccessRequest, TGameAccess } from '../helpers';

export const fetchGameAccess = createAsyncThunk<TGameAccess | void, void, { state: IRootState }>(
  'gamesAccess/fetchGameAccess',
  (_, { getState }) => {
    const { user } = getState();

    if (!user?.id) {
      return;
    }

    return fetchGameAccessRequest(user.id.toString());
  }
);
