import { createSlice } from '@reduxjs/toolkit';

import {
  fetchGameChallenge,
  updateNudgeStatus,
  updateRegistrationStatus
} from '@app/common/actions/gameChallengeActions';
import { IGameChallenge } from '@app/common/interfaces/gameChallenge';

export const initialState: IGameChallenge = {} as IGameChallenge;

const nflChallenge = createSlice({
  name: 'nflChallenge',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGameChallenge.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(updateRegistrationStatus.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(updateRegistrationStatus.rejected, (_, action) => {
      throw action.error;
    });
    builder.addCase(updateNudgeStatus.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(updateNudgeStatus.rejected, (_, action) => {
      throw action.error;
    });
  }
});

export default nflChallenge.reducer;
