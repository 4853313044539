export enum LOCALE {
  DE = 'de',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  JA = 'ja',
  PT = 'pt',
  RU = 'ru',
  ZH = 'zh',
}
