import merge from 'lodash/merge';

import { IAction } from '@app/common/interfaces';
import { VERIFICATION } from '@app/modules/GameManagement/common/actions';
import { TVerificationReducerState } from '@app/modules/GameManagement/common/interfaces';

type TAction = IAction<TVerificationReducerState>;

const verificationReducer = (
  state: TVerificationReducerState = null,
  action: TAction
): TVerificationReducerState => {
  let newState: TVerificationReducerState;
  Object.freeze(state);
  switch (action.type) {
    case VERIFICATION.RECEIVE_DATA:
      newState = merge({}, state, action.data);

      return newState;
    default:
      return state;
  }
};

export default verificationReducer;
