import merge from 'lodash/merge';

import { IAction } from '@app/common/interfaces';
import { PRIZE_ENABLEMENT_REQUEST_ACTIONS } from '@appOverview/actions';
import { IPrizeEnablementRequest } from '@appOverview/interfaces';

type TPrizeEnablementRequestAction = IAction<IPrizeEnablementRequest>;

function prizeEnablementRequest(
  state: IPrizeEnablementRequest | null = null,
  action: TPrizeEnablementRequestAction
): IPrizeEnablementRequest | null {
  Object.freeze(state);
  switch (action.type) {
    case PRIZE_ENABLEMENT_REQUEST_ACTIONS.RECEIVE:
      return merge({}, state, action.data);

    default:
      return state;
  }
}

export default prizeEnablementRequest;
