import { IAction } from '@app/common/interfaces';

export enum GAMES_COUNT {
  RECEIVE_COUNT = 'gamesCount/RECEIVE_COUNT'
}

export function clearGamesTotalCount(): IAction<number> {
  return { type: GAMES_COUNT.RECEIVE_COUNT, data: 0 };
}

export function receiveGamesTotalCount(data: number): IAction<number> {
  return {
    type: GAMES_COUNT.RECEIVE_COUNT,
    data
  };
}
