import React, { useContext, useState, createContext } from 'react';

import { ISurveyState } from './interfaces';

interface ISurveyContext extends ISurveyState {
  openSurvey: (surveyTrigger: string) => void;
  closeSurvey: () => void;
}

const SurveyContext = createContext<ISurveyContext>({
  openSurvey: () => undefined,
  closeSurvey: () => undefined,
});

export function useSurveyContext() {
  return useContext(SurveyContext);
}

export function SurveyContextProvider(props: { children: JSX.Element[] | JSX.Element }) {
  const [surveyState, setSurveyState] = useState<ISurveyState>({});

  const openSurvey = (surveyTrigger: string) => {
    setSurveyState({
      surveyTrigger,
    });
  };

  const closeSurvey = () => {
    setSurveyState({
      surveyTrigger: undefined,
    });
  };

  return (
    <SurveyContext.Provider value={{ ...surveyState, openSurvey, closeSurvey }}>
      {props.children}
    </SurveyContext.Provider>
  );
}
