import React from 'react';

import useWindowDimensions from '@app/common/hooks/useWindowDimensions';
import { IHelpBitResponse } from '@app/modules/ContentManagementSystem/interfaces';

import { componentClassName } from '../WizardView';
import SidebarLink from './SidebarLink';
import './wizardSidebar.scss';

export const WIZARD_SIDEBAR_TITLE_CUTOFF = 960;

const renderSidebarLinks = (
  helpBits: IHelpBitResponse[],
  currentHelpBitId: number,
  handleChangeHelpBit: (helpBitId: number) => () => void,
  shouldShowTooltips: boolean
) => {
  return helpBits.map((helpBit) => (
    <SidebarLink
      shouldShowTooltip={shouldShowTooltips}
      currentHelpBitId={currentHelpBitId}
      key={helpBit.id}
      id={helpBit.id}
      linkTitle={helpBit.title}
      handleChangeHelpBit={handleChangeHelpBit}
    />
  ));
};

interface IWizardSidebarProps {
  helpBits: IHelpBitResponse[];
  currentHelpBit: IHelpBitResponse;
  handleChangeHelpBit: (helpBitId: number) => () => void;
}

const WizardSidebar = ({
  helpBits,
  currentHelpBit,
  handleChangeHelpBit,
}: IWizardSidebarProps): JSX.Element => {
  const { width } = useWindowDimensions();
  let shouldShowTooltips = false;
  if (width <= WIZARD_SIDEBAR_TITLE_CUTOFF) {
    shouldShowTooltips = true;
  }

  return (
    <div
      className={`${componentClassName}__sidebar`}
      style={{
        backgroundImage: `url(${currentHelpBit.backgroundUrl})`,
      }}
      data-qa={'wizard-sidebar-container'}
    >
      {renderSidebarLinks(helpBits, currentHelpBit.id, handleChangeHelpBit, shouldShowTooltips)}
    </div>
  );
};

export default WizardSidebar;
