import React from 'react';

import { IHelpBitResponse } from '@app/modules/ContentManagementSystem/interfaces';

import { NavigationButton } from '../modules/Content/modules/Footer/subcomponents';

export const renderFooterComponents = (
  currentHelpBit: IHelpBitResponse,
  handleChangeHelpBit: (helpBitId: number) => () => void
): JSX.Element[] => {
  const hasPrevious = !!currentHelpBit.previous;
  const previousFunction = !!currentHelpBit.previous
    ? handleChangeHelpBit(currentHelpBit.previous.id)
    : undefined;

  const hasNext = !!currentHelpBit.next;
  const nextFunction = !!currentHelpBit.next
    ? handleChangeHelpBit(currentHelpBit.next.id)
    : undefined;

  return [
    <div
      key={'back-icon'}
      data-qa={'back-arrow'}
      onClick={previousFunction}
      className={hasPrevious ? '' : 'disabled'}
    >
      <NavigationButton id={'back-arrow'} type={'previous'} />
    </div>,
    <div
      key={'forward-icon'}
      data-qa={'forward-arrow'}
      onClick={nextFunction}
      className={hasNext ? '' : 'disabled'}
    >
      <NavigationButton id={'forward-arrow'} type={'next'} />
    </div>,
  ];
};
