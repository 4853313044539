import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { openSnackbar } from '@app/common/actions';

const Jobs = loadable(() => import(/* webpackChunkName: "jobs" */ './Jobs'));

const mapDispatchToProps = { openSnackbar };

const connector = connect(null, mapDispatchToProps);

export type TJobsConnectedProps = ConnectedProps<typeof connector>;

export default connector(withRouter(Jobs));
