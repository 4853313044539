import { IErrors, IError } from '@app/common/interfaces';
import { AxiosError, AxiosResponse } from '@app/common/setup/axiosWithHeader';

export const handleResponse = (response: AxiosResponse<unknown>) => response.status;

export const handleError = (axiosError: AxiosError<IError | IErrors>) => {
  if (axiosError.response?.data) {
    const { data } = axiosError.response;
    const errorMessage = (data as IError).error ?? (data as IErrors).errors;

    throw new Error(errorMessage);
  }

  throw new Error('Unknown error');
};
