import axios, { AxiosError, AxiosResponse } from 'axios';
// @ts-ignore: camelize is by definition an un-typeable Any => Any
import camelize from 'camelize';

import { IErrors, IPermissions } from '@app/common/interfaces';

export type TGameAccess = { productionId: number }[];

type TResponseProgressionRooms = AxiosResponse<TGameAccess>;

export const fetchGameAccessRequest = async (userId: string) => {
  return axios
    .get(`/api_internal/v1/users/${userId}/game_access`)
    .then(camelize)
    .then((response: TResponseProgressionRooms) => {
      return response.data;
    })
    .catch((e) => {
      const error = e as AxiosError<IErrors>;

      throw new Error(error.response?.data.errors);
    });
};

interface IAccessGameCheck {
  productionId: number;
  permissions: IPermissions;
  gameAccess: TGameAccess;
  permissionToCheck: string;
}

export const hasAccessGameForPermission = ({
  productionId,
  permissions,
  gameAccess,
  permissionToCheck,
}: IAccessGameCheck) => {
  return (
    !!permissions[permissionToCheck] && gameAccess.some((el) => el.productionId === productionId)
  );
};
