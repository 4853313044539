import React, { useState, PropsWithChildren } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import AtomButton from 'portal-common-library/atoms/AtomButton';

import variables from '@app/core/variables.scss';

import { COMPONENT_CLASS_NAME } from '../constants';
import ImageCard from './ImageCard';

const VIDEO_COMPONENT_CLASS_NAME = `${COMPONENT_CLASS_NAME}__video-modal`;

interface IVideoModalPropsWithoutPlaceholder {
  videoTitle: string;
  videoUrl: string;
}

export interface IVideoModalProps extends IVideoModalPropsWithoutPlaceholder {
  videoThumbnail: string;
  videoDescription?: string;
}

const videoModalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 1152,
  bgcolor: variables.colorWhite,
  boxShadow: 24,
  border: 0,
  aspectRatio: '16 / 9',
};

function renderPlaceholder(
  props: IVideoModalProps | PropsWithChildren<IVideoModalPropsWithoutPlaceholder>
) {
  if ('children' in props) {
    return props.children;
  }

  const { videoTitle, videoThumbnail, videoDescription, videoUrl } = props as IVideoModalProps;
  const shouldShowImageOnly = videoUrl === '';

  return (
    <>
      <ImageCard
        dataId={'video-thumbnail'}
        image={videoThumbnail}
        handleClick={() => null}
        title={shouldShowImageOnly ? '' : videoTitle}
        icon={shouldShowImageOnly ? <></> : <PlayCircleOutlineIcon />}
        hideGradientOverlay={shouldShowImageOnly}
      />
      <Typography sx={{ padding: '36px 0', lineHeight: '21px' }}>{videoDescription}</Typography>
    </>
  );
}

// generic version of video modal, used in InteractiveCard
export function renderVideoModal(
  isOpen: boolean,
  videoTitle: string,
  handleToggle: () => void,
  videoUrl: string
): JSX.Element {
  return (
    <Modal open={isOpen} disableAutoFocus={true} sx={{ margin: '5%' }}>
      <Box sx={videoModalStyle}>
        <Grid container style={{ padding: '24px 32px' }}>
          <Grid xs={11} item data-qa={'video-modal-title'}>
            <Typography variant={'h7'}>{videoTitle}</Typography>
          </Grid>
          <Grid xs={1} item sx={{ textAlign: 'right' }}>
            <CloseIcon
              onClick={handleToggle}
              style={{ cursor: 'pointer' }}
              data-qa={'close-icon'}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <iframe
              style={{ width: '100%', aspectRatio: '16/9' }}
              data-qa={'embedded-video-player'}
              title={'video-player'}
              // placeholder video, actual video needs to be uploaded to vimeo or youtube
              src={videoUrl}
              frameBorder={'0'}
              allowFullScreen
              allow="autoplay"
            />
          </Grid>
          <Grid item xs={12} sx={{ padding: '16px 32px', textAlign: 'right' }}>
            <AtomButton
              id={`${VIDEO_COMPONENT_CLASS_NAME}-close-toggle`}
              buttonContent={'Close'}
              buttonType={'primary'}
              onClick={handleToggle}
              dataAttributes={{ 'data-qa': `close-button` }}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

function VideoModal(
  params: IVideoModalProps | PropsWithChildren<IVideoModalPropsWithoutPlaceholder>
): JSX.Element {
  const { videoTitle, videoUrl } = params;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const shouldShowImageOnly = videoUrl === '';

  const handleToggle = (): void => {
    if (shouldShowImageOnly) return;
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <Box onClick={handleToggle}>{renderPlaceholder(params)}</Box>
      {renderVideoModal(isOpen, videoTitle, handleToggle, videoUrl)}
    </>
  );
}

export default VideoModal;
