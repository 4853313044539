import { Cookies } from 'react-cookie';

import { PEEK_COOKIE, isPeekPageRegex } from '@app/common/constants';
import { ITabTooltip } from '@app/modules/GameManagement/modules/GameHome/common/interfaces';

import { EU_COUNTRIES } from '../constants';
import { ICurrentUser, IPublisher2, IUser } from '../interfaces';
import { isSkillzSupport } from './';

declare global {
  interface Window {
    'ga-disable-UA-40410914-4': boolean;
  }
}

const cookies = new Cookies();

export function setGDPRCookie(value: boolean): void {
  let date: Date = new Date();
  let convertedDate: string = new Date(date.setFullYear(date.getFullYear() + 1)).toUTCString();
  document.cookie = `gdpr-cookie=${value.toString()}; path=/; Expires=${convertedDate}`;
}

export function deleteCookie(name: string): void {
  const expiration = 'Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = `${name}=; Path=/; Domain=.skillz.com; ${expiration}`;
  document.cookie = `${name}=; Path=/; Domain=${window.location.hostname}; ${expiration}`;
  document.cookie = `${name}=; Path=/; Domain=.${window.location.hostname}; ${expiration}`;
  // safari
  const keyDivider = name.indexOf('=');
  const cookieKey = name.substring(0, keyDivider);
  document.cookie = `${cookieKey}=;${expiration}`;
}

export function removeGDPRCookies(): void {
  let cookieArray: string[] = document.cookie.split(';');
  cookieArray.forEach((name: string): void => {
    if (name.indexOf('close-banner=true') !== -1) {
      return;
    }

    if (name.includes('notifications')) {
      return;
    }

    if (name.includes('gdpr-cookie')) {
      return;
    }

    if (name.includes('drift')) {
      return;
    }

    if (name.includes('peek_header_on')) {
      return;
    }

    deleteCookie(name);
  });

  setGDPRCookie(false);
}

export function addGDPRCookies(): void {
  // this is to get the GMT one year from now
  setGDPRCookie(true);

  // TODO [WS-10956] Remove the dangling _ga cookie
  // eslint-disable-next-line no-underscore-dangle
  let _ga: string[][] = [];
  _ga.push(['_setAccount', 'UA-40410914-4']);
  _ga.push(['_trackPageview']);
  window['ga-disable-UA-40410914-4'] = false;
}

export const addTooltipCookie = (tooltip: ITabTooltip): void => {
  const expiration = new Date(tooltip.expirationDate);
  cookies.set(tooltip.cookieName, true, { path: '/', expires: expiration });
};

export const getTooltipCookie = (tooltip: ITabTooltip): boolean => {
  return !!cookies.get(tooltip.cookieName);
};

export function checkCookieExists(): boolean {
  return document.cookie.indexOf('gdpr-cookie') === -1;
}

export const hasCookies = (cookieName: string): boolean => {
  return !!cookies.get(cookieName);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
export const shouldViewPeekPage = (user: any, publisher: IPublisher2): boolean => {
  return isSkillzSupport(user, publisher) || hasCookies(PEEK_COOKIE);
};

export const shouldShowPeekHeader = (user: IUser | ICurrentUser, publisher: IPublisher2) => {
  return (
    (user.id !== undefined && !!cookies.get(PEEK_COOKIE)) ||
    (isPeekPageRegex.test(window.location.pathname) && isSkillzSupport(user, publisher))
  );
};

export function checkEUcountries(country: string): boolean {
  return EU_COUNTRIES.includes(country);
}
