import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hasBorder?: boolean;
  style?: Record<string, string>;
  component?: string;
}

export const DialogTitleWithCloseButton = ({ hasBorder = true, ...props }: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        borderBottom: `${hasBorder ? '1px solid #E0E0E0' : 'unset'}`,
        textAlign: 'left',
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
