import * as React from 'react';
import FileCopy from '@mui/icons-material/FileCopy';
import { TOpenSnackbarFunc } from '@app/common/interfaces';

import './styles/atomCopyToClipboardStyles.scss';

export interface IAtomCopyToClipboardProps {
  id: string;
  copyValue: string;
  labelText: string | JSX.Element;
  openSnackbar: TOpenSnackbarFunc;
  hasIcon: boolean;
  snackbarText: string;
}

class AtomCopyToClipboard extends React.PureComponent<IAtomCopyToClipboardProps> {
  public copyToClipboard = (): void => {
    let { openSnackbar, copyValue, snackbarText } = this.props;

    let textField: HTMLTextAreaElement = document.createElement('textarea');
    textField.innerText = copyValue;
    textField.textContent = copyValue;
    document.body.appendChild(textField);
    textField.select();
    textField.focus();
    document.execCommand('copy');
    textField.remove();
    openSnackbar({
      text: snackbarText,
      autoHideDuration: 2000,
      anchorOriginVertical: 'bottom',
      anchorOriginHorizontal: 'left'
    });
  };

  public render(): JSX.Element {
    let { labelText, id, hasIcon } = this.props;

    return (
      <span
        id={`${id}-container`}
        className="atom-component__copy-to-clipboard-container"
        onClick={this.copyToClipboard}
      >
        {labelText}

        {hasIcon && <FileCopy className="atom-component__copy-to-clipboard-icon" />}
      </span>
    );
  }
}

export { AtomCopyToClipboard };
