import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios, { AxiosResponse, AxiosError } from 'axios';
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';

import { IRootState } from '@app/core/rootReducer';
import {
  IAction,
  TErrorGeneral,
  IErrors,
  IZendeskTicket,
  TAxiosPromise
} from '@app/common/interfaces';
import { IPrizeEnablementRequest } from '@appOverview/interfaces';

export enum PRIZE_ENABLEMENT_REQUEST_ACTIONS {
  RECEIVE = 'prizeEnablementRequest/RECEIVE'
}

export function receivePrizeEnablementRequest(
  data: IPrizeEnablementRequest
): IAction<IPrizeEnablementRequest> {
  return {
    type: PRIZE_ENABLEMENT_REQUEST_ACTIONS.RECEIVE,
    data
  };
}

type TPrizeEnablementReturn = TAxiosPromise<void>;

export const fetchPrizeEnablementRequest = (
  gameId: number
): ThunkAction<TPrizeEnablementReturn, IRootState, unknown, IAction<IPrizeEnablementRequest>> => {
  return (dispatch: Dispatch<IAction<IPrizeEnablementRequest>>): TPrizeEnablementReturn => {
    return axios
      .get(`/api_internal/v1/games/${gameId}/prize_enablement_request`)
      .then(camelize)
      .then((response: AxiosResponse<IPrizeEnablementRequest>) => {
        dispatch(receivePrizeEnablementRequest(response.data));
      })
      .catch(
        (error: AxiosError<IErrors>): TErrorGeneral => {
          return error.response ? error.response.data : error;
        }
      );
  };
};

export const createPrizeEnablementRequest = (
  gameId: number
): ThunkAction<TPrizeEnablementReturn, IRootState, unknown, IAction<IPrizeEnablementRequest>> => {
  return (dispatch: Dispatch<IAction<IPrizeEnablementRequest>>): TPrizeEnablementReturn => {
    return axios
      .post(`/api_internal/v1/games/${gameId}/prize_enablement_request`)
      .then(camelize)
      .then((response: AxiosResponse<IPrizeEnablementRequest>) => {
        dispatch(receivePrizeEnablementRequest(response.data));
      })
      .catch(
        (error: AxiosError<IErrors>): TErrorGeneral => {
          return error.response ? error.response.data : error;
        }
      );
  };
};

export const createPrizeEnablementRequestZendeskTicket = (
  gameId: number,
  prizeEnablementRequestId: number
): Promise<IZendeskTicket> | TErrorGeneral => {
  return axios
    .post(
      `/api/v1/games/${gameId}/prize_enablement_requests/${prizeEnablementRequestId}/zendesk_tickets`
    )
    .then(camelize)
    .then((response: AxiosResponse<IZendeskTicket>) => {
      return response.data;
    })
    .catch(
      (error: AxiosError<IErrors>): TErrorGeneral => {
        return error.response ? error.response.data : error;
      }
    );
};
