import { snakeCaseConverter } from '@app/common/helpers';
import { IQuestionSet } from '@app/common/interfaces';
import { ICreateResponseData, IPatchResponseData } from './interfaces';

type TSurveyFetch = (userId: number) => JQuery.jqXHR<IQuestionSet>;
type TSurveyCreate = (surveyResponse: ICreateResponseData) => JQuery.jqXHR<void>;
type TSurveyUpdate = (surveyResponse: IPatchResponseData) => JQuery.jqXHR<void>;

// calls server to fetch the valid questions
export const fetchValidQuestions: TSurveyFetch = (userId: number): JQuery.jqXHR<IQuestionSet> => {
  return $.ajax({
    url: `/survey_questions/show_valid_questions.json`,
    method: 'GET',
    data: snakeCaseConverter({
      surveyQuestions: {
        userId
      }
    })
  });
};

export const createResponse: TSurveyCreate = (
  surveyResponse: ICreateResponseData
): JQuery.jqXHR<void> => {
  return $.ajax({
    url: `/survey_responses`,
    method: 'POST',
    data: snakeCaseConverter({ surveyResponse })
  });
};

export const updateResponse: TSurveyUpdate = (
  surveyResponse: IPatchResponseData
): JQuery.jqXHR<void> => {
  return $.ajax({
    url: `/survey_responses/${surveyResponse.responseId}`,
    method: 'PATCH',
    data: snakeCaseConverter({ surveyResponse })
  });
};
