// TODO REPLACE [WS-10403] remove the below disable and fix error
/* eslint-disable @typescript-eslint/no-explicit-any */
import snakeCase from 'lodash/snakeCase';

export interface IObject {
  [key: string]: any;
}

type TCaseConverter = (object: IObject) => IObject;
export const snakeCaseConverter: TCaseConverter = (object: IObject): IObject => {
  let result: IObject = {};

  const isObject: boolean = object && typeof object === 'object' && object.constructor === Object;
  const isArray: boolean = Array.isArray(object);
  if (!isObject && !isArray) {
    return object;
  } else if (isArray) {
    return object.map(snakeCaseConverter);
  }

  Object.keys(object).forEach((key: string) => {
    let snakeCaseKey: string = snakeCase(key);
    let value: any = object[key.toString()];
    /* eslint-enable @typescript-eslint/no-explicit-any */

    if (value && typeof value === 'object' && value.constructor === Object) {
      result[snakeCaseKey.toString()] = snakeCaseConverter(value);
    } else if (value instanceof Array) {
      result[snakeCaseKey.toString()] = value.map(snakeCaseConverter);
    } else {
      result[snakeCaseKey.toString()] = object[key.toString()];
    }
  });

  return result;
};
