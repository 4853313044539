// TODO [WS-21024] REFACTOR TO USE GENERIC FUNCTION TO OPEN AND CLOSE MODAL
import { IAction, IActionNoData } from '@app/common/interfaces';

export enum MODAL_MANAGEMENT {
  OPEN_PROFILE_MODAL = 'modalManagement/OPEN_PROFILE_MODAL',
  CLOSE_PROFILE_MODAL = 'modalManagement/CLOSE_PROFILE_MODAL',
  OPEN_GAME_INFO_MODAL = 'modalManagement/OPEN_GAME_INFO_MODAL',
  CLOSE_GAME_INFO_MODAL = 'modalManagement/CLOSE_GAME_INFO_MODAL',
  OPEN_ARCHIVE_MODAL = 'modalManagement/OPEN_ARCHIVE_MODAL',
  CLOSE_ARCHIVE_MODAL = 'modalManagement/CLOSE_ARCHIVE_MODAL',
  OPEN_FAIRNESS_MODAL = 'modalManagement/OPEN_FAIRNESS_MODAL',
  CLOSE_FAIRNESS_MODAL = 'modalManagement/CLOSE_FAIRNESS_MODAL',
  OPEN_PRIZE_ENABLEMENT_MODAL = 'modalManagement/OPEN_PRIZE_ENABLEMENT_MODAL',
  CLOSE_PRIZE_ENABLEMENT_MODAL = 'modalManagement/CLOSE_PRIZE_ENABLEMENT_MODAL',
  OPEN_ACCELERATE_MODAL = 'modalManagement/OPEN_ACCELERATE_MODAL',
  CLOSE_ACCELERATE_MODAL = 'modalManagement/CLOSE_ACCELERATE_MODAL',
  OPEN_FEEDBACK_MODAL = 'modalManagement/OPEN_FEEDBACK_MODAL',
  CLOSE_FEEDBACK_MODAL = 'modalManagement/CLOSE_FEEDBACK_MODAL',
  OPEN_STUDIO_MODAL = 'modalManagement/OPEN_STUDIO_MODAL',
  CLOSE_STUDIO_MODAL = 'modalManagement/CLOSE_STUDIO_MODAL',
  OPEN_USER_MODAL = 'modalManagement/OPEN_USER_MODAL',
  CLOSE_USER_MODAL = 'modalManagement/CLOSE_USER_MODAL',
  OPEN_REMOVE_USER_MODAL = 'modalManagement/OPEN_REMOVE_USER_MODAL',
  CLOSE_REMOVE_USER_MODAL = 'modalManagement/CLOSE_REMOVE_USER_MODAL',
  OPEN_DEACTIVATE_SKILLZ_SUPPORT_MODAL = 'modalManagement/OPEN_DEACTIVATE_SKILLZ_SUPPORT_MODAL',
  CLOSE_DEACTIVATE_SKILLZ_SUPPORT_MODAL = 'modalManagement/CLOSE_DEACTIVATE_SKILLZ_SUPPORT_MODAL',
  OPEN_INVITE_USER_MODAL = 'modalManagement/OPEN_INVITE_USER_MODAL',
  CLOSE_INVITE_USER_MODAL = 'modalManagement/CLOSE_INVITE_USER_MODAL',
  OPEN_TRANSFER_OWNERSHIP_MODAL = 'modalManagement/OPEN_TRANSFER_OWNERSHIP_MODAL',
  CLOSE_TRANSFER_OWNERSHIP_MODAL = 'modalManagement/CLOSE_TRANSFER_OWNERSHIP_MODAL',
  OPEN_EDIT_USER_MODAL = 'modalManagement/OPEN_EDIT_USER_MODAL',
  CLOSE_EDIT_USER_MODAL = 'modalManagement/CLOSE_EDIT_USER_MODAL',
  OPEN_DISABLE_THIRD_PARTY_ADS_MODAL = 'modalManagement/OPEN_DISABLE_THIRD_PARTY_ADS_MODAL',
  CLOSE_DISABLE_THIRD_PARTY_ADS_MODAL = 'modalManagement/CLOSE_DISABLE_THIRD_PARTY_ADS_MODAL',
  OPEN_PROGRESSION_DELETE_ITEM_MODAL = 'modalManagement/OPEN_PROGRESSION_DELETE_ITEM_MODAL',
  CLOSE_PROGRESSION_DELETE_ITEM_MODAL = 'modalManagement/CLOSE_PROGRESSION_DELETE_ITEM_MODAL',
  OPEN_PLAYER_DATA_MODAL = 'modalManagement/OPEN_PLAYER_DATA_MODAL',
  CLOSE_PLAYER_DATA_MODAL = 'modalManagement/CLOSE_PLAYER_DATA_MODAL',
  OPEN_PROGRESSION_PUBLISH_MODAL = 'modalManagement/OPEN_PROGRESSION_PUBLISH_MODAL',
  CLOSE_PROGRESSION_PUBLISH_MODAL = 'modalManagement/CLOSE_PROGRESSION_PUBLISH_MODAL',
  OPEN_PROGRESSION_CHANGELOG_MODAL = 'modalManagement/OPEN_PROGRESSION_CHANGELOG_MODAL',
  CLOSE_PROGRESSION_CHANGELOG_MODAL = 'modalManagement/CLOSE_PROGRESSION_CHANGELOG_MODAL',
  OPEN_PROGRESSION_PLAYER_LOOKUP_MODAL = 'modalManagement/OPEN_PROGRESSION_PLAYER_LOOKUP_MODAL',
  CLOSE_PROGRESSION_PLAYER_LOOKUP_MODAL = 'modalManagement/CLOSE_PROGRESSION_PLAYER_LOOKUP_MODAL',
  OPEN_IN_GAME_ITEMS_MODAL = 'modalManagement/OPEN_IN_GAME_ITEMS_MODAL',
  CLOSE_IN_GAME_ITEMS_MODAL = 'modalManagement/CLOSE_IN_GAME_ITEMS_MODAL',
  OPEN_PROGRESSION_ROOM_PUBLISH_AND_ASSIGN_MODAL = 'modalManagement/OPEN_PROGRESSION_ROOM_PUBLISH_AND_ASSIGN_MODAL',
  CLOSE_PROGRESSION_ROOM_PUBLISH_AND_ASSIGN_MODAL = 'modalManagement/CLOSE_PROGRESSION_ROOM_PUBLISH_AND_ASSIGN_MODAL',
  OPEN_PROGRESSION_ROOM_SAVE_MODAL = 'modalManagement/OPEN_PROGRESSION_ROOM_SAVE_MODAL',
  CLOSE_PROGRESSION_ROOM_SAVE_MODAL = 'modalManagement/CLOSE_PROGRESSION_ROOM_SAVE_MODAL',
  OPEN_PROGRESSION_ROOM_CREATE_MODAL = 'modalManagement/OPEN_PROGRESSION_ROOM_CREATE_MODAL',
  CLOSE_PROGRESSION_ROOM_CREATE_MODAL = 'modalManagement/CLOSE_PROGRESSION_ROOM_CREATE_MODAL',
  OPEN_PROGRESSION_ROOM_DELETE_MODAL = 'modalManagement/OPEN_PROGRESSION_ROOM_DELETE_MODAL',
  CLOSE_PROGRESSION_ROOM_DELETE_MODAL = 'modalManagement/CLOSE_PROGRESSION_ROOM_DELETE_MODAL',
  OPEN_ENTRY_POINT_ASSIGN_AND_SYNC_MODAL = 'modalManagement/OPEN_ENTRY_POINT_ASSIGN_AND_SYNC_MODAL',
  CLOSE_ENTRY_POINT_ASSIGN_AND_SYNC_MODAL = 'modalManagement/CLOSE_ENTRY_POINT_ASSIGN_AND_SYNC_MODAL',
  OPEN_MODAL = 'modalManagement/OPEN_MODAL',
  CLOSE_MODAL = 'modalManagement/CLOSE_MODAL',
}

export type TModalAction = IAction<{ [modalOpenAttribute: string]: boolean }>;

export const openProfileModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_PROFILE_MODAL,
  };
};

export const closeProfileModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_PROFILE_MODAL,
  };
};

export const openGameInfoModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_GAME_INFO_MODAL,
  };
};

export const closeGameInfoModal = (): IActionNoData => {
  return { type: MODAL_MANAGEMENT.CLOSE_GAME_INFO_MODAL };
};

export const openArchiveModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_ARCHIVE_MODAL,
  };
};

export const closeArchiveModal = (): IActionNoData => {
  return { type: MODAL_MANAGEMENT.CLOSE_ARCHIVE_MODAL };
};

export const openFairnessModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_FAIRNESS_MODAL,
  };
};

export const closeFairnessModal = (): IActionNoData => {
  return { type: MODAL_MANAGEMENT.CLOSE_FAIRNESS_MODAL };
};

export const openPrizeEnablementModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_PRIZE_ENABLEMENT_MODAL,
  };
};

export const closePrizeEnablementModal = (): IActionNoData => {
  return { type: MODAL_MANAGEMENT.CLOSE_PRIZE_ENABLEMENT_MODAL };
};

export const openAccelerateModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_ACCELERATE_MODAL,
  };
};

export const closeAccelerateModal = (): IActionNoData => {
  return { type: MODAL_MANAGEMENT.CLOSE_ACCELERATE_MODAL };
};

export const openFeedbackModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_FEEDBACK_MODAL,
  };
};

export const closeFeedbackModal = (): IActionNoData => {
  return { type: MODAL_MANAGEMENT.CLOSE_FEEDBACK_MODAL };
};

export const openStudioModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_STUDIO_MODAL,
  };
};

export const closeStudioModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_STUDIO_MODAL,
  };
};

export const openUserModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_USER_MODAL,
  };
};

export const closeUserModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_USER_MODAL,
  };
};

export const openRemoveUserModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_REMOVE_USER_MODAL,
  };
};

export const closeRemoveUserModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_REMOVE_USER_MODAL,
  };
};

export const openDeactivateSkillzSupportModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_DEACTIVATE_SKILLZ_SUPPORT_MODAL,
  };
};

export const closeDeactivateSkillzSupportModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_DEACTIVATE_SKILLZ_SUPPORT_MODAL,
  };
};

export const openInviteUserModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_INVITE_USER_MODAL,
  };
};

export const closeInviteUserModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_INVITE_USER_MODAL,
  };
};

export const openTransferOwnershipModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_TRANSFER_OWNERSHIP_MODAL,
  };
};

export const closeTransferOwnershipModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_TRANSFER_OWNERSHIP_MODAL,
  };
};

export const openEditUserModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_EDIT_USER_MODAL,
  };
};

export const closeEditUserModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_EDIT_USER_MODAL,
  };
};

export const openProgressionDeleteItemModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_PROGRESSION_DELETE_ITEM_MODAL,
  };
};

export const closeProgressionDeleteItemModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_PROGRESSION_DELETE_ITEM_MODAL,
  };
};

export const openPlayerDataModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_PLAYER_DATA_MODAL,
  };
};

export const closePlayerDataModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_PLAYER_DATA_MODAL,
  };
};

export const openProgressionPublishModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_PROGRESSION_PUBLISH_MODAL,
  };
};

export const closeProgressionPublishModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_PROGRESSION_PUBLISH_MODAL,
  };
};

export const openProgressionChangelogModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_PROGRESSION_CHANGELOG_MODAL,
  };
};

export const closeProgressionChangelogModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_PROGRESSION_CHANGELOG_MODAL,
  };
};
export const openProgressionPlayerLookupModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_PROGRESSION_PLAYER_LOOKUP_MODAL,
  };
};

export const closeProgressionPlayerLookupModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_PROGRESSION_PLAYER_LOOKUP_MODAL,
  };
};

export const openInGameItemsModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_IN_GAME_ITEMS_MODAL,
  };
};

export const closeInGameItemsModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_IN_GAME_ITEMS_MODAL,
  };
};

export const openEntryPointAssignAndSyncModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_ENTRY_POINT_ASSIGN_AND_SYNC_MODAL,
  };
};

export const closeEntryPointAssignAndSyncModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_ENTRY_POINT_ASSIGN_AND_SYNC_MODAL,
  };
};

export const openProgressionRoomSaveModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_PROGRESSION_ROOM_SAVE_MODAL,
  };
};

export const closeProgressionRoomSaveModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_PROGRESSION_ROOM_SAVE_MODAL,
  };
};

export const openProgressionRoomCreateModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_PROGRESSION_ROOM_CREATE_MODAL,
  };
};

export const closeProgressionRoomCreateModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_PROGRESSION_ROOM_CREATE_MODAL,
  };
};

export const openProgressionRoomDeleteModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.OPEN_PROGRESSION_ROOM_DELETE_MODAL,
  };
};

export const closeProgressionRoomDeleteModal = (): IActionNoData => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_PROGRESSION_ROOM_DELETE_MODAL,
  };
};

export const openModal = (modalOpenAttribute: string): TModalAction => {
  return {
    type: MODAL_MANAGEMENT.OPEN_MODAL,
    data: { [modalOpenAttribute]: true },
  };
};

export const closeModal = (modalOpenAttribute: string): TModalAction => {
  return {
    type: MODAL_MANAGEMENT.CLOSE_MODAL,
    data: { [modalOpenAttribute]: false },
  };
};
