import { IDashboardGame } from '@app/common/interfaces';
import { STATUS } from '@app/modules/GameManagement/common/constants';

export function isGameLaunched(game: IDashboardGame): boolean {
  return game.live === STATUS.COMPLETED;
}

export function hasLaunchedToStore(games: IDashboardGame[]): boolean {
  return games.some(isGameLaunched);
}

export function hasFirstGame(games: IDashboardGame[]): boolean {
  return games.length > 0;
}
