import * as React from 'react';

import { Link } from 'react-router-dom';

import { TTooltipNoAccessConnectedProps } from '.';
import { GrayTooltipWithStyle } from '../TooltipWithStyle';
import './styles.scss';

const TooltipNoAccess = (props: TTooltipNoAccessConnectedProps) => {
  const { children, publisherId, feature, active: isActive } = props;

  if (!isActive || !feature.userRolesU6GameRolesAndPermissions) {
    return children;
  }

  const tooltipContent = (
    <div className={`no-access-tooltip`} data-qa={'no-access-tooltip'}>
      <div className={`no-access-tooltip__text`}>
        Ask your account Admins for access to this feature.
      </div>
      <div className={`no-access-tooltip__url`}>
        <Link to={`/account/${publisherId}/teammates`}>View Admins</Link>
      </div>
    </div>
  );

  return (
    <GrayTooltipWithStyle
      id={`no-access-tooltip`}
      title={tooltipContent}
      arrow={true}
      enterDelay={500}
      placement={'top-end'}
    >
      <div> {children}</div>
    </GrayTooltipWithStyle>
  );
};

export default TooltipNoAccess;
