export const ONE_KB_IN_BYTES: number = Math.pow(1024, 1); // 1KB

export const ONE_MB_IN_BYTES: number = Math.pow(1024, 2); // 1MB

export const ONE_GB_IN_BYTES: number = Math.pow(1024, 3);

export enum BYTE_UNIT {
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
}

export const convertByteToUnit = (byteSize: number, unit: BYTE_UNIT): string => {
  let bytes = 0;
  switch (unit) {
    case BYTE_UNIT.KB:
      bytes = byteSize / ONE_KB_IN_BYTES;
      break;
    case BYTE_UNIT.MB:
      bytes = byteSize / ONE_MB_IN_BYTES;
      break;
    case BYTE_UNIT.GB:
      bytes = byteSize / ONE_GB_IN_BYTES;
      break;
    default:
      bytes = byteSize / ONE_MB_IN_BYTES;
      break;
  }

  return `${bytes.toFixed(2)} ${unit}`;
};
