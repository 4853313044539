export enum GAME_STATES {
  GAME_INFORMATION = 'gameInformation',
  DISTRIBUTION = 'distribution',
  SKILLZ_SDK = 'skillzSdk', // also known as setupSDK page
  PHOTON_SETUP = 'photonSetup',
  INITIALIZE_SKILLZ_UI = 'initializeSkillzUi',
  INITIALIZE_SKILLZ_UI_VERIFICATION = 'initializeSkillzUiVerification',
  CORE_LOOP = 'coreLoop',
  CORE_LOOP_VERIFICATION = 'coreLoopVerification',
  FAIRNESS = 'fairness',
  SANDBOX_READY = 'sandboxReady',
  INTRODUCTION = 'introduction',
  SDK_THEME = 'sdkTheme',
  TOURNAMENTS = 'tournaments',
  VIRTUAL_CURRENCY = 'virtualCurrency',
  APPLE_PAY = 'applePay',
  DEEP_LINKING = 'deepLinking',
  SINGLE_SIGN_ON = 'singleSignOn',
  PUSH_NOTIFICATIONS = 'pushNotifications',
  UPLOAD_BINARY = 'uploadBinary',
  VERIFY_CONFIGURATION = 'verifyConfiguration',
  PUBLISH_GAME = 'publishGame',
  NEXT_STEPS = 'nextSteps',
  LIVE = 'live',
  HAS_SEEN_PRIZE_ENABLEMENT_SUCCESS = 'hasSeenPrizeEnablementSuccess',
  THIRD_PARTY_ADS = 'thirdPartyAds',
  PLAYER_DATA = 'playerData',
  IN_GAME_ITEMS = 'inGameItems',
  PROGRESSION_ROOMS = 'progression_rooms',
  FTUE_CONFIGURATION = 'ftueConfiguration',
}

export enum GAME_STATES_REQUEST {
  GAME_INFORMATION = 'game_information',
}

export enum STATUS {
  NOT_NEEDED = -1,
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
  ACTION_REQUIRED = 3,
}
