import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { showSurvey } from '@app/modules/Survey/actions';

const CaseStudyBenchmarks = loadable(
  () => import(/* webpackChunkName: "case-study-benchmarks" */ './CaseStudyBenchmarks')
);

const mapDispatchToProps = { showSurvey };

const connector = connect(null, mapDispatchToProps);

export type TCaseStudyBenchmarksConnectedProps = ConnectedProps<typeof connector> &
  RouteComponentProps;

export default connector(withRouter(CaseStudyBenchmarks));
