import * as React from 'react';
import Chip from '@mui/material/Chip';

import './styles/atomMetaTagStyles.scss';

export interface IAtomMetaTagProps {
  id: string;
  chipText: string;
}

class AtomMetaTag extends React.PureComponent<IAtomMetaTagProps> {
  public renderChipText(chipText: string): JSX.Element {
    return <span className="atom-component__meta-tag-text"> {chipText}</span>;
  }

  public render(): JSX.Element {
    let { id, chipText } = this.props;

    return (
      <Chip
        className="atom-component__meta-tag-styles"
        id={id}
        label={this.renderChipText(chipText)}
      />
    );
  }
}

export { AtomMetaTag };
