import merge from 'lodash/merge';

import { GAMES } from '@app/common/actions';
import { findNestedIndex } from '@app/common/helpers';
import { IAction, IActionNoData, IDashboardGame, TGamesPerPage } from '@app/common/interfaces';

// type guard for actions with data
function hasActionData(
  action: IAction<TGamesPerPage> | IActionNoData
): action is IAction<TGamesPerPage> {
  return (action as IAction<TGamesPerPage>).hasOwnProperty('data');
}

export const itemsInitialState: TGamesPerPage = {};

export function itemsReducer(
  state: TGamesPerPage = itemsInitialState,
  action: IAction<TGamesPerPage> | IActionNoData
): TGamesPerPage {
  let newState: TGamesPerPage;

  Object.freeze(state);

  switch (action.type) {
    case GAMES.CLEAR_GAMES:
      return itemsInitialState;

    case GAMES.RECEIVE_GAMES:
      if (hasActionData(action)) {
        newState = merge({}, state, action.data);

        return newState;
      }

      return state;
    case GAMES.RECEIVE_UPDATED_GAME:
      if (hasActionData(action)) {
        let firstKey: string = Object.keys(action.data)[0];
        let page: number = parseInt(firstKey, 10);

        if (isNaN(page) || page < 0) {
          return state;
        }

        let game: IDashboardGame = action.data[page][0];
        game.updatedAt = new Date().toISOString();
        let gameIndex: number = findNestedIndex(state[page], game.id);

        if (gameIndex > -1) {
          // copy all levels of nested state and set active attribute from response data
          newState = merge({}, state, {
            [page]: {
              [gameIndex]: game
            }
          });

          return newState;
        }
      }

      return state;
    default:
      return state;
  }
}
