import { GAME_STATES } from '@app/modules/GameManagement/common/constants';
import { ITabTooltip } from '@appGameHome/common/interfaces';

type TGameStateWithUrl =
  | GAME_STATES.APPLE_PAY
  | GAME_STATES.DEEP_LINKING
  | GAME_STATES.DISTRIBUTION
  | GAME_STATES.PUSH_NOTIFICATIONS
  | GAME_STATES.SDK_THEME
  | GAME_STATES.SINGLE_SIGN_ON
  | GAME_STATES.TOURNAMENTS
  | GAME_STATES.UPLOAD_BINARY
  | GAME_STATES.VIRTUAL_CURRENCY
  | GAME_STATES.THIRD_PARTY_ADS
  | GAME_STATES.PLAYER_DATA
  | GAME_STATES.IN_GAME_ITEMS
  | GAME_STATES.SKILLZ_SDK;

export type IGameStatePageUrl = { [key in TGameStateWithUrl]: string };

export const GAME_HOME_TAB_BASE_URLS = [
  'overview',
  'optimize',
  'game_builds',
  'progression',
  'seasons',
  'deployments',
];

export const GAME_HOME_PAGE_URL: IGameStatePageUrl = {
  [GAME_STATES.APPLE_PAY]: 'apple_pay',
  [GAME_STATES.DEEP_LINKING]: 'deep_linking',
  [GAME_STATES.DISTRIBUTION]: 'distribution',
  [GAME_STATES.PUSH_NOTIFICATIONS]: 'push_notifications',
  [GAME_STATES.SDK_THEME]: 'theme',
  [GAME_STATES.SINGLE_SIGN_ON]: 'single_sign_on',
  [GAME_STATES.TOURNAMENTS]: 'tournaments',
  [GAME_STATES.UPLOAD_BINARY]: 'game_builds',
  [GAME_STATES.VIRTUAL_CURRENCY]: 'manage_in_app_purchase_package',
  [GAME_STATES.THIRD_PARTY_ADS]: 'monetization',
  [GAME_STATES.PLAYER_DATA]: 'player_data',
  [GAME_STATES.IN_GAME_ITEMS]: 'in_game_items',
  // placeholder for Update Sdk
  [GAME_STATES.SKILLZ_SDK]: '',
};

export type TGameHomePageUrlKey = keyof typeof GAME_HOME_PAGE_URL;

export enum TAB_PATH_ENUM {
  OVERVIEW = 'overview',
  OPTIMIZE = 'optimize',
  GAME_BUILDS = 'game_builds',
  PLAYER_DATA = 'player_data',
  IN_GAME_ITEMS = 'in_game_items',
  PROGRESSION = 'progression',
  SEASONS = 'seasons',
  DEPLOYMENTS = 'deployments',
}

type TTabTooltip = { [key in TAB_PATH_ENUM]?: ITabTooltip };

export const TAB_TOOLTIPS: TTabTooltip = {
  [TAB_PATH_ENUM.PLAYER_DATA]: {
    tab: TAB_PATH_ENUM.PLAYER_DATA,
    cookieName: 'player-data-tooltip-cookie',
    copy: 'New feature! Create custom player data here to engage with your players.',
    expirationDate: '7/31/2021',
    launchDate: '4/22/2021',
  },
  [TAB_PATH_ENUM.IN_GAME_ITEMS]: {
    tab: TAB_PATH_ENUM.IN_GAME_ITEMS,
    cookieName: 'in-game-items-tooltip',
    copy: 'New feature! Create custom in game items here to engage with your players.',
    expirationDate: '8/31/2021',
    launchDate: '7/14/2021',
  },
  [TAB_PATH_ENUM.PROGRESSION]: {
    tab: TAB_PATH_ENUM.PROGRESSION,
    cookieName: 'in-game-items-tooltip',
    copy: 'New feature! Create custom in game items here to engage with your players.',
    expirationDate: '8/31/2021',
    launchDate: '7/14/2021',
  },
};
