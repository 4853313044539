/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// TODO UPDATE [U3] update types when types are defined
import { Component } from 'react';

import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchCurrentUser, fetchPermissions, updateCurrentUser } from '@app/common/actions';
import { IWithSnackbarProp } from '@app/common/helpers/snackbarHookHelper';
import { IRootState } from '@app/core/rootReducer';

const Account = loadable(
  () => import(/* webpackChunkName: "account" */ './Account')
) as typeof Component;

const mapStateToProps = (state: IRootState) => {
  let {
    currentUser,
    publisher: { publisherInfo },
    feature,
    permissions,
  } = state;

  return {
    publisherInfo,
    currentUser,
    feature,
    permissions,
  };
};

const mapDispatchToProps = {
  fetchCurrentUser,
  updateCurrentUser,
  fetchPermissions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TAccountConnectedProps = ConnectedProps<typeof connector> & IWithSnackbarProp;

export default connector(withRouter(Account));
