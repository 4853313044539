import * as React from 'react';

import { connect } from 'react-redux';

import { AtomButton } from '@app/common/atoms';

import { TooltipNoAccess } from '.';
import { IRootState } from '../../core/rootReducer';
import { GAME_PERMISSIONS } from '../constants';
import { IFeature, IGameInfo, IPermissions } from '../interfaces';

declare const swal: (param: unknown) => void;

export interface ISyncButtonProps {
  gameInfo: IGameInfo;
  permissions: IPermissions;
  feature: IFeature;
}

class ProductionSyncButton extends React.Component<ISyncButtonProps> {
  public sync = (id: number): JQuery.jqXHR<unknown> => {
    let form: FormData = new FormData();
    form.append('id', String(id));

    return $.ajax({
      url: `/games/${id}/sync_prod.json`,
      method: 'GET',
      data: form,
      processData: false,
      contentType: false,
      success(): void {
        swal({
          type: 'success',
          title: 'Success!',
          allowOutsideClick: false,
          confirmButtonClass: 'integration-swal-confirm',
        });
      },
      // eslint-disable-next-line
      error(XMLHttpRequest: any, textStatus: string, errorThrown: string): void {
        swal({
          type: 'error',
          title: 'Oh no!',
          // eslint-disable-next-line
          text: XMLHttpRequest.responseJSON.errors,
          allowOutsideClick: false,
          confirmButtonClass: 'integration-swal-confirm',
        });
      },
    });
  };

  public handleClick = (): void => {
    swal({
      title: 'Hold Please!',
      html: 'Processing ... <i class="fa fa-cog fa-2x fa-spin"></i>',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    // performs sync and shows success and error swals.
    void this.sync(this.props.gameInfo.id);
  };

  public render(): JSX.Element {
    const { feature, permissions } = this.props;

    const isDisabled =
      feature.userRolesU6GameRolesAndPermissions && !permissions[GAME_PERMISSIONS.PUBLISH];

    return (
      <div style={{ display: 'flex' }}>
        <TooltipNoAccess active={isDisabled}>
          <AtomButton
            id="production-sync-button"
            buttonContent="SYNC"
            buttonType="primary"
            onClick={this.handleClick}
            isDisabled={isDisabled}
          />
        </TooltipNoAccess>
      </div>
    );
  }
}

export function mapStateToProps({ gameInfo, permissions, feature }: IRootState): ISyncButtonProps {
  return {
    gameInfo,
    permissions,
    feature,
  };
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const SyncButton: any = connect(mapStateToProps, null)(ProductionSyncButton);
