import * as React from 'react';

import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { Theme, createTheme, ThemeProvider } from '@mui/material/styles';

import variables from '@app/core/variables.scss';
import info from '@app/imageAssets/info.svg';

import './styles/atomTextField.scss';

export interface IArrowTooltipProps extends TooltipProps {
  muitheme: Theme;
}

export interface ITooltipProps {
  copy: string | JSX.Element;
  placement: 'top' | 'bottom' | 'right' | 'left';
  disableInteractive?: boolean;
  muitheme?: Theme;
  className?: string;
  id?: string;
  icon?: string;
  shouldDisableHover?: boolean;
  shouldEnableClick?: boolean;
  isOpen?: boolean;
}

type TTooltipOptionalProps = 'className' | 'id' | 'icon' | 'muitheme';

type IDefaultProps = Required<Pick<ITooltipProps, TTooltipOptionalProps>>;

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Roboto',
          color: `${variables.colorGray500SecondaryText}`,
          backgroundColor: `${variables.colorWhite}`,
          borderColor: `${variables.colorWhite}`,
          boxShadow: `0 4px 8px 0 ${variables.colorGray100Borders}`,
          position: 'relative',
          maxWidth: 500,
        },
        arrow: {
          color: `${variables.colorWhite}`,
          fontSize: 6,
        },
      },
    },
  },
});

function ArrowTooltip(props: IArrowTooltipProps): JSX.Element {
  const { muitheme, ...restProps } = props;

  return (
    <ThemeProvider theme={muitheme}>
      <Tooltip
        arrow
        classes={{ tooltip: 'atom-tooltip', tooltipArrow: 'atom-tooltipArrow' }}
        enterTouchDelay={100}
        leaveTouchDelay={5000}
        {...restProps}
        title={<React.Fragment>{props.title}</React.Fragment>}
      />
    </ThemeProvider>
  );
}

class AtomTooltip extends React.PureComponent<ITooltipProps & IDefaultProps> {
  public static defaultProps: IDefaultProps = {
    className: 'atom-tooltip__',
    id: 'atom-tooltip__',
    icon: info,
    muitheme: theme,
  };

  public render(): JSX.Element {
    let {
      copy,
      placement,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      disableInteractive,
      className,
      id,
      icon,
      muitheme,
      shouldDisableHover,
      isOpen,
    } = this.props;

    return (
      <ArrowTooltip
        title={copy}
        placement={placement}
        disableInteractive={disableInteractive}
        muitheme={muitheme}
        disableHoverListener={shouldDisableHover}
        open={isOpen}
        className="atom-component__tooltip"
      >
        <img className={`${className}-tooltip-icon`} id={`${id}-tooltip-icon-id`} src={icon} />
      </ArrowTooltip>
    );
  }
}

export { AtomTooltip };
