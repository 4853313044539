import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Loading } from '@app/common/components';
import { IFeature, ILoadableComponent, ISurveyTool, IUser } from '@app/common/interfaces';
import { IRootState } from '@app/core/rootReducer';

import { fetchAllQuestions, hideSurvey } from './actions';
import { ICreateResponseData, IPatchResponseData } from './interfaces';
import { createResponse, updateResponse } from './utils';

const Survey: ILoadableComponent['component'] = Loadable({
  loader: (): Promise<any> => import(/* webpackChunkName: "survey-tool" */ './Survey'),
  loading: Loading
});

interface IStateProps {
  feature: IFeature;
  survey: ISurveyTool;
  user: IUser;
}

const mapStateToProps = (state: IRootState): IStateProps => {
  return {
    feature: state.feature,
    survey: state.survey,
    user: state.user
  };
};
interface IDispatchProps {
  hideSurvey: (fromLocation: string) => Promise<any>;
  fetchAllQuestions: (userId: number) => Promise<any>;
  createResponse: (surveyResponse: ICreateResponseData) => JQuery.jqXHR<any>;
  updateResponse: (surveyResponse: IPatchResponseData) => JQuery.jqXHR<any>;
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    hideSurvey: (fromLocation: string): Promise<any> => dispatch(hideSurvey(fromLocation)),
    fetchAllQuestions: (userId: number): Promise<any> => dispatch(fetchAllQuestions(userId)),
    createResponse,
    updateResponse
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Survey));
