import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  fetchGameChallenge,
  fetchGameChallengeProposal,
  fetchPermissions,
} from '@app/common/actions';
import { IRootState } from '@app/core/rootReducer';

const NflChallenge = loadable(
  () => import(/* webpackChunkName: "nfl-challenge" */ './NflChallenge')
);

const mapStateToProps = (state: IRootState) => {
  const { feature } = state;

  return {
    feature,
  };
};

const mapDispatchToProps = {
  fetchGameChallenge,
  fetchGameChallengeProposal,
  fetchPermissions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TNflChallengeConnectedProps = ConnectedProps<typeof connector>;

export default connector(withRouter(NflChallenge));
