/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosResponse } from 'axios';
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import { Dispatch } from 'redux';

import { snakeCaseConverter } from '@app/common/helpers';
import {
  IAction,
  IAnalyticsQuery,
  IDateRange,
  IAnalyticDataResponse,
  IAnalyticInfo,
} from '@app/common/interfaces';

export enum ANALYTICS {
  RECEIVE_ADMIN_ACCESS = 'analytics/RECEIVE_ADMIN_ACCESS',
  RECEIVE_RAW_DATA = 'analytics/RECEIVE_RAW_DATA',
  RECEIVE_DATE_RANGE_LIMIT = 'analytics/RECEIVE_DATE_RANGE_LIMIT',
}

export function receiveAnalyticsRawData(data: IAnalyticInfo[]): IAction<IAnalyticInfo[]> {
  return {
    type: ANALYTICS.RECEIVE_RAW_DATA,
    data,
  };
}

export function receiveDateRangeLimit(data: IDateRange<string>): IAction<IDateRange<string>> {
  return {
    type: ANALYTICS.RECEIVE_DATE_RANGE_LIMIT,
    data,
  };
}

export function fetchAnalytics(analyticsQuery: IAnalyticsQuery) {
  return async (dispatch: Dispatch<IAction<boolean> | IAction<IAnalyticInfo[]>>): Promise<void> => {
    const response: AxiosResponse<IAnalyticDataResponse> = await axios.get(
      '/api/v1/developer_analytics',
      {
        params: snakeCaseConverter(analyticsQuery),
      }
    );

    const { data } = camelize(response.data);
    dispatch(receiveAnalyticsRawData(data));
  };
}

export function fetchAnalyticsDateRangeLimit() {
  return async (dispatch: Dispatch<IAction<IDateRange<string>>>): Promise<void> => {
    const response = await axios.get(`/api/v1/developer_analytics/date_range_limit`);

    dispatch(receiveDateRangeLimit(camelize(response.data)));
  };
}
