import { Dispatch } from 'redux';

import { IAction } from '@app/common/interfaces';
import { IQuestionSet } from '@app/common/interfaces';

import * as ApiUtils from './utils';

export enum SURVEY_TOOL {
  SHOW_SURVEY = 'survey/SHOW_SURVEY',
  HIDE_SURVEY = 'survey/HIDE_SURVEY',
  RECEIVE_QUESTIONS = 'survey/RECEIVE_QUESTIONS',
}

export const showSurvey = (data: string): IAction<string> => {
  return {
    type: SURVEY_TOOL.SHOW_SURVEY,
    data,
  };
};

export const hideSurvey = (fromLocation: string): IAction<string> => {
  return {
    type: SURVEY_TOOL.HIDE_SURVEY,
    data: fromLocation,
  };
};

export const receiveQuestions = (data: IQuestionSet): IAction<IQuestionSet> => {
  return {
    type: SURVEY_TOOL.RECEIVE_QUESTIONS,
    data,
  };
};

export const fetchAllQuestions = (userId: number) => {
  return (dispatch: Dispatch<IAction<IQuestionSet>>): void => {
    void ApiUtils.fetchValidQuestions(userId).then((response: IQuestionSet) => {
      dispatch(receiveQuestions(response));
    });
  };
};
