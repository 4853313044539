import React from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Store } from 'redux';

import Application from './app';
import { IRootState } from './rootReducer';

interface IRootProps {
  store: Store<IRootState>;
}

const Root = ({ store }: IRootProps): JSX.Element => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Application />
      </BrowserRouter>
    </Provider>
  );
};

export default Root;
