import { createAsyncThunk } from '@reduxjs/toolkit';

import axios, { AxiosError, AxiosResponse } from 'axios';
// @ts-ignore: camelize is by definition an un-typeable Any => Any
import camelize from 'camelize';
import merge from 'lodash/merge';

import { ENVIRONMENT } from '@app/common/constants';
import { IErrors } from '@app/common/interfaces';
import { KEY_NAMESPACE } from '@appGameProgression/common/constants';
import {
  IInGameItemsSchema,
  IInGameItemsSchemaChangelog,
  IProgressionSchemaChangelogsResponse,
  IProgressionSchemaResponse,
} from '@appGameProgression/common/interfaces';

interface IFetchInGameItemsProps {
  env: ENVIRONMENT;
}

type AxiosResponseInGameItemsChangelogs = AxiosResponse<
  IProgressionSchemaChangelogsResponse<IInGameItemsSchemaChangelog>
>;

export const fetchInGameItemsSchema = createAsyncThunk(
  'progression/fetchInGameItemsSchema',
  async ({ env }: IFetchInGameItemsProps) => {
    return axios
      .get(`/api/v1/progression_schemas/${KEY_NAMESPACE.IN_GAME_ITEMS}/${env}`)
      .then(camelize)
      .then((response: AxiosResponse<IProgressionSchemaResponse<IInGameItemsSchema>>) => {
        return merge(response.data, { env });
      })
      .catch((e) => {
        const error = e as AxiosError<IErrors>;

        throw new Error(error.response?.data.errors);
      });
  }
);

export const fetchInGameItemsSchemaChangelogs = createAsyncThunk(
  'progression/fetchInGameItemsSchemaChangelogs',
  async () => {
    return axios
      .get(
        `/api/v1/progression_schemas/${KEY_NAMESPACE.IN_GAME_ITEMS}/${ENVIRONMENT.PRODUCTION}/changelogs`
      )
      .then(camelize)
      .then((response: AxiosResponseInGameItemsChangelogs) => {
        return merge(response.data, { env: ENVIRONMENT.PRODUCTION });
      })
      .catch((e) => {
        const error = e as AxiosError<IErrors>;

        throw new Error(error.response?.data.errors);
      });
  }
);
