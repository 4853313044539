import isEmpty from 'lodash/isEmpty';

import { MATH_OPERATIONS } from '@app/common/constants';
import { IDateTotalCount, ITotalCount } from '@app/common/interfaces';

/**
 * calculates either the sum or the average of ITotalCounts passed to it in the IDateTotalCount
 * @param { IDateTotalCount<ITotalCount> } data - An object containing dateStrings as keys with ITotalCounts as values
 * @param { MATH_OPERATIONS } mathOperation - A ENUM with string key with the name of the math operation to perform,
 * will be either 'sum' or 'average'
 * @returns { number } - either the sum or average of the values of the ITotalCounts
 */

export function calculateSumOrAverage(
  data: IDateTotalCount<ITotalCount>,
  mathOperation: MATH_OPERATIONS
): number {
  // on the initial render, return 0 when the data object is empty
  if (isEmpty(data)) {
    return 0;
  }

  let { total, count } = calculateTotalAndCountOfAttribute(data);

  if (mathOperation === MATH_OPERATIONS.AVERAGE && !!count) {
    return parseFloat((total / count).toFixed(2));
  } else {
    return total;
  }
}

/**
 * sums all of the values and counts of ITotalCounts passed to it in the IDateTotalCount
 * @param { IDateTotalCount<ITotalCount> } data - An object containing dateStrings as keys with ITotalCounts as values
 * @returns { ITotalCount } - returns a total and count
 * of the values and the second index is the sum of the counts
 */

export function calculateTotalAndCountOfAttribute(data: IDateTotalCount<ITotalCount>): ITotalCount {
  let total: number = 0;
  let count: number = 0;

  Object.values(data).forEach((point: ITotalCount) => {
    total += point.total;
    count += point.count;
  });

  return { total, count };
}
