import { IModalGame } from '@app/common/interfaces';

export function generateModalGameFormData(game: IModalGame): FormData {
  let form: FormData = new FormData();
  form.append('game[platform]', game.platform);
  form.append('game[title]', game.title);
  form.append('game[description]', game.description);
  form.append('game[development_environment]', game.developmentEnvironment as string);
  if (game.icon) {
    form.append('game[icon]', game.icon);
  }

  return form;
}
