import { GAMES_COUNT } from '@app/common/actions';
import { IAction } from '@app/common/interfaces';

export function totalCountReducer(state: number = 0, action: IAction<number>): number {
  Object.freeze(state);

  switch (action.type) {
    case GAMES_COUNT.RECEIVE_COUNT:
      return action.data;
    default:
      return state;
  }
}
