import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosResponse } from 'axios';
// @ts-ignore: camelize is by definition an un-typeable Any => Any
import camelize from 'camelize';

import { IErrors, IPublisher2, TPublisherUpdateParams } from '@app/common/interfaces';

const basePublisherUrl = '/api_internal/v1/publisher';

const preparePublisherData = (publisher: TPublisherUpdateParams): FormData => {
  let form = new FormData();

  if (!!publisher.logo) {
    form.append('publisher[logo]', publisher.logo);
  }

  // can not use !! syntax since field is boolean | undefined
  if (publisher.allowSkillzSupportAccess !== undefined) {
    form.append(
      'publisher[allow_skillz_support_access]',
      publisher.allowSkillzSupportAccess ? 'true' : 'false'
    );
  }

  if (!!publisher.companySize) {
    form.append('publisher[company_size]', publisher.companySize);
  }

  if (!!publisher.logo) {
    form.append('publisher[logo]', publisher.logo);
  }

  if (!!publisher.name) {
    form.append('publisher[name]', publisher.name);
  }

  if (!!publisher.phoneNumber) {
    form.append('publisher[phone_number]', publisher.phoneNumber);
  }

  if (!!publisher.website) {
    form.append('publisher[website]', publisher.website);
  }

  return form;
};

export const fetchPublisher = createAsyncThunk('publisher/fetch', async () => {
  const response: AxiosResponse<IPublisher2> = await axios.get(basePublisherUrl).then(camelize);

  return response.data;
});

export const updatePublisher = createAsyncThunk<IPublisher2, TPublisherUpdateParams>(
  'publisher/update',
  (publisher: TPublisherUpdateParams) => {
    return axios
      .patch(basePublisherUrl, preparePublisherData(publisher))
      .then(camelize)
      .then((response: AxiosResponse<IPublisher2>) => {
        return response.data;
      })
      .catch((e) => {
        const error = e as AxiosError<IErrors>;

        throw new Error(error.response?.data.errors);
      });
  }
);
