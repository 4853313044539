import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import PhotoSizeSelectActual from '@mui/icons-material/PhotoSizeSelectActual';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderGetTagProps,
} from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip, { ChipProps } from '@mui/material/Chip';

import omit from 'lodash/omit';
import AtomTextField from 'portal-common-library/atoms/AtomTextField';
import { IBaseProps } from 'portal-common-library/interfaces/componentBase';
import { IDataAttributes } from 'portal-common-library/interfaces/dataAttributes';

interface OptionDetails {
  iconUrl: string;
  label: string;
  isDisabled?: boolean;
}

export interface IRequiredKey {
  key: string;
}

export interface IBaseAutocompleteProps<T>
  extends Omit<IBaseProps, 'id'>, // conflicts with AutocompleteProps?.id
    Omit<AutocompleteProps<T, true, false, false>, 'renderInput' | 'ref'> {
  placeholder?: string;
  allowRemove?: boolean;
  shouldShowIcon?: boolean;
  getOptionDetails: (option: T) => OptionDetails;
}

// this is globally styled via app.scss
class BaseAutocomplete<T extends IRequiredKey> extends React.Component<IBaseAutocompleteProps<T>> {
  public static defaultProps = {
    placeholder: '',
    allowRemove: true,
    shouldShowIcon: true,
  };

  private readonly getOptionLabel = (option: T) => {
    const { getOptionDetails } = this.props;

    return getOptionDetails(option).label;
  };

  private readonly renderOption = (
    passDownProps: React.HTMLAttributes<HTMLLIElement>,
    option: T
  ) => {
    const { getOptionDetails, shouldShowIcon, dataAttributes } = this.props;

    const details = getOptionDetails(option);

    const dataQa: IDataAttributes = { 'data-qa': `${dataAttributes['data-qa']}_option` };
    const optionDataAttributes: IDataAttributes = { ...dataAttributes, ...dataQa };

    return (
      <Box component="li" {...passDownProps} key={option.key}>
        {shouldShowIcon && (
          <Avatar src={details.iconUrl}>
            <PhotoSizeSelectActual />
          </Avatar>
        )}
        {/* TODO: WS-16165 Add uniq id to span for QA purposes */}
        <span {...optionDataAttributes}>{details.label}</span>
      </Box>
    );
  };

  private readonly renderTags = (tags: T[], getTagProps: AutocompleteRenderGetTagProps) => {
    const { getOptionDetails, shouldShowIcon, allowRemove, dataAttributes } = this.props;

    return tags.map((option, index) => {
      const dataQa: IDataAttributes = { 'data-qa': `${dataAttributes['data-qa']}_chip` };
      const chipDataAttributes: IDataAttributes = { ...dataAttributes, ...dataQa };
      const { iconUrl, label, isDisabled } = getOptionDetails(option);
      const avatar = shouldShowIcon ? (
        <Avatar src={iconUrl}>
          <PhotoSizeSelectActual />
        </Avatar>
      ) : undefined;

      let tagProps: ChipProps = getTagProps({ index });

      if (!allowRemove || isDisabled) {
        tagProps = omit(tagProps, 'onDelete');
      }

      return (
        // eslint-disable-next-line react/jsx-key -- key provided by tagProps
        <Chip
          avatar={avatar}
          label={label}
          {...tagProps}
          {...chipDataAttributes}
          // TODO: WS-16165 Add uniq id to CloseIcon for QA purposes
          deleteIcon={<CloseIcon />}
          disabled={isDisabled}
        />
      );
    });
  };

  private readonly noop = () => null;

  private readonly renderInput = (params: AutocompleteRenderInputParams) => {
    const { placeholder } = this.props;

    return (
      <AtomTextField
        {...params}
        // bunch of TextField arguments that required for no reason
        onChange={this.noop}
        label=""
        value=""
        attributeName=""
        placeholder={placeholder ?? ''}
        dataAttributes={{ 'data-qa': 'games-autoselect__game-input' }}
      />
    );
  };

  public render(): JSX.Element {
    const { dataAttributes } = this.props;

    return (
      <Autocomplete
        multiple
        getOptionLabel={this.getOptionLabel}
        renderInput={this.renderInput}
        renderOption={this.renderOption}
        renderTags={this.renderTags}
        {...omit(
          this.props,
          'getOptionDetails',
          'allowRemove',
          'shouldShowIcon',
          'placeholder',
          'dataAttributes',
          'gameAccess',
          'userRolesU6GameRolesAndPermissions'
        )}
        {...dataAttributes}
      />
    );
  }
}

export { BaseAutocomplete };
