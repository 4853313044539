import { PLATFORM } from '@app/common/constants';
import { IValidation } from '@app/common/helpers';

export const GAME_BUILDS_PER_PAGE: number = 10;

export enum GAME_BUILD_STATUS {
  FAILURE_PROCESSING = 'failure_processing',
  FAILURE_UPLOAD = 'failure_upload',
  CANCELLED = 'cancelled',
  IN_PROGRESS = 'in_progress',
  PROCESSING = 'processing',
  COMPLETE = 'complete',
}

export const GAME_BUILD_PROCESS_END_STATE = [
  GAME_BUILD_STATUS.FAILURE_PROCESSING,
  GAME_BUILD_STATUS.CANCELLED,
  GAME_BUILD_STATUS.COMPLETE,
];

export type BINARY_PLATFORM = Exclude<PLATFORM, PLATFORM.CROSS_PLATFORM>;

export const UPLOADER_TOOLTIP_COPY: string =
  'When you upload a build to the Skillz servers you enable your game to be seen by Skillz players worldwide. When you develop new features you will upload your new game build here.';

export const QA_CHECKLIST_LINK: string =
  'https://docs.skillz.com/docs/release-your-game-overview/#test-skillz-in-production';

export const RELEASE_GUIDE_LINK: string =
  'https://docs.skillz.com/docs/release-your-game-overview/';

export enum FILE_TYPE {
  IPA = '.ipa',
  APK = '.apk',
  BLANK = '',
}

export enum CONTENT_TYPE {
  IPA = 'application/octet-stream',
  APK = 'application/vnd.android.package-archive',
}

export enum ATTRIBUTE_NAME {
  NAME = 'name',
  GAME_VERSION = 'gameVersion',
  DESCRIPTION = 'description',
}

export enum READABLE_ATTRIBUTE_NAME {
  NAME = 'Build name',
  GAME_VERSION = 'Version number',
  DESCRIPTION = 'Build notes',
}

export const BLANK_VALIDATION: IValidation = { status: true, error: '' };

// max segment value for each chunk of process
export enum UPLOAD_PROGRESS_MAX {
  CREATE = 5,
  CHUNK_UPLOAD = 70,
  COMPLETE_MULTI = 80,
  PROCESS_UPLOAD = 95,
  SYNC = 100,
}

export enum UPLOAD_PROGRESS_STEP_VALUE {
  CHUNK_UPLOAD = UPLOAD_PROGRESS_MAX.CHUNK_UPLOAD - UPLOAD_PROGRESS_MAX.CREATE,
  COMPLETE_MULTI = UPLOAD_PROGRESS_MAX.COMPLETE_MULTI - UPLOAD_PROGRESS_MAX.CHUNK_UPLOAD,
  PROCESS_UPLOAD = UPLOAD_PROGRESS_MAX.PROCESS_UPLOAD - UPLOAD_PROGRESS_MAX.COMPLETE_MULTI,
  SYNC = UPLOAD_PROGRESS_MAX.SYNC - UPLOAD_PROGRESS_MAX.PROCESS_UPLOAD,
}

export enum SUCCESS_UPLOAD_MESSAGE {
  CASH_LIVE_HEADER = 'Skillz quality assurance',
  CASH_LIVE_DETAIL = 'Since your game is prize enabled, new game builds will be verified by our DevSuccess team. Please allow up to 2 business days for it to be enabled in production.',
  NON_CASH_LIVE_DETAIL = 'Suggested step: error-proof your game',
}

export const CASH_LIVE_NOTE =
  'Your game is prize enabled!  To ensure fair play, our Developer Success team will be verifying each build that you enable and disable.';
