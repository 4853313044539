export const DEFAULT_SURVEY_TITLE = 'How was your overall experience?';

export const DEFAULT_FREEFORM_TEXT_QUESTION = 'Comments';

export const FREEFORM_TEXT_PLACEHOLDER = 'Your feedback (optional)';

/**
 * player - user who has no games at all, might have mistakenly signed up as developer
 * developer - user who has games in progress but no launched games
 * activeDeveloper - user who has launched games
 */

export const USER_TYPES = ['player', 'developer', 'activeDeveloper'] as const;

export const SURVEY_TARGETS = ['players', 'developers', 'activeDevelopers'] as const;

export const SURVEY_TYPES = ['CSAT', 'NPS', 'SUS'] as const;

// use the PopupSurvey component
export const TOAST_SURVEYS = ['CSAT', 'NPS'];

// use the DialogSurvey compoent
export const DIALOG_SURVEYS = ['SUS'];

export const SURVEY_24H_COOKIE = 'last_survey_completed_at';

export const SUCCESS_SUBMISSION_SNACKBAR_MESSAGE = 'Response submitted, thank you.';
