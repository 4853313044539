interface IPluralOverride {
  suffix: string;
  replaceChars: number;
}

const basePluralCases = (word: string) => {
  switch (true) {
    case word.endsWith('y'):
      return { defaultSuffix: 'ies', defaultCount: 1 };
    case word.endsWith('f'):
      return { defaultSuffix: 'ves', defaultCount: 1 };
    case word.endsWith('fe'):
      return { defaultSuffix: 'ves', defaultCount: 2 };
    default:
      return { defaultSuffix: 's', defaultCount: 0 };
  }
};

export const pluralize = (word: string, count: number, override?: IPluralOverride) => {
  const { defaultSuffix, defaultCount } = basePluralCases(word);

  const suffix = override ? override.suffix : defaultSuffix;
  const replaceCharsCount = override ? override.replaceChars : defaultCount;

  return `${count} ${
    count !== 1 ? word.substr(0, word.length - replaceCharsCount).concat(suffix) : word
  }`;
};

export const truncateWithElipses = (text: string, maxLength: number = 200) => {
  return text.substring(0, maxLength) + (text.length > maxLength ? '...' : '');
};
