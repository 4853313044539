import { GAME_STATES } from '@app/modules/GameManagement/common/constants';

import { TGameStateMapNextStepConstant } from '../interfaces';
import {
  stepApplePay,
  stepAppStoreUrl,
  stepDeepLinking,
  stepFixApplePay,
  stepFixPushNotification,
  stepGameBuilds,
  stepInAppPurchase,
  stepInGameItems,
  stepPlayerData,
  stepPushNotification,
  stepSingleSignOn,
  stepTheme,
  stepTournament,
  stepUploadSdk,
} from './stepConstants';

export const OVERVIEW_GAME_STATE_ORDER: GAME_STATES[] = [
  GAME_STATES.TOURNAMENTS,
  GAME_STATES.VIRTUAL_CURRENCY,
  GAME_STATES.UPLOAD_BINARY,
  GAME_STATES.DISTRIBUTION,
  GAME_STATES.SDK_THEME,
  GAME_STATES.PUSH_NOTIFICATIONS,
  GAME_STATES.APPLE_PAY,
  GAME_STATES.DEEP_LINKING,
  GAME_STATES.SINGLE_SIGN_ON,
];

export const OVERVIEW_ACTION_REQUIRED_ORDER: GAME_STATES[] = [
  GAME_STATES.APPLE_PAY,
  GAME_STATES.PUSH_NOTIFICATIONS,
  // placeholder for Update Sdk
  GAME_STATES.SKILLZ_SDK,
];

interface IGameStateWithFeatureFlag {
  featureFlag: string;
  gameState: GAME_STATES;
}

export const POST_PERFORMANCE_GAME_STATE_ORDER: IGameStateWithFeatureFlag[] = [
  { featureFlag: 'gameProgressionPlayerSchema', gameState: GAME_STATES.PLAYER_DATA },
  { featureFlag: 'progressionPr3iIgiCaseStudy', gameState: GAME_STATES.IN_GAME_ITEMS },
];

export type TNextStepActionRequiredSteps =
  | GAME_STATES.APPLE_PAY
  | GAME_STATES.PUSH_NOTIFICATIONS
  | GAME_STATES.SKILLZ_SDK;
type TActionRequiredStepsKey = Required<
  Pick<TGameStateMapNextStepConstant, TNextStepActionRequiredSteps>
>;

// this should match fields in OVERVIEW_ACTION_REQUIRED_ORDER
export const NEXT_STEP_ACTION_REQUIRED_CONSTANTS: TActionRequiredStepsKey = {
  [GAME_STATES.APPLE_PAY]: stepFixApplePay,
  [GAME_STATES.PUSH_NOTIFICATIONS]: stepFixPushNotification,
  [GAME_STATES.SKILLZ_SDK]: stepUploadSdk,
};

export type TNextStepOptimizeSteps =
  | GAME_STATES.TOURNAMENTS
  | GAME_STATES.VIRTUAL_CURRENCY
  | GAME_STATES.UPLOAD_BINARY
  | GAME_STATES.DISTRIBUTION
  | GAME_STATES.SDK_THEME
  | GAME_STATES.PUSH_NOTIFICATIONS
  | GAME_STATES.APPLE_PAY
  | GAME_STATES.DEEP_LINKING
  | GAME_STATES.SINGLE_SIGN_ON;
type TOptimizeStepsKey = Required<Pick<TGameStateMapNextStepConstant, TNextStepOptimizeSteps>>;

// this should match fields in OVERVIEW_GAME_STATE_ORDER with addition of GAME_INFORMATION map to AppStoreUrl
export const NEXT_STEP_GAME_STATE_CONSTANTS: TOptimizeStepsKey = {
  [GAME_STATES.TOURNAMENTS]: stepTournament,
  [GAME_STATES.VIRTUAL_CURRENCY]: stepInAppPurchase,
  [GAME_STATES.UPLOAD_BINARY]: stepGameBuilds,
  [GAME_STATES.DISTRIBUTION]: stepAppStoreUrl,
  [GAME_STATES.SDK_THEME]: stepTheme,
  [GAME_STATES.PUSH_NOTIFICATIONS]: stepPushNotification,
  [GAME_STATES.APPLE_PAY]: stepApplePay,
  [GAME_STATES.DEEP_LINKING]: stepDeepLinking,
  [GAME_STATES.SINGLE_SIGN_ON]: stepSingleSignOn,
};

export type TNextStepPostPerformanceSteps = GAME_STATES.PLAYER_DATA | GAME_STATES.IN_GAME_ITEMS;

type TPostPerformanceStepsKey = Required<
  Pick<TGameStateMapNextStepConstant, TNextStepPostPerformanceSteps>
>;

// this should match fields in OVERVIEW_GAME_STATE_ORDER with addition of GAME_INFORMATION map to AppStoreUrl
export const NEXT_STEP_POST_PERFORMANCE_CONSTANTS: TPostPerformanceStepsKey = {
  [GAME_STATES.PLAYER_DATA]: stepPlayerData,
  [GAME_STATES.IN_GAME_ITEMS]: stepInGameItems,
};
