import merge from 'lodash/merge';

import { IAction } from '@app/common/interfaces';

import { INITIAL_PACKAGES, VIRTUAL_CURRENCY } from './constants';
import { IVirtualCurrencyResponse, IVirtualCurrency } from './interfaces';

const initialState: IVirtualCurrency = {
  android: INITIAL_PACKAGES,
  ios: INITIAL_PACKAGES,
};

const virtualCurrencyReducer = (
  state = initialState,
  action: IAction<IVirtualCurrencyResponse>
) => {
  let newState;
  Object.freeze(state);
  switch (action.type) {
    case VIRTUAL_CURRENCY.RECEIVE_PACKAGE:
      newState = merge({}, initialState);
      newState.ios = action.data.virtualCurrency.ios;
      newState.iosOfferSetId = action.data.virtualCurrency.iosOfferSetId;
      newState.android = action.data.virtualCurrency.android;
      newState.androidOfferSetId = action.data.virtualCurrency.androidOfferSetId;

      return newState;

    default:
      return state;
  }
};

export default virtualCurrencyReducer;
