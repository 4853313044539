import merge from 'lodash/merge';
import { GAME_BUILDS_UPLOAD } from '../actions';
import { IGameBuildUploads, TActionUpload } from '../interfaces';

export function uploadsReducer(
  state: IGameBuildUploads = {},
  action: TActionUpload
): IGameBuildUploads {
  let newState: IGameBuildUploads;
  Object.freeze(state);

  switch (action.type) {
    case GAME_BUILDS_UPLOAD.RECEIVE_NEW_GAME_BUILD:
      newState = merge({}, state, {
        [action.data.id]: action.data
      });

      return newState;
    case GAME_BUILDS_UPLOAD.CLEAR_FINALIZE_GAME_BUILD:
      newState = Object.assign({}, state);
      delete newState[action.data.id];

      return newState;
    default:
      return state;
  }
}
