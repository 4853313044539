import merge from 'lodash/merge';

import { IAction } from '@app/common/interfaces';

import { SINGLE_SIGN_ON } from './actions';

interface IState {
  prefix: string | null;
  verified: boolean;
}

let initialState: IState = {
  prefix: '',
  verified: false,
};

type TAction = IAction<IState>;

const singleSignOnReducer = (state: IState = initialState, action: TAction): IState => {
  let newState: IState;

  Object.freeze(state);

  switch (action.type) {
    case SINGLE_SIGN_ON.RECEIVE_PREFIX:
      newState = merge({}, state, action.data);

      return newState;
    default:
      return state;
  }
};

export default singleSignOnReducer;
