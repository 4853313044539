import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import leftArrow from '@app/imageAssets/leftArrow.svg';

import './styles/backButtonStyles.scss';

interface IProps extends RouteComponentProps {
  fallBackUrl: string;
  customUrl?: string;
}

const CLASS_NAME = 'common-component__back-button-container';

const BackButton = (props: IProps) => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleBackButtonClick = () => {
    const { history, fallBackUrl, customUrl } = props;

    if (customUrl !== undefined) {
      history.push({ pathname: customUrl });

      return;
    }

    // if the value of the `history.action` is NOT `POP` then there is a route to go back to, but
    // if it is, then we use the fallBackUrl instead
    return history.action !== 'POP' ? history.goBack() : (window.location.href = fallBackUrl);
  };

  return (
    <div className={CLASS_NAME} onClick={handleBackButtonClick} data-qa={CLASS_NAME}>
      <img src={leftArrow} className={'icon'} />
      <p className={'text'}>Back</p>
    </div>
  );
};

export { BackButton };
