export enum PAGE_NAMES {
  INTRODUCTION = 'introduction',
  GAME_INFO = 'game_information',
  SKILLZ_SDK = 'skillz_sdk',
  FAIRNESS = 'fairness',
  SDK_THEME = 'sdk_theme',
  TOURNAMENTS = 'tournaments',
  VIRTUAL_CURRENCY = 'virtual_currency',
  APPLE_PAY = 'apple_pay',
  DEEP_LINKING = 'deep_linking',
  PUSH_NOTIFICATIONS = 'push_notifications',
  SINGLE_SIGN_ON = 'single_sign_on',
  UPLOAD_BINARY = 'upload_binary',
  VERIFY_CONFIGURATION = 'verify_configuration',
  PUBLISH_GAME = 'publish_game',
  NEXT_STEPS = 'next_steps',
  ENABLE_PRIZES = 'enable_real_prizes',
  USERS = 'users',
  LANDING_PAGE = '',
  DASHBOARD = 'dashboard',
  SDK_DOWNLOADS = 'downloads',
  SUPPORT_PAGE = 'support',
  ANALYTICS = 'analytics',
  MANAGE_ACCOUNT = 'manage_accounts',
  STATEMENTS = 'statements',
  GAME_MANAGEMENT = 'games', // TODO change to game_management
}

export enum MAINTENANCE_FLOW_PAGE_NAMES {
  GAME_INFORMATION = 'Game Information',
  TOURNAMENTS = 'Tournaments',
  BINARIES = 'Binaries',
  PUSH_NOTIFICATIONS = 'Push Notifications',
  APPLE_PAY = 'Apple Pay',
  THEME = 'Theme',
  DEEP_LINKING = 'Deep Linking',
  SINGLE_SIGN_ON = 'Single Sign On',
  IN_APP_PURCHASE_PACKAGE = 'In App Purchase Packages',
  ANALYTICS = 'Analytics',
}

export enum MAINTENANCE_FLOW_PAGE_LINKS {
  GAME_INFORMATION = 'game_information',
  TOURNAMENTS = 'manage_tournaments',
  BINARIES = 'binaries',
  PUSH_NOTIFICATIONS = 'manage_notifications',
  APPLE_PAY = 'apple_pay',
  THEME = 'theme',
  DEEP_LINKING = 'deep_linking',
  SINGLE_SIGN_ON = 'single_sign_on',
  IN_APP_PURCHASE_PACKAGE = 'in_app_purchases',
  ANALYTICS = 'overview',
}

export const UPDATED_MAINTENANCE_FLOW_PAGES: string[] = [
  MAINTENANCE_FLOW_PAGE_NAMES.GAME_INFORMATION,
  MAINTENANCE_FLOW_PAGE_NAMES.THEME,
  MAINTENANCE_FLOW_PAGE_NAMES.DEEP_LINKING,
  MAINTENANCE_FLOW_PAGE_NAMES.SINGLE_SIGN_ON,
  MAINTENANCE_FLOW_PAGE_NAMES.BINARIES,
  MAINTENANCE_FLOW_PAGE_NAMES.APPLE_PAY,
  MAINTENANCE_FLOW_PAGE_NAMES.IN_APP_PURCHASE_PACKAGE,
];

export const ORDER: string[] = [
  PAGE_NAMES.INTRODUCTION,
  PAGE_NAMES.GAME_INFO,
  PAGE_NAMES.SKILLZ_SDK,
  PAGE_NAMES.FAIRNESS,
  PAGE_NAMES.SDK_THEME,
  PAGE_NAMES.TOURNAMENTS,
  PAGE_NAMES.VIRTUAL_CURRENCY,
  PAGE_NAMES.APPLE_PAY,
  PAGE_NAMES.DEEP_LINKING,
  PAGE_NAMES.PUSH_NOTIFICATIONS,
  PAGE_NAMES.SINGLE_SIGN_ON,
  PAGE_NAMES.UPLOAD_BINARY,
  PAGE_NAMES.VERIFY_CONFIGURATION,
  PAGE_NAMES.PUBLISH_GAME,
  PAGE_NAMES.NEXT_STEPS,
];

export const NON_VERIFY_PAGES: string[] = [
  PAGE_NAMES.INTRODUCTION,
  PAGE_NAMES.SKILLZ_SDK,
  PAGE_NAMES.FAIRNESS,
  PAGE_NAMES.PUBLISH_GAME,
  PAGE_NAMES.NEXT_STEPS,
];

export const PUBLIC_PRIVATE_ROUTES: string[] = [
  '/learning_center/player_rewards_and_in_game_items_study',
  '/learning_center/progression_and_player_data_case_study',
  '/learning_center/developer_analytics_benchmarks',
  '/learning_center/chat_blog',
];

export const PUBLIC_ROUTES: string[] = ['/'];

export const PUBLIC_FLOWS: string[] = [PAGE_NAMES.USERS];

export enum VIRTUAL_CURRENCY_OPTIONS {
  CASH_AND_VIRTUAL_CURRENCY = 'cash_and_virtual_currency',
  VIRTUAL_CURRENCY_ONLY = 'virtual_currency_only',
}

export enum STATUS {
  NOT_NEEDED = -1,
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
  ACTION_REQUIRED = 3,
}

export const LIVE: string = 'live';
export const GAMES: string = 'games';
export const DATA: string = 'data';

export enum PLATFORM {
  IOS = 'ios',
  ANDROID = 'android',
  CROSS_PLATFORM = 'cross_platform',
}

export enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
}

export enum RESPONSE_STATUS {
  FORBIDDEN = 'forbidden',
  UNPROCESSABLE_ENTITY = 'unprocessable_entity',
  OK = 'ok',
}

type TEnvironment = string | undefined;

export const ENVIRONMENTS: TEnvironment[] = ['production', 'staging'];
export const PRODUCTION_ONLY: TEnvironment[] = ['production'];
export const TOURNAMENT_NAME_INVALID_CHARACTERS: RegExp = /[%]/;
