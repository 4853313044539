import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { openSnackbar } from '@app/common/actions';
import { Loading } from '@app/common/components';
import { ILoadableComponent } from '@app/common/interfaces';

const PeekPage: ILoadableComponent['component'] = Loadable({
  loader: () => import(/* webpackChunkName: "peek-page" */ './PeekPage'),
  loading: Loading
});

const mapDispatchToProps = { openSnackbar };

export default connect(null, mapDispatchToProps)(withRouter(PeekPage));
