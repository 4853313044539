import { Data } from '@nivo/bar';
import { Serie } from '@nivo/line';

import isEmpty from 'lodash/isEmpty';

import { ANALYTICS_ATTRIBUTES, CHART_TYPE, MATH_OPERATIONS } from '@app/common/constants';
import { IAnalyticInfo, IKeysObj, ITempData, ITotalCount } from '@app/common/interfaces';

/**
 * creates an createTempData for calculateTotalAndCountByDate (totals and count)
 * and calculateSumOrAverageByDate (number)
 * @template T
 * @param { IKeysObj } keysObj - an object of desiredKeys (desired frontend keys) and analyticsKeys (from the database)
 * @returns { ITempData<T> } - an tempLineData object with the { desiredKey: { } }
 */
export function createTempData<T>(keysObj: IKeysObj): ITempData<T> {
  let tempData: ITempData<T> = {};

  Object.keys(keysObj).forEach((key: string) => {
    tempData[key] = {};
  });

  return tempData;
}

/**
 * step 1: Takes in the analytics and calculates the total and count (number of times at that startTime)
 * @param { IAnalyticInfo[] } analyticsInfo - an array of filtered analytics
 * @param { IKeysObj } keysObj - an object of desiredKeys (desired chart keys) and analyticsKeys (from the database)
 * @returns { ITempData<ITotalCount> } - a tempLineData object with the {desiredKey: {startTime: { total, count }}}
 */
export function calculateTotalAndCountByDate(
  analyticsInfo: IAnalyticInfo[],
  keysObj: IKeysObj
): ITempData<ITotalCount> {
  const tempLineData: ITempData<ITotalCount> = createTempData<ITotalCount>(keysObj);
  // database names or the actual keys
  const analyticsKeys: string[] = Object.values(keysObj);

  // iterate through the desired keys
  Object.keys(keysObj).forEach((key: string, index: number) => {
    // current analytic key can be 'skillzDau', 'd1', 'installs'
    const currentAnalyticKey = analyticsKeys[index] as keyof IAnalyticInfo;

    for (const analytic of analyticsInfo) {
      const analyticValue: number = analytic[currentAnalyticKey] as number;

      // if null stop the current iteration in the loop
      if (analyticValue === null) {
        continue;
      }

      if (tempLineData[key].hasOwnProperty(analytic.startTime)) {
        const currentAccValue: number = tempLineData[key][analytic.startTime].total;
        // store the total total of the accumulator and database analytic total
        tempLineData[key][analytic.startTime].total = analyticValue + currentAccValue;
        tempLineData[key][analytic.startTime].count++;
      } else {
        tempLineData[key][analytic.startTime] = { total: analyticValue, count: 1 };
      }
    }
  });

  return tempLineData;
}

/**
 * step 2: Takes in the tempLineData from calculateTotalsForLineChart and returns tempData with number value
 * This number value is calculated by (total / count)
 * In the case where count = 1, the total will be (total / 1) = total
 * @param { ITempData<ITotalCount> } calculateTotals - the temp with total and count
 * @param { IKeysObj } keysObj - an object of desiredKeys (desired frontend keys) and analyticsKeys (from the database)
 * @param { string } mathOperation - to either sum or average the passed in values
 * @returns { Object } - an tempLineData with { desiredKey: { startTime: avgValue } }
 */
export function calculateSumOrAverageByDate(
  calculateTotals: ITempData<ITotalCount>,
  keysObj: IKeysObj,
  mathOperation: MATH_OPERATIONS
): ITempData<number> {
  const tempData: ITempData<number> = createTempData<number>(keysObj);
  const desiredKeys: string[] = Object.keys(keysObj);

  for (const key of desiredKeys) {
    if (isEmpty(calculateTotals[key])) {
      continue;
    }

    const startTimesByKey: string[] = Object.keys(calculateTotals[key]);
    // iterate through all the dates for each key d1, d7, d30
    startTimesByKey.forEach((startTime: string) => {
      const currentData: ITotalCount = calculateTotals[key][startTime];

      if (currentData.hasOwnProperty('total') && currentData.hasOwnProperty('count')) {
        const { total, count } = currentData;

        if (mathOperation === MATH_OPERATIONS.AVERAGE) {
          tempData[key][startTime] = Number((total / count).toFixed(2));
        } else {
          tempData[key][startTime] = Number(total.toFixed(2));
        }
      }
    });
  }

  return tempData;
}

/**
 * step 3: Converts the tempData into data for the line chart
 * @param { ITempData<number> } tempData - a line accumulator object with the desiredKey: {startTime: avgValue}
 * @param { IKeysObj } keysObj - an object of desiredKeys (desired frontend keys) and analyticsKeys (from the database)
 * @param { string[] } dateRange - selected date range of the retention data
 * @returns { string[] } - a line series data for nivo line with id as the key and data x: startTime,
 *  y: avg / sum / null values
 */
export function normalizeDataForLineChart(
  tempData: ITempData<number>,
  keysObj: IKeysObj,
  dateRange: string[]
): Serie[] {
  let buildLineData: Serie[] = [];
  let desiredKeys: string[] = Object.keys(keysObj);

  for (const key of desiredKeys) {
    let lineDataObj: Serie = { id: key, color: 'hsl(62, 70%, 50%)', data: [] }; // color is arbitrary

    // in the case of all d1 is empty, it will push an empty line obj
    if (isEmpty(tempData[key])) {
      buildLineData.push(lineDataObj);
      continue;
    }

    // create an array with date as the x and the y is the value (sum or avg)
    // if the data point does not exist on that startTime, that startTime is set to null
    lineDataObj.data = dateRange.map((startTime: string) => {
      if (tempData[key].hasOwnProperty(startTime)) {
        return { x: startTime, y: tempData[key][startTime] };
      } else {
        return { x: startTime, y: null };
      }
    });

    buildLineData.push(lineDataObj);
  }

  return buildLineData;
}

/**
 * step 3: Converts the tempLineData into data for the bar chart
 * @param { ITempData<number> } tempData - a line accumulator object with the desiredKey: {startTime: avgValue}
 * @param { IKeysObj } keysObj - an object of desiredKeys (desired frontend keys) and analyticsKeys (from the database)
 * @returns { Data['data'] } - nivo bar data set
 */
export function normalizeDataForBarChart(
  tempData: ITempData<number>,
  keysObj: IKeysObj
): Data['data'] {
  const buildBarData: Data['data'] = [];

  const dateValue: { [date: string]: Data['data'] } = {};

  Object.keys(keysObj).forEach((key) => {
    Object.keys(tempData[key as ANALYTICS_ATTRIBUTES]).forEach((date: string) => {
      dateValue[date] = Object.assign({}, dateValue[date], {
        label: date,
        [key]: tempData[key][date],
        [`${key}Color`]: 'hsl(62, 70%, 50%)',
      });
    });
  });

  Object.keys(dateValue)
    .sort()
    .forEach((date: string) => {
      buildBarData.push(dateValue[date]);
    });

  return buildBarData;
}

/**
 * sum of Steps 1 - 3: Converts temp data into the nivo bar or line data
 * @param { CHART_TYPE } type - a string to determine the chart type
 * @param { ITempData<ITotalCount> } calculateTotalsCount - analytics total count obj
 * @param { IKeysObj } keysObj - an object of desiredKeys (desired frontend keys) and analyticsKeys (from the database)
 * @param { MATH_OPERATIONS } mathOperation - used to tell 'calculateSumOrAverageByDate' which math operation to use
 * @param { string[] } dateRange? - optional date range
 * @returns { Data['data'] } - nivo bar or line data set
 */
export function normalizeGraph(
  type: CHART_TYPE,
  calculateTotalsCount: ITempData<ITotalCount>,
  keysObj: IKeysObj,
  mathOperation: MATH_OPERATIONS,
  dateRange?: string[]
): Serie[] | Data['data'] {
  if (isEmpty(calculateTotalsCount)) {
    if (type === CHART_TYPE.LINE) {
      return [] as Serie[];
    } else {
      return [] as Data['data'];
    }
  }

  let calculateData: ITempData<number> = calculateSumOrAverageByDate(
    calculateTotalsCount,
    keysObj,
    mathOperation
  );

  if (type === CHART_TYPE.LINE && dateRange) {
    return normalizeDataForLineChart(calculateData, keysObj, dateRange);
  } else if (type === CHART_TYPE.BAR) {
    return normalizeDataForBarChart(calculateData, keysObj);
  }

  return [];
}
