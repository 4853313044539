import { AxiosPromise } from 'axios';
import * as rax from 'retry-axios';

import { IFeature } from '@app/common/interfaces';
import axios from '@app/common/setup/axiosWithHeader';

export interface IEnvironment {
  appEnv: string;
  nodeEnv: string;
  railsEnv: string;
  skillzUrl: string;
  cmsBaseUrl: string;
  skillzDocBaseUrl: string;
  themeImagesAwsS3Bucket: string;
  bestPracticeProductionIds: number[];
  progressionPlayerDataMaxCount: [number, number][];
  progressionInGameItemsMaxCount: [number, number][];
  zendeskDashboardEarlyAdopterGameIds: number[];
  prePrizeAnalyticsPublisherIds: number[];
  discordInviteUrl: string;
  oAuthClientId: string | null;
  oAuthRedirectUri: string;
  oAuthDomain: string;
}

export interface IAppConfig {
  featureFlags: IFeature;
  environment: IEnvironment;
}

export const initialAppConfig = {
  featureFlags: {},
  environment: {
    appEnv: '',
    nodeEnv: '',
    railsEnv: '',
    skillzUrl: '',
    cmsBaseUrl: '',
    skillzDocBaseUrl: '',
    discordInviteUrl: '',
    themeImagesAwsS3Bucket: '',
    bestPracticeProductionIds: [],
    progressionPlayerDataMaxCount: [],
    progressionInGameItemsMaxCount: [],
    zendeskDashboardEarlyAdopterGameIds: [],
    prePrizeAnalyticsPublisherIds: [],
    oAuthClientId: '',
    oAuthRedirectUri: '',
    oAuthDomain: '',
  },
};

const axiosWithRetry = axios.create();
axiosWithRetry.defaults.raxConfig = {
  instance: axiosWithRetry,
};
rax.attach(axiosWithRetry);

export const fetchEnvironmentConfig = () =>
  axiosWithRetry({
    method: 'get',
    url: `/api_internal/v1/configuration?name=developer-console`,
    raxConfig: {
      retry: 10,
    },
  }) as AxiosPromise<IEnvironment>;
