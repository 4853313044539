import * as ApiUtils from './utils';
import { Dispatch } from 'redux';
import { TThunkAction, TDispatch, IAction, IGame } from '@app/common/interfaces';

export enum CONTACT_US {
  RECEIVE_GAMES = 'contactUs/RECEIVE_GAMES',
  RECEIVE_SDK_VERSIONS = 'contactUs/RECEIVE_SDK_VERSIONS'
}

type TReceiveContactUs<T> = (data: T) => IAction<T>;

export const receiveGames: TReceiveContactUs<IGame[]> = (data: IGame[]): IAction<IGame[]> => {
  return {
    type: CONTACT_US.RECEIVE_GAMES,
    data
  };
};

export const receiveSdkVersions: TReceiveContactUs<string[]> = (
  data: string[]
): IAction<string[]> => {
  return {
    type: CONTACT_US.RECEIVE_SDK_VERSIONS,
    data
  };
};
export const fetchGames: TThunkAction = (): TDispatch => {
  return (dispatch: Dispatch<any>): any => {
    return ApiUtils.fetchGames().then((response: IGame[]) => {
      return dispatch(receiveGames(response));
    });
  };
};

export const fetchSdkVersions: TThunkAction = (): TDispatch => {
  return (dispatch: Dispatch<any>): any => {
    return ApiUtils.fetchSdkVersions().then((response: string[]) => {
      return dispatch(receiveSdkVersions(response));
    });
  };
};
