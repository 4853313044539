import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import pick from 'lodash/pick';

import { ENVIRONMENT } from '@app/common/constants';
import {
  fetchPlayerDataSchema,
  fetchPlayerDataSchemaChangelogs,
  fetchInGameItemsSchema,
  fetchInGameItemsSchemaChangelogs,
} from '@appGameHome/common/actions';
import {
  IGameProgression,
  IInGameItemsSchema,
  IInGameItemsSchemaChangelog,
  IPlayerDataSchema,
  IPlayerDataSchemaChangelog,
} from '@appGameProgression/common/interfaces';

import { KEY_NAMESPACE } from './common/constants';

interface IReducerPayload<T> {
  env: ENVIRONMENT;
  items: T[];
  count: number;
}
interface IReducerGenerator {
  <T extends IPlayerDataSchema | IPlayerDataSchemaChangelog>(
    keyNamespace: KEY_NAMESPACE.PLAYER_DATA
  ): CaseReducer<IGameProgression, PayloadAction<IReducerPayload<T>>>;
  <T extends IInGameItemsSchema | IInGameItemsSchemaChangelog>(
    keyNamespace: KEY_NAMESPACE.IN_GAME_ITEMS
  ): CaseReducer<IGameProgression, PayloadAction<IReducerPayload<T>>>;
}

const initialState = {
  PlayerData: {
    production: {
      count: 0,
      items: [],
    },
    sandbox: {
      count: 0,
      items: [],
    },
    productionChangelog: { count: 0, items: [] },
  },
  InGameItems: {
    production: {
      count: 0,
      items: [],
    },
    sandbox: {
      count: 0,
      items: [],
    },
    productionChangelog: { count: 0, items: [] },
  },
};

const createNamespaceReducer: IReducerGenerator = <
  T extends
    | IPlayerDataSchema
    | IPlayerDataSchemaChangelog
    | IInGameItemsSchema
    | IInGameItemsSchemaChangelog
>(
  keyNamespace: KEY_NAMESPACE
) =>
  (function fulfilledProgressionFetchActionReducer(
    state: IGameProgression,
    action: PayloadAction<{
      env: ENVIRONMENT;
      items: T[];
      count: number;
    }>
  ) {
    const env = action.payload.env;
    const filteredPayload = pick(action.payload, ['items', 'count']);

    const { count, items } = filteredPayload;

    switch (action.type) {
      case 'progression/fetchPlayerDataSchemaChangelogs/fulfilled':
        if (env === ENVIRONMENT.PRODUCTION) {
          state[KEY_NAMESPACE.PLAYER_DATA].productionChangelog = {
            count,
            items: items as IPlayerDataSchemaChangelog[],
          };
        }
        break;

      case 'progression/fetchInGameItemsSchemaChangelogs/fulfilled':
        if (env === ENVIRONMENT.PRODUCTION) {
          state[KEY_NAMESPACE.IN_GAME_ITEMS].productionChangelog = {
            count,
            items: items as IInGameItemsSchemaChangelog[],
          };
        }
        break;

      default:
        // technically this ternary is unnecessary and used only for type casting
        state[keyNamespace][env] =
          keyNamespace === KEY_NAMESPACE.PLAYER_DATA
            ? { count, items: items as IPlayerDataSchema[] }
            : { count, items: items as IInGameItemsSchema[] };
    }
  });

const progression = createSlice({
  name: 'progression',
  initialState: initialState as IGameProgression,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchPlayerDataSchema.fulfilled,
      createNamespaceReducer<IPlayerDataSchema>(KEY_NAMESPACE.PLAYER_DATA)
    );

    builder.addCase(fetchPlayerDataSchema.rejected, (_, action) => {
      throw action.error;
    });

    builder.addCase(
      fetchPlayerDataSchemaChangelogs.fulfilled,
      createNamespaceReducer<IPlayerDataSchemaChangelog>(KEY_NAMESPACE.PLAYER_DATA)
    );

    builder.addCase(fetchPlayerDataSchemaChangelogs.rejected, (_, action) => {
      throw action.error;
    });

    builder.addCase(
      fetchInGameItemsSchema.fulfilled,
      createNamespaceReducer<IInGameItemsSchema>(KEY_NAMESPACE.IN_GAME_ITEMS)
    );

    builder.addCase(fetchInGameItemsSchema.rejected, (_, action) => {
      throw action.error;
    });

    builder.addCase(
      fetchInGameItemsSchemaChangelogs.fulfilled,
      createNamespaceReducer<IInGameItemsSchemaChangelog>(KEY_NAMESPACE.IN_GAME_ITEMS)
    );

    builder.addCase(fetchInGameItemsSchemaChangelogs.rejected, (_, action) => {
      throw action.error;
    });
  },
});

export default progression.reducer;
