import axios, { AxiosError, AxiosResponse } from 'axios';
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import { Dispatch } from 'redux';

import {
  IAction,
  IActionNoData,
  IErrors,
  TAxiosPromise,
  TDispatch,
  TErrorGeneral,
} from '@app/common/interfaces';
import { IGamePerformanceStatus } from '@app/modules/GameManagement/common/interfaces';

export enum GAME_PERFORMANCE_STATUS_ACTIONS {
  RECEIVE = 'gamePerformanceStatus/RECEIVE',
  CLEAR = 'gamePerformanceStatus/CLEAR',
}

export function receiveGamePerformanceStatus(
  data: IGamePerformanceStatus
): IAction<IGamePerformanceStatus> {
  return {
    type: GAME_PERFORMANCE_STATUS_ACTIONS.RECEIVE,
    data,
  };
}

export function clearGamePerformanceStatus(): IActionNoData {
  return {
    type: GAME_PERFORMANCE_STATUS_ACTIONS.CLEAR,
  };
}

export function fetchGamePerformanceStatus(gameId: number): TDispatch {
  return (dispatch: Dispatch<IAction<IGamePerformanceStatus>>): TAxiosPromise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return axios
      .get(`/api/v1/games/${gameId}/game_performance_status`)
      .then(camelize)
      .then((response: AxiosResponse<IGamePerformanceStatus>) => {
        dispatch(receiveGamePerformanceStatus(response.data));
      })
      .catch((error: AxiosError<IErrors>): TErrorGeneral => {
        return error.response ? error.response.data : error;
      });
  };
}
