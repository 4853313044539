import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { fetchGameAccess, fetchGames, fetchPermissions, findGame } from '@app/common/actions';
import { IRootState } from '@app/core/rootReducer';
import { fetchGameLatestSdkDownload } from '@appIntegration/modules/SetupSDK/actions';
import { clearGamePerformanceStatus } from '@appOverview/actions';

import { fetchGameStateByGameId } from './common/actions';
import { IMatchParamsGameId } from './common/interfaces';

const GameManagement = loadable(
  () => import(/* webpackChunkName: "game-management" */ './GameManagement')
);

const mapStateToProps = (state: IRootState) => {
  return {
    gameInfo: state.gameInfo,
    permissions: state.permissions,
    gameAccess: state.gameAccess,
    feature: state.feature,
  };
};

const mapDispatchToProps = {
  fetchGames,
  fetchGameStateByGameId,
  fetchGameLatestSdkDownload,
  clearGamePerformanceStatus,
  findGame,
  fetchPermissions,
  fetchGameAccess,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TGameManagementConnectedProps = ConnectedProps<typeof connector> &
  RouteComponentProps<IMatchParamsGameId>;

export default connector(withRouter(GameManagement));
