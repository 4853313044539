import axios, { AxiosResponse } from 'axios';
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';

import { snakeCaseConverter } from '@app/common/helpers';
import { handleError } from '@app/common/helpers';

import { ISurvey, ISurveyResponse, ISurveyAnswer, TSurveyTarget } from './interfaces';

// a separate instance of `axios` which removes the `x-csrf-token` that keeps
// getting injected on each request to prevent CORS errors
export const contentManagementSystemInstance = axios.create();

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
delete contentManagementSystemInstance.defaults.headers.common['X-CSRF-TOKEN'];

interface IPostSurveyResponseParams {
  userId: number;
  userType: string;
  isDismissal: boolean;
  pageLocation: string;
  surveyId: number;
  answers: ISurveyAnswer[];
}

export const getSurvey = (surveyTrigger: string, targetType: TSurveyTarget) =>
  contentManagementSystemInstance
    .get(
      `${window.appConfig.environment.cmsBaseUrl}/publisher/v1/cms/surveys?trigger=${surveyTrigger}&target_in=all&target_in=${targetType}`
    )
    .then(camelize)
    .then(({ data }: AxiosResponse<ISurvey[]>) => data[0])
    .catch(handleError);

export const getLatestSurveyResponse = (userId: number, surveyId: number) =>
  contentManagementSystemInstance
    .get(
      `${window.appConfig.environment.cmsBaseUrl}/publisher/v1/cms/survey-responses?user_id=${userId}&survey_id=${surveyId}&_sort=created_at:DESC&_limit=1`
    )
    .then(camelize)
    .then(({ data }: AxiosResponse<ISurveyResponse[]>) => data[0])
    .catch(handleError);

export const postSurveyResponse = (params: IPostSurveyResponseParams) => {
  // TODO change this back to contentManagementSystemInstance when we call CMS directly
  return axios
    .post(`/publisher/v1/survey_responses/cms`, snakeCaseConverter(params))
    .then(camelize)
    .catch(handleError);
};
