import { connect, ConnectedProps } from 'react-redux';

import { openSnackbar } from '@app/common/actions';
import { IRootState } from '@app/core/rootReducer';

import TooltipNoAccessContainer from './TooltipNoAccess';

interface ITooltipNoAccessOwnProps {
  children: JSX.Element;
  active?: boolean;
}

const mapStateToProps = (state: IRootState, ownProps: ITooltipNoAccessOwnProps) => {
  const {
    publisher: {
      publisherInfo: { id: publisherId },
    },
    feature,
  } = state;

  return {
    ...ownProps,
    publisherId,
    feature,
  };
};

const mapDispatchToProps = {
  openSnackbar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TTooltipNoAccessConnectedProps = ConnectedProps<typeof connector>;

export const TooltipNoAccess = connector(TooltipNoAccessContainer);
