import merge from 'lodash/merge';

import { USER } from '@app/common/actions';
import { IUser } from '@app/common/interfaces';

let initialState: IUser = {} as IUser;

interface IUserProps {
  type: string;
  data: IUser;
}

const userReducer = (state: IUser = initialState, action: IUserProps): IUser => {
  let newState: IUser;
  Object.freeze(state);

  switch (action.type) {
    case USER.INFO:
      newState = merge({}, state, action.data);

      return newState;
    default:
      return state;
  }
};

export default userReducer;
