import isEmpty from 'lodash/isEmpty';

import {
  TGameProgressionRooms,
  TGameProgressionRoomVersionMappings,
} from '@appEntryPoints/common/interfaces';

export const hasLoadedAllVersionLocales = (
  reduxProgressionRooms: TGameProgressionRooms,
  reduxProgressionRoomVersionMappings: TGameProgressionRoomVersionMappings
) => {
  if (isEmpty(reduxProgressionRooms)) return false;

  const isAnyVersionEmpty = Object.values(reduxProgressionRoomVersionMappings).some(
    ({ production, sandbox }) => isEmpty(sandbox) || isEmpty(production)
  );

  const isRoomAndVersionCountMatching =
    Object.keys(reduxProgressionRooms).length ===
    Object.keys(reduxProgressionRoomVersionMappings).length;

  const isRoomAndVersionLoaded = isRoomAndVersionCountMatching && !isAnyVersionEmpty;

  return isRoomAndVersionLoaded;
};
