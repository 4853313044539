import merge from 'lodash/merge';

import { ACCELERATE_PROGRAM } from '@appOverview/actions';
import { IAction } from '@app/common/interfaces';
import { IAccelerateApplication, TAccelerateApplication } from '@appOverview/interfaces';

function accelerateApplication(
  state: TAccelerateApplication = null,
  action: IAction<IAccelerateApplication>
): TAccelerateApplication {
  Object.freeze(state);
  switch (action.type) {
    case ACCELERATE_PROGRAM.RECEIVE_ACCELERATE_APPLICATION:
      return merge({}, action.data);

    default:
      return state;
  }
}

export default accelerateApplication;
