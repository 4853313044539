import * as React from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import './styles/atomProgressBar.scss';

type TProgressBarHeight = 'large' | 'small';
type TAtomProgressBarOptionalProps =
  | 'segments'
  | 'animationChange'
  | 'animationSecond'
  | 'height'
  | 'isError';

export interface IAtomProgressBarProps {
  id: string;
  currentSegment: number; // index/percentage% of current segment, the progress bar is on
  previousSegment: number; // index/percentage% of previous segment, the progress bar is on
  segments?: number; // segment as index: number of segments in the progress bar
  animationChange?: number;
  animationSecond?: number;
  height?: TProgressBarHeight;
  isError?: boolean;
}

type IDefaultProps = Required<Pick<IAtomProgressBarProps, TAtomProgressBarOptionalProps>>;

export interface IAtomProgressBarState {
  startProgress: number; // the percentage% where the progress bar should start
  endProgress: number; // the end value of the progress bar can be at from calculation from props
  isIncrease: boolean;
  pValue: number;
}

type TAtomProgressBarProps = IAtomProgressBarProps & IDefaultProps;
class AtomProgressBar extends React.PureComponent<TAtomProgressBarProps, IAtomProgressBarState> {
  public static defaultProps: IDefaultProps = {
    segments: -1,
    animationChange: 5,
    animationSecond: 100,
    height: 'small',
    isError: false,
  };

  private timer: NodeJS.Timeout | undefined;

  constructor(props: TAtomProgressBarProps) {
    super(props);

    let { segments, currentSegment } = this.props;

    this.state = {
      startProgress: 0,
      endProgress: this.isIndexProgressBar()
        ? this.indexProgressCalculation(segments, currentSegment)
        : currentSegment,
      isIncrease: true,
      pValue: 0,
    };
  }

  public componentDidMount(): void {
    this.timer = setTimeout(() => this.progress(), this.props.animationSecond);
  }

  public componentDidUpdate(previousProps: IAtomProgressBarProps): void {
    let { currentSegment, previousSegment, animationSecond } = this.props;
    // progress bar loads from the previous segment on update
    if (currentSegment !== previousProps.currentSegment) {
      let isIncreaseProgressBar: boolean = currentSegment > previousProps.currentSegment;
      this.setState({
        startProgress: isIncreaseProgressBar
          ? this.calculateSegmentValue(previousSegment)
          : this.calculateSegmentValue(previousProps.currentSegment),
        endProgress: this.calculateSegmentValue(currentSegment),
        isIncrease: isIncreaseProgressBar,
        pValue: isIncreaseProgressBar
          ? 0
          : this.calculateSegmentValue(previousProps.currentSegment),
      });
      this.timer = setTimeout(() => this.progress(), animationSecond);
    }
  }

  public componentWillUnmount(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  public progress = (): void => {
    let isLoadingProgress: boolean = true;
    let { animationChange, animationSecond } = this.props;

    this.setState((state: IAtomProgressBarState) => {
      const { startProgress, endProgress } = state;
      let startProgressTemp: number = startProgress;

      if (startProgress < endProgress) {
        // increasing progress bar
        if (startProgress + animationChange >= endProgress) {
          isLoadingProgress = false;
        }
        startProgressTemp = Math.min(startProgress + animationChange, endProgress);
      } else {
        // decreasing progress bar
        if (startProgress - animationChange <= endProgress) {
          isLoadingProgress = false;
        }
        startProgressTemp = Math.max(startProgress - animationChange, endProgress);
      }

      return { startProgress: startProgressTemp };
    });
    if (isLoadingProgress) {
      this.timer = setTimeout(() => this.progress(), animationSecond);
    } else {
      if (this.timer) {
        clearTimeout(this.timer);
      }
    }
  };

  public render(): JSX.Element {
    let { isError, height, id } = this.props;

    let className: string = `atom-component__progress-bar background ${height}`;

    if (isError) {
      className += ' error';
      this.setState({ ...{ startProgress: 100 } });
    }

    if (this.state.startProgress === 100) {
      className += ' completed';
    }

    // need the pValue for decreasing progress bar of the start of decrement
    let pValue: number = this.state.isIncrease ? this.state.startProgress : this.state.pValue;
    let backgroundSizeUpdate = {
      '--p': `${pValue}%`,
      '--panimate': `${this.state.startProgress}%`,
    } as React.CSSProperties;

    return (
      <LinearProgress
        id={id}
        className={className}
        variant="determinate"
        value={this.state.startProgress}
        style={backgroundSizeUpdate}
      />
    );
  }

  private readonly indexProgressCalculation = (segments: number, segmentIndex: number): number => {
    return Math.ceil((100 / segments) * segmentIndex);
  };

  // determine if progress bar is an index progress bar (evenly split progress bar)
  private readonly isIndexProgressBar = (): boolean => {
    return !!this.props.segments && this.props.segments > 0;
  };

  private readonly calculateSegmentValue = (segmentValue: number): number => {
    return this.isIndexProgressBar()
      ? this.indexProgressCalculation(this.props.segments, segmentValue)
      : segmentValue;
  };
}

export { AtomProgressBar };
