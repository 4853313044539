import React from 'react';

import Box from '@mui/material/Box';
import GlobalStyles from '@mui/material/GlobalStyles';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider, createTheme, Theme } from '@mui/material/styles';
import { SnackbarContainer } from '@skillzet/components';
import { ThemeProvider as SkillzetThemeProvider } from '@skillzet/developer';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PromotionBanner } from '@app/common/components/PromotionBanner/PromotionBanner';
import ReduxSnackbarAdapter from '@app/common/components/ReduxSnackbarAdaptor';
import {
  ANALYTICS_PERMISSIONS,
  BILLING_PERMISSIONS,
  GAME_PERMISSIONS,
} from '@app/common/constants';
import UserContext from '@app/common/contexts/UserContext';
import {
  shouldViewPeekPage,
  isPublicRoute,
  isPublicOrPrivateRoute,
  shouldShowPeekHeader,
} from '@app/common/helpers';
import Account from '@app/modules/Account';
import Analytics from '@app/modules/Analytics';
import ContactUs from '@app/modules/ContactUs';
import CookieBanner from '@app/modules/CookieBanner';
import Dashboard from '@app/modules/Dashboard';
import GameInfoModal from '@app/modules/GameInfoModal';
import GameManagement from '@app/modules/GameManagement';
import HomePage from '@app/modules/HomePage';
import Introduction from '@app/modules/IntegrationIntroduction';
import Jobs from '@app/modules/Jobs';
import ManagedLearningCenter from '@app/modules/LearningCenter/modules/ManagedLearningCenter/ManagedLearningCenter';
import NavBar from '@app/modules/NavBar';
import NflChallenge from '@app/modules/NflChallenge';
import NoAccessPage from '@app/modules/NoAccessPage';
import NotFoundPage from '@app/modules/NotFoundPage';
import Notification from '@app/modules/Notifications';
import PeekHeader from '@app/modules/PeekMode/modules/PeekHeader';
import PeekPage from '@app/modules/PeekMode/modules/PeekPage';
import Playground from '@app/modules/Playground';
import PlaygroundOld from '@app/modules/PlaygroundOld';
import PrivacyPolicyBar from '@app/modules/PrivacyPolicyBar';
import ProfileModal from '@app/modules/ProfileModal';
import { RevenueModelNotification } from '@app/modules/RevenueModelNotification/RevenueModelNotification';
import { shouldRenderRevenueModelNotification } from '@app/modules/RevenueModelNotification/helpers';
import SdkDownloads from '@app/modules/SdkDownloads';
import { SignUpFlow } from '@app/modules/SignUpFlow/SignUpFlow';
import Statements from '@app/modules/Statements';
import Survey from '@app/modules/Survey';
import SurveyLandingPage from '@app/modules/Survey/SurveyContainer/LandingPage/';
import { SurveyContextProvider } from '@app/modules/Survey/SurveyContainer/SurveyContext';
import Surveys from '@app/modules/Survey/SurveyContainer/Surveys';
import UserInvite from '@app/modules/UserInvite';
import YourGames from '@app/modules/YourGames';

import './app.scss';
import { IAppState, TAppProps } from './interfaces';

const queryClient = new QueryClient();
declare module '@mui/material/styles' {
  interface Theme {
    appBar: {
      navBarHeight: { small: string; large: string };
      drawerWidth: string;
      peekHeaderHeight: string;
    };
  }
  interface ThemeOptions {
    appBar?: {
      navBarHeight?: {
        small: string;
        large: string;
      };
      drawerWidth?: string;
      peekHeaderHeight?: string;
    };
  }

  interface TypographyVariants {
    h7: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
  }
}

// update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true;
  }
}

const appBar = {
  navBarHeight: {
    small: '48px',
    large: '64px',
  },
  drawerWidth: '194px',
  peekHeaderHeight: '80px',
};

const portalDeveloperThemeOverrides = (theme: Theme) =>
  createTheme({
    ...theme,
    appBar,
  });

class App extends React.Component<TAppProps, IAppState> {
  constructor(props: TAppProps) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  public componentDidMount(): void {
    let {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      config: { flow },
      findFeatureFlags,
    } = this.props;

    // feature flag needs to be fetched first because of the feature flags on home page
    void findFeatureFlags().then(() => {
      // TODO: update logic once more non-logged-in pages to react
      const locationPathName = window.location.pathname;

      if (!isPublicRoute(flow, locationPathName)) {
        void this.getPublisherGameUserInfo();
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  private getPublisherGameUserInfo(): Promise<void> {
    let { findUser, fetchPublisher, feature, fetchPermissions } = this.props;

    return Promise.all([findUser(), fetchPublisher()])
      .then(async () => {
        if (feature.userRolesU6GameRolesAndPermissions) {
          await fetchPermissions(
            `${ANALYTICS_PERMISSIONS.READ}&` +
              `${BILLING_PERMISSIONS.DOWNLOAD_STATEMENTS}&` +
              `${GAME_PERMISSIONS.READ}&` +
              `${GAME_PERMISSIONS.CREATE}&` +
              `${GAME_PERMISSIONS.UPDATE}&` +
              `${GAME_PERMISSIONS.PUBLISH}&`
          );
        }
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        // response when logged in is an error
        const locationPathName = window.location.pathname;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (isPublicOrPrivateRoute(locationPathName) && e.status === 401) {
          this.setState({ isLoading: false });

          return;
        }
        // TODO REPLACE [WS-8355] update Routes to use PrivateRoute to handle this logic that
        // handles logged in/out users. When that change is done (when the entire frontend is
        // in React) update the redirect logic to use history.goBack() on successful sign in
        // to allow redirect to the original requested URL instead of loading in the dashboard
        this.props.history.push('/users/sign_in');
        window.location.reload();
      });
  }

  private readonly hasPermission = (permissionKey: string) => {
    const { feature, permissions } = this.props;
    if (!feature.userRolesU6GameRolesAndPermissions) return true;

    return !!permissions[permissionKey];
  };

  public render(): JSX.Element | null {
    let { user, publisher, snackbar, closeSnackbar, fetchPublisher } = this.props;

    if (this.state.isLoading) {
      return null;
    }

    const isLoggedIn = user.id !== undefined;

    const shouldAddPeekHeaderOffset = shouldShowPeekHeader(user, publisher);

    const whenPeeking = (themePeekHeaderHeight: string) =>
      shouldAddPeekHeaderOffset ? themePeekHeaderHeight : '0px';

    return (
      <StyledEngineProvider injectFirst>
        {/* using GlobalStyles for MuiDrawer peek header offset, because it's injected outside of main container */}
        <GlobalStyles
          styles={{
            '.MuiDrawer-paperAnchorLeft.peek-header-offset, .MuiModal-root.peek-header-offset, .MuiModal-root.peek-header-offset > .MuiBackdrop-root':
              {
                // the GlobalStyles is using DefaultTheme, so we don't have access to theme.appBar
                top: `calc(${whenPeeking(appBar.peekHeaderHeight)} + ${appBar.navBarHeight.small})`,
              },
          }}
        />
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <SkillzetThemeProvider>
            <ThemeProvider theme={portalDeveloperThemeOverrides}>
              <SnackbarContainer>
                <UserContext.Provider value={user}>
                  <SurveyContextProvider>
                    <PeekHeader />
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        minHeight: '100vh',
                        '& .peek-header-offset': {
                          top: {
                            xs: `calc(${whenPeeking(theme.appBar.peekHeaderHeight)} + ${
                              theme.appBar.navBarHeight.small
                            })`,
                            md: whenPeeking(theme.appBar.peekHeaderHeight),
                          },
                        },

                        '& .peek-header-offset.no-navbar-offset': {
                          top: whenPeeking(theme.appBar.peekHeaderHeight),
                        },
                      })}
                      className={
                        isLoggedIn ? 'neutral-white-background' : 'neutral-gray-background'
                      }
                    >
                      <Box
                        component="nav"
                        sx={(theme) => ({
                          width: { md: isLoggedIn ? theme.appBar.drawerWidth : 0 },
                          flexShrink: { md: 0 },
                        })}
                      >
                        <NavBar />
                      </Box>

                      <Box
                        component="main"
                        sx={(theme) => ({
                          flexGrow: 1,
                          width: {
                            md: isLoggedIn ? `calc(100% - ${theme.appBar.drawerWidth})` : '100%',
                          },
                          height: '100%',
                          maxWidth: '100%',
                        })}
                      >
                        {/* adds proper margin so top bar doesn't cover content */}
                        <Box
                          sx={(theme) => ({
                            height: isLoggedIn
                              ? {
                                  xs: `calc(${theme.appBar.navBarHeight.small} + ${whenPeeking(
                                    theme.appBar.peekHeaderHeight
                                  )})`,
                                  md: whenPeeking(theme.appBar.peekHeaderHeight),
                                }
                              : 0,
                            backgroundColor: '#2e323a',
                          })}
                        />
                        <div className={`app__main-container ${isLoggedIn ? '' : 'log-out'}`}>
                          <CookieBanner id="app__common-snackbar" country={user?.country} />

                          <ReduxSnackbarAdapter {...snackbar} closeSnackbar={closeSnackbar} />

                          {isLoggedIn && shouldRenderRevenueModelNotification(publisher) && (
                            <RevenueModelNotification reduxFetchPublisher={fetchPublisher} />
                          )}

                          {isLoggedIn && (
                            <>
                              <GameInfoModal />
                              <ProfileModal />
                              <Notification />

                              <PrivacyPolicyBar userId={user.id} />
                            </>
                          )}

                          {isLoggedIn && window.appConfig.featureFlags.ufcGamePartnership && (
                            <PromotionBanner
                              isFullWidth={true}
                              hideDescription={false}
                              header={true}
                            />
                          )}

                          <Switch>
                            <Route exact path="/" component={HomePage} />

                            <Route path="/dashboard" component={Dashboard} />

                            <Route path="/downloads" component={SdkDownloads} />

                            <Route path="/users/invitation/accept.:id" component={UserInvite} />

                            <Route path="/support/contact_us" component={ContactUs} />

                            <Route
                              path="/statements"
                              component={
                                this.hasPermission(BILLING_PERMISSIONS.DOWNLOAD_STATEMENTS)
                                  ? Statements
                                  : NoAccessPage
                              }
                            />

                            <Route path="/users" component={SignUpFlow} />

                            {/* @ts-ignore [WS-11959] fix typescript issue */}
                            <Route path="/integration/introduction" component={Introduction} />

                            <Route
                              path="/your_games"
                              component={
                                this.hasPermission(GAME_PERMISSIONS.READ) ? YourGames : NoAccessPage
                              }
                            />

                            <Route
                              path="/analytics"
                              component={
                                this.hasPermission(ANALYTICS_PERMISSIONS.READ)
                                  ? Analytics
                                  : NoAccessPage
                              }
                            />

                            <Route path="/games/:gameId/*" component={GameManagement} />

                            <Route path="/nfl_challenge" component={NflChallenge} />

                            <Route path="/learning_center*" component={ManagedLearningCenter} />

                            <Route
                              path={[
                                '/account/:publisherId/:tabName/:userId',
                                '/account/:publisherId/:tabName',
                              ]}
                              component={Account}
                            />

                            <Redirect from="/account*" to={`/account/${publisher.id}/user`} />

                            {shouldViewPeekPage(user, publisher) && (
                              <Route path="/peek" component={PeekPage} />
                            )}

                            {!window.location.href.includes('https://developers.skillz.com') && [
                              <Route
                                exact
                                path="/playground_old"
                                key="playground_old"
                                component={PlaygroundOld}
                              />,
                              <Route
                                exact
                                path="/playground"
                                key="playground"
                                component={Playground}
                              />,
                            ]}

                            {/* TODO [WS-24674] Hide from non Skillz users */}
                            {isLoggedIn && <Route path="/jobs" component={Jobs} />}

                            <Route
                              exact
                              path="/survey"
                              key={'survey'}
                              component={SurveyLandingPage}
                            />

                            <Route path="*" component={NotFoundPage} />
                          </Switch>

                          {isLoggedIn && !window.appConfig.featureFlags.surveysInDc && <Survey />}
                        </div>
                      </Box>
                    </Box>
                    {isLoggedIn && window.appConfig.featureFlags.surveysInDc ? (
                      <Surveys user={user} />
                    ) : (
                      <></>
                    )}
                  </SurveyContextProvider>
                </UserContext.Provider>
              </SnackbarContainer>
            </ThemeProvider>
          </SkillzetThemeProvider>
        </QueryClientProvider>
      </StyledEngineProvider>
    );
  }
}

export default App;
