import snakeCase from 'lodash/snakeCase';

import { ITicketInfo } from '@app/modules/ContactUs/interfaces';

type TContactUsFetch = () => JQuery.jqXHR<any>;
type TContactUsSendTicket = (data: ITicketInfo) => JQuery.jqXHR<any>;

export const fetchGames: TContactUsFetch = (): JQuery.jqXHR<any> => {
  return $.ajax({
    url: `/games.json`,
    method: 'GET'
  });
};

export const fetchSdkVersions: TContactUsFetch = (): JQuery.jqXHR<any> => {
  return $.ajax({
    url: `/mobile_sdk_releases/get_sdk_releases.json`,
    method: 'GET'
  });
};

export const sendTicket: TContactUsSendTicket = (data: ITicketInfo): JQuery.jqXHR<any> => {
  let form: FormData = new FormData();
  Object.entries(data).forEach((key: any) => {
    let ticketEntry: string = key[0];
    let ticketValue: any = key[1];

    // skip when null, empty string value, and default -1
    if (ticketValue === null || ticketValue === '' || ticketValue === -1) {
      return;
    }

    form.append(`send_ticket[${snakeCase(ticketEntry)}`, ticketValue);
  });

  return $.ajax({
    url: `/support/contact_us`,
    method: 'POST',
    data: form,
    contentType: false,
    processData: false
  });
};
