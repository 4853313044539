export const hasBothUpperAndLowerCaseLetters = (input: string): boolean => {
  // has at least one upper and at least one lower case
  return new RegExp('(?=.*[a-z])(?=.*[A-Z])').test(input);
};

export const hasAtLeastOneNumber = (input: string): boolean => {
  return new RegExp('(?=.*[0-9])').test(input);
};

export const hasMinimumEightCharacters = (input: string): boolean => {
  return input.length >= 8;
};

export const hasAtLeastOneSpecialCharacter = (input: string): boolean => {
  return new RegExp('[^a-zA-Z0-9]', 'i').test(input);
};

export const hasSubstring = (input: string, substring: string): boolean => {
  return input.toLowerCase().includes(substring.toLowerCase());
};

export const hasOnlyAsciiCharacters = (input: string): boolean => {
  return new RegExp('^[\x00-\x7F]*$').test(input);
};
