import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { fetchPublisher, findUser, fetchGames } from '@app/common/actions';
import { IRootState } from '@app/core/rootReducer';

const NavBar = loadable(() => import(/* webpackChunkName: "nav-bar" */ './NavBar'));

const mapStateToProps = (state: IRootState, ownProps: Record<string, never>) => {
  return {
    ...ownProps,
    user: state.user,
    publisher: state.publisher.publisherInfo,
    games: state.games,
  };
};

const mapDispatchToProps = {
  fetchPublisher,
  findUser,
  fetchGames,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TNavBarConnectedProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(withRouter(NavBar));
