import * as React from 'react';
import './styles/sectionHeaderStyles.scss';

interface ITitleProps {
  title: string;
  id: string;
}

// TODO DELETE [WS-10319] replace with PageHeader
class SectionHeader extends React.PureComponent<ITitleProps> {
  public render(): JSX.Element {
    let { title, id } = this.props;

    return (
      <div className="section-header__container" id={`${id}-container`}>
        <div className="section-header__blue-vertical-bar" id={`${id}-bar`} />
        <p className="section-header__title" id={`${id}-title`}>
          {title}
        </p>
      </div>
    );
  }
}

export { SectionHeader };
