import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { SORT_DIRECTION } from '@app/common/constants';
import { IAtomTableHeaderColumn, Order } from '@app/common/interfaces';

import './styles/atomTableHeaderStyles.scss';

interface IAtomTableHeaderProps<T> {
  id: string;
  headRows: IAtomTableHeaderColumn<T>[];
  isShaded?: boolean;
  orderBy?: T;
  order?: Order;
  onRequestSort?: (property: T) => () => void;
}

type TAtomTableHeaderDefaultProps = Required<Pick<IAtomTableHeaderProps<null>, 'isShaded'>>;

class AtomTableHeader<T> extends React.PureComponent<IAtomTableHeaderProps<T>> {
  public static defaultProps: TAtomTableHeaderDefaultProps = {
    isShaded: false
  };

  public renderTableSortLabelOrCellContent(column: IAtomTableHeaderColumn<T>): JSX.Element {
    let { id, order, orderBy, onRequestSort } = this.props;
    let isSortableTableHeader: boolean =
      !!onRequestSort && !!order && !!orderBy && !!column.property;

    if (!isSortableTableHeader || !onRequestSort || !column.property) {
      return <React.Fragment>{column.cellContent}</React.Fragment>;
    }

    return (
      <TableSortLabel
        id={`${id}__atom-table-header-cell-sort-label`}
        active={orderBy === column.property}
        direction={orderBy === column.property ? order : SORT_DIRECTION.ASC}
        onClick={onRequestSort(column.property)}
        IconComponent={KeyboardArrowDownIcon}
      >
        {column.cellContent}
      </TableSortLabel>
    );
  }

  public render(): JSX.Element {
    let { headRows, id, isShaded, orderBy, order } = this.props;
    let shadedMode: string = isShaded ? 'atom-component__table-header-shaded-mode' : '';

    return (
      <TableHead
        id={`${id}-atom-table-header-container`}
        className={`atom-component__table-header ${shadedMode}`}
      >
        <TableRow className={'atom-component__table-header-row'}>
          {headRows.map((column: IAtomTableHeaderColumn<T>) => (
            <TableCell
              key={`atom-table-header-cell-${column.id}`}
              id={`${id}__atom-table-header-cell-${column.id}`}
              className="atom-component__table-header-cell"
              sortDirection={orderBy === column.property ? order : false}
            >
              {this.renderTableSortLabelOrCellContent(column)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}
export { AtomTableHeader };
