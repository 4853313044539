export enum NEXT_STEP_FIX_APPLE_PAY_COPY {
  TITLE = 'Suggested action: fix Apple Pay',
  DESCRIPTION = 'Our system has alerted us that there is an issue with Apple Pay in your game. Please take a look and address the issue.',
  BUTTON_TEXT = 'Fix Apple Pay',
}

export enum NEXT_STEP_FIX_PUSH_NOTIFICATION_COPY {
  TITLE = 'Suggested action: fix push notifications',
  DESCRIPTION = 'Our system has alerted us that your push notification certificate has expired. Please take a look and address the issue.',
  BUTTON_TEXT = 'Fix push notifications',
}

export enum NEXT_STEP_UPLOAD_SDK_COPY {
  TITLE = 'Suggested action: update your SDK',
  DESCRIPTION = 'We’ve just released a brand new SDK! Download and integrate it into your game now to access our newest features and tech improvements.',
  BUTTON_TEXT = 'Update SDK',
}

export enum NEXT_STEP_TOURNAMENT_COPY {
  TITLE = 'Next step: tournaments',
  DESCRIPTION = 'Having fun new ways to compete can increase player engagement. With this tool you can create custom game modes, implement dynamic difficulty scaling and run special events.',
  BUTTON_TEXT = 'Set up tournaments',
}

export enum NEXT_STEP_IN_APP_PURCHASE_COPY {
  TITLE = 'Next step: in-app purchases (IAP)',
  DESCRIPTION = 'Add your package IDs here to enable in-app purchasing in your game.',
  BUTTON_TEXT = 'Set up IAP',
}

export enum NEXT_STEP_GAME_BUILDS_COPY {
  TITLE = 'Next step: game builds',
  DESCRIPTION = 'It’s time for players to access your game! Upload your game build to be enabled in our production environment and get all the latest updates. Return here anytime to release your new builds.',
  BUTTON_TEXT = 'Upload a build',
}

export enum NEXT_STEP_APP_STORE_URL_COPY {
  TITLE = 'Next step: Publish to Skillz Games',
  DESCRIPTION = 'After submitting your game to the app stores, provide us with a tagline and store URL(s) so we can list your game on Skillz Games and share your app with our millions of players!',
  BUTTON_TEXT = 'Set up page listing',
}

export enum NEXT_STEP_THEME_COPY {
  TITLE = 'Next step: custom theme',
  DESCRIPTION = 'Customize the look and feel of the Skillz UI to match your game and create a seamless player experience.',
  BUTTON_TEXT = 'Customize theme',
}

export enum NEXT_STEP_PUSH_NOTIFICATION_COPY {
  TITLE = 'Next step: push notifications',
  DESCRIPTION = 'It’s no secret that enabling push notifications boosts user engagement! Turn on this feature and get your players coming back for more.',
  BUTTON_TEXT = 'Enable notifications',
}

export enum NEXT_STEP_APPLE_PAY_COPY {
  TITLE = 'Next step: Apple Pay',
  DESCRIPTION = 'When available, 30% of depositing players use Apple Pay. Activate this feature to enable your users to make seamless secure purchases on their iOS devices.',
  BUTTON_TEXT = 'Activate Apple Pay',
}

export enum NEXT_STEP_DEEP_LINKING_COPY {
  TITLE = 'Next step: deep linking',
  DESCRIPTION = '"Deep links" are used to send players directly to a specific screen in your game from a link in an email or push notification. Verify it’s working properly in your game here.',
  BUTTON_TEXT = 'Verify deep linking',
}

export enum NEXT_STEP_SINGLE_SIGN_ON_COPY {
  TITLE = 'Next step: single sign on (SSO)',
  DESCRIPTION = 'Did you know that 17% of paying Skillz players play multiple Skillz games? SSO recognizes existing players, letting them log in without having to enter their username or password. ',
  BUTTON_TEXT = 'Set up SSO',
}

export enum NEXT_STEP_HUNDRED_DAU {
  TITLE = 'Next step: Achieve 100 DAU',
  DESCRIPTION = 'Getting to 100 daily active users is typically the first sign of a successful game and a hard requirement to become prize enablement. Apply for the Accelerate program to help boost traffic to your game.',
  BUTTON_TEXT = 'Accelerate program',
}

export enum NEXT_STEP_SEVENTY_FIVE_DAU {
  TITLE = 'Next step: Achieve 75 DAU',
  DESCRIPTION = 'Getting to 75 daily active users is the first step towards success and a hard requirement for prize enablement. Apply for our Accelerate program to help boost traffic to your game.',
  BUTTON_TEXT = 'Accelerate program',
}

export enum NEXT_STEP_FAIR_PLAY {
  TITLE = 'Next step: Ensure Fair Play',
  DESCRIPTION = 'At the heart of any competition is that each participant is offered an equal opportunity to win. Skillz automatically runs our patented algorithm on each game to ensure fairness. Lets focus on getting your game past this checkpoint.',
  BUTTON_TEXT = 'Learn More',
}

export enum NEXT_STEP_PRIZE_ENABLEMENT {
  TITLE = 'Next step: prize enablement',
  DESCRIPTION = 'Incredible work - your game is off to a great start! Reach out to our developer success team today to request prize enablement and start monetizing your hard work. ',
  BUTTON_TEXT = 'Turn on prizes',
}

export enum NEXT_STEP_ANALYTICS_COPY {
  TITLE = 'Suggested action: Review your game’s performance',
  DESCRIPTION = 'Your game is looking and feeling great! Check out the Analytics dashboard to monitor the performance of your game.',
  BUTTON_TEXT = 'View Analytics',
}

export enum NEXT_STEP_MONETIZATION_CHANNEL {
  TITLE = 'Next step: Understanding Monetization',
  DESCRIPTION = 'Now that your game is live, learn more about how monetization works on Skillz and manage your preferences via this step.',
  BUTTON_TEXT = 'Learn more',
}

export enum NEXT_STEP_PLAYER_DATA {
  TITLE = 'Next step: Player Data',
  DESCRIPTION = 'Create unique gameplay experiences for each of your players with customizable Player Data storage. From statistics to progression systems and more - unlock your creativity with these APIs!',
  BUTTON_TEXT = 'Check it out',
}

export enum NEXT_STEP_IN_GAME_ITEMS {
  TITLE = 'Next step: In Game Items',
  DESCRIPTION = 'Create unique gameplay experiences for each of your players with customizable in game items. From virtual goods to progression systems and more - unlock your creativity with these APIs!',
  BUTTON_TEXT = 'Check it out',
}
