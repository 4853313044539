// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import { Dispatch } from 'redux';

import { IAction } from '@app/common/interfaces';
import axios, { AxiosResponse } from '@app/common/setup/axiosWithHeader';

export interface ISingleSignOn {
  prefix: string | null;
  verified: boolean | null;
}

export const SINGLE_SIGN_ON = {
  RECEIVE_PREFIX: 'singleSignOn/RECEIVE_PREFIX',
};

export const sendSSORequest = (gameId: number, prefix: string) => {
  let form = new FormData();
  form.append('sso[id]', gameId.toString());
  form.append('sso[prefix]', prefix);

  return axios.post(`/single_sign_on/${gameId}/send_sso_request.json`, form);
};

export const receivePrefix = (data: ISingleSignOn) => {
  return {
    type: SINGLE_SIGN_ON.RECEIVE_PREFIX,
    data,
  };
};

export const fetchSSOPrefix = (gameId: number) => (dispatch: Dispatch<IAction<ISingleSignOn>>) => {
  return axios
    .get(`/single_sign_on/${gameId}/get_sso_prefix.json`)
    .then((response: AxiosResponse<ISingleSignOn>) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(receivePrefix(camelize(response.data)));
    });
};
