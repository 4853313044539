import { IAnalyticCsvObject } from '@app/common/interfaces';

export const TITLE: string = 'Analytics';

export enum DATE_RANGE_OPTIONS {
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  MTD = 'MTD',
  PRIOR_MONTH = 'Prior Month',
  LAST_90_DAYS = 'Last 90 days',
  LAST_180_DAYS = 'Last 180 days',
  YTD = 'YTD',
  CUSTOM_DATE_RANGE = 'Custom Date Range',
}

export enum TAB {
  OVERVIEW = 'overview',
  ENGAGEMENT = 'engagement',
  MONETIZATION = 'monetization',
  FUNNEL = 'funnel',
}

export const ANALYTICS_TABS: string[] = ['Overview', 'Engagement', 'Monetization', 'Funnel'];
export const TABS: string[] = [TAB.OVERVIEW, TAB.ENGAGEMENT, TAB.MONETIZATION, TAB.FUNNEL];

// old keys from developer_analytic table
export const DEVELOPER_ANALYTIC_METRICS: IAnalyticCsvObject = {
  grossRevenue: 'Revenue',
  estimatedGrossRevenue: 'Estimated Revenue',
  skillzArpdau: 'Avg Rev/DAU',
  estSkillzArpdau: 'Est Avg Rev/DAU',
  skillzArppu: 'Avg Rev/Paying DAU',
  estSkillzArppu: 'Est Avg Rev/Paying DAU',
  installsTo1z: 'Installs to 1Z',
  installsTo2z: 'Installs to 2Z',
  installsTo3z: 'Installs to 3Z',
  installsToDeposit: 'Installs to Deposit',
  d1: 'D1 Retention',
  d7: 'D7 Retention',
  d30: 'D30 Retention',
  p1: 'P1 Retention',
  p7: 'P7 Retention',
  p30: 'P30 Retention',
  skillzPayingDau: 'Paying DAU',
  proGamesPerPlayer: 'Paid Games per Paying DAU',
  newProPlayers: 'NPU',
  skillzInstalls: 'Installs',
};

// generic keys for record & abortRate which is still reading from developer_analytic table
export const COMMON_ANALYTICS_METRICS: IAnalyticCsvObject = {
  startTime: 'Date',
  gameTitle: 'Game Name',
  gameProductionId: 'Game ID',
  // only key from developer_analytic table
  abortRate: 'Abort Rate',
};

// keys from developer_analytic table and mapped to game analytic service data
export const GAME_ANALYTICS_REPLACED_METRICS: IAnalyticCsvObject = {
  skillzDau: 'DAU',
  gamesPerPlayer: 'Games per DAU',
  gamesPlayed: 'Games Played',
  proGamesPlayed: 'Pro Games Played',
  zGamesPlayed: 'Z Games Played',
  dauOfMau: 'DAU/MAU',
};

// new keys defined in controller and data from game analytic service
export const GAME_ANALYTICS_METRICS: IAnalyticCsvObject = {
  installs: 'Installs',
  installsZ1: 'Z1 Installs',
  installsZ2: 'Z2 Installs',
  installsZ3: 'Z3 Installs',
  retentionD1: 'D1 Retention',
  retentionD7: 'D7 Retention',
  retentionD30: 'D30 Retention',
  payerProRetentionD1: 'D1 Paying User Retention',
  payerProRetentionD7: 'D7 Paying User Retention',
  payerProRetentionD30: 'D30 Paying User Retention',
  proDau: 'Pro DAU',
  proGamesPerProDau: 'Pro Games per Pro DAU',
  entryFeePerProDau: 'Entry Fee per Pro DAU',
  firstTimePayersToSkillzNetwork: 'New Paying Users',
  kpiInstallsToDeposit: 'Installs To Deposit',
  entryFee: 'Entry Fee',
  entryFeeD1: 'D1 Entry Fee',
  entryFeeD7: 'D7 Entry Fee',
  entryFeeD30: 'D30 Entry Fee',
  entryFeeLifetime: 'Lifetime Entry Fee',
  newProUsers: 'New Pro Playing Users',
};

export const ESTIMATED_REVENUE_METRIC: IAnalyticCsvObject = {
  estimatedRevenue: 'Estimated Revenue',
};

export const idPrefix: string = 'analytics';

export const ALL_GAMES: string = 'All Games';

export const DEFAULT_DATE_RANGE: DATE_RANGE_OPTIONS = DATE_RANGE_OPTIONS.LAST_30_DAYS;
