import React, { useState, useEffect } from 'react';

import variables from '@app/core/variables.scss';

import { componentClassName } from '../WizardView';
import HexagonSvg from './HexagonSvg';
import SidebarTooltip from './SidebarTooltip';

const getIconColor = (isVisited: boolean, isActive: boolean) => {
  if (isActive) return variables.colorBrandBlue;
  if (isVisited) return variables.colorWhite;

  return variables.colorGray100Borders;
};

const SidebarLink = ({
  id,
  linkTitle,
  currentHelpBitId,
  handleChangeHelpBit,
  shouldShowTooltip,
}: {
  id: number;
  linkTitle: string;
  currentHelpBitId: number;
  handleChangeHelpBit: (helpBitId: number) => () => void;
  shouldShowTooltip: boolean;
}): JSX.Element => {
  const [isVisited, setIsVisited] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (id === currentHelpBitId) {
      setIsActive(true);
      setIsVisited(true);
    } else {
      setIsActive(false);
    }
  }, [currentHelpBitId]);

  const className =
    `${componentClassName}__sidebar-link` +
    (isVisited ? ' visited' : '') +
    (isActive ? ' active' : '');

  return (
    <div className={className} onClick={handleChangeHelpBit(id)} data-qa={'wizard-sidebar-link'}>
      <div
        className={`${componentClassName}__sidebar-link-icon-container`}
        data-qa={'wizard-sidebar-link-icon'}
      >
        <SidebarTooltip key={id} id={id} title={linkTitle} shouldShowTooltip={shouldShowTooltip}>
          <HexagonSvg color={getIconColor(isVisited, isActive)} />
        </SidebarTooltip>
      </div>
      <div className={`${componentClassName}__sidebar-link-title`}>{linkTitle}</div>
    </div>
  );
};

export default SidebarLink;
