import uniqBy from 'lodash/uniqBy';
import ReactGA from 'react-ga';

import { IAnalyticInfo, IGAEvent, IPublisher2 } from '@app/common/interfaces';
import { ALL_GAMES } from '@app/modules/Analytics/constants';

export function sendGaEvent(
  selectedGameName: string,
  selectedDateRange: string,
  analytics: IAnalyticInfo[],
  selectedGameProductionId: number,
  publisher: IPublisher2
): IGAEvent {
  let gameIdsString: string;
  let event: IGAEvent = { category: '', action: '', label: '' };
  let clientId: string = '';
  // assign clientId from the ReactGA clientId
  ReactGA.ga((tracker: any): void => {
    clientId = tracker.get('clientId');
  });

  // if the selected game is All the games, then the GameID sent to the GA
  // event is a long string all the Game IDs separated by a '_'
  if (selectedGameName === ALL_GAMES) {
    // filter out the analytics data to not duplicate games
    let unique: IAnalyticInfo[] = uniqBy(analytics, 'gameProductionId');
    // create an array of only gameIds
    let gameIds: string[] = unique.map((analytic: IAnalyticInfo) => {
      return analytic.gameProductionId.toString();
    });
    // create a string of all game ids separated by '_'
    gameIdsString = gameIds.join('_');
  } else {
    // if only one game was selected nothing special has to be done to the string
    gameIdsString = selectedGameProductionId.toString();
  }

  event.category = 'Analytics Data Download';
  event.action = `${publisher.id}`;
  event.label = `${gameIdsString}:${selectedDateRange}`;

  // send event to google analytics
  ReactGA.event({
    category: event.category,
    action: event.action,
    label: event.label
  });

  return event;
}
