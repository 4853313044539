import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const CaseStudyInGameItems = loadable(
  () => import(/* webpackChunkName: "case-study-in-game-items" */ './CaseStudyInGameItems')
);

const mapStateToProps = () => {
  return {} as Record<string, unknown>;
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TCaseStudyInGameItemsConnectedProps = ConnectedProps<typeof connector> &
  RouteComponentProps;

export default connector(withRouter(CaseStudyInGameItems));
