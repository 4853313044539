import * as React from 'react';

import { IBaseProps } from 'portal-common-library/interfaces/componentBase';

import { TMouseEventDiv } from '../interfaces';
import './styles/atomInteractiveIconStyles.scss';

export interface IAtomInteractiveIconProps extends IBaseProps {
  icon: JSX.Element | string;
  className?: string;
  isActive: boolean;
  clickFunction: (event: TMouseEventDiv) => void;
  isCornerIcon?: boolean;
}

function AtomInteractiveIcon({
  id,
  className,
  icon,
  isActive,
  clickFunction,
  dataAttributes,
  isCornerIcon = false,
}: IAtomInteractiveIconProps): JSX.Element {
  let atomClassName = `atom-component__interactive-icon${isActive ? ' active' : ''}${
    isCornerIcon ? ' corner' : ''
  }`;

  return (
    <div
      onClick={clickFunction}
      id={id}
      className={`${atomClassName} ${className ?? ''}`}
      {...dataAttributes}
    >
      {typeof icon === 'string' ? (
        <img src={icon} className={`atom-component__interactive-icon-img`} />
      ) : (
        icon
      )}
    </div>
  );
}

export { AtomInteractiveIcon };
