import { IAction } from '@app/common/interfaces';
import { GAME_BUILDS_COUNT } from '../actions';

export function totalCountReducer(state: number = 0, action: IAction<number>): number {
  Object.freeze(state);

  switch (action.type) {
    case GAME_BUILDS_COUNT.RECEIVE_COUNT:
      return action.data;
    default:
      return state;
  }
}
