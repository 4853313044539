import { LOCALE, SORT_DIRECTION } from '@app/common/constants';
import {
  IBodyImagesWithConditionValidation,
  TCondition,
  IProgressionRoomVersionBodyImages,
  IProgressionRoomVersionLocaleFiles,
  IStateProgressionRoomVersionLocaleBodyImage,
  TStateProgressionRoomVersionLocale,
} from '@appEntryPoints/common/interfaces';
import { GAME_BUILD_STATUS } from '@appGameHome/modules/GameBuild/constants';
import { IGameBuildIndexParams } from '@appGameHome/modules/GameBuild/interfaces';
import { TABLE_SORT_ATTRIBUTES } from '@appGameHome/modules/GameBuild/modules/GameBuildsTable/constants';
import { OPERATORS } from '@appGameProgression/modules/EntryPoints/common/constants/condition';
import { IProgressionRoomValidation } from '@appGameProgression/modules/EntryPoints/modules/EntryPoint/interfaces';

export const supportImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'];
export const supportImageTypesBodyImage = supportImageTypes.concat(['video/mp4', 'image/gif']);

export const initialBaseVersionLocaleFiles: IProgressionRoomVersionLocaleFiles = {
  roomIconFile: null,
  headerImageFile: null,
  headerBadgeFile: null,
};

export const initialBaseBodyImages: IProgressionRoomVersionBodyImages = {
  defaultBodyImage: {
    condition: null,
    url: '',
    file: null,
    mimeType: '',
  },
  bodyImagesWithCondition: [] as IStateProgressionRoomVersionLocaleBodyImage[],
};

export const initialRoomVersionLocaleState = (
  locale: LOCALE
): TStateProgressionRoomVersionLocale => {
  return {
    ...initialBaseVersionLocaleFiles,
    ...initialBaseBodyImages,
    locale,
    roomIcon: '',
    roomName: '',
    headerTitle: '',
    headerSubtitle1: '',
    headerSubtitle2: '',
    headerFontColor1: '#F2F2F2',
    headerFontColor2: '#FFDD66',
    headerBadge: '',
    headerImage: '',
    headerColor: {
      renderType: 'gradient',
      colors: ['#343434', '#8C8C8C'],
    },
  };
};

export const initialValidations: IProgressionRoomValidation = {
  headerFontColor1: { status: true, error: '' },
  headerFontColor2: { status: true, error: '' },
  backgroundColorLeft: { status: true, error: '' },
  backgroundColorRight: { status: true, error: '' },
};

export const ENTRY_POINT_CSS_PREFIX = 'entry-point';

export const headerFontColor1Swatches = ['#E5F3FE', '#710000', '#02005D', '#000000'];
export const headerFontColor2Swatches = ['#006C2B', '#FFD9C3', '#390066', '#000000'];
export const headerColorLeftSwatches = ['#548D21', '#FFD0AF', '#EFECFF', '#FAEA99'];
export const headerColorRightSwatches = ['#9ECE5B', '#FF753A', '#A8A8F6', '#DCAB00'];

export const emptyConditionState = (developerKey: string): TCondition => {
  return ({ [OPERATORS.EQ]: ['${' + `${developerKey}` + '}', ''] } as unknown) as TCondition;
};

export const emptyConditionValidation: IBodyImagesWithConditionValidation[] = [
  {
    value: [{ status: true, error: '' }],
  },
];

export const comparisonOperators = {
  [OPERATORS.GT]: 'More than',
  [OPERATORS.LT]: 'Less than',
  [OPERATORS.GTE]: 'More than or equal to',
  [OPERATORS.LTE]: 'Less than or equal to',
  [OPERATORS.EQ]: 'Equal to',
};

export const defaultGameBuildFetchParams: IGameBuildIndexParams = {
  uploadStatus: GAME_BUILD_STATUS.COMPLETE,
  sortAttribute: TABLE_SORT_ATTRIBUTES.CREATED_AT,
  sortDirection: SORT_DIRECTION.DESC,
  offset: 0,
  limit: 30,
};

export enum FIELDS_OF_TSTATE_LOCAL {
  BODY_IMAGES_WITH_CONDITION = 'bodyImagesWithCondition',
  DEFAULT_BDY_IMAGE = 'defaultBodyImage',
  HEADER_SUBTITLE_2 = 'headerSubtitle2',

  // part of the required fields
  ROOM_NAME = 'roomName',
  ROOM_ICON = 'roomIcon',
  HEADER_TITLE = 'headerTitle',
  HEADER_SUBTITLE_1 = 'headerSubtitle1',
  HEADER_FONT_COLOR_1 = 'headerFontColor1',
  HEADER_FONT_COLOR_2 = 'headerFontColor2',
  HEADER_COLOR = 'headerColor',
}

export const localeFieldsToValidate: (keyof TStateProgressionRoomVersionLocale)[] = [
  'roomName',
  'headerTitle',
  'headerSubtitle1',
  'headerSubtitle2',
  'bodyImagesWithCondition',
];

export const allRequiredFieldsToValidate: (keyof TStateProgressionRoomVersionLocale)[] = [
  'roomName',
  'headerTitle',
  'roomIcon',
  'headerSubtitle1',
  'headerFontColor1',
  'headerFontColor2',
  'headerColor',
];
