import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import AtomButton from 'portal-common-library/atoms/AtomButton';
import AtomTextField from 'portal-common-library/atoms/AtomTextField';
import { Link } from 'react-router-dom';

import { MATERIAL_UI_VARIANTS } from '@app/common/constants';
import { TInputChangeEvent } from '@app/common/interfaces';

import { IEmail, IRailsUserData, UserFlowErrors } from './';

export interface IForgotPassState extends IEmail {
  error: string;
  isToastOpen: boolean;
}

class ForgotPassword extends React.Component<unknown, IForgotPassState> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      email: '',
      error: '',
      isToastOpen: false,
    };
  }

  public handleInputChange = (event: TInputChangeEvent): void => {
    // handles setting email state and error state if needed
    let { error } = this.state;
    let { value } = event.currentTarget;
    if (error) {
      this.setState({
        email: value,
        error: '',
      });
    } else {
      this.setState({ email: value });
    }
  };

  public validateEmail(): boolean {
    let { email } = this.state;
    if (!email) {
      // email should not be blank
      this.setState({ error: UserFlowErrors.Blank });

      return false;
    }
    // email should have an @
    if (!email.includes('@')) {
      this.setState({ error: UserFlowErrors.InvalidEmail });

      return false;
    }

    return true;
  }

  public handleSubmit = (): void => {
    let { email } = this.state;

    if (!this.validateEmail()) {
      return;
    }

    let data: IRailsUserData = { user: { email } };
    void $.ajax({
      url: `/users/password`,
      method: 'POST',
      data,
    }).then(() => {
      this.setState({ isToastOpen: true });
    });
  };

  public handleToastClose = (): void => {
    this.setState({ isToastOpen: false });
  };

  public renderToast(): JSX.Element {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={true}
        ContentProps={{
          'aria-describedby': 'message-id',
          style: {
            backgroundColor: '#dff0d8',
            fontSize: 12.5,
            marginTop: 62,
            fontFamily: 'Roboto',
            color: '#3c763d',
            border: 1,
          },
        }}
        message={
          <span id="message-id">A link to reset your password has been sent to your email</span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleToastClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }

  public render(): JSX.Element {
    let { email, error, isToastOpen } = this.state;

    return (
      <div className="sign-in-background__container">
        <div className="sign-up-flow__container forgot-pass__container">
          <h2 id="sign-up-forgot-password-title">Forgot Password?</h2>
          <div className="page-title-container">
            <div className="sign-up-flow__subheader">
              <h4 id="sign-up-forgot-password-subtitle">
                Enter the email address associated with your account. We will email you a link to
                reset your password.
              </h4>
            </div>
          </div>

          <div className="sign-up-flow__input">
            <AtomTextField
              id="password-reset-email"
              label="Email"
              attributeName="email"
              variant={MATERIAL_UI_VARIANTS.OUTLINED}
              placeholder="youraddress@email.com"
              onChange={this.handleInputChange}
              hasError={!!error}
              errorText={error}
              value={email}
              dataAttributes={{ 'data-qa': 'auth__email-input' }}
            />
          </div>
          <div className="sign-up-flow__button">
            <AtomButton
              id="password-reset-button"
              buttonContent="Send reset link"
              buttonType="primary"
              onClick={this.handleSubmit}
              dataAttributes={{ 'data-qa': 'auth__reset-cta' }}
            />
          </div>
          <div className="sign-up-flow__bottom-subtext" id="sign-up-remembered-password">
            Remembered your password?&nbsp;
            <Link
              id="sign-up-remembered-password-link"
              className="sign-up-flow__link"
              to="/users/sign_in"
            >
              Login here
            </Link>
          </div>
          {isToastOpen && this.renderToast()}
          <div className="sign-up-flow__already-have-account-container" id="sign-up-need-account">
            Don&apos;t have an account?
            <br />
            <Link id="sign-up-need-account-link" className="sign-up-flow__link" to="/users/sign_up">
              Sign up here
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export { ForgotPassword };
