import loadable from '@loadable/component';

const LoadingIndicator = loadable(
  () =>
    import(
      /* webpackChunkName: "content-management-system-loading-indicator" */ './LoadingIndicator'
    )
);

export default LoadingIndicator;
