import { useState, useEffect } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

// resizing can cause up to 200 renders going from 1200px to 600px
const debounce = (func: () => void) => {
  let timer: ReturnType<typeof setTimeout>;

  return () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, 100);
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', debounce(handleResize));

    return () => window.removeEventListener('resize', debounce(handleResize));
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
