export const printFile = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      if (!!e.target) {
        resolve(e.target.result);
      } else {
        reject(e);
      }
    };
    reader.readAsText(file);
  });
};
