import * as React from 'react';
import greenCheckMark from '@app/imageAssets/greenCheckMark.svg';
import './styles/atomColoredCircle.scss';

export interface IAtomColoredCircleProps {
  id: string;
  circleContent?: JSX.Element | string | null;
  circumference?: string;
  fontSize?: string;
}

type TDefaultPropKeys = 'circleContent' | 'circumference' | 'fontSize';

type TDefaultProps = Required<Pick<IAtomColoredCircleProps, TDefaultPropKeys>>;

class AtomColoredCircle extends React.PureComponent<IAtomColoredCircleProps> {
  public static defaultProps: TDefaultProps = {
    circleContent: null,
    circumference: '24px',
    fontSize: '16px'
  };

  public render(): JSX.Element {
    let { id, circleContent, circumference, fontSize } = this.props;

    return (
      <div
        id={id}
        className={`atom-component__colored-circle ${id}`}
        style={{ width: circumference, height: circumference }}
      >
        <span
          id={`${id}-span`}
          className={`atom-component__colored-circle-span ${id}`}
          style={{ fontSize }}
        >
          {circleContent ?? <img src={greenCheckMark} />}
        </span>
      </div>
    );
  }
}

export { AtomColoredCircle };
