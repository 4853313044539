import { Component } from 'react';

import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  clearGameInfo,
  fetchGames,
  fetchPermissions,
  openGameInfoModal,
  openProfileModal,
} from '@app/common/actions';
import { IWithSnackbarProp } from '@app/common/helpers/snackbarHookHelper';
import { IRootState } from '@app/core/rootReducer';

const Dashboard = loadable(
  () => import(/* webpackChunkName: "dashboard" */ './Dashboard')
) as typeof Component;

const mapStateToProps = (state: IRootState) => {
  let {
    publisher: { publisherInfo },
    user,
    games,
    feature,
    permissions,
  } = state;

  return {
    publisher: publisherInfo,
    user,
    games,
    feature,
    permissions,
  };
};

const mapDispatchToProps = {
  openGameInfoModal,
  clearGameInfo,
  fetchGames,
  fetchPermissions,
  openProfileModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TDashboardConnectProps = ConnectedProps<typeof connector> &
  RouteComponentProps &
  IWithSnackbarProp;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
