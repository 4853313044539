import React from 'react';

import { Switch, Route } from 'react-router-dom';

import NotFoundPage from '@app/modules/NotFoundPage';

import CaseStudyBenchmarks from './../CaseStudyBenchmarks';
import CaseStudyChat from './../CaseStudyChat';
import CaseStudyInGameItems from './../CaseStudyInGameItems';
import CaseStudyProgression from './../CaseStudyProgression';
import ManagedLearningCenterIndex from './ManagedLearningCenterIndex';

export default function ManagedLearningCenter() {
  return (
    <div id={'managed-learning-center__container'}>
      <Switch>
        {window.appConfig.featureFlags.progressionPr3iIgiCaseStudy && (
          <Route
            path="/learning_center/player_rewards_and_in_game_items_study"
            component={CaseStudyInGameItems}
          />
        )}
        {window.appConfig.featureFlags.progressionPr7LearningCenterAndCaseStudy && (
          <Route
            path="/learning_center/progression_and_player_data_case_study"
            component={CaseStudyProgression}
          />
        )}
        <Route path="/learning_center/chat_blog" component={CaseStudyChat} />
        <Route
          path="/learning_center/developer_analytics_benchmarks"
          component={CaseStudyBenchmarks}
        />

        <Route exact path="/learning_center" component={ManagedLearningCenterIndex} />
        <Route path="/learning_center/*" component={NotFoundPage} />
      </Switch>
    </div>
  );
}
