import React from 'react';

import './imageCard.scss';

interface IImageCardProps {
  dataId?: string;
  image: string;
  handleClick: () => void;
  title: string;
  icon: JSX.Element;
  hideGradientOverlay?: boolean;
}

function ImageCard({
  dataId,
  image,
  handleClick,
  title,
  icon,
  hideGradientOverlay,
}: IImageCardProps): JSX.Element {
  return (
    <div
      className={`image-card`}
      data-qa={dataId}
      style={{
        backgroundImage: `url(${image})`,
        cursor: hideGradientOverlay ? 'default' : 'pointer',
      }}
      onClick={handleClick}
    >
      <div className={hideGradientOverlay ? undefined : 'gradient-bg-layer'}>
        <div className={'text-overlay-layer'}>
          {icon}
          <span data-qa={'video-thumbnail-title'}>{title}</span>
        </div>
      </div>
    </div>
  );
}

export default ImageCard;
