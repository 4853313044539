import React from 'react';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Grid from '@mui/material/Grid';

import { IActionNoData } from '@app/common/interfaces';
import document from '@app/imageAssets/Dashboard/document.svg';
import downloadFile from '@app/imageAssets/Dashboard/downloadFile.svg';
import { TOrder } from '@app/modules/GameManagement/modules/GameHome/modules/GameProgression/modules/SeasonsTab/subcomponents/SeasonDetails/interfaces';

import { ProfileWidget } from '../WhatsNew/subcomponents';
import InteractiveCard from './InteractiveCard';
import LinkCard from './LinkCard';
import { ILearningCenterCard, IProfileWidgetData, TSortItem, TSortKey } from './interfaces';
import { TCardBehavior, TLearningCenterIcon } from './interfaces';

const descendingComparator = (a: TSortItem, b: TSortItem, orderBy: TSortKey) => {
  const highestUtfCode = String.fromCodePoint(0x10ffff);
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  const A = a[orderBy] ?? highestUtfCode;
  const B = b[orderBy] ?? highestUtfCode;
  /* eslint-enable @typescript-eslint/no-unsafe-assignment */

  if (B < A) {
    return -1;
  }
  if (B > A) {
    return 1;
  }

  return 0;
};

export const learningCenterSortComparator = (
  order: TOrder,
  orderBy: TSortKey
): ((a: TSortItem, b: TSortItem) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const renderCardIcon = (iconType: TLearningCenterIcon) => {
  if (iconType === 'play') {
    return <PlayCircleOutlineIcon />;
  }

  if (iconType === 'download') {
    return <img src={downloadFile} />;
  }

  return <img src={document} />;
};

export const renderCardWithComponent = (
  card: ILearningCenterCard,
  shouldMaintainAspectRatio?: boolean
) => {
  const typesThatUseInteractiveCard = ['video', 'wizard'];

  return (
    <Grid item key={card.id} xs={12} md={4}>
      {typesThatUseInteractiveCard.includes(card.target) ? (
        <InteractiveCard
          icon={renderCardIcon(card.iconType)}
          backgroundImage={card.backgroundImage.url}
          videoUrl={card.targetUrl as string}
          helpBitId={card.helpBits.length > 0 ? card.helpBits[0].id : undefined}
          shouldMaintainAspectRatio={shouldMaintainAspectRatio}
          category={card.learningCenterCategory.CategoryName}
        >
          {card.Title}
        </InteractiveCard>
      ) : (
        <LinkCard
          // only Interactive/Wizard cards won't have a targetLink, all LinkCards have targetUrl
          targetLink={card.targetUrl as string}
          behavior={card.target as TCardBehavior}
          icon={renderCardIcon(card.iconType)}
          backgroundImage={card.backgroundImage.url}
          shouldMaintainAspectRatio={shouldMaintainAspectRatio}
          category={card.learningCenterCategory.CategoryName}
        >
          {card.Title}
        </LinkCard>
      )}
    </Grid>
  );
};

export const sortAndRenderLearningCenterCards = (
  categoryId: number,
  cards: ILearningCenterCard[],
  sortBy: keyof ILearningCenterCard,
  shouldCardsRetainAspectRatio?: boolean
) =>
  cards
    .filter((card) => !!card[sortBy] && card.learningCenterCategory?.id === categoryId)
    // hard coded for now, possible to sort by other ways in the future
    .sort(learningCenterSortComparator('asc', sortBy as TSortKey))
    .map((card) => renderCardWithComponent(card, shouldCardsRetainAspectRatio));

export const sortAndRenderWhatsNewCards = (
  cards: ILearningCenterCard[],
  sortBy: keyof ILearningCenterCard,
  openProfileModal: () => IActionNoData,
  shouldShowProfileWidget: boolean,
  profileWidgetData?: IProfileWidgetData
): JSX.Element[] => {
  const renderedCards = cards
    .filter((card) => !!card[sortBy])
    // hard coded for now, possible to sort by other ways in the future
    .sort(learningCenterSortComparator('asc', sortBy as TSortKey))
    .slice(0, 3)
    .map((card) => renderCardWithComponent(card, !shouldShowProfileWidget));

  if (shouldShowProfileWidget && !!profileWidgetData) {
    const { user, publisher, segments } = profileWidgetData;
    const testId = 'dashboard-profile-widget';

    renderedCards.pop();
    renderedCards.push(
      <Grid item xs={12} md={4} key={testId} data-qa={testId}>
        <ProfileWidget
          user={user}
          publisher={publisher}
          segments={segments}
          openProfileModal={openProfileModal}
        />
      </Grid>
    );
  }

  return renderedCards;
};
