import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ENVIRONMENT } from '@app/common/constants';
import {
  fetchProgressionRoomVersionLocales,
  fetchProgressionRoomVersions,
} from '@appGameHome/common/actions';

import {
  TProgressionRoomVersion,
  IGameProgressionRoomVersions,
  IProgressionRoomVersion,
  IProgressionRoomVersionLocale,
} from '../../common/interfaces';

const initialState = {
  sandbox: {} as TProgressionRoomVersion,
  production: {} as TProgressionRoomVersion,
};

interface IReducerPayload<T> {
  env: ENVIRONMENT;
  items: T[];
  totalCount: number;
  currentVersionId?: string;
}

type TProgressionRoomVersionReducer = IProgressionRoomVersion | IProgressionRoomVersionLocale;

interface IReducerGenerator {
  <T extends TProgressionRoomVersionReducer>(): CaseReducer<
    IGameProgressionRoomVersions,
    PayloadAction<IReducerPayload<T>>
  >;
}

const createProgressionRoomReducer: IReducerGenerator = <
  T extends TProgressionRoomVersionReducer
>() =>
  function fulfilledProgressionRoomFetchActionReducer(
    state: IGameProgressionRoomVersions,
    action: PayloadAction<IReducerPayload<T>>
  ) {
    const { env } = action.payload;

    switch (action.type) {
      case fetchProgressionRoomVersions.fulfilled.type:
        (action.payload.items as IProgressionRoomVersion[]).forEach((version) => {
          const roomVersionId = version.id;
          state[env][roomVersionId] = version;
        });
        break;
      case fetchProgressionRoomVersionLocales.fulfilled.type:
        const currentVersionId = action.payload.currentVersionId;
        const versionLocales = action.payload.items as IProgressionRoomVersionLocale[];

        if (!!currentVersionId && state[env][currentVersionId]) {
          state[env][currentVersionId].locales = versionLocales;
        }
        break;
      default:
        state = initialState;
        break;
    }
  };

const progressionRoomVersions = createSlice({
  name: 'progressionRooms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchProgressionRoomVersions.fulfilled,
      createProgressionRoomReducer<IProgressionRoomVersion>()
    );

    builder.addCase(fetchProgressionRoomVersions.rejected, (_, action) => {
      throw action.error;
    });

    builder.addCase(
      fetchProgressionRoomVersionLocales.fulfilled,
      createProgressionRoomReducer<IProgressionRoomVersionLocale>()
    );

    builder.addCase(fetchProgressionRoomVersionLocales.rejected, (_, action) => {
      throw action.error;
    });
  },
});

export default progressionRoomVersions.reducer;
