import { PAGE_NAMES, STATUS } from '@app/common/constants';
import * as ApiUtils from './utils';

export const VERIFY_CONFIG = {
  PUSH: 'verifyConfig/PUSH'
};

export const storePushVerification = (data) => {
  return {
    type: VERIFY_CONFIG.PUSH,
    data
  };
};

export const sendPush = (platform, name, gameInfo) => (dispatch) => {
  return ApiUtils.sendPush(platform, name, gameInfo).then((response) => {
    dispatch(storePushVerification(response));
    return response;
  });
};

export const verifyPush = (platform, productionId, pushCode, verifyId) => (dispatch) => {
  return ApiUtils.verifyPush(platform, productionId, pushCode, verifyId).then((response) => {
    if (response.status !== 'unprocessable_entity') {
      dispatch(storePushVerification(response));
    }
    return response;
  });
};
