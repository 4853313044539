import axios, { AxiosResponse } from 'axios';
// @ts-ignore: camelize is by definition an un-typeable Any => Any
import camelize from 'camelize';
import { Dispatch } from 'redux';

import { IAction, IFeature, TDispatch, TThunkAction } from '@app/common/interfaces';

interface IFeatureProps {
  FLAGS: string;
}

type TFetchFlags = (data: IFeature) => IAction<IFeature>;

export const FEATURE: IFeatureProps = {
  FLAGS: 'feature/FEATURE_FLAGS',
};

export const fetchFeatureFlags: TFetchFlags = (data: IFeature): IAction<IFeature> => {
  return {
    type: FEATURE.FLAGS,
    data,
  };
};

export const findFeatureFlags: TThunkAction = (): TDispatch => (
  dispatch: Dispatch<IAction<IFeature>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  return axios
    .get(`/feature_toggles/fetch_feature_flags.json`)
    .then(camelize)
    .then((response: AxiosResponse<IFeature>) => {
      window.appConfig.featureFlags = response.data;
      dispatch(fetchFeatureFlags(response.data));
    });
};
