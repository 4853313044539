import { IPublisher2 } from '@app/common/interfaces';

export function shouldRenderNotificationWithNoAction() {
  return (
    !window.appConfig.featureFlags.developerRevenueOverhaulContract &&
    window.appConfig.featureFlags.developerRevenueOverhaulContractNoAction
  );
}

export function shouldRenderNotificationWithAction(publisher: IPublisher2) {
  return (
    window.appConfig.featureFlags.developerRevenueOverhaulContract &&
    !publisher.revenueModelAgreedOn
  );
}

export function shouldRenderRevenueModelNotification(publisher: IPublisher2) {
  return shouldRenderNotificationWithAction(publisher) || shouldRenderNotificationWithNoAction();
}
