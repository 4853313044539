import merge from 'lodash/merge';
import { GAME } from '@app/common/actions';
import camelize from 'camelize';

let initialState = {};

const gameInfoReducer = (state = initialState, action) => {
  let newState;
  Object.freeze(state);
  switch (action.type) {
    // TODO: deprecate this action including gameInfo after games.json endpoints deprecated
    case GAME.CLEAR_GAME:
      return initialState;
    case GAME.GAME_INFO:
      newState = merge({}, state, camelize(action.data));

      return newState;
    case GAME.RECEIVE_GAME:
      newState = merge({}, state, action.data);

      return newState;
    case GAME.APPLE_PAY_UPDATE:
      newState = merge({}, state);
      newState.applePayCertificates = action.data.gameInfo.applePayCertificates;

      return newState;
    default:
      return state;
  }
};

export default gameInfoReducer;
