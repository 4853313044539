export const TITLE: string = 'Contact Us';
export const DESCRIPTION: string = 'How can we help?';
export const SELECT_TEXT: string = 'Select your ';

export enum CONTACT_US_SNACKBAR_MESSAGE {
  REQUEST_RECEIVED = 'Help request received. Our support team will be in touch with you shortly',
  REQUEST_FAILED = 'Error submitting report'
}

export const FRAMEWORKS: string[] = [
  SELECT_TEXT + 'framework',
  'Unity',
  'Cordova',
  'Native iOS',
  'Native Android',
  'N/A'
];

export const ENVIRONMENTS: string[] = [SELECT_TEXT + 'environment', 'Sandbox', 'Production'];
