import {
  IProgressionRoomVersionLocale,
  IResponseProgressionRoomVersionLocale,
  TBaseOperand,
  TCondition,
  TRoomCondition,
} from '@appEntryPoints/common/interfaces';
import {
  OPERATORS,
  OPERATORS_OTHER,
} from '@appGameProgression/modules/EntryPoints/common/constants';

export const formatLocalesBodyImagesConditions = (
  locales: IResponseProgressionRoomVersionLocale[]
): IProgressionRoomVersionLocale[] => {
  const formattedLocales = locales.map((locale) => {
    const { bodyImages, ...rest } = locale;

    const formattedBodyImage = bodyImages.map(({ url, mimeType, condition }) => {
      let formattedCondition = condition ? flattenAndRoomCondition(condition, []) : null;

      return {
        url,
        mimeType,
        condition: formattedCondition,
      };
    });

    return {
      ...rest,
      bodyImages: formattedBodyImage,
    };
  });

  return formattedLocales;
};

export const flattenAndRoomCondition = (
  conditions: TRoomCondition,
  acc: TCondition[]
): TCondition[] => {
  const isObject: boolean =
    !!conditions && typeof conditions === 'object' && conditions.constructor === Object;

  if (isObject) {
    if (conditions?.[OPERATORS_OTHER.AND]) {
      const formattedCondition = conditions[OPERATORS_OTHER.AND] as TCondition[];
      const formattedCondition1 = flattenAndRoomCondition(formattedCondition[0], []);
      const formattedCondition2 = flattenAndRoomCondition(formattedCondition[1], []);

      return acc.concat(formattedCondition1.concat(formattedCondition2));
    }

    acc.push(conditions as TCondition);
  }

  return acc;
};

export const convertArrayIntoFormDataBase = <T, G>(
  baseConditionHelper: (item: T, acc: string) => G[],
  items: T[],
  accAddOnKey: string,
  acc: string = ''
): G[] => {
  if (items.length === 0) {
    return [];
  }

  if (items.length === 1) {
    return baseConditionHelper(items[0], acc);
  }

  let halfIndex = Math.ceil(items.length / 2);

  const firstHalf = convertArrayIntoFormDataBase(
    baseConditionHelper,
    items.slice(0, halfIndex), // halfIndex non-inclusive
    accAddOnKey,
    `${acc}[${accAddOnKey}][0]`
  );
  const secondHalf = convertArrayIntoFormDataBase(
    baseConditionHelper,
    items.slice(halfIndex), // halfIndex as start
    accAddOnKey,
    `${acc}[${accAddOnKey}][1]`
  );

  return firstHalf.concat(secondHalf);
};

interface IFormDataKeyValue {
  formDataStr: string;
  value: string;
}

export const convertSingleConditionIntoFormData = (
  condition: TCondition,
  acc: string
): IFormDataKeyValue[] => {
  if (Object.keys(condition).length === 0) {
    return [];
  }

  const conditionKey = Object.keys(condition)[0] as OPERATORS;
  const conditionValues = Object.values(condition)[0] as [TBaseOperand, TBaseOperand];

  return conditionValues.map((value, index) => {
    return {
      formDataStr: `${acc}[${conditionKey}][${index}]`,
      value,
    };
  });
};

export const convertConditionsIntoFormData = (
  conditions: TCondition[],
  acc: string = ''
): IFormDataKeyValue[] => {
  const accAddOnKey = OPERATORS_OTHER.AND;

  return convertArrayIntoFormDataBase<TCondition, IFormDataKeyValue>(
    convertSingleConditionIntoFormData,
    conditions,
    accAddOnKey,
    acc
  );
};
