import * as React from 'react';

import Grid from '@mui/material/Grid';

import kebabCase from 'lodash/kebabCase';
import { ArcherContainer, ArcherElement, LineStyle, Relation } from 'react-archer';

import { IGameInfo } from '@app/common/interfaces';
import variables from '@app/core/variables.scss';

import { PRIZE_ENABLEMENT_STEPS_STATE } from '../constants';
import { renderPrizeEnablementAttributeCopy, renderPrizeEnablementAttributeIcon } from '../helpers';
import {
  ISortedGamePerformanceStatus,
  IViewProps,
  TSORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE,
} from '../interfaces';
import { PathIcon } from './PathIcon';
import './styles/mobileViewStyles.scss';

const completeStepLineStyle: LineStyle = {
  strokeWidth: 4,
  strokeColor: variables.colorGreen,
};

const inProgressStepLineStyle: LineStyle = {
  strokeWidth: 4,
  strokeColor: variables.colorGray100Borders,
};

class MobileView extends React.PureComponent<IViewProps> {
  public renderStep = (
    archerElementSteps: string[],
    isComplete: boolean,
    isNextComplete: boolean,
    step: string,
    idx: number
  ): JSX.Element => {
    // adding a `relation` which tells the `ArcherElement` how to connect this element to the next
    let relations: Relation[] = [
      {
        targetId: '',
        targetAnchor: 'left',
        sourceAnchor: 'right',
        style: isNextComplete ? completeStepLineStyle : inProgressStepLineStyle,
      },
    ];

    // if this is the last step then we connect this element with this gift-icon element
    // if not, then we just connect it with the next element
    if (idx === archerElementSteps.length - 1) {
      relations[0].targetId = 'gift-icon';
    } else {
      relations[0].targetId = archerElementSteps[idx + 1];
    }

    // given how the `ArcherElement` works - I have to wrap the component with a <div>
    // but the div I am using is actually creating the CSS objet I need - so I have the <>
    // </> that renders nothing inside the <div> to conform to the `ArcherElement`'s requirements
    return (
      <ArcherElement id={step} relations={relations}>
        <div className={`step-oval ${isComplete ? 'complete' : ''}`}>
          <></>
        </div>
      </ArcherElement>
    );
  };

  public renderGameStep = (
    sortedGamePerformanceStatus: ISortedGamePerformanceStatus | null,
    gameInfo: IGameInfo
  ): JSX.Element => {
    return (
      <ArcherElement
        id={'game-icon'}
        relations={[
          {
            targetId: 'step-1',
            targetAnchor: 'left',
            sourceAnchor: 'right',
            style:
              sortedGamePerformanceStatus && sortedGamePerformanceStatus.complete.length > 0
                ? completeStepLineStyle
                : inProgressStepLineStyle,
          },
        ]}
      >
        <div>
          <PathIcon
            state={PRIZE_ENABLEMENT_STEPS_STATE.ICON_GAME}
            icon={gameInfo.iconUrl ? gameInfo.iconUrl : undefined}
          />
        </div>
      </ArcherElement>
    );
  };

  public renderGiftStep = (
    sortedGamePerformanceStatus: ISortedGamePerformanceStatus | null
  ): JSX.Element => {
    return (
      <ArcherElement id={'gift-icon'}>
        <div>
          <PathIcon
            state={
              sortedGamePerformanceStatus && sortedGamePerformanceStatus.incomplete.length === 0
                ? PRIZE_ENABLEMENT_STEPS_STATE.ICON_GIFT_COLOR
                : PRIZE_ENABLEMENT_STEPS_STATE.ICON_GIFT_SHADE
            }
          />
        </div>
      </ArcherElement>
    );
  };

  public render = (): JSX.Element => {
    const { gameInfo, openFairnessModal, sortedGamePerformanceStatus, gamePerformanceStatus } =
      this.props;

    let sortedAttributes: TSORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE[] | undefined =
      sortedGamePerformanceStatus?.complete.concat(sortedGamePerformanceStatus.incomplete);

    let archerElementSteps: string[] = ['step-1', 'step-2', 'step-3', 'step-4', 'step-5'];

    let numberOfCompleteSteps: number | undefined = sortedGamePerformanceStatus?.complete.length;

    return (
      <Grid container={true} direction={'row'} spacing={2} className={'mobile-view-container'}>
        <Grid item={true} xs={12}>
          <ArcherContainer
            strokeColor={variables.colorGray100Borders}
            endShape={{ arrow: { arrowLength: 0.01 } }}
          >
            <div className={'archer-container'}>
              {this.renderGameStep(sortedGamePerformanceStatus, gameInfo)}

              {archerElementSteps.map((step: string, idx: number): JSX.Element | null => {
                if (numberOfCompleteSteps === undefined) {
                  return null;
                }

                numberOfCompleteSteps = numberOfCompleteSteps - 1;

                let isNextComplete: boolean =
                  sortedGamePerformanceStatus?.incomplete.length === 0
                    ? true
                    : numberOfCompleteSteps > 0;

                return (
                  <div key={idx}>
                    {this.renderStep(
                      archerElementSteps,
                      numberOfCompleteSteps >= 0,
                      isNextComplete,
                      step,
                      idx
                    )}
                  </div>
                );
              })}

              {this.renderGiftStep(sortedGamePerformanceStatus)}
            </div>
          </ArcherContainer>
        </Grid>
        <Grid item={true} xs={12}>
          <Grid container={true} spacing={1} direction={'column'} className={'steps-container'}>
            {sortedAttributes?.map((attribute: TSORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE) => {
              return (
                <Grid
                  item={true}
                  key={attribute}
                  className={'step-container'}
                  id={`step-container__${kebabCase(attribute)}`}
                >
                  <div className={'step-icon'}>
                    {renderPrizeEnablementAttributeIcon(gamePerformanceStatus, attribute)}
                  </div>
                  <div className={'step-copy'}>
                    {renderPrizeEnablementAttributeCopy(
                      gamePerformanceStatus,
                      attribute,
                      gameInfo,
                      openFairnessModal
                    )}
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  };
}

export { MobileView };
