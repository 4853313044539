import * as React from 'react';

import AtomLink from 'portal-common-library/atoms/AtomLink';

import './styles/cardWithIconAndLinkStyles.scss';

export interface ICardWithIconAndLinkProps {
  id: string;
  cardIcon: JSX.Element;
  cardText: string[];
  linkTitle: string;
  linkUrl: string;
  linkIcon?: boolean;
  linkNewTab?: boolean;
}

type TCardWithIconAndLinkOptionalProps = 'linkIcon' | 'linkNewTab';

type TDefaultProps = Required<Pick<ICardWithIconAndLinkProps, TCardWithIconAndLinkOptionalProps>>;

class CardWithIconAndLink extends React.PureComponent<ICardWithIconAndLinkProps> {
  public static defaultProps: TDefaultProps = {
    linkIcon: false,
    linkNewTab: false
  };

  public render(): JSX.Element {
    let {
      cardIcon,
      id,
      cardText,
      linkTitle,
      linkUrl,
      linkIcon: hasLinkIcon,
      linkNewTab: shouldLinkNewTab
    } = this.props;

    return (
      <div className="card-with-icon-and-link__container">
        <div className="card-with-icon-and-link__icon">{cardIcon}</div>
        <p className="card-with-icon-and-link__text">
          {cardText.map(
            (text: string, index: number): JSX.Element => {
              return <span key={`${id}__text-${index}`}>{text}</span>;
            }
          )}
        </p>
        <div className="card-with-icon-and-link__link-container">
          <AtomLink
            id={`${id}__link`}
            title={linkTitle}
            url={linkUrl}
            icon={hasLinkIcon}
            newTab={shouldLinkNewTab}
            dataAttributes={{ 'data-qa': 'card-with-icon-and-link__link' }}
          />
        </div>
      </div>
    );
  }
}

export { CardWithIconAndLink };
