import { combineReducers } from 'redux';

import activeUsers from './modules/ActiveUsers/reducer';
import invitedUsers from './modules/InvitedUsers/reducer';
import publisherInfo from './modules/PublisherInfo/reducer';

const publisherReducer = combineReducers({
  publisherInfo,
  activeUsers,
  invitedUsers
});

export default publisherReducer;
