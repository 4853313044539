import merge from 'lodash/merge';

import { IAction, IActionNoData } from '@app/common/interfaces';
import { IGamePerformanceStatus } from '@app/modules/GameManagement/common/interfaces';
import { GAME_PERFORMANCE_STATUS_ACTIONS } from '@appOverview/actions/gamePerformanceStatusActions';

type TGamePerformanceStatusAction = IAction<IGamePerformanceStatus> | IActionNoData;

function gamePerformanceStatus(
  state: IGamePerformanceStatus | null = null,
  action: TGamePerformanceStatusAction
): IGamePerformanceStatus | null {
  Object.freeze(state);
  switch (action.type) {
    case GAME_PERFORMANCE_STATUS_ACTIONS.RECEIVE:
      return merge({}, state, (action as IAction<IGamePerformanceStatus>).data);

    case GAME_PERFORMANCE_STATUS_ACTIONS.CLEAR:
      return null;

    default:
      return state;
  }
}

export default gamePerformanceStatus;
