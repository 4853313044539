import * as React from 'react';

import { IDataAttributes } from 'portal-common-library/interfaces/dataAttributes';

import { AtomColoredCircle } from '@app/common/atoms';

import './styles/banner.scss';

type TBannerState = 'info' | 'warn' | 'error';

export interface IBannerProps {
  id: string;
  bannerContent: string | JSX.Element;
  circleContent?: string;
  customContent?: JSX.Element;
  state: TBannerState;
  iconCircumference: string;
  fontSize: string;
  dataAttributes?: IDataAttributes;
  isDismissed?: boolean;
}

export type TDefaultProps = Required<Pick<IBannerProps, 'iconCircumference' | 'fontSize'>>;

class Banner extends React.PureComponent<IBannerProps> {
  public static defaultProps: TDefaultProps = {
    iconCircumference: '24px',
    fontSize: '16px',
  };

  public renderIcon(): JSX.Element {
    let { circleContent, state, iconCircumference, fontSize, customContent } = this.props;
    let iconId: string = 'banner__icon';

    if (state === 'warn') {
      return (
        <div id={`${iconId}-triangle`} className={`${iconId}-triangle`}>
          <span id={`${iconId}-triangle-span`} className={`${iconId}-triangle-span`}>
            {circleContent}
          </span>
        </div>
      );
    }

    if (customContent) {
      return customContent;
    }

    return (
      <AtomColoredCircle
        id={`${iconId}-circle`}
        circleContent={circleContent}
        circumference={iconCircumference}
        fontSize={fontSize}
      />
    );
  }

  public render(): JSX.Element | null {
    let { id, bannerContent, state, dataAttributes, isDismissed } = this.props;

    if (isDismissed) return null;

    return (
      <div
        role="banner"
        id={`${id}-banner__container-${state}`}
        className={`banner__container ${state}`}
        {...dataAttributes}
      >
        {this.renderIcon()}
        <div id={`${id}-banner__content`} className="banner__content">
          {bannerContent}
        </div>
      </div>
    );
  }
}

export { Banner };
