import axios, { AxiosResponse } from 'axios';

import { postRequest } from '@app/common/helpers';

const checkSdkThemingFf = (): boolean => {
  if (window.appConfig.featureFlags.sdkThemingRevamp) return true;

  return false;
};

interface IFieldValue {
  description: string;
  type: string;
  values: string;
  image: string;
}

interface IGradientFieldValue {
  description: string;
  gradient: string[];
  type: string;
}

interface IImageFieldValue {
  description: string;
  type: string;
  value?: string;
  image?: {
    lastModified: number;
    name: string;
    size: number;
    type: string;
  };
}

interface IOpacityLevelFieldValue {
  description: string;
  type: string;
  value: number;
}
export interface IJsonBody {
  currencyValues: {
    description: IFieldValue;
    practiceCurrencyString: IFieldValue;
    practiceCurrencyFullIconLargeURI: IFieldValue;
    practiceCurrencySimpleIconURI: IFieldValue;
  };
  end: { value: string };
  enums: {
    sidePanelBlurStyle: IFieldValue;
  };
  statusBarContentStyle: IFieldValue;
  gradients: {
    bracketPrizeViewCashGradient: IGradientFieldValue;
    bracketPrizeViewLiveEventGradient: IGradientFieldValue;
    bracketPrizeViewZGradient: IGradientFieldValue;
    btnGradientOne: IGradientFieldValue;
    btnGradientStore: IGradientFieldValue;
    btnGradientThree: IGradientFieldValue;
    btnGradientTwo: IGradientFieldValue;
    cashIconGradient: IGradientFieldValue;
    description: IGradientFieldValue;
    navigationBarGradient: IGradientFieldValue;
    overlayEntryContainerGradient: IGradientFieldValue;
    playButtonGradient: IGradientFieldValue;
    pointsPrizeGradient: IGradientFieldValue;
    practiceCurrencyIconGradient: IGradientFieldValue;
    prizeViewCashGradient: IGradientFieldValue;
    prizeViewLiveEventGradient: IGradientFieldValue;
    prizeViewTicketzGradient: IGradientFieldValue;
    prizeViewZGradient: IGradientFieldValue;
    profileIconGradient: IGradientFieldValue;
    progressViewDarkGradient: IGradientFieldValue;
    selectedCellBackgroundGradient: IGradientFieldValue;
    sideMenuHighlightedGradient: IGradientFieldValue;
    tauntImageGradient: IGradientFieldValue;
    textCashGradient: IGradientFieldValue;
    textNeutralGradient: IGradientFieldValue;
    textTicketzGradient: IGradientFieldValue;
    textZGradient: IGradientFieldValue;
    excitingBannerGradient: IGradientFieldValue;
    excitingHighlightGradient: IGradientFieldValue;
    primaryRibbonGradient: IGradientFieldValue;
  };
  images: {
    backgroundImage: IImageFieldValue;
    bracketPrizeViewCashImage: IImageFieldValue;
    bracketPrizeViewLiveEventImage: IImageFieldValue;
    bracketPrizeViewZImage: IImageFieldValue;
    prizeViewCashImage: IImageFieldValue;
    prizeViewLiveEventImage: IImageFieldValue;
    prizeViewSyncImage: IImageFieldValue;
    prizeViewTicketzImage: IImageFieldValue;
    prizeViewZImage: IImageFieldValue;
    excitingBannerImage: IImageFieldValue;
    interstitialBackgroundImage: IImageFieldValue;
  };
  opacityLevels: {
    description: string;
    opacityNone: IOpacityLevelFieldValue;
    opacityPrimary: IOpacityLevelFieldValue;
    opacitySecondary: IOpacityLevelFieldValue;
    opacityTertiary: IOpacityLevelFieldValue;
  };
  sheetStyles: Record<string, Record<string, string>>;
  start: { value: string };
  version: { SkillzOTAVersion: string | null; description: string; type: string };
}

export interface IFetchResponseItem {
  id: string;
  jsonBody: IJsonBody;
}

interface IFetchResponseItems {
  items: IFetchResponseItem[];
}

interface IUploadedImagesProperties {
  value?: string;
  description: string;
  type: string;
}

export interface IUploadedImages {
  backgroundImage: IUploadedImagesProperties;
  bracketPrizeViewCashImage: IUploadedImagesProperties;
  bracketPrizeViewLiveEventImage: IUploadedImagesProperties;
  bracketPrizeViewZImage: IUploadedImagesProperties;
  prizeViewCashImage: IUploadedImagesProperties;
  prizeViewLiveEventImage: IUploadedImagesProperties;
  prizeViewSyncImage: IUploadedImagesProperties;
  prizeViewTicketzImage: IUploadedImagesProperties;
  prizeViewZImage: IUploadedImagesProperties;
  excitingBannerImage: IUploadedImagesProperties;
  interstitialBackgroundImage: IUploadedImagesProperties;
}

const oldFetch = (themeId: number, gameId: number): Promise<IFetchResponseItem> => {
  return axios
    .get(`/themes/${themeId}/find_themes.json?game_id=${gameId}`)
    .then(
      (response: AxiosResponse<IFetchResponseItem>) => response.data
    ) as unknown as Promise<IFetchResponseItem>;
};

const newFetch = (themeId: number, gameId: number): Promise<IFetchResponseItem> => {
  if (themeId === 0) {
    return axios
      .get(`/publisher/v1/games/${gameId}/custom_themes`)
      .then((response: AxiosResponse<IFetchResponseItem>) => response.data)
      .catch(() => {
        return axios
          .get(`/publisher/v1/base_themes`)
          .then((response: AxiosResponse<IFetchResponseItems>) => response.data.items[0]);
      });
  }

  return axios
    .get(`/publisher/v1/base_themes/${themeId}`)
    .then((response: AxiosResponse<IFetchResponseItem>) => response.data);
};

export const getBaseTheme = (themeId: number, gameId: number) => {
  if (checkSdkThemingFf()) {
    return newFetch(themeId, gameId);
  }

  return oldFetch(themeId, gameId);
};

export const saveBaseTheme = (
  gameId: string,
  jsonBody: IJsonBody,
  themeName: string,
  themeId: string,
  uploadedImages: IUploadedImages
) => {
  let form = new FormData();
  /* eslint-disable */

  // the original params do not have an images key therefore, the k:v pair is deleted and manually placed
  // in with the custom images that the user inputs in the advanced theme. Does not affect base theme.

  Object.entries(jsonBody).forEach((entry): boolean | void => {
    if (entry[0] === 'currencyValues') {
      // stringify currency string and parse it in the rails controller to
      // prevent breaking the theme creation

      form.append(
        `theme[fields][currencyValues][practiceCurrencyString]`,
        JSON.stringify(entry[1].practiceCurrencyString)
      );
    } else if (entry[0] === 'images') {
      return true;
    } else {
      form.append(`theme[fields][${entry[0]}]`, JSON.stringify(entry[1]));
    }
  });

  // uploading the files for custom images field

  Object.entries(uploadedImages).forEach((entry) => {
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    if (entry[1].image) {
      form.append(`theme[fields][images][${entry[0]}]`, entry[1].image);
    }
  });

  // uploading the files for currency values field

  Object.entries(jsonBody.currencyValues).forEach((entry) => {
    if (entry[1].image) {
      form.append(`theme[fields][currencyValues][${entry[0]}]`, entry[1].image);
    }
  });

  form.append('theme[game_id]', gameId);
  form.append('commit', 'Setup Theme');
  form.append('base_theme_id', themeId);
  form.append('game_id', gameId);
  form.append('name', themeName);

  if (!checkSdkThemingFf()) {
    form.append('identifier', 'ivt');
  }

  const postUrl = checkSdkThemingFf()
    ? `/publisher/v1/games/${gameId}/custom_themes.json`
    : `/themes`;

  return postRequest(postUrl, form);
};

export const updateTheme = (
  gameId: string,
  jsonBody: IJsonBody,
  baseThemeId: string,
  themeId: string,
  uploadedImages: IUploadedImages
) => {
  let form = new FormData();

  // the original params do not have an images key therefore, the k:v pair is deleted and manually placed
  // in with the custom images that the user inputs in the advanced theme. Does not affect base theme.

  Object.entries(jsonBody).forEach((entry): boolean | void => {
    if (entry[0] === 'currencyValues') {
      // stringify currency string and parse it in the rails controller to
      // prevent breaking the theme creation

      form.append(
        `theme[fields][currencyValues][practiceCurrencyString]`,
        JSON.stringify(entry[1].practiceCurrencyString)
      );
    } else if (entry[0] === 'images') {
      return true;
    } else {
      form.append(`theme[fields][${entry[0]}]`, JSON.stringify(entry[1]));
    }
  });

  // uploading the files for custom images field

  Object.entries(uploadedImages).forEach((entry) => {
    if (entry[1].image) {
      form.append(`theme[fields][images][${entry[0]}]`, entry[1].image);
    }
  });

  // uploading the files for currency values field

  Object.entries(jsonBody.currencyValues).forEach((entry) => {
    if (entry[1].image) {
      form.append(`theme[fields][currencyValues][${entry[0]}]`, entry[1].image);
    }
  });

  form.append('theme[game_id]', gameId);
  form.append('commit', 'Setup Theme');
  form.append('base_theme_id', baseThemeId);
  form.append('game_id', gameId);

  if (!checkSdkThemingFf()) {
    form.append('identifier', 'ivt');
  }

  const url = checkSdkThemingFf()
    ? `/publisher/v1/games/${gameId}/custom_themes.json`
    : `/themes/${themeId}`;

  return $.ajax({
    url,
    method: 'PATCH',
    data: form,
    processData: false,
    contentType: false,
  });
};

export const emailCustomTheme = (id: string) => {
  return $.ajax({
    url: `/email_custom_themes/${id}`,
    method: 'GET',
  });
};

export const requestSync = (gameId: string) => {
  const url = checkSdkThemingFf()
    ? `/publisher/v1/games/${gameId}/custom_themes/request_sync.json`
    : `/custom_theme/${gameId}/request_sync.json`;

  return $.ajax({
    url,
    method: 'POST',
  });
};

export const syncThemeToProd = (id: string) => {
  return $.ajax({
    url: `/themes/${id}/sync_production`,
    method: 'POST',
  });
};
