import * as React from 'react';
import Collapse from '@mui/material/Collapse';

export interface IAtomCollapsibleProps {
  id: string;
  titleLineElements: string | JSX.Element;
  collapserBody: JSX.Element;
  isCollapsibleExpanded: boolean;
}

type TAtomCollapsibleDefaultProps = Required<Pick<IAtomCollapsibleProps, 'titleLineElements'>>;

export interface IAtomCollapsibleState {
  isCollapsibleExpanded: boolean;
}

class AtomCollapsible extends React.PureComponent<IAtomCollapsibleProps> {
  public static defaultProps: TAtomCollapsibleDefaultProps = {
    titleLineElements: ''
  };

  public render(): JSX.Element {
    let { id, titleLineElements, collapserBody, isCollapsibleExpanded } = this.props;

    return (
      <div
        id={`${id}-atom-collapsible-container`}
        className="atom-collapsible__collapsible-container"
      >
        {titleLineElements}
        <div id={`${id}-atom-collapsible-list`} className="atom-collapsible__collapsed-list">
          <Collapse in={isCollapsibleExpanded} timeout="auto">
            {collapserBody}
          </Collapse>
        </div>
      </div>
    );
  }
}

export { AtomCollapsible };
