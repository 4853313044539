import loadable from '@loadable/component';

import { ConnectedProps, connect } from 'react-redux';

import { IRootState } from '@app/core/rootReducer';

import { fetchNotificationItems } from './actions';

const Notification = loadable(
  () => import(/* webpackChunkName: "notification" */ './Notification')
);

const mapStateToProps = (state: IRootState) => {
  const {
    publisher: { publisherInfo },
    user,
  } = state;

  return {
    publisherInfo,
    user,
  };
};

const mapDispatchToProps = {
  fetchNotificationItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TNotificationConnectedProps = ConnectedProps<typeof connector>;

export default connector(Notification);
