import * as React from 'react';

import { Route, Switch } from 'react-router-dom';

import { SignIn } from '@app/modules/SignUpFlow/modules/SignIn';

import ChangePassword from './modules/ChangePassword';
import { SignUp } from './modules/SignUp';
import './styles.scss';
import { ForgotPassword } from './subcomponents';

function SignUpFlow() {
  return (
    <>
      <Switch>
        <Route path={'/users/sign_up'} component={SignUp} />
        <Route path={'/users/sign_in'} component={SignIn} />
        <Route path={'/users/password/edit'} component={ChangePassword} />
        <Route path={'/users/password/new'} component={ForgotPassword} />
      </Switch>
    </>
  );
}

export { SignUpFlow };
