import { createSlice } from '@reduxjs/toolkit';

import { fetchCurrentUser, updateCurrentUser } from '@app/common/actions';
import { ICurrentUser } from '@app/common/interfaces';

const currentUser = createSlice({
  name: 'currentUser',
  initialState: {} as ICurrentUser,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(updateCurrentUser.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(updateCurrentUser.rejected, (_, action) => {
      throw action.error;
    });
  },
});

export default currentUser.reducer;
