import { ANALYTICS } from '@app/common/actions';
import { IAction, IAnalyticInfo } from '@app/common/interfaces';

type TAction = IAction<IAnalyticInfo[]>;

const rawData = (state: IAnalyticInfo[] = [], action: TAction): IAnalyticInfo[] => {
  Object.freeze(state);

  switch (action.type) {
    case ANALYTICS.RECEIVE_RAW_DATA:
      return action.data;
    default:
      return state;
  }
};

export { rawData };
