import * as React from 'react';

import Radio, { RadioProps } from '@mui/material/Radio';

import { TInputChangeEvent } from '../interfaces';
import './styles/atomRadioButtonStyles.scss';

interface IAtomRadioButtonProps {
  id: string;
  isChecked: boolean;
  name: string;
  value: string | number | boolean;
  inputProps?: RadioProps['inputProps'];
  onChange: (event: TInputChangeEvent) => void;
  size?: 'medium' | 'small' | undefined;
  hasSpecialIcons?: boolean;
  icon?: JSX.Element;
  checkedIcon?: JSX.Element;
  isDisabled?: boolean;
}

type TPickProps = 'isDisabled' | 'inputProps' | 'hasSpecialIcons' | 'size';

type TDefaultProps = Required<Pick<IAtomRadioButtonProps, TPickProps>>;

class AtomRadioButton extends React.PureComponent<IAtomRadioButtonProps> {
  public static defaultProps: TDefaultProps = {
    isDisabled: false,
    inputProps: {},
    hasSpecialIcons: false,
    size: 'medium',
  };

  public render(): JSX.Element {
    let {
      icon,
      checkedIcon,
      id,
      isChecked,
      name,
      value,
      onChange,
      inputProps,
      isDisabled,
      hasSpecialIcons,
      size,
    } = this.props;

    let selected: string = isChecked ? '-selected' : '';
    let disabled: string = isDisabled ? '-disabled' : '';

    let radioProps: RadioProps = {
      id: `${id}-atom-radio-button`,
      checked: isChecked,
      name,
      value,
      disabled: isDisabled,
      onChange,
      inputProps,
      size,
      className: `atom-component__radio-button${selected}${disabled}`,
      color: 'default',
    };

    radioProps = hasSpecialIcons ? { ...radioProps, icon, checkedIcon } : radioProps;

    return <Radio {...radioProps} />;
  }
}

export { AtomRadioButton };
