import React from 'react';

import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';

import ReactGA from 'react-ga';

import { GOOGLE_ANALYTICS } from '@app/common/constants';

interface INavigationButton {
  id: string;
  type: 'previous' | 'next';
}

export const NavigationButton = ({ id, type }: INavigationButton): JSX.Element => {
  const onClickHandler = () => {
    ReactGA.event({
      label: id,
      category: GOOGLE_ANALYTICS.HELP_BITS.CATEGORY,
      action:
        type === 'next'
          ? GOOGLE_ANALYTICS.HELP_BITS.ACTIONS.CLICK_NEXT
          : GOOGLE_ANALYTICS.HELP_BITS.ACTIONS.CLICK_BACK,
    });
  };

  const style = { height: '14px', width: '14px' };

  return (
    <div
      data-qa={`footer-${type}-container`}
      className={`footer-${type}-container`}
      onClick={onClickHandler}
      style={{ cursor: 'pointer' }}
    >
      {type === 'next' ? <ArrowForward style={style} /> : <ArrowBack style={style} />}
    </div>
  );
};
