import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/styles/useTheme';

import times from 'lodash/times';

import { ISurveyAnswer, TSurveyQuestion } from './interfaces';

export const testIdPrefix = 'survey-numeric-selector-option-';

export interface INumericSelectorProps {
  question: TSurveyQuestion;
  startLabel?: string;
  endLabel?: string;
  onChange: (newAnswer: ISurveyAnswer) => void;
}

const labelStyle = { fontStyle: 'italic' };

export default function NumericSelector({
  question,
  startLabel,
  endLabel,
  onChange,
}: INumericSelectorProps) {
  const [selectedOption, setSelectedOption] = useState<number | undefined>();
  const muiTheme = useTheme();

  // tells typescript this is a numeric question for sure
  if (question.questionType !== 'numeric') return <></>;

  return (
    <>
      <Grid
        container
        spacing={1}
        columns={question.numberOfOptions}
        data-qa={'survey-numeric-selector'}
      >
        {times(question.numberOfOptions, (index) => {
          const optionValue = index + Number(!question.optionsStartFromZero);
          const selectOption = () => {
            setSelectedOption(optionValue);
            onChange({ surveyQuestionId: question.id, body: `${optionValue}` });
          };

          return (
            <Grid item key={optionValue} xs={1}>
              <Button
                variant={'outlined'}
                size={'small'}
                sx={{
                  minWidth: '100%',
                  '&.Mui-disabled': {
                    backgroundColor: muiTheme.palette.primary.main,
                    color: 'white',
                  },
                }}
                disabled={selectedOption === optionValue}
                onClick={selectOption}
                data-qa={`${testIdPrefix}${optionValue}`}
              >
                {optionValue}
              </Button>
            </Grid>
          );
        })}
      </Grid>
      {startLabel && endLabel && (
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography sx={labelStyle}>{startLabel}</Typography>
          <Typography sx={labelStyle}>{endLabel}</Typography>
        </Stack>
      )}
    </>
  );
}
