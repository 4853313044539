import { createAsyncThunk } from "@reduxjs/toolkit";

// @ts-ignore: camelize is by definition an un-typeable Any => Any
import camelize from "camelize";
import { SORT_DIRECTION } from "portal-common-library/constants/table";

import { snakeCaseConverter } from "@app/common/helpers";
import { IErrors, IIndexResponse, IUser2 } from "@app/common/interfaces";
import axios, {
  AxiosError,
  AxiosResponse,
} from "@app/common/setup/axiosWithHeader";

import { TABLE_SORT_ATTRIBUTES } from "./constants";
import { IUsersFetchParams, TUserGameRoleValues } from "./interfaces";

const fetchUsers = (
  params?: IUsersFetchParams,
  hasPendingInvitation?: boolean
): Promise<IIndexResponse<IUser2>> => {
  const sortAttributes: string[] = [];

  if (params?.sortAttribute) {
    const directionSign =
      params.sortDirection === SORT_DIRECTION.DESC ? "-" : "";
    sortAttributes.push(`${directionSign}${params?.sortAttribute}`);
  }

  // always sort by email in addition to provided sort attribute
  if (params?.sortAttribute !== TABLE_SORT_ATTRIBUTES.EMAIL) {
    sortAttributes.push(TABLE_SORT_ATTRIBUTES.EMAIL);
  }

  delete params?.sortDirection;

  return axios
    .get("/api_internal/v1/users", {
      params: snakeCaseConverter({
        ...params,
        sortAttribute: sortAttributes.join(","),
        hasPendingInvitation,
      }),
    })
    .then(camelize)
    .then((response: AxiosResponse<IIndexResponse<IUser2>>) => {
      return response.data;
    })
    .catch((e) => {
      const error = e as AxiosError<IErrors>;

      throw new Error(error.response?.data.errors);
    });
};

export const fetchActiveUsers = createAsyncThunk(
  "activeUsers/fetch",
  async (params?: IUsersFetchParams) => {
    return await fetchUsers(params, false);
  }
);

export const fetchInvitedUsers = createAsyncThunk(
  "invitedUsers/fetch",
  async (params?: IUsersFetchParams) => {
    return await fetchUsers(params, true);
  }
);

export const assignGameRolesToUser = (
  userId: number,
  gameRoles: TUserGameRoleValues
) => {
  return axios
    .patch(
      `/api_internal/v1/users/${userId}/game_roles`,
      snakeCaseConverter({ gameRoles })
    )
    .catch((e) => {
      const error = e as AxiosError<IErrors>;
      throw new Error(error.response?.data.errors);
    });
};
