import { createSlice } from '@reduxjs/toolkit';

import { fetchPublisher, updatePublisher } from '@app/common/actions';
import { IPublisher2 } from '@app/common/interfaces';

export const initialState: IPublisher2 = {} as IPublisher2;

const publisherInfo = createSlice({
  name: 'publisherInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPublisher.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(updatePublisher.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(updatePublisher.rejected, (_, action) => {
      throw action.error;
    });
  },
});

export default publisherInfo.reducer;
