import * as React from 'react';

import AtomLink from 'portal-common-library/atoms/AtomLink';

export const bundleMismatchError: JSX.Element = (
  <React.Fragment>
    Mismatch bundle names - Follow&nbsp;
    <AtomLink
      id="game-build-modal__bundle-mismatch-error-link"
      title="these instructions"
      url="https://docs.skillz.com/docs/resolving-ios-naming-mismatch"
      icon={false}
      dataAttributes={{ 'data-qa': 'game-build-modal__bundle-mismatch-error-link' }}
    />
    &nbsp;to resolve this error
  </React.Fragment>
);
