import * as React from 'react';

import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';

import {
  PlayerDataTooltipWithStyle,
  BlueTooltipText,
} from '@app/common/components/TooltipWithStyle';

import './styles/materialTabStyles.scss';

type TColor = 'primary' | 'secondary';

interface IProps {
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  value: number;
  labels: React.ReactNode[];
  id: string;
  isCentered?: boolean;
  indicatorColor?: TColor;
  textColor?: TColor | 'inherit';
  tabComponents: JSX.Element[];
  backgroundColor?: string;
  togglePlayerDataTooltip: () => void;
  showTooltip: boolean;
  tabWithTooltip?: number;
  tooltipCopy?: string;
  hasDisabled?: boolean;
  tabDisabled?: number;
  tabOnClick?: (link: string) => () => void;
  tabLinks?: string[];
}

interface IDefaultProps {
  indicatorColor: TColor;
  textColor: TColor | 'inherit';
  isCentered: boolean;
  backgroundColor: string;
  tabComponents: JSX.Element[];
  togglePlayerDataTooltip: () => void;
  showTooltip: boolean;
  hasDisabled: boolean;
}
interface Ia11yProps {
  id: string;
  'aria-controls': string;
}

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#4890e8',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Roboto',
    button: {
      textTransform: 'none',
    },
  },
});

function a11yProps(index: number): Ia11yProps {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const defaultOnClick = () => {
  return () => {
    return;
  };
};

class MaterialTab extends React.PureComponent<IProps> {
  public static defaultProps: IDefaultProps = {
    indicatorColor: 'primary',
    textColor: 'primary',
    isCentered: false,
    tabComponents: [],
    backgroundColor: '#ffffff', // white
    togglePlayerDataTooltip: () => ({}),
    showTooltip: false,
    hasDisabled: false,
  };

  public tabLinkOverride = (links: string[] | undefined, index: number) => {
    if (!links) return '';
    if (!links[index]) return '';

    return links[index];
  };

  public render(): JSX.Element {
    let {
      onChange,
      value,
      labels,
      isCentered,
      indicatorColor,
      textColor,
      tabComponents,
      id,
      backgroundColor,
      togglePlayerDataTooltip,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      showTooltip,
      tabWithTooltip,
      tooltipCopy,
      hasDisabled,
      tabDisabled,
      tabOnClick,
      tabLinks,
    } = this.props;

    return (
      <Paper id={`${id}__tabs-container`} className="common-component__tabs-container">
        <ThemeProvider theme={theme}>
          <Tabs
            value={value}
            onChange={onChange}
            indicatorColor={indicatorColor}
            textColor={textColor}
            centered={isCentered}
            variant="scrollable"
            className={'common-component__tabs-component'}
            style={{ backgroundColor, height: '48px' }}
          >
            {labels.map((labelName: React.ReactNode, index: number) => {
              if (tooltipCopy && togglePlayerDataTooltip) {
                return (
                  <PlayerDataTooltipWithStyle
                    key={`game-home__player-data-tooltip-${index}`}
                    id={`game-home__player-data-tooltip`}
                    title={
                      <BlueTooltipText
                        id={`game-home__player-data-tooltip-text`}
                        classNamePrefix={'player-data'}
                        text={tooltipCopy}
                        toggleTooltip={togglePlayerDataTooltip}
                        dataAttributes={{ 'data-qa': 'game-home__player-data-tooltip' }}
                      />
                    }
                    arrow={true}
                    placement={'top-start'}
                    open={showTooltip && tabWithTooltip === index}
                  >
                    <Tab
                      label={labelName}
                      key={`common-component__tab-${index}`}
                      {...a11yProps(index)}
                      className="common-component__tabs-cell"
                      id={`${id}__${index}-tabs-cell`}
                      disabled={hasDisabled && tabDisabled === index}
                      onClick={
                        tabOnClick === undefined
                          ? defaultOnClick()
                          : tabOnClick(this.tabLinkOverride(tabLinks, index))
                      }
                    />
                  </PlayerDataTooltipWithStyle>
                );
              } else {
                return (
                  <Tab
                    label={labelName}
                    key={`common-component__tab-${index}`}
                    {...a11yProps(index)}
                    className="common-component__tabs-cell"
                    id={`${id}__${index}-tabs-cell`}
                    disabled={hasDisabled && tabDisabled === index}
                    onClick={
                      tabOnClick === undefined
                        ? defaultOnClick()
                        : tabOnClick(this.tabLinkOverride(tabLinks, index))
                    }
                  />
                );
              }
            })}
          </Tabs>

          <div id={`${id}__tabs-content`} className="common-component__tabs-content">
            {tabComponents[value] !== undefined ? tabComponents[value] : null}
          </div>
        </ThemeProvider>
      </Paper>
    );
  }
}
export { MaterialTab };
