import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export interface ICheckBoxProps {
  checked: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string | JSX.Element;
  id: string;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#4890e8',
    },
  },
  typography: {
    fontFamily: 'Roboto',
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: '#979797',
        },
        root: {
          alignItems: 'flex-start',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#9ca3b1',
          marginTop: '-9px', // override 9px mui padding so checkbox is top aligned
        },
      },
    },
  },
});

class MaterialCheckbox extends React.PureComponent<ICheckBoxProps> {
  public render(): JSX.Element {
    let { label, id } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <FormControlLabel
          id={id}
          control={this.renderCheckbox()}
          label={<div id={`${id}-label`}>{label}</div>}
        />
      </ThemeProvider>
    );
  }

  private renderCheckbox(): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let { checked, handleChange, id } = this.props;

    return (
      <Checkbox
        id={`${id}-checkbox`}
        checked={checked}
        onChange={handleChange}
        value=""
        color="primary"
      />
    );
  }
}

export { MaterialCheckbox };
