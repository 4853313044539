import axios from 'axios';
// @ts-ignore: camelize is by definition an un-typeable Any => Any
import camelize from 'camelize';

import { openSnackbar } from '@app/common/actions';
import { store } from '@app/developerPortal';

import { formatFindParams } from './helpers';
import { IStrapiFindParams, IStrapiFindOneParams } from './interfaces';
import { FETCH_ERROR_MESSAGE } from './modules/Content/modules/HelpSearch/constants';

// need to create a separate instance of `axios` which removes the `x-csrf-token` that keeps
// getting injected on each request to prevent CORS errors
export const contentManagementSystemInstance = axios.create();
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
delete contentManagementSystemInstance.defaults.headers.common['X-CSRF-TOKEN'];

export interface IFetchHelpBitsHeaders {
  userId?: string;
  pageSearchedFrom?: string;
}

export const fetchHelpBit = ({ id }: IStrapiFindOneParams) => {
  return contentManagementSystemInstance
    .get(`${window.appConfig.environment.cmsBaseUrl}/publisher/v1/cms/help-bits/${id}`)
    .then(camelize)
    .catch(() => {
      store.dispatch(
        openSnackbar({
          text: FETCH_ERROR_MESSAGE,
          anchorOriginVertical: 'bottom',
          anchorOriginHorizontal: 'left',
          autoHideDuration: 2000,
        })
      );
    });
};

export const fetchHelpBits = (
  fetchHelpBitsHeaders: IFetchHelpBitsHeaders = {},
  params: IStrapiFindParams = {}
) => {
  const urlParams = formatFindParams(params);
  /* eslint-disable camelcase */
  const headers = {
    user_id: fetchHelpBitsHeaders.userId,
    page_searched_from: fetchHelpBitsHeaders.pageSearchedFrom,
  } as Record<string, string>;
  /* eslint-enable camelcase */

  return contentManagementSystemInstance
    .get(`${window.appConfig.environment.cmsBaseUrl}/publisher/v1/cms/help-bits?${urlParams}`, {
      headers,
    })
    .then(camelize)
    .catch(() => {
      store.dispatch(
        openSnackbar({
          text: FETCH_ERROR_MESSAGE,
          anchorOriginVertical: 'bottom',
          anchorOriginHorizontal: 'left',
          autoHideDuration: 2000,
        })
      );
    });
};
