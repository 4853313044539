import * as React from 'react';

import Grid from '@mui/material/Grid';

import './styles/pageHeaderStyles.scss';

export interface IPageHeaderProps {
  title: string | JSX.Element;
  id: string;
}

class PageHeader extends React.PureComponent<IPageHeaderProps> {
  public renderHeaderElement(): JSX.Element {
    let { title, id } = this.props;
    if (typeof title === 'string') {
      return (
        <h1 id={id} className="page-header__text">
          {title}
        </h1>
      );
    }

    return title;
  }

  public render(): JSX.Element {
    return (
      <Grid
        container={true}
        sx={{ marginTop: '0px' }}
        spacing={1.5}
        className="page-header__outer-container"
      >
        <Grid item={true} sm={12} className="page-header__inner-container">
          {this.renderHeaderElement()}
        </Grid>
      </Grid>
    );
  }
}

export { PageHeader };
