// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import axios, { AxiosResponse } from '@app/common/setup/axiosWithHeader';

import { IAction } from '@app/common/interfaces';

export interface IDataProps {
  count?: number | null;
  id?: number;
  admin?: boolean;
  name: string;
  website?: string;
  phoneNumber?: string;
}

type TReceivePublisherInfo = (data: IDataProps) => IAction<IDataProps>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TFindPublisher = () => Promise<any>;

export enum PUBLISHER {
  INFO = 'publisher/INFO'
}

export const receivePublisherInfo: TReceivePublisherInfo = (
  data: IDataProps
): IAction<IDataProps> => {
  return {
    type: PUBLISHER.INFO,
    data
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findPublisher: TFindPublisher = (): any => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: React.Dispatch<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return axios.get('/publisher_info.json').then((response: AxiosResponse<any>) => {
    dispatch(receivePublisherInfo(camelize(response.data)));

    return response;
  });
};
