import { combineReducers } from 'redux';

import { dateRangeLimit, rawData } from './reducers';

const analyticsReducer = combineReducers({
  rawData,
  dateRangeLimit,
});

export default analyticsReducer;
