import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { updateGame, closeGameInfoModal } from '@app/common/actions';
import { IGameInfo } from '@app/common/interfaces';
import { IRootState } from '@app/core/rootReducer';
import { fetchGameStateByGameId } from '@app/modules/GameManagement/common/actions';

import { createGame } from './actions';

const GameInfoModal = loadable(
  () => import(/* webpackChunkName: "game-management-game-info-modal" */ './GameInfoModal')
);

interface IStateProps {
  gameInfo: IGameInfo;
  isOpen: boolean;
  totalGames: number;
}

const mapStateToProps = ({ gameInfo, modalManagement, games }: IRootState): IStateProps => {
  return {
    gameInfo,
    isOpen: modalManagement.isGameInfoModalOpen,
    totalGames: games.totalCount,
  };
};

const mapDispatchToProps = {
  createGame,
  updateGame,
  closeGameInfoModal,
  fetchGameStateByGameId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TGameInfoModalProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(withRouter(GameInfoModal));
