import { IFeature } from '@app/common/interfaces';
import { GAME_STATES, STATUS } from '@app/modules/GameManagement/common/constants';
import { IGamePerformanceStatus, TGameState } from '@app/modules/GameManagement/common/interfaces';
import {
  OVERVIEW_ACTION_REQUIRED_ORDER,
  OVERVIEW_GAME_STATE_ORDER,
  POST_PERFORMANCE_GAME_STATE_ORDER,
} from '@appGameHome/common/constants';
import { TNextStepGameState } from '@appGameHome/common/interfaces';

import { isPrizeEnablementRevamp } from '../../modules/Overview/modules/PrizeEnablement/modules/PrizeEnablementWidget/helpers';

// functions to find step for next step
export function nextStepSelectorActionRequired(
  gameState: TGameState,
  isLatestSdk: boolean
): TNextStepGameState {
  for (const tempGameState of OVERVIEW_ACTION_REQUIRED_ORDER) {
    if (tempGameState === GAME_STATES.SKILLZ_SDK) {
      let isGameBuildStepComplete = gameState[GAME_STATES.UPLOAD_BINARY] === STATUS.COMPLETED;
      if (isGameBuildStepComplete && !isLatestSdk) {
        return tempGameState;
      }
    }

    if (gameState[tempGameState] === STATUS.ACTION_REQUIRED) {
      return tempGameState;
    }
  }

  return null;
}

export function nextStepSelectorGameState(gameState: TGameState): TNextStepGameState {
  for (const tempGameState of OVERVIEW_GAME_STATE_ORDER) {
    if (
      gameState[tempGameState] === STATUS.NOT_STARTED ||
      gameState[tempGameState] === STATUS.IN_PROGRESS
    ) {
      return tempGameState;
    }
  }

  return null;
}

export const postPerformanceGameStateSelector = (
  isPerformanceRequirementAchieved: boolean,
  gameState: TGameState,
  feature: IFeature
): TNextStepGameState => {
  if (!isPerformanceRequirementAchieved) return null;

  for (const gameStateWithFeature of POST_PERFORMANCE_GAME_STATE_ORDER) {
    if (
      (gameState[gameStateWithFeature.gameState] === STATUS.NOT_STARTED ||
        gameState[gameStateWithFeature.gameState] === STATUS.IN_PROGRESS) &&
      feature[gameStateWithFeature.featureFlag]
    ) {
      return gameStateWithFeature.gameState;
    }
  }

  return null;
};

export function isGameFair(
  gamePerformanceStatus: IGamePerformanceStatus | null,
  cashLive: boolean | null
): boolean {
  return !cashLive && !!gamePerformanceStatus && gamePerformanceStatus.isFair !== true;
}

// older prize enablement DAU threshold was 100. It is changed to 75 now.
// this function returns true if a cash live game has less DAU than this threshold
export function hasFewerDAU(
  gamePerformanceStatus: IGamePerformanceStatus | null,
  cashLive: boolean | null
): boolean {
  if (isPrizeEnablementRevamp()) {
    return (
      !cashLive &&
      !!gamePerformanceStatus &&
      (gamePerformanceStatus.skillzDau7DayAverage === null ||
        gamePerformanceStatus.skillzDau7DayAverage < 75)
    );
  }

  return (
    !cashLive &&
    !!gamePerformanceStatus &&
    (gamePerformanceStatus.skillzDau7DayAverage === null ||
      gamePerformanceStatus.skillzDau7DayAverage < 100)
  );
}
