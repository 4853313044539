export enum OPERATORS {
  GT = '>',
  LT = '<',
  GTE = '>=',
  LTE = '<=',
  EQ = '===',
}

export enum OPERATORS_OTHER {
  AND = '&&',
  OR = '||',
}
