import { combineReducers } from 'redux';

import gamePerformanceStatus from '@app/modules/GameManagement/modules/GamePerformanceStatus/reducer';
import gameState from '@app/modules/GameManagement/modules/GameState/reducer';
import gameBuilds from '@appGameHome/modules/GameBuild/reducer';
import progressionRoomVersions from '@appGameProgression/modules/EntryPoints/modules/RoomVersion/reducer';
import progressionRoomVersionMappings from '@appGameProgression/modules/EntryPoints/modules/RoomVersionMapping/reducer';
import progressionRooms from '@appGameProgression/modules/EntryPoints/reducer';
import sdkDownloadInfo from '@appIntegration/modules/SetupSDK/reducer';
import deepLinking from '@appOptimize/modules/DeepLinking/reducer';
import notificationCertificates from '@appOptimize/modules/PushNotifications/reducer';
import singleSignOn from '@appOptimize/modules/SingleSignOn/reducer';
import virtualCurrency from '@appOptimize/modules/VirtualCurrency/reducer';
import accelerateApplication from '@appOverview/modules/AccelerateProgram/reducer';
import prizeEnablementRequest from '@appOverview/modules/PrizeEnablement/reducer';

import verification from './modules/Verification/reducer';

const gameReducer = combineReducers({
  verification,
  singleSignOn,
  deepLinking,
  virtualCurrency,
  sdkDownloadInfo,
  gameState,
  gameBuilds,
  notificationCertificates,
  gamePerformanceStatus,
  prizeEnablementRequest,
  accelerateApplication,
  progressionRooms,
  progressionRoomVersions,
  progressionRoomVersionMappings,
});

export default gameReducer;
