import { createStore, applyMiddleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import rootReducer, { IRootState } from './rootReducer';

function configureStore(): Store<IRootState> {
  // this function is executed before environment config is fetched, so using domain to determine env
  const isProductionEnv = window.location.host === 'developers.skillz.com';

  return createStore(
    rootReducer,
    {},
    isProductionEnv ? applyMiddleware(thunk) : composeWithDevTools(applyMiddleware(thunk))
  );
}

export default configureStore;
