import * as React from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker, { DatePickerProps } from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';

import './styles/atomDateSelector.scss';

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#4890e8',
      dark: '#4890e8',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
  },
});

export interface IAtomDateSelector extends DatePickerProps<Date> {
  id: string;
}

type TDefaultPropKeys = 'onChange' | 'rifmFormatter' | 'renderInput';
type TDefaultProps = Required<Pick<IAtomDateSelector, TDefaultPropKeys>>;

class AtomDateSelector extends React.PureComponent<IAtomDateSelector> {
  public static denseMarginOverrideClass = (label: React.ReactNode | string | undefined) => {
    return `atom-component__date-selector__text-field-container ${!label ? 'no-label' : ''}`;
  };

  public static defaultProps: TDefaultProps = {
    onChange: () => ({}),
    rifmFormatter: (val: string): string => val.replace(/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi, ''),
    renderInput: (props) => (
      <TextField
        {...props}
        id={'atom-component__date-selector-default-input'}
        margin="dense"
        className={AtomDateSelector.denseMarginOverrideClass(props.label)}
      />
    ),
  };

  public render(): JSX.Element {
    return (
      <div className="atom-component__date-selector-container">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <DatePicker {...this.props} />
          </ThemeProvider>
        </LocalizationProvider>
      </div>
    );
  }
}

export { AtomDateSelector };
