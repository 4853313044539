import {
  IPlatformKeys,
  IProTournaments,
  IOrientation,
  IWinMetric,
  IFromList,
  IPlatformValues,
  IOrientationValues,
  IWinMetricValues,
  ISkuValues,
  IEntryFeeValues,
  IExistingGameValue,
  IGameAttributes,
  IMissingUrlText,
  IMissingUrl,
  IFieldNames
} from './interfaces';

const platformKeys: IPlatformKeys = {
  iOS: 'ios',
  Android: 'android',
  'Cross Platform': 'cross_platform'
};

const proTournaments: IProTournaments = {
  'Cash And Virtual Currency': 'cash_and_virtual_currency',
  'Virtual Currency Only': 'virtual_currency_only'
};

const orientation: IOrientation = {
  Portrait: 'portrait',
  Landscape: 'landscape'
};

const winMetric: IWinMetric = {
  'Higher Score Wins': 'high_score',
  'Low Score Wins': 'low_score'
};

const skuValues: ISkuValues = {
  Yes: 1,
  No: 0
};

export const FORM_LIST: IFromList = {
  platformKeys,
  proTournaments,
  orientation,
  winMetric,
  skuValues
};

export const platformValues: IPlatformValues = {
  ios: 'iOS',
  android: 'Android',
  cross_platform: 'Cross Platform'
};

const orientationValues: IOrientationValues = {
  portrait: 'Portrait',
  landscape: 'Landscape'
};

const winMetricValues: IWinMetricValues = {
  high_score: 'High Score',
  low_score: 'Low Score'
};

const entryFeeValues: IEntryFeeValues = {
  cash_and_virtual_currency: 'Cash And Virtual Currency',
  virtual_currency_only: 'Virtual Currency Only'
};

export const EXISTING_GAME_VALUE: IExistingGameValue = {
  platformValues,
  orientationValues,
  winMetricValues,
  entryFeeValues
};

export const GAME_ATTRIBUTES: IGameAttributes = {
  TITLE: 'Game Title:',
  PLATFORM: 'Game Platform:',
  VC_ONLY: 'Entry Fee Types:',
  IOS_APP_ID: 'Game iOS App ID:',
  IOS_BUNDLE_ID: 'iOS Bundle ID:',
  ANDROID_PACKAGENAME: 'Android Package Name:',
  IOS_STORE_URL: 'iOS Store URL:',
  ANDROID_STORE_URL: 'Skillz Games Portal URL:',
  SAMSUNG_STORE_URL: 'Samsung Store URL:',
  ORIENTATION: 'Game Orientation:',
  WIN_METRIC: 'Win Metric:',
  DESCRIPTION: 'App Description:',
  ICON_URL: 'App Icon',
  GAMEPLAY_SCREENSHOT: 'Tutorial Image'
};

export const MISSING_URL_TEXT: IMissingUrlText = {
  IOS: 'How to submit your game to the Apple Store',
  SAMSUNG: 'How to submit your game to the Samsung Galaxy Store',
  ANDROID: 'How to submit your Android game to the Skillz Games Portal'
};

export const MISSING_URL: IMissingUrl = {
  IOS: 'https://docs.skillz.com/docs/release-to-ios-app-store/',
  SAMSUNG: 'https://docs.skillz.com/docs/release-to-samsung-galaxy-store/',
  ANDROID: 'https://docs.skillz.com/docs/release-to-skillz-gamer-portal/'
};

export const FIELD_NAMES: IFieldNames = {
  ICON: 'icon'
};
