import React from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { default as ReactGA4 } from 'react-ga4';
import { useHistory } from 'react-router-dom';

import { GOOGLE_ANALYTICS_4 } from '@app/common/constants';

import {
  learningCenterCardStyles,
  LINEAR_BACKGROUND,
  learningCenterCardAspectRatio,
} from './constants';
import { ILearningCenterCardProps, TCardBehavior } from './interfaces';

interface ILinkCardProps extends ILearningCenterCardProps {
  targetLink: string;
  behavior: TCardBehavior;
}

export default function LinkCard({
  children,
  icon,
  backgroundImage,
  behavior,
  targetLink,
  shouldMaintainAspectRatio,
  category,
}: ILinkCardProps) {
  const history = useHistory();
  const muiTheme = useTheme();

  const cardStyle = {
    ...learningCenterCardStyles,
    backgroundImage: `${LINEAR_BACKGROUND}, url(${backgroundImage})`,
  };

  const handleClick = () => {
    ReactGA4.event({
      category: GOOGLE_ANALYTICS_4.USER.CATEGORY,
      action: GOOGLE_ANALYTICS_4.USER.ACTIONS.LEARNING_CENTER_CARD_CLICK(category, children),
    });

    if (behavior === 'link') {
      if (targetLink.startsWith('http')) {
        window.location.href = targetLink;

        return;
      }

      return history.push(targetLink);
    }

    if (behavior === 'download') {
      return (window.location.href = targetLink);
    }

    return window.open(targetLink, '_blank');
  };

  // maintain aspect ratio in learning center, when there's no profile widget, or when less than md width
  if (shouldMaintainAspectRatio || useMediaQuery(muiTheme.breakpoints.down('md'))) {
    Object.assign(cardStyle, { aspectRatio: learningCenterCardAspectRatio });
  }

  return (
    <Card sx={cardStyle} onClick={handleClick} data-qa={'learning-center-link-card'}>
      <Grid
        container
        alignItems={'center'}
        data-qa={'learning-center-card-content'}
        wrap={'nowrap'}
      >
        <Grid item pr={1}>
          {icon}
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Card>
  );
}
