import * as React from 'react';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import './styles/atomTableRowsStyles.scss';

type TCellAlign = 'right' | 'inherit' | 'left' | 'center' | 'justify' | undefined;

export interface IAtomTableRowRequiredProps {
  shouldHover: boolean;
  isActive: boolean;
}

export type IAtomTableRowPropsFromAtomTableRows<T> = IAtomTableRowRequiredProps & T;

type TRowKeys<T> = keyof IAtomTableRowPropsFromAtomTableRows<T>;
export interface IAtomTableRowsProps<T = null> {
  id: string;
  rows: IAtomTableRowPropsFromAtomTableRows<T>[];
  align?: TCellAlign;
}

type TAtomTableRowsDefaultProps = Required<Pick<IAtomTableRowsProps, 'align'>>;

// TODO deprecate AtomTableRows and replace with AtomTableRow
class AtomTableRows<T> extends React.PureComponent<IAtomTableRowsProps<T>> {
  public static defaultProps: TAtomTableRowsDefaultProps = {
    align: 'left'
  };

  public renderTableCellsHelper(
    row: IAtomTableRowPropsFromAtomTableRows<T>,
    rowIndex: number,
    key: TRowKeys<T>,
    cellIndex: number
  ): JSX.Element | null {
    let { id, align } = this.props;

    // we need to filter out the keys that are actually props
    if (key === 'shouldHover' || key === 'isActive') {
      return null;
    }

    return (
      <TableCell
        align={align}
        id={`${id}__table-rows-row-${rowIndex}-cell-${cellIndex}`}
        key={`atom-component__table-rows-row-${rowIndex}-cell-${cellIndex}`}
        className={`atom-component__table-rows-row-cell ${row.isActive ? 'enabled' : 'disabled'}`}
      >
        {row[key]}
      </TableCell>
    );
  }

  public render(): JSX.Element {
    let { id, rows } = this.props;

    return (
      <TableBody className="atom-component__table-rows-body" id={`${id}__table-rows-body`}>
        {rows.map((row: IAtomTableRowPropsFromAtomTableRows<T>, rowIndex: number) => {
          return (
            <TableRow
              id={`${id}__table-rows-row-${rowIndex}`}
              key={`atom-component__table-rows-row-${rowIndex}`}
              className={`atom-component__table-rows-row`}
              hover={row.shouldHover}
            >
              {(Object.keys(row) as TRowKeys<T>[]).map((key: TRowKeys<T>, cellIndex: number) =>
                this.renderTableCellsHelper(row, rowIndex, key, cellIndex)
              )}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }
}

export { AtomTableRows };
