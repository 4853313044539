import React from 'react';

import { AxisProps } from '@nivo/axes';
import { Data, ResponsiveBar, TooltipProp } from '@nivo/bar';
import { OrdinalColorsInstruction } from '@nivo/colors';

import { barLegends } from '@app/common/constants';
import { IChartKeys } from '@app/common/interfaces';

interface IDataProps {
  data: Data['data'];
  colors?: OrdinalColorsInstruction;
  keys: (keyof IChartKeys)[];
  xAxisDates: string;
  axisLeft?: AxisProps | null;
  tooltip?: TooltipProp;
}

/**
 * finds the max value of all of the bars in the chart and returns that value multiplied by 1.2
 * in order to create a buffer for the max value of the chart
 * @param { Data['data'] } data - bar chart data
 * @param { (keyof IChartKeys)[] } keys - the keys that are used in the data
 * @return { number } - returns the max value of all bars multiplied by 1.2
 */
export function findMaxValueFromBarChartData(
  data: Data['data'],
  keys: (keyof IChartKeys)[]
): number {
  const values = data.map((bar) =>
    keys
      .filter(
        (key) => bar.hasOwnProperty(key) && !Number.isNaN((bar as Record<string, number>)[key])
      )
      .map((key) => (bar as Record<string, number>)[key])
      .reduce((val, acc) => val + acc, 0)
  );

  return Math.max(...values) * 1.2;
}

export const ResponsiveBarChart = ({
  data,
  colors = { scheme: 'nivo' },
  keys,
  xAxisDates,
  axisLeft,
  tooltip,
}: IDataProps): JSX.Element => {
  return (
    <ResponsiveBar
      maxValue={findMaxValueFromBarChartData(data, keys)}
      minValue={0}
      data={data}
      keys={keys as string[]}
      indexBy="label"
      margin={{ top: 48, right: 8, bottom: 48, left: 48 }}
      colors={colors}
      defs={[
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#A1D1B5',
          rotation: -45,
          lineWidth: 1,
          spacing: 8,
        },
      ]}
      animate={false}
      enableLabel={false}
      padding={0.1}
      groupMode="stacked"
      axisLeft={axisLeft}
      axisBottom={{
        tickSize: 0,
        legend: xAxisDates,
        legendPosition: 'middle',
        legendOffset: 29,
        format: (): string => '',
      }}
      legends={[barLegends]}
      tooltip={tooltip}
    />
  );
};
