import { IPackages, IPackagesOrder, IVirtualCurrencyInitial } from './interfaces';

export enum VIRTUAL_CURRENCY {
  STORE_PACKAGE = 'virtualCurrency/STORE_PACKAGE',
  RECEIVE_PACKAGE = 'virtualCurrency/RECEIVE_PACKAGE',
}

export enum PACKAGES_NAME {
  TINY_PACKAGE = 'Tiny Package',
  SMALL_PACKAGE = 'Small Package',
  MEDIUM_PACKAGE = 'Medium Package',
  LARGE_PACKAGE = 'Large Package',
  XLARGE_PACKAGE = 'X-Large Package',
  HUGE_PACKAGE = 'Huge Package',
}

export const PACKAGES: IPackages[] = [
  {
    name: PACKAGES_NAME.TINY_PACKAGE,
    virtualCurrency: '100',
    cashValue: '0.99',
  },
  {
    name: PACKAGES_NAME.SMALL_PACKAGE,
    virtualCurrency: '600',
    cashValue: '4.99',
  },
  {
    name: PACKAGES_NAME.MEDIUM_PACKAGE,
    virtualCurrency: '1500',
    cashValue: '9.99',
  },
  {
    name: PACKAGES_NAME.LARGE_PACKAGE,
    virtualCurrency: '3200',
    cashValue: '19.99',
  },
  {
    name: PACKAGES_NAME.XLARGE_PACKAGE,
    virtualCurrency: '8500',
    cashValue: '49.99',
  },
  {
    name: PACKAGES_NAME.HUGE_PACKAGE,
    virtualCurrency: '19000',
    cashValue: '99.99',
  },
];

export const PACKAGES_ORDER: IPackagesOrder = {
  [PACKAGES_NAME.TINY_PACKAGE]: 0,
  [PACKAGES_NAME.SMALL_PACKAGE]: 1,
  [PACKAGES_NAME.MEDIUM_PACKAGE]: 2,
  [PACKAGES_NAME.LARGE_PACKAGE]: 3,
  [PACKAGES_NAME.XLARGE_PACKAGE]: 4,
  [PACKAGES_NAME.HUGE_PACKAGE]: 5,
};

export const INITIAL_PACKAGES: IVirtualCurrencyInitial[] = [
  {
    productId: '',
  },
  {
    productId: '',
  },
  {
    productId: '',
  },
  {
    productId: '',
  },
  {
    productId: '',
  },
  {
    productId: '',
  },
];
