import * as React from 'react';

import Grid from '@mui/material/Grid';

import { ArcherContainer, ArcherElement, LineStyle, Relation } from 'react-archer';

import { IActionNoData, IGameInfo } from '@app/common/interfaces';
import variables from '@app/core/variables.scss';
import { IGamePerformanceStatus } from '@app/modules/GameManagement/common/interfaces';

import { PRIZE_ENABLEMENT_LABEL_POSITION, PRIZE_ENABLEMENT_STEPS_STATE } from '../constants';
import { renderPrizeEnablementAttributeCopy, renderPrizeEnablementAttributeIcon } from '../helpers';
import { IViewProps, TSORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE } from '../interfaces';
import { PathIcon } from './PathIcon';
import './styles/desktopViewStyles.scss';

const completeStepLineStyle: LineStyle = {
  strokeWidth: 4,
  strokeColor: variables.colorBrandBlue,
};

const inProgressStepLineStyle: LineStyle = {
  strokeWidth: 2,
  strokeColor: variables.colorGray100Borders,
  strokeDasharray: '10, 10',
};

class DesktopView extends React.PureComponent<IViewProps> {
  public positionHelper = (
    index: number
  ): { style: React.CSSProperties; position: PRIZE_ENABLEMENT_LABEL_POSITION } => {
    switch (index) {
      case 1:
        return {
          style: { paddingBottom: '22px' },
          position: PRIZE_ENABLEMENT_LABEL_POSITION.BOTTOM,
        };

      case 2:
        return {
          style: { paddingBottom: '15px' },
          position: PRIZE_ENABLEMENT_LABEL_POSITION.TOP,
        };

      case 3:
        return {
          style: { paddingBottom: '10px' },
          position: PRIZE_ENABLEMENT_LABEL_POSITION.BOTTOM,
        };

      case 4:
        return { style: { paddingBottom: '25px' }, position: PRIZE_ENABLEMENT_LABEL_POSITION.TOP };

      default:
        return { style: { paddingBottom: '32px' }, position: PRIZE_ENABLEMENT_LABEL_POSITION.TOP };
    }
  };

  public renderStep = (
    gamePerformanceStatus: IGamePerformanceStatus | null,
    attribute: TSORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE,
    openFairnessModal: () => IActionNoData,
    archerElementSteps: string[],
    isComplete: boolean,
    isNextComplete: boolean,
    idx: number,
    gameInfo: IGameInfo
  ): JSX.Element => {
    // adding a `relation` which tells the `ArcherElement` how to connect this element to the next
    let relations: Relation[] = [
      {
        targetId: '',
        targetAnchor: 'middle',
        sourceAnchor: 'middle',
        style: isNextComplete ? completeStepLineStyle : inProgressStepLineStyle,
      },
    ];

    if (idx === 2) {
      relations[0].targetAnchor = 'left';
    }

    if (idx === 3) {
      relations[0].sourceAnchor = 'right';
    }

    // if this is the last step then we connect this element with this gift-icon element
    // if not, then we just connect it with the next element
    if (idx === archerElementSteps.length - 1) {
      relations[0].targetId = 'gift-icon';
    } else {
      relations[0].targetId = archerElementSteps[idx + 1];
    }

    return (
      <div
        className={`step-container ${this.positionHelper(idx).position}`}
        id={`step-container__${archerElementSteps[idx]}`}
      >
        <div
          className={`step-copy ${this.positionHelper(idx).position} ${
            !isComplete ? 'incomplete' : ''
          }`}
        >
          {renderPrizeEnablementAttributeCopy(
            gamePerformanceStatus,
            attribute,
            gameInfo,
            openFairnessModal
          )}
        </div>
        <ArcherElement id={archerElementSteps[idx]} relations={relations}>
          <div>{renderPrizeEnablementAttributeIcon(gamePerformanceStatus, attribute)}</div>
        </ArcherElement>
      </div>
    );
  };

  public render = (): JSX.Element => {
    const { gameInfo, openFairnessModal, sortedGamePerformanceStatus, gamePerformanceStatus } =
      this.props;

    const sortedAttributes: TSORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE[] | undefined =
      sortedGamePerformanceStatus?.complete.concat(sortedGamePerformanceStatus.incomplete);

    const archerElementSteps: string[] = ['step-1', 'step-2', 'step-3', 'step-4', 'step-5'];
    let numberOfCompleteSteps: number | undefined = sortedGamePerformanceStatus?.complete.length;

    return (
      <ArcherContainer
        endShape={{ arrow: { arrowLength: 0.01 } }}
        className={'desktop-view-container'}
      >
        <Grid
          container={true}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Grid item={true} className={'game-icon-step-container'} id={`step-container__game-icon`}>
            <ArcherElement
              id={'game-icon'}
              relations={[
                {
                  targetId: 'step-1',
                  targetAnchor: 'left',
                  sourceAnchor: 'right',
                  style:
                    sortedGamePerformanceStatus && sortedGamePerformanceStatus.complete.length > 0
                      ? completeStepLineStyle
                      : inProgressStepLineStyle,
                },
              ]}
            >
              <div>
                <PathIcon
                  state={PRIZE_ENABLEMENT_STEPS_STATE.ICON_GAME}
                  icon={gameInfo.iconUrl ? gameInfo.iconUrl : undefined}
                />
              </div>
            </ArcherElement>
          </Grid>

          {sortedAttributes?.map(
            (
              attribute: TSORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE,
              index: number
            ): JSX.Element | null => {
              if (numberOfCompleteSteps === undefined) {
                return null;
              }

              numberOfCompleteSteps = numberOfCompleteSteps - 1;

              let isNextComplete: boolean =
                sortedGamePerformanceStatus?.incomplete.length === 0
                  ? true
                  : numberOfCompleteSteps > 0;

              return (
                <Grid item={true} key={index} style={this.positionHelper(index).style}>
                  {this.renderStep(
                    gamePerformanceStatus,
                    attribute,
                    openFairnessModal,
                    archerElementSteps,
                    numberOfCompleteSteps > 0,
                    isNextComplete,
                    index,
                    gameInfo
                  )}
                </Grid>
              );
            }
          )}

          <Grid item={true} className={'gift-icon-step-container'} id={`step-container__gift-icon`}>
            <ArcherElement id={'gift-icon'}>
              <div>
                <PathIcon
                  state={
                    sortedGamePerformanceStatus &&
                    sortedGamePerformanceStatus.incomplete.length === 0
                      ? PRIZE_ENABLEMENT_STEPS_STATE.ICON_GIFT_COLOR
                      : PRIZE_ENABLEMENT_STEPS_STATE.ICON_GIFT_SHADE
                  }
                />
              </div>
            </ArcherElement>
          </Grid>
        </Grid>
      </ArcherContainer>
    );
  };
}

export { DesktopView };
