export const GOOGLE_ANALYTICS = {
  HELP_BITS: {
    CATEGORY: 'Help Bits',
    ACTIONS: {
      CLICK_HELP: 'Click Help',
      HELP_BIT_VIEW: 'Help Bit View',
      CLICK_BACK: 'Click Back',
      CLICK_NEXT: 'Click Next',
      CLICK_SEARCH: 'Click Search',
      SEARCH_TERM: 'Search Term',
    },
  },
  ZENDESK_DASHBOARD: {
    CATEGORY: 'Zendesk Dashboard',
    ACTIONS: {
      OPEN_TAB: 'Open Tab',
      OPEN_TICKET: 'Open Ticket',
      CHANGE_PAGE: 'Change Page',
      CHANGE_PAGE_SIZE: 'Change Page Size',
      CHANGE_TICKET: 'Change Ticket',
      CHANGE_CATEGORY: 'Change Category',
      CHANGE_DATE_RANGE: 'Change Date Range',
    },
  },
};
