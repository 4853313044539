import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import {
  fetchGameAccess,
  openArchiveModal,
  updateGameEnabled,
  fetchPermissions,
} from '@app/common/actions';
import { IAction, IActionNoData, IGameEnabled, TErrorGeneral } from '@app/common/interfaces';
import { IRootState } from '@app/core/rootReducer';

import { IDispatchProps } from './interfaces';

const GameObjectListComponent = loadable(
  () => import(/* webpackChunkName: "common-component-game-object-list" */ './GameObjectList')
);

const mapStateToProps = (state: IRootState) => {
  const { feature, gameAccess, permissions } = state;

  return { feature, gameAccess, permissions };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, unknown, IActionNoData | IAction<IGameEnabled>>
): IDispatchProps => {
  return {
    openArchiveModal: (): IActionNoData => dispatch(openArchiveModal()),
    updateGameEnabled: (game: IGameEnabled): Promise<void> | TErrorGeneral =>
      dispatch(updateGameEnabled(game)),
    fetchGameAccess: () => dispatch(fetchGameAccess()),
    fetchPermissions: (...args) => dispatch(fetchPermissions(...args)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TGameObjectListConnectedProps = ConnectedProps<typeof connector>;

export const GameObjectList = connector(withRouter(GameObjectListComponent));
