import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

import variables from '@app/core/variables.scss';

export interface ICommonTooltipProps {
  id: string;
  text: string;
  dataAttributes: { 'data-qa': string };
  placement?:
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right'
    | 'bottom-start'
    | 'bottom'
    | 'bottom-end'
    | 'left';
}

export const commonTooltipStyles = {
  tooltip: {
    padding: '16px',
    color: `${variables.colorGray900PrimaryText}`,
    fontWeight: 400,
    fontFamily: 'Roboto',
    lineHeight: '19px',
    boxShadow:
      '0 1px 10px 0 rgba(0, 0, 0, 0.18), 0 4px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 4px 0 rgba(0, 0, 0, 0.14)',
  },
  arrow: {
    color: `${variables.colorGray50Background}`,
  },
  popper: {
    zIndex: 1300,
  },
};

export const CommonTooltipActionWithStyle = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '4px',
    fontSize: '14px',
    lineHeight: '19px',
  },
})(Typography);

export const commonTooltipTextStyles = {
  fontSize: '14px',
  fontWeight: 400,
};
