/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';

import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';

import { IDataAttributes } from 'portal-common-library/interfaces/dataAttributes';

import './styles/atomTextField.scss';

export interface ITextFieldProps {
  id: string;
  label: string;
  placeholder: string;
  attributeName: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  errorText?: string;
  type?: string;
  rows?: number;
  multiline?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  required?: boolean;
  autoComplete?: string;
  inputProps?: { [key: string]: JSX.Element };
  disabled?: boolean;
  dataAttributes?: IDataAttributes;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  hasAnimation?: boolean;
}

type TDefaultPropKeys =
  | 'onKeyPress'
  | 'fullWidth'
  | 'errorText'
  | 'type'
  | 'rows'
  | 'multiline'
  | 'variant'
  | 'required'
  | 'error'
  | 'autoComplete'
  | 'autoFocus'
  | 'hasAnimation';

type TDefaultProps = Required<Pick<ITextFieldProps, TDefaultPropKeys>>;

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#4890e8',
      dark: '#4890e8',
    },
  },
});

class AtomTextField extends React.PureComponent<ITextFieldProps> {
  public static defaultProps: TDefaultProps = {
    fullWidth: true,
    errorText: '',
    required: true,
    type: 'text',
    multiline: false,
    onKeyPress: (): void => undefined,
    rows: 1,
    variant: 'outlined',
    error: false,
    autoComplete: 'on',
    autoFocus: false,
    hasAnimation: false,
  };

  public render(): JSX.Element {
    let {
      id,
      placeholder,
      attributeName,
      onChange,
      fullWidth,
      error,
      errorText,
      type,
      value,
      onKeyPress,
      rows,
      multiline,
      variant,
      label,
      autoComplete,
      inputProps,
      disabled,
      dataAttributes,
      autoFocus,
      onBlur,
      hasAnimation,
    } = this.props;

    const classNames = `${!hasAnimation ? '' : value ? 'expand' : 'animated'} ${
      label === '' ? 'no-label' : ''
    }`;

    return (
      <ThemeProvider theme={theme}>
        <TextField
          autoFocus={autoFocus}
          disabled={disabled}
          InputProps={inputProps}
          id={id}
          className={`atom-component__text-field-container${classNames}`}
          name={attributeName}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          fullWidth={fullWidth}
          error={error}
          helperText={errorText}
          label={label === '' ? '\u200B' : label}
          type={type}
          value={value}
          onKeyPress={onKeyPress}
          minRows={rows}
          multiline={multiline}
          variant={variant}
          margin="dense"
          autoComplete={autoComplete}
          {...dataAttributes}
        />
      </ThemeProvider>
    );
  }
}

export { AtomTextField };
