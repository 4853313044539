/* eslint-disable */
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import { Dispatch } from 'redux';

import { snakeCaseConverter } from '@app/common/helpers';
import {
  TThunkAction,
  TDispatch,
  IAction,
  ITournament,
  TOpenSnackbarFunc,
  ITournamentSet,
  ITournamentRule,
} from '@app/common/interfaces';
import axios, { AxiosResponse, AxiosError } from '@app/common/setup/axiosWithHeader';

import { ITournamentUpdate, ITournamentRuleWithIndex, ITournamentWithIndex } from './interfaces';
import { getAmzDate } from './utils/utils';

type TReceiveSandboxTournament = (data: ITournamentWithIndex) => IAction<ITournamentWithIndex>;
type TReceiveSandboxTournaments = (data: ITournament[]) => IAction<ITournament[]>;
type TReceiveSandboxTournamentSet = (data: ITournamentSet[]) => IAction<ITournamentSet[]>;
type TReceiveProductionTournamentSet = (data: ITournamentSet[]) => IAction<ITournamentSet[]>;
type TReceiveProductionTournaments = (data: ITournament[]) => IAction<ITournament[]>;
type TReceiveTournaentRule = (data: ITournamentRuleWithIndex) => IAction<ITournamentRuleWithIndex>;

export enum NEW_TOURNAMENTS {
  RECEIVE_SANDBOX_TOURNAMENT = 'tournament/RECEIVE_SANDBOX_TOURNAMENT',
  RECEIVE_SANDBOX_TOURNAMENTS = 'tournaments/RECEIVE_SANDBOX_TOURNAMENTS',
  RECEIVE_PRODUCTION_TOURNAMENTS = 'tournaments/RECEIVE_PRODUCTION_TOURNAMENTS',
  RECEIVE_TOURNAMENT_RULE = 'tournaments/RECEIVE_TOURNAMENT_RULE',
  UPDATE_TOURNAMENT_RULE = 'tournaments/UPDATE_TOURNAMENT_RULE',
  REMOVE_TOURNAMENT_RULE = 'tournaments/REMOVE_TOURNAMENT_RULE',
  RECEIVE_SANDBOX_TOURNAMENT_SET = 'tournaments/RECEIVE_SANDBOX_TOURNAMENT_SET',
  RECEIVE_PRODUCTION_TOURNAMENT_SET = 'tournaments/RECEIVE_PRODUCTION_TOURNAMENT_SET',
}

export const receiveProductionTournaments: TReceiveProductionTournaments = (
  data: ITournament[]
): IAction<ITournament[]> => {
  return {
    type: NEW_TOURNAMENTS.RECEIVE_PRODUCTION_TOURNAMENTS,
    data,
  };
};

export const receiveSandboxTournament: TReceiveSandboxTournament = (
  data: ITournamentWithIndex
): IAction<ITournamentWithIndex> => {
  return {
    type: NEW_TOURNAMENTS.RECEIVE_SANDBOX_TOURNAMENT,
    data,
  };
};

export const receiveSandboxTournaments: TReceiveSandboxTournaments = (
  data: ITournament[]
): IAction<ITournament[]> => {
  return {
    type: NEW_TOURNAMENTS.RECEIVE_SANDBOX_TOURNAMENTS,
    data,
  };
};

export const receiveSandboxTournamentSet: TReceiveSandboxTournamentSet = (
  data: ITournamentSet[]
): IAction<ITournamentSet[]> => {
  return {
    type: NEW_TOURNAMENTS.RECEIVE_SANDBOX_TOURNAMENT_SET,
    data,
  };
};

export const receiveProductionTournamentSet: TReceiveProductionTournamentSet = (
  data: ITournamentSet[]
): IAction<ITournamentSet[]> => {
  return {
    type: NEW_TOURNAMENTS.RECEIVE_PRODUCTION_TOURNAMENT_SET,
    data,
  };
};

export const receiveTournamentRule: TReceiveTournaentRule = (
  data: ITournamentRuleWithIndex
): IAction<ITournamentRuleWithIndex> => {
  return {
    type: NEW_TOURNAMENTS.RECEIVE_TOURNAMENT_RULE,
    data,
  };
};

export const receiveUpdatedTournamentRule: TReceiveTournaentRule = (
  data: ITournamentRuleWithIndex
): IAction<ITournamentRuleWithIndex> => {
  return {
    type: NEW_TOURNAMENTS.UPDATE_TOURNAMENT_RULE,
    data,
  };
};

export const removeTournamentRule: TReceiveTournaentRule = (
  data: ITournamentRuleWithIndex
): IAction<ITournamentRuleWithIndex> => {
  return {
    type: NEW_TOURNAMENTS.REMOVE_TOURNAMENT_RULE,
    data,
  };
};

export const fetchSandboxTournaments: TThunkAction = (gameId: number): TDispatch => {
  // TODO REPLACE [WS-10403] remove the below disable and fix error
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (dispatch: Dispatch<any>): any => {
    return axios
      .get(`/games/${gameId}/get_tournaments.json`)
      .then((response: AxiosResponse<ITournament[]>) => {
        return dispatch(receiveSandboxTournaments(camelize(response.data)));
      });
  };
};

export const updateSandboxTournamentTemplateSet = async (
  setId: number,
  gameId: number,
  tournamentSet: ITournamentSet,
  image: File | null,
  scoring: string,
  players: number
) => {
  let backgroundImageUrl = tournamentSet.backgroundImageUrl;

  if (image) {
    const presignedUrl = await axios
      .get(`/api_internal/v1/games/${gameId}/sandbox_tournament_template_sets/get_presigned_url`)
      .catch(() => Promise.reject('Error generating presigned url'));

    const form = new FormData();

    const extension = image.type.split('/')[1];
    let filename = `background_image_${gameId}_${setId}.${extension}`;

    //@ts-ignore
    const fields = presignedUrl.data.presigned_urls[0].fields;
    Object.keys(fields).forEach((key) => {
      if (key === 'key') {
        // to be used for uploading image url to database?
        filename = fields[key].replace('${filename}', filename);
        form.append('key', filename);
      } else if (key == 'X-Amz-Date') {
        const date = getAmzDate(fields[key]);
        form.append(key, date);
      } else {
        const value = fields[key];
        form.append(key, value);
      }
    });
    form.append('Content-Type', image.type);
    form.append('file', image);

    //@ts-ignore
    const url = presignedUrl.data.presigned_urls[0].url;
    await axios.post(url, form).catch(() => Promise.reject('Error uploading background image'));
    //@ts-ignore
    backgroundImageUrl = `${presignedUrl.data.cdn_base_url}${encodeURI(filename)}`;
  }
  await axios
    .patch(
      `/api_internal/v1/games/${gameId}/sandbox_tournament_template_sets/${setId}`,
      snakeCaseConverter({
        ...tournamentSet,
        backgroundImageUrl,
        players,
        scoring,
      })
    )
    .then(camelize);
};

export const addGameParameterToSet = async (
  gameId: number,
  setId: number,
  gameplayParamKey: string,
  gameplayParamValue: string
) => {
  await axios
    .post(
      `/api_internal/v1/games/${gameId}/sandbox_tournament_template_sets/${setId}/gameplay_parameters`,
      snakeCaseConverter({
        gameplayParamKey,
        gameplayParamValue,
      })
    )
    .then(camelize);
};

export const deleteGameParameterFromSet = async (
  gameId: number,
  setId: number,
  gameplayParamid: number
) => {
  await axios
    .delete(
      `/api_internal/v1/games/${gameId}/sandbox_tournament_template_sets/${setId}/gameplay_parameters/${gameplayParamid}`
    )
    .then(camelize);
};

export const editGameParameterFromSet = async (
  gameId: number,
  setId: number,
  gameplayParamId: number,
  gameplayParamKey: string,
  gameplayParamValue: string
) => {
  await axios
    .patch(
      `/api_internal/v1/games/${gameId}/sandbox_tournament_template_sets/${setId}/edit_gameplay_parameters`,
      snakeCaseConverter({
        gameplayParamId,
        gameplayParamKey,
        gameplayParamValue,
      })
    )
    .then(camelize);
};

export const fetchProductionTournamentSet: TThunkAction = (gameId: number): TDispatch => {
  // TODO REPLACE [WS-10403] remove the below disable and fix error
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (dispatch: Dispatch<any>): any => {
    return axios
      .get(`/api_internal/v1/games/${gameId}/production_tournament_template_sets`)
      .then((response: AxiosResponse<ITournamentSet[]>) => {
        return dispatch(receiveProductionTournamentSet(camelize(response.data)));
      });
  };
};

export const fetchSandboxTournamentSet: TThunkAction = (gameId: number): TDispatch => {
  // TODO REPLACE [WS-10403] remove the below disable and fix error
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (dispatch: Dispatch<any>): any => {
    return axios
      .get(`/api_internal/v1/games/${gameId}/sandbox_tournament_template_sets`)
      .then((response: AxiosResponse<ITournament[]>) => {
        return dispatch(receiveSandboxTournamentSet(camelize(response.data)));
      });
  };
};

// the redux for this isn't fully setup yet - it will be done once IVTV3 is fully deprecated
export const fetchProductionTournaments: TThunkAction = (gameId: number): TDispatch => {
  return (dispatch: Dispatch<any>): any => {
    return axios
      .get(`/production_tournament_templates.json?game_id=${gameId}`)
      .then((response: AxiosResponse<ITournament[]>) => {
        return dispatch(receiveProductionTournaments(camelize(response.data)));
      });
  };
};

export const deleteTournamentRule: TThunkAction = (index: number, id: number): TDispatch => {
  return (dispatch: Dispatch<any>): any => {
    return axios
      .delete(`/tournament_rules/${id}.json`)
      .then((response: AxiosResponse<any>) => {
        dispatch(removeTournamentRule(camelize({ index, response: response.data })));
      })
      .catch((error: AxiosError<any>) => {
        return error;
      });
  };
};

export const createTournamentRule: TThunkAction = (
  index: number,
  tournamentRuleParentId: number,
  key: string,
  value: string
): TDispatch => {
  return (dispatch: Dispatch<any>): any => {
    const tournamentRuleParentType: string = 'SandboxTournamentTemplate';

    return axios
      .post(`/tournament_rules.json`, {
        /* eslint-disable-next-line camelcase */
        tournament_rule: snakeCaseConverter({
          tournamentRuleParentId,
          key,
          value,
          tournamentRuleParentType,
        }),
      })
      .then((response: AxiosResponse<any>) => {
        return dispatch(receiveTournamentRule(camelize({ index, response: response.data })));
      })
      .catch((error: AxiosError<any>) => {
        return error;
      });
  };
};

export const updateTournamentRule: TThunkAction = (
  index: number,
  id: number,
  tournamentRuleParentId: number,
  key: string,
  value: string
): TDispatch => {
  return (dispatch: Dispatch<any>): any => {
    return axios
      .patch(`/tournament_rules/${id}.json`, {
        /* eslint-disable-next-line camelcase */
        tournament_rule: snakeCaseConverter({
          tournamentRuleParentId,
          key,
          value,
        }),
      })
      .then((response: AxiosResponse<TReceiveTournaentRule>) => {
        return dispatch(receiveUpdatedTournamentRule(camelize({ index, response: response.data })));
      })
      .catch((error: AxiosError<any>) => {
        return error;
      });
  };
};

export const saveTournament: TThunkAction = (
  tournamentId: number,
  index: number,
  data: ITournamentUpdate,
  openSnackbar: TOpenSnackbarFunc
): TDispatch => {
  return (dispatch: Dispatch<any>): any => {
    return (
      axios
        .patch(`/sandbox_tournament_templates/${tournamentId}.json`, {
          /* eslint-disable-next-line camelcase */
          sandbox_tournament_template: snakeCaseConverter(data),
        })
        .then((response: AxiosResponse<ITournament>) => {
          openSnackbar({
            text: 'Saved tournament',
            autoHideDuration: 2000,
            anchorOriginVertical: 'bottom',
            anchorOriginHorizontal: 'left',
          });

          return dispatch(receiveSandboxTournament(camelize({ index, response: response.data })));
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((_: AxiosError<any>) => {
          /* eslint-enable @typescript-eslint/no-explicit-any */
          openSnackbar({
            text: 'Error saving tournament!',
            autoHideDuration: 2000,
            anchorOriginVertical: 'bottom',
            anchorOriginHorizontal: 'left',
          });
        })
    );
  };
};

export const publishTournamentSet = (gameId: number, setId: number) => {
  return axios.patch(
    `/api_internal/v1/games/${gameId}/sandbox_tournament_template_sets/${setId}/publish`
  );
};
