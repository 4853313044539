import merge from 'lodash/merge';

import { GAME_BUILDS_FETCH, GAME_BUILDS_UPDATE } from '../actions';
import { findGameBuildIndex } from '../helper';
import { TActionItems, TGameBuildsPerPage } from '../interfaces';

export const BINARIES_PLACEHOLDER_CONSTANT: number = 100;

export const itemsInitialState: TGameBuildsPerPage = { [BINARIES_PLACEHOLDER_CONSTANT]: [] };

export function itemsReducer(
  state: TGameBuildsPerPage = itemsInitialState,
  action: TActionItems
): TGameBuildsPerPage {
  let newState: TGameBuildsPerPage;
  Object.freeze(state);
  if (!action.data) {
    if (action.type === GAME_BUILDS_FETCH.CLEAR_GAME_BUILDS) {
      return itemsInitialState;
    }

    return state;
  }

  switch (action.type) {
    case GAME_BUILDS_FETCH.RESET_AND_RECEIVE_GAME_BUILDS:
      newState = merge({}, itemsInitialState, action.data);

      return newState;

    case GAME_BUILDS_FETCH.RECEIVE_GAME_BUILDS:
      newState = merge({}, state, action.data);

      return newState;
    case GAME_BUILDS_UPDATE.RECEIVE_GAME_BUILD_UPDATE:
      let firstKey: string = Object.keys(action.data)[0];
      let page: number = parseInt(firstKey, 10);

      if (isNaN(page) || page < 0) {
        return state;
      }

      let newData = action.data;
      let gameBuild = newData[page][0];
      let gameBuildIndex: number = findGameBuildIndex(state[page], gameBuild.id);

      if (gameBuildIndex > -1) {
        // copy all levels of nested state and set active attribute from response data
        newState = merge({}, state, {
          [page]: {
            [gameBuildIndex]: gameBuild
          }
        });

        return newState;
      }

      return state;
    default:
      return state;
  }
}
