import { IStrapiFindParams } from '../interfaces';

export const formatFindParams = (params: IStrapiFindParams): string => {
  const result: string[] = [];
  const urlParams = new URLSearchParams();
  const map: Partial<Record<keyof IStrapiFindParams, string>> = {
    limit: '_limit',
    sort: '_sort',
    start: '_start',
  };

  // need to handle the values in the `map` because they need to be prefixed with `_`
  Object.keys(map).forEach((key) => {
    const value = params[key as keyof IStrapiFindParams];

    if (value !== undefined) {
      const keyFrom = map[key as keyof IStrapiFindParams];

      urlParams.append(keyFrom as string, value.toString());
    }
  });

  if (urlParams.toString() !== '') {
    result.push(urlParams.toString());
  }

  // the values not in the `map` attribute are expressions, so we just need to pass
  // them into the url if they are not undefined
  Object.entries(params).forEach(([key, value]) => {
    if (!Object.keys(map).includes(key) && value !== undefined) {
      result.push(value);
    }
  });

  return result.join('&');
};
