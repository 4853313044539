import Loadable from 'react-loadable';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchPublisher, findUser, openSnackbar } from '@app/common/actions';
import { Loading } from '@app/common/components';
import { ILoadableComponent } from '@app/common/interfaces';
import { IRootState } from '@app/core/rootReducer';

const PeekHeader: ILoadableComponent['component'] = Loadable({
  loader: () => import(/* webpackChunkName: "peek-header" */ './PeekHeader'),
  loading: Loading,
});

const mapStateToProps = (state: IRootState) => {
  let {
    publisher: { publisherInfo },
    user,
  } = state;

  return {
    publisher: publisherInfo,
    user,
  };
};

const mapDispatchToProps = {
  openSnackbar,
  findUser,
  fetchPublisher,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TPeekHeaderConnectedProps = ConnectedProps<typeof connector>;

export default connector(withRouter(PeekHeader));
