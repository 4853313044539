/* eslint-disable */
import merge from 'lodash/merge';
import findIndex from 'lodash/findIndex';
import { NEW_TOURNAMENTS } from './new-actions';

let initialState = {};

const tournamentReducer = (state = initialState, action) => {
  let newState;
  Object.freeze(state);
  switch (action.type) {
    case NEW_TOURNAMENTS.RECEIVE_SANDBOX_TOURNAMENT:
      let { index, response } = action.data;
      newState = merge({}, state);
      newState[index] = merge({}, newState[index], response);

      return newState;

    case NEW_TOURNAMENTS.RECEIVE_SANDBOX_TOURNAMENTS:
      newState = merge({}, action.data.tournaments);
      return newState;

    case NEW_TOURNAMENTS.RECEIVE_TOURNAMENT_RULE:
      let { index, response } = action.data;
      newState = merge({}, state);
      newState[index].rules.push(response.tournamentRule);
      return newState;

    case NEW_TOURNAMENTS.UPDATE_TOURNAMENT_RULE:
      let { index, response } = action.data;
      newState = merge({}, state);
        // lets find the correct tournament rule to update from the redux store
      let updateIndex = findIndex(newState[index].rules, (rule) => {
          return rule.id === action.data.response.tournamentRule.id;
      });
      newState[index].rules[updateIndex] = action.data.response.tournamentRule;
      return newState;

    case NEW_TOURNAMENTS.REMOVE_TOURNAMENT_RULE:
      let { index } = action.data;
      newState = merge({}, state);

      // lets find the correct tournament rule to remove from the redux store
      let deleteIndex = findIndex(newState[index].rules, (rule) => {
        return rule.id === action.data.response.tournamentRule.id;
      });

      newState[index].rules.splice(deleteIndex, 1);

      return newState;

    default:
      return state;
  }
};

export default tournamentReducer;
