import axios, { AxiosError, AxiosResponse } from 'axios';
// @ts-ignore: camelize is by definition an un-typeable Any => Any
import camelize from 'camelize';

import { ENVIRONMENT } from '@app/common/constants';
import { snakeCaseConverter } from '@app/common/helpers';
import { IErrors } from '@app/common/interfaces';
import {
  IResponseProgressionRoom,
  IProgressionRoomResponse,
  IResponseProgressionRoomVersionBinaryMapping,
  IResponseProgressionRoomVersion,
  IResponseProgressionRoomVersionLocale,
} from '@appEntryPoints/common/interfaces';
import { formatLocalesBodyImagesConditions } from '@appGameProgression/modules/EntryPoints/common/helpers';

export interface IFetchProgressionRoomBaseProps {
  gameId: string | number;
}

interface IFetchProgressionRoomWithEnv extends IFetchProgressionRoomBaseProps {
  env: ENVIRONMENT;
}

interface IDeleteProgressionRoomWithEnv extends IFetchProgressionRoomBaseProps {
  env: ENVIRONMENT;
  roomId: string;
}

type TResponseProgressionRooms = AxiosResponse<IProgressionRoomResponse<IResponseProgressionRoom>>;

interface IFetchProgressionRoomWithId extends IFetchProgressionRoomBaseProps {
  roomId: string;
  roomIdentifier: string;
}

interface ICreateProgressionRoom extends IFetchProgressionRoomBaseProps {
  name: string;
}

interface IUpdateProgressionRoom {
  gameId: string | number;
  name: string;
  productionRoomId: string;
  sandboxRoomId: string;
}

interface IFetchProgressionRoomVersionLocalesWithEnv extends IFetchProgressionRoomWithEnv {
  roomId: string;
  versionId: string;
}

type TResponseVersionLocale = AxiosResponse<
  IProgressionRoomResponse<IResponseProgressionRoomVersionLocale>
>;

type TResponseProgressionRoomMappings = AxiosResponse<
  IProgressionRoomResponse<IResponseProgressionRoomVersionBinaryMapping>
>;

interface IFetchProgressionRoomVersionsWithEnv extends IFetchProgressionRoomWithEnv {
  roomId: string;
  roomIdentifier: string;
  limit?: number;
}

interface IActivateProgressionRoom extends IFetchProgressionRoomWithEnv {
  roomId: string;
}

export const fetchProgressionRoomsRequest = async ({
  gameId,
  env,
}: IFetchProgressionRoomWithEnv) => {
  return axios
    .get(`/api/v1/${env}/games/${gameId}/progression_rooms`)
    .then(camelize)
    .then((response: TResponseProgressionRooms) => {
      return { ...response.data, env };
    })
    .catch((e) => {
      const error = e as AxiosError<IErrors>;

      throw new Error(error.response?.data.errors);
    });
};

export const fetchProgressionRoomMappingsRequest = async ({
  gameId,
  roomId,
  roomIdentifier,
}: IFetchProgressionRoomWithId) => {
  const env = ENVIRONMENT.PRODUCTION;

  return axios
    .get(`/api/v1/${env}/games/${gameId}/progression_rooms/${roomId}/mappings`)
    .then(camelize)
    .then((response: TResponseProgressionRoomMappings) => {
      return { ...response.data, env, currentRoomIdentifier: roomIdentifier };
    })
    .catch((e) => {
      const error = e as AxiosError<IErrors>;

      throw new Error(error.response?.data.errors);
    });
};

export const createProgressionRoomRequest = ({ gameId, name }: ICreateProgressionRoom) => {
  return axios
    .post(`/api/v1/games/${gameId}/progression_rooms`, snakeCaseConverter({ name }))
    .then(camelize)
    .catch((e) => {
      const error = e as AxiosError<IErrors>;

      throw new Error(error.response?.data.errors);
    });
};

export const editProgressionRoomRequest = ({
  gameId,
  name,
  productionRoomId,
  sandboxRoomId,
}: IUpdateProgressionRoom) => {
  return axios
    .patch(
      `/api/v1/games/${gameId}/progression_rooms`,
      snakeCaseConverter({ name, productionRoomId, sandboxRoomId })
    )
    .then(camelize)
    .catch((e) => {
      const error = e as AxiosError<IErrors>;

      throw new Error(error.response?.data.errors);
    });
};

export const fetchProgressionRoomVersionsRequest = async ({
  gameId,
  env,
  roomId,
  roomIdentifier,
  limit = 20,
}: IFetchProgressionRoomVersionsWithEnv) => {
  // hard coding sort since ordering since progressionRooms.env.versionRooms stores array of id in the sorted order
  const sort = '-created_at';

  return axios
    .get(`/api/v1/${env}/games/${gameId}/progression_rooms/${roomId}/versions`, {
      params: { limit: limit.toString(), sort },
    })
    .then(camelize)
    .then((response: AxiosResponse<IProgressionRoomResponse<IResponseProgressionRoomVersion>>) => {
      const formattedData = response.data.items.map((version) => {
        return { ...version, roomIdentifier };
      });

      return {
        items: formattedData,
        totalCount: response.data.totalCount,
        env,
        currentRoomIdentifier: roomIdentifier,
      };
    })
    .catch((e) => {
      const error = e as AxiosError<IErrors>;

      throw new Error(error.response?.data.errors);
    });
};

export const fetchProgressionRoomVersionLocalesRequest = async ({
  env,
  gameId,
  roomId,
  versionId,
}: IFetchProgressionRoomVersionLocalesWithEnv) => {
  return axios
    .get(`/api/v1/${env}/games/${gameId}/progression_rooms/${roomId}/versions/${versionId}/locales`)
    .then(camelize)
    .then((response: TResponseVersionLocale) => {
      const { totalCount, items } = response.data;
      const formattedItems = formatLocalesBodyImagesConditions(items);

      return {
        totalCount,
        items: formattedItems,
        env,
        currentVersionId: versionId,
      };
    })
    .catch((e) => {
      const error = e as AxiosError<IErrors>;

      throw new Error(error.response?.data.errors);
    });
};

export const activateProgressionRoomRequest = ({
  gameId,
  roomId,
  env,
}: IActivateProgressionRoom): Promise<AxiosResponse<unknown>> => {
  return axios
    .patch(
      `/api/v1/${env}/games/${gameId}/progression_rooms/activate`,
      snakeCaseConverter({ roomId })
    )
    .then(camelize)
    .catch((e) => {
      const error = e as AxiosError<IErrors>;

      throw new Error(error.response?.data.errors);
    });
};

export const deactivateProgressionRoomRequest = ({
  gameId,
  roomId,
  env,
}: IActivateProgressionRoom): Promise<AxiosResponse<unknown>> => {
  return axios
    .patch(`/api/v1/${env}/games/${gameId}/progression_rooms/${roomId}/deactivate`)
    .then(camelize)
    .catch((e) => {
      const error = e as AxiosError<IErrors>;

      throw new Error(error.response?.data.errors);
    });
};

export const deleteProgressionRoomRequest = async ({
  env,
  gameId,
  roomId,
}: IDeleteProgressionRoomWithEnv) => {
  return axios
    .delete(`/api/v1/${env}/games/${gameId}/progression_rooms/${roomId}`)
    .then(camelize)
    .catch((e) => {
      const error = e as AxiosError<IErrors>;

      throw new Error(error.response?.data.errors);
    });
};
