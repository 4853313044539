import merge from 'lodash/merge';
import { IActionNoData } from '@app/common/interfaces';
export interface IConfig {
  flow: string;
}

function configReducer(state: IConfig = { flow: '' }, action: IActionNoData): IConfig {
  let newState: IConfig;
  Object.freeze(state);
  switch (action.type) {
    default:
      newState = merge({}, state);
      newState.flow = window.location.pathname.split('/')[1];

      return newState;
  }
}
export default configReducer;
