import React from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';

import { PageHeader } from '@app/common/components';
import LoadingIndicator from '@app/modules/ContentManagementSystem/modules/LoadingIndicator/LoadingIndicator';
import { hasLaunchedToStore } from '@app/modules/Dashboard/helpers';

import { sortAndRenderLearningCenterCards, learningCenterSortComparator } from './helpers';
import { ILearningCenterCard, ILearningCenterCategory, TSortKey } from './interfaces';
import { useLearningCenterCategories, useLearningCenterCards, useGames } from './queries';

function BackButton() {
  const history = useHistory();

  const handleClick = () => {
    // https://stackoverflow.com/questions/37385570/how-to-know-if-react-router-can-go-back-to-display-back-button-in-react-app
    if (history.action === 'POP') {
      return history.push('/dashboard');
    }

    history.goBack();
  };

  return (
    <Button
      variant={'text'}
      startIcon={<ArrowBackIosIcon />}
      onClick={handleClick}
      data-qa={'back-button'}
    >
      Back
    </Button>
  );
}

const renderLearningCenterContent = (
  { data: categories }: AxiosResponse<ILearningCenterCategory[]>,
  { data: cards }: AxiosResponse<ILearningCenterCard[]>,
  cardsSortBy: keyof ILearningCenterCard,
  categoriesSortBy: keyof ILearningCenterCategory,
  shouldCardsMaintainAspectRation?: boolean
) => {
  return categories
    .sort(learningCenterSortComparator('asc', categoriesSortBy as TSortKey))
    .map((category) => (
      <Container key={category.id} sx={{ mb: 7, ml: 0, mr: 0 }} maxWidth={false}>
        <Typography variant={'h6'} sx={{ mb: 2 }}>
          {category.CategoryName}
        </Typography>
        <Grid container spacing={2} data-qa={`learning-center-cards-container-${category.id}`}>
          {/* cards should maintain aspect ratio in learning center only */}
          {sortAndRenderLearningCenterCards(
            category.id,
            cards,
            cardsSortBy,
            shouldCardsMaintainAspectRation
          )}
        </Grid>
      </Container>
    ));
};

export default function ManagedLearningCenterIndex() {
  const learningCenterCategoriesQuery = useLearningCenterCategories();
  const learningCenterCardsQuery = useLearningCenterCards();
  const gamesQuery = useGames();

  const learningCenterCategories = learningCenterCategoriesQuery.data;
  const learningCenterCards = learningCenterCardsQuery.data;
  const games = gamesQuery.data;

  if (!learningCenterCategories || !learningCenterCards || !games) {
    return (
      <Container sx={{ height: '100vh' }}>
        <LoadingIndicator />
      </Container>
    );
  }

  const cardsSortByColumn = hasLaunchedToStore(games.items)
    ? 'learningCenterNonFtueOrder'
    : 'learningCenterFtueOrder';

  return (
    <>
      <PageHeader title="Learning Center" id="learning_center_index_header" />
      <Container sx={{ mt: 4, mb: 4 }} maxWidth={false}>
        <BackButton />
      </Container>

      <Stack>
        {renderLearningCenterContent(
          learningCenterCategories,
          learningCenterCards,
          cardsSortByColumn,
          'Order',
          true
        )}
      </Stack>
    </>
  );
}
