import { SDK_DOWNLOADS } from '@app/common/actions';
import { IAction, ISdkDownloads } from '@app/common/interfaces';

type TReducerState = ISdkDownloads;

let initialState: TReducerState = {};

type TAction = IAction<ISdkDownloads>;

const sdkDownloadsReducer = (
  state: TReducerState = initialState,
  action: TAction
): TReducerState => {
  Object.freeze(state);

  switch (action.type) {
    case SDK_DOWNLOADS.RECEIVE:
      return action.data;
    default:
      return state;
  }
};

export default sdkDownloadsReducer;
