import * as React from 'react';

import './styles/subTextStyles.scss';

interface ITitle {
  className: string;
  title?: string | JSX.Element;
}

const SubText = ({ className, title, children }: React.PropsWithChildren<ITitle>): JSX.Element => {
  return <div className={`${className}__subtext subtext__text`}>{title || children}</div>;
};

export { SubText };
