import loadable from '@loadable/component';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import {
  RouteComponentProps,
  withRouter
} from 'react-router-dom';

import { openSnackbar } from '@app/common/actions';

const Playground = loadable(() => import(/* webpackChunkName: "playground-old" */ './Playground'));

const mapDispatchToProps = {
  openSnackbar
};

const connector = connect(null, mapDispatchToProps);

export type TPlayGroundOldConnectedProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(withRouter(Playground));
