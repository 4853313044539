import loadable from '@loadable/component';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  closeProfileModal,
  fetchPublisher,
  findUser,
  openSnackbar,
  fetchPermissions
} from '@app/common/actions';
import { IRootState } from '@app/core/rootReducer';

const ProfileModal = loadable(
  () => import(/* webpackChunkName: "profile-modal" */ './ProfileModal')
);

const mapStateToProps = ({
  publisher: { publisherInfo },
  user,
  modalManagement: { isProfileModalOpen },
  feature,
  permissions
}: IRootState) => {
  return {
    publisher: publisherInfo,
    user,
    isProfileModalOpen,
    feature,
    permissions
  };
};

const mapDispatchToProps = {
  closeProfileModal,
  fetchPublisher,
  findUser,
  openSnackbar,
  fetchPermissions
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TProfileModalConnectedProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(withRouter(ProfileModal));
