import * as React from 'react';

import { IDataAttributes } from 'portal-common-library/interfaces/dataAttributes';

import './styles/atomLinkButtonStyles.scss';

export interface IAtomLinkButtonProps {
  id: string;
  text: string;
  onClick: () => void;
  dataAttributes?: IDataAttributes;
}

class AtomLinkButton extends React.PureComponent<IAtomLinkButtonProps> {
  public render(): JSX.Element {
    let { text, onClick, id, dataAttributes } = this.props;

    return (
      <p
        className="atom-component__link-button"
        onClick={onClick}
        id={id}
        data-qa={dataAttributes?.['data-qa']}
      >
        {text}
      </p>
    );
  }
}

export { AtomLinkButton };
