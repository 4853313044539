import * as React from 'react';

import LaunchIcon from '@mui/icons-material/Launch';

import { IBaseProps } from '../../interfaces/componentBase';
import './styles.scss';

export interface IAtomLinkProps extends IBaseProps {
  title: string;
  url: string;
  icon?: boolean;
  newTab?: boolean;
  download?: string;
  onClick?: () => void;
}

/**
 * @param {string} id - html id value
 * @param {Object} dataAttributes - Object with data-qa attribute used for automation.
 * @param {string} title - the text used for the link
 * @param {string} url
 * @param {boolean} [icon=true] - (optional) if launch icon is visible. Default true
 * @param {boolean} [newTab=true] - (optional) if the link opens in a new tab. Default true
 * @param {function} onClick - (option) On click listener
 * @returns A link component
 * @example
 * <AtomLink
 *   id="some-id"
 *   title="Link"
 *   url="https://url.com"
 *   dataAttributes={{ 'data-qa': 'atom-link' }}
 * />
 */
function AtomLink({
  title,
  url,
  id,
  dataAttributes,
  icon = true,
  newTab = true,
  download = undefined,
  onClick = undefined,
}: IAtomLinkProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      className="atom-component__link"
      {...dataAttributes}
      id={id}
      onClick={
        onClick ??
        (() => {
          return;
        })
      }
      href={url}
      target={newTab ? '_blank' : ''}
      rel={newTab ? 'noopener' : ''}
      download={download}
    >
      <span className="atom-component__link-title">{title}</span>
      {icon && (
        <span className="atom-component__link-icon">
          <LaunchIcon />
        </span>
      )}
    </a>
  );
}

export default AtomLink;
