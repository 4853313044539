import loadable from '@loadable/component';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import {
  closeArchiveModal,
  updateGameEnabled
} from '@app/common/actions';
import {
  IAction,
  IActionNoData,
  IGameEnabled
} from '@app/common/interfaces';
import { IRootState } from '@app/core/rootReducer';

import {
  IArchiveModalOwnProps,
  IDispatchProps
} from './interfaces';

const ArchiveModalContainer = loadable(
  () => import(/* webpackChunkName: "common-component-archive-modal" */ './ArchiveModal')
);

const mapStateToProps = (state: IRootState, ownProps: IArchiveModalOwnProps) => {
  return {
    isArchiveModalOpen: state.modalManagement.isArchiveModalOpen,
    gameId: ownProps.gameId
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, unknown, IActionNoData | IAction<IGameEnabled>>
): IDispatchProps => {
  return {
    closeArchiveModal: (): IActionNoData => dispatch(closeArchiveModal()),
    updateGameEnabled: (game: IGameEnabled): Promise<void> => dispatch(updateGameEnabled(game))
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TArchiveModalConnectedProps = ConnectedProps<typeof connector>;

export const ArchiveModal = connector(withRouter(ArchiveModalContainer));
