import * as React from 'react';

import { InputProps as StandardInputProps } from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';

import { IBaseProps } from '../../interfaces/componentBase';
import variables from '../../styles/core/variables.scss';
import './styles.scss';

export interface IAtomTextFieldProps extends IBaseProps {
  attributeName: string;
  autoComplete: string;
  isAutoFocused: boolean;
  isDisabled: boolean;
  errorText: string;
  isFullWidth: boolean;
  isMultiline: boolean;
  hasAnimation: boolean;
  hasError: boolean;
  inputProps?: StandardInputProps['inputProps'];
  InputProps?: Partial<StandardInputProps>;
  label: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
  required: boolean;
  // we need to use `minRows` & `maxRows` moving forward to align with Material UI interfaces
  rows: number;
  rowsMax?: number;
  minRows?: number;
  maxRows?: number;
  type: string;
  value: unknown;
  variant: 'outlined' | 'standard' | 'filled';
}

type TDefaultPropKeys =
  | 'autoComplete'
  | 'isAutoFocused'
  | 'errorText'
  | 'hasAnimation'
  | 'hasError'
  | 'isDisabled'
  | 'isFullWidth'
  | 'isMultiline'
  | 'onKeyPress'
  | 'required'
  | 'rows'
  | 'type'
  | 'variant';

type TDefaultProps = Required<Pick<IAtomTextFieldProps, TDefaultPropKeys>>;

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: `${variables.colorBrandBlue}`,
      dark: `${variables.colorBrandBlue}`,
    },
  },
});

class AtomTextField extends React.PureComponent<IAtomTextFieldProps> {
  public static defaultProps: TDefaultProps = {
    autoComplete: 'on',
    isAutoFocused: false,
    errorText: '',
    hasAnimation: false,
    hasError: false,
    isDisabled: false,
    isFullWidth: true,
    isMultiline: false,
    onKeyPress: (): void => undefined,
    required: true,
    rows: 1,
    type: 'text',
    variant: 'outlined',
  };

  public render(): JSX.Element {
    let {
      id,
      autoComplete,
      isAutoFocused,
      hasAnimation,
      isDisabled,
      hasError,
      isFullWidth,
      errorText,
      inputProps,
      InputProps,
      label,
      isMultiline,
      attributeName,
      onBlur,
      onChange,
      onKeyPress,
      placeholder,
      rows,
      rowsMax,
      type,
      value,
      variant,
      dataAttributes,
      minRows,
      maxRows,
    } = this.props;

    const hasNoLabel = label === '' || label === undefined;

    const classNames = `${!hasAnimation ? '' : value ? 'expand' : 'animated'} ${
      hasNoLabel ? 'no-label' : ''
    }`;

    return (
      <ThemeProvider theme={theme}>
        <TextField
          id={id}
          autoComplete={autoComplete}
          autoFocus={isAutoFocused}
          className={`atom-component__text-field-container ${classNames}`}
          disabled={isDisabled}
          error={hasError}
          fullWidth={isFullWidth}
          helperText={errorText}
          inputProps={inputProps}
          InputProps={InputProps}
          label={hasNoLabel ? '\u200B' : label}
          margin="dense"
          multiline={isMultiline}
          name={attributeName}
          onBlur={onBlur}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          minRows={minRows ?? rows}
          maxRows={maxRows ?? rowsMax}
          type={type}
          value={value}
          variant={variant}
          {...dataAttributes}
        />
      </ThemeProvider>
    );
  }
}

export default AtomTextField;
