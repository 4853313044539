import axios, { AxiosResponse } from '@app/common/setup/axiosWithHeader';
import { Dispatch } from 'redux';
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import { snakeCaseConverter } from '@app/common/helpers';
import { TThunkAction, TDispatch, IAction } from '@app/common/interfaces';
import { ISdkDownloadInfo, ISdkDownloadBody } from '@app/modules/GameManagement/common/interfaces';

export enum SDK_DOWNLOAD_INFO {
  RECEIVE = 'sdkDownloadInfo/RECEIVE'
}

type TReceiveSdkDownloadInfo = (data: ISdkDownloadInfo) => IAction<ISdkDownloadInfo>;

export const receiveSdkDownloadInfo: TReceiveSdkDownloadInfo = (
  data: ISdkDownloadInfo
): IAction<ISdkDownloadInfo> => {
  return {
    type: SDK_DOWNLOAD_INFO.RECEIVE,
    data
  };
};

export const createSdkDownload: TThunkAction = (data: ISdkDownloadBody): TDispatch => {
  // TODO REPLACE [WS-10403] remove the below disable and fix error
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (dispatch: Dispatch<any>): any => {
    return axios({
      url: `/api/v1/sdk_downloads`,
      method: 'post',
      data: snakeCaseConverter(data)
    }).then((response: AxiosResponse<ISdkDownloadInfo>) => {
      return dispatch(receiveSdkDownloadInfo(camelize(response.data)));
    });
  };
};

export const fetchGameLatestSdkDownload: TThunkAction = (gameId: number): TDispatch => (
  dispatch: Dispatch<any>
): any => {
  /* eslint-enable @typescript-eslint/no-explicit-any */
  return axios({
    url: `/api/v1/games/${gameId}/sdk_downloads`,
    method: 'get'
  }).then((response: AxiosResponse<ISdkDownloadInfo>) => {
    return dispatch(receiveSdkDownloadInfo(camelize(response.data)));
  });
};
