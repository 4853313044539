import * as React from 'react';

import LaunchIcon from '@mui/icons-material/Launch';

import './styles/atomLinkStyles.scss';

export interface IAtomLinkProps {
  id: string;
  title: string;
  url: string;
  icon?: boolean;
  newTab?: boolean;
}

// icon is default to true => mostly for external link => newtab is default to true
// icon for internal links should pass in newtab to be false
// icon for in-sentence links should pass in icon to be false
function AtomLink({ title, url, id, icon = true, newTab = true }: IAtomLinkProps): JSX.Element {
  return (
    <a
      className="atom-component__link"
      id={id}
      href={url}
      target={newTab ? '_blank' : ''}
      rel={newTab ? 'noopener' : ''}
    >
      <span className="atom-component__link-title">{title}</span>
      {icon && (
        <span className="atom-component__link-icon">
          <LaunchIcon />
        </span>
      )}
    </a>
  );
}

export { AtomLink };
