import * as React from 'react';

import PhotoSizeSelectActual from '@mui/icons-material/PhotoSizeSelectActual';

import { format } from 'date-fns';
import { RouteComponentProps } from 'react-router-dom';

import { AtomMenu, AtomMetaTag, IMenuItem } from '@app/common/atoms';
import {
  DEFAULT_MENU_ITEMS,
  MENU_ITEM_ATTRIBUTES,
  MENU_ITEM_COPY,
  STATUS,
} from '@app/common/constants';
import { IDashboardGame, TMouseEventDiv } from '@app/common/interfaces';
import kabobMenu from '@app/imageAssets/kabobMenu.svg';

import { TooltipNoAccess } from './TooltipNoAccess';
import './styles/gameObjectCardStyles.scss';

export interface IGameObjectCardProps {
  id: string;
  game: IDashboardGame;
  cardMenuItems?: IMenuItem[];
  handleMenuSelection: (attributeName: string, gameId: number) => void;
  disabled?: boolean;
}

export interface IGameObjectCardState {
  anchorElement: Element | null;
  isMenuOpen: boolean;
}

export type TGameObjectCardProps = IGameObjectCardProps & Pick<RouteComponentProps, 'history'>;
export type TGameObjectCardDefaultProps = Required<Pick<IGameObjectCardProps, 'cardMenuItems'>>;

export enum META_TAG_TEXT {
  PRIZE_ENABLED = 'Prize enabled',
  LAUNCHED = 'Launched',
  IN_PROGRESS = 'In-progress',
  ARCHIVED = 'Archived',
}

class GameObjectCard extends React.Component<TGameObjectCardProps, IGameObjectCardState> {
  constructor(props: TGameObjectCardProps) {
    super(props);

    this.state = {
      anchorElement: null,
      isMenuOpen: false,
    };
  }

  public static defaultProps: TGameObjectCardDefaultProps = {
    cardMenuItems: DEFAULT_MENU_ITEMS,
  };

  public handleKabobClick = (event: TMouseEventDiv): void => {
    this.setState({ isMenuOpen: true, anchorElement: event.currentTarget });
    event.stopPropagation();
  };

  public handleCloseMenu = (event: TMouseEventDiv): void => {
    event.stopPropagation();
    this.setState({ isMenuOpen: false, anchorElement: null });
  };

  public handleMenuItemClick: (attributeName: string) => () => void = (
    attributeName: string
  ): (() => void) => {
    return (): void => {
      let { game, handleMenuSelection } = this.props;
      handleMenuSelection(attributeName, game.id);
      this.setState({ isMenuOpen: false, anchorElement: null });
    };
  };

  private readonly handleCardClick =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (url: string, isDisabled?: boolean) => (_event: TMouseEventDiv) => {
      if (isDisabled) return;
      this.props.history.push(url);
    };

  public determineMenuItems(): IMenuItem[] {
    let {
      game: { enabled: isEnabled, live: isLive },
      cardMenuItems,
    } = this.props;
    let menuOptions: IMenuItem[] = [
      {
        attributeName: MENU_ITEM_ATTRIBUTES.MANAGE,
        itemBody: MENU_ITEM_COPY.MANAGE,
        handleItemClick: this.handleMenuItemClick(MENU_ITEM_ATTRIBUTES.MANAGE),
      },
    ];

    if (cardMenuItems && cardMenuItems !== DEFAULT_MENU_ITEMS) {
      return cardMenuItems;
    }

    if (!isEnabled) {
      menuOptions = [
        {
          attributeName: MENU_ITEM_ATTRIBUTES.UNARCHIVE,
          itemBody: MENU_ITEM_COPY.UNARCHIVE,
          handleItemClick: this.handleMenuItemClick(MENU_ITEM_ATTRIBUTES.UNARCHIVE),
        },
      ];
    } else if (isLive !== STATUS.COMPLETED) {
      menuOptions.push({
        attributeName: MENU_ITEM_ATTRIBUTES.ARCHIVE,
        itemBody: MENU_ITEM_COPY.ARCHIVE,
        handleItemClick: this.handleMenuItemClick(MENU_ITEM_ATTRIBUTES.ARCHIVE),
      });
    }

    return menuOptions;
  }

  public determineTagChipText(): META_TAG_TEXT {
    let {
      game: { enabled: isEnabled, live: isLive, cashLive: isCashLive },
    } = this.props;

    if (!isEnabled) return META_TAG_TEXT.ARCHIVED;
    if (isLive !== STATUS.COMPLETED) return META_TAG_TEXT.IN_PROGRESS;
    if (!isCashLive) return META_TAG_TEXT.LAUNCHED;

    return META_TAG_TEXT.PRIZE_ENABLED;
  }

  public renderGameIconOrBlankIcon(): JSX.Element {
    let { id, game } = this.props;

    if (game.iconUrl) {
      return <img className="game-object-card__icon" src={game.iconUrl} />;
    }

    return (
      <div className="game-object-card__blank-container">
        <PhotoSizeSelectActual
          id={`${id}-object-card-blank-icon`}
          className="game-object-card__blank-container-icon"
        />
      </div>
    );
  }

  public render(): JSX.Element {
    let { id, game, disabled: isDisabled } = this.props;
    let { anchorElement, isMenuOpen } = this.state;

    let additionalClasses = '';

    if (!game.enabled) additionalClasses += '-archived';
    if (isMenuOpen) additionalClasses += ' raised';
    if (isDisabled) additionalClasses += ' disabled';

    return (
      <React.Fragment>
        <TooltipNoAccess active={isDisabled}>
          <div
            id={id}
            className={`game-object-card__container${additionalClasses}`}
            onClick={this.handleCardClick(`/games/${game.id}/home`, isDisabled)}
          >
            <div className="game-object-card__content-section">
              {this.renderGameIconOrBlankIcon()}
              <div className="game-object-card__content-section-text">
                <p className="game-object-card__game-name">{game.title}</p>
                <p className="game-object-card__updated-at">{`Last updated: ${format(
                  new Date(game.updatedAt),
                  'MM/dd/yyyy'
                )}`}</p>
                <AtomMetaTag
                  id="game-object-card__meta-tag"
                  chipText={this.determineTagChipText()}
                />
              </div>
            </div>
            {!isDisabled && (
              <div
                onClick={this.handleKabobClick}
                id={`game-object-card__menu-section-${game.id}`}
                className={`game-object-card__menu-section ${isMenuOpen ? 'active' : ''}`}
                data-qa={`game-object-card__menu-section`}
              >
                <img src={kabobMenu} className="game-object-card__kabob-menu-icon" />
              </div>
            )}
          </div>
        </TooltipNoAccess>

        <AtomMenu
          id={`game-object-card__atom-menu-${id}`}
          menuItems={this.determineMenuItems()}
          anchorEl={anchorElement}
          isOpen={isMenuOpen}
          handleCloseMenu={this.handleCloseMenu}
        />
      </React.Fragment>
    );
  }
}

export { GameObjectCard };
