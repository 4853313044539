import * as React from 'react';

// @ts-ignore: camelize package is not typed
import camelize from 'camelize';

import { fetchPublicGameApiCall } from '@app/common/actions';
import { makeCancelable } from '@app/common/helpers';
import {
  IWithSnackbarProp,
  snackbarErrorMessage,
  WithSnackbar,
} from '@app/common/helpers/snackbarHookHelper';
import { IPublicGame } from '@app/common/interfaces';

import './styles/bestPracticeCardStyles.scss';

export interface IBestPracticeCardProps {
  id: string;
  gameProductionId: number;
}
export interface IBestPracticeCardState {
  game: IPublicGame | null;
}

// TODO MOVE [WS-10322] to Dashboard folder since it is not used elsewhere
class BestPracticeCard extends React.Component<
  IBestPracticeCardProps & IWithSnackbarProp,
  IBestPracticeCardState
> {
  private cancelFetch: undefined | (() => void);

  constructor(props: IBestPracticeCardProps & IWithSnackbarProp) {
    super(props);
    this.state = {
      game: null,
    };
  }

  public async componentDidMount(): Promise<void> {
    const { gameProductionId } = this.props;
    try {
      const cancelableFetch = makeCancelable(fetchPublicGameApiCall(gameProductionId));
      this.cancelFetch = cancelableFetch.cancel;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const game = camelize((await cancelableFetch.promise).data) as IPublicGame;
      this.setState({ game });
    } catch (error) {
      this.props.snackbar.show(snackbarErrorMessage(error), 'error');
    }
  }

  public componentWillUnmount() {
    if (this.cancelFetch) {
      this.cancelFetch();
    }
  }

  public render(): JSX.Element | null {
    const { id, gameProductionId } = this.props;
    const { game } = this.state;

    if (!game) return null;

    return (
      <a
        id={`${id}-best-practice-card-link`}
        href={`https://games.skillz.com/game/${gameProductionId}`}
        target="_blank"
        rel="noopener"
      >
        <div id={id} className="best-practice-card__container">
          <p className="best-practice-card__game-genre">{game.genre}</p>
          <div className="best-practice-card__content-section">
            <div className="best-practice-card__content-section-column">
              {game.iconUrl && <img className="best-practice-card__icon" src={game.iconUrl} />}
            </div>
            <div className="best-practice-card__content-section-column">
              <p className="best-practice-card__game-name">{game.title}</p>
              <p className="best-practice-card__game-description">{game.tagline}</p>
            </div>
          </div>
        </div>
      </a>
    );
  }
}

const component = WithSnackbar(BestPracticeCard);
export { component as BestPracticeCard, BestPracticeCard as BestPracticeCardComponent };
