import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useSnackbarContext } from '@skillzet/components';

import { Cookies } from 'react-cookie';
import ReactMarkdown from 'react-markdown';
import { useMutation, useQueryClient } from 'react-query';

import { TIME_IN_MILLISECONDS } from '@app/common/constants';

import '../Notifications/styles/notificationModal.scss';
import { updatePublisherRevenueModelAgreedOn, usePublisherQuery } from './actions';
import {
  revenueModelNotificationContent,
  revenueModelNotificationCheckboxLabel,
  revenueModelNotificationAgreedSnackbar,
  WithNoActionModalHeader,
  WithActionModalHeader,
  COOKIE_REVENUE_MODEL_CHANGE,
} from './constants';
import {
  shouldRenderNotificationWithAction,
  shouldRenderNotificationWithNoAction,
  shouldRenderRevenueModelNotification,
} from './helpers';
import './styles.scss';

interface IProps {
  reduxFetchPublisher: () => Promise<unknown>;
}

const staleTime = 10 * TIME_IN_MILLISECONDS.MINUTE;
const reOpenTime = 10 * TIME_IN_MILLISECONDS.MINUTE;

export function RevenueModelNotification({ reduxFetchPublisher }: IProps) {
  const [isOpen, setIsOpen] = useState(true);
  const [isAgreeChecked, setIsAgreeChecked] = useState(false);

  const [isAtBottom, setIsAtBottom] = useState(false);

  // react query doesn't have isRefetched state
  const [isRefetched, setIsRefetched] = useState(false);
  const { show: showSnackbar } = useSnackbarContext();

  const queryClient = useQueryClient();

  const theme = useTheme();

  const cookies = new Cookies();

  const publisherQuery = shouldRenderNotificationWithNoAction()
    ? usePublisherQuery({ staleTime: Infinity })
    : usePublisherQuery({ staleTime });

  const closeModal = () => setIsOpen(false);

  const openModal = () => setIsOpen(true);

  const openModalTimeout = () => {
    if (!publisherQuery.data?.revenueModelAgreedOn && !isOpen) {
      openModal();
    }
  };

  const handleClose = (): void => {
    setIsAtBottom(false);
    setIsAgreeChecked(false);

    if (shouldRenderNotificationWithNoAction()) {
      cookies.set(COOKIE_REVENUE_MODEL_CHANGE, true, {
        path: '/',
      });
    }

    closeModal();
  };

  const handleCheckboxClick = (): void => {
    setIsAgreeChecked(!isAgreeChecked);
  };

  // const handleDialogClose = (event: unknown, reason: string): void => {
  //   if (reason === 'escapeKeyDown') {}
  //   if (reason === 'backdropClick') {}
  //   closeModal();
  // };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleScroll = (e: any) => {
    // set to true once been scroll to the bottom
    if (isAtBottom) {
      return;
    }

    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    const hasReachedBottom =
      e.target.scrollHeight - e.target.scrollTop < (e.target.clientHeight as number) + 1 &&
      e.target.scrollHeight - e.target.scrollTop > (e.target.clientHeight as number) - 1;
    /* eslint-enable @typescript-eslint/no-unsafe-member-access */

    setIsAtBottom(hasReachedBottom);
  };

  const updatePublisherMutation = useMutation(
    () => {
      return updatePublisherRevenueModelAgreedOn(publisherQuery.data?.id ?? 0);
    },
    {
      onSuccess: () => {
        cookies.set(COOKIE_REVENUE_MODEL_CHANGE, true, {
          path: '/',
        });

        showSnackbar(revenueModelNotificationAgreedSnackbar);
        closeModal();
      },
      onError: (error: Error) => {
        showSnackbar(`An error occurred: ${error.message}. Please try again.`, 'error');
      },
      onSettled: async () => {
        void reduxFetchPublisher();
        await queryClient.invalidateQueries(['publisher']);
      },
    }
  );

  useEffect(() => {
    if (
      !isOpen &&
      !!publisherQuery.data &&
      shouldRenderNotificationWithAction(publisherQuery.data)
    ) {
      const timer = setTimeout(openModalTimeout, reOpenTime);

      return () => clearTimeout(timer);
    }

    return;
  }, [isOpen]);

  useEffect(() => {
    if (isRefetched && !publisherQuery.data?.revenueModelAgreedOn && !isOpen) {
      openModal();
    }
  }, [isRefetched]);

  // userQuery does not have a isRefetched prop
  useEffect(() => {
    if (isOpen) {
      return;
    }

    if (publisherQuery.isRefetching && isRefetched) {
      setIsRefetched(false);
    }

    if (!publisherQuery.isRefetching && !isRefetched) {
      setIsRefetched(true);
    }
  }, [publisherQuery.isRefetching]);

  /* eslint-disable react/prop-types */
  const renderLinks = (props: { href: string; children: string }): JSX.Element => {
    if (!props.href.startsWith('http')) {
      return (
        <a href={props.href} className="atom-component__link">
          {props.children}
        </a>
      );
    }

    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a href={props.href} rel="nofollow noopener" target="_blank" className="atom-component__link">
        {props.children}
      </a>
    );
  };
  /* eslint-enable react/prop-types */

  /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access*/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderItems = (props: { children: any[] }) => {
    // if item starts with image, use it as the list icon
    // this avoids writing custom CSS for every notification
    // eslint-disable-next-line react/prop-types
    let children = props.children;
    // eslint-disable-next-line react/prop-types
    const img = children[0]?.props?.src;
    let style = {};
    if (img) {
      style = {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        listStyleImage: `url(${img})`,
      };
      // eslint-disable-next-line react/prop-types
      children = props.children.slice(1);
    }

    return <li style={style}>{children}</li>;
  };
  /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderImages = (props: any) => {
    return <img {...props} />;
  };

  if (
    publisherQuery.isLoading ||
    !!cookies.get(COOKIE_REVENUE_MODEL_CHANGE) ||
    (!!publisherQuery.data && !shouldRenderRevenueModelNotification(publisherQuery.data))
  ) {
    return null;
  }

  if (!isOpen) {
    return (
      <div
        className="revenue-model-notification__hidden"
        style={{ visibility: 'hidden', height: 0 }}
      >
        to allow publisher query to stay alive
      </div>
    );
  }

  return (
    <Dialog
      id="notifications"
      open={isOpen}
      data-qa="notifications__modal"
      className="revenue-model-notification__dialog"
      // dialog default is 1300
      style={{ zIndex: 1350 }}
    >
      <DialogTitle sx={{ textAlign: 'left' }}>
        {shouldRenderNotificationWithNoAction() && <WithNoActionModalHeader theme={theme} />}
        {!shouldRenderNotificationWithNoAction() && <WithActionModalHeader theme={theme} />}
      </DialogTitle>
      <Divider />
      <DialogContent className="notifications__modal-body" onScroll={handleScroll}>
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant="h5">Developer Terms and Conditions of Service</Typography>
          <Typography
            sx={{
              color: '#717B8F',
              fontSize: 16,
              fontWeight: 'fontWeightMedium',
              fontStyle: 'italic',
            }}
          >
            Updated as of April 14th, 2023
          </Typography>
          <Typography sx={{ color: '#717B8F', fontSize: 14 }}>
            Hello and welcome to Skillz. We&quot;re glad to have you on our team. These Developer
            Terms and Conditions of Service&nbsp;
            <span
              style={{
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: '14px',
                display: 'inline',
              }}
            >
              (“Developer Terms”)
            </span>
            &nbsp;will help ensure we&quot;re all able to achieve more together.
          </Typography>
        </Box>
        <ReactMarkdown
          source={revenueModelNotificationContent}
          renderers={{
            link: renderLinks,
            listItem: renderItems,
            image: renderImages,
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          width: '100%',
          display: 'inline-flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack width={'100%'}>
          {!shouldRenderNotificationWithNoAction() && (
            <FormGroup>
              <FormControlLabel
                onChange={handleCheckboxClick}
                disabled={!isAtBottom}
                control={
                  <Checkbox
                    checked={isAgreeChecked}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  />
                }
                label={revenueModelNotificationCheckboxLabel}
              />
            </FormGroup>
          )}
          <div className="notifications__modal-body-action" style={{ marginBottom: 0 }}>
            <div className="notifications__modal-button-secondary">
              <Button
                id="notifications__modal-cta-button-secondary"
                data-qa="notifications__modal-cta-secondary"
                onClick={handleClose}
                variant="outlined"
              >
                Close
              </Button>
            </div>
            {!shouldRenderNotificationWithNoAction() && (
              <div className="notifications__modal-button-primary">
                <Button
                  id="notifications__modal-cta-button-primary"
                  data-qa="notifications__modal-cta-primary"
                  onClick={() => updatePublisherMutation.mutate()}
                  variant="contained"
                  disabled={!isAgreeChecked}
                >
                  I Agree
                </Button>
              </div>
            )}
          </div>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
