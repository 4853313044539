// TODO UPDATE [WS-10976] remove eslint comments & fix issues

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable react/display-name */
import * as React from 'react';

import { AxisProps } from '@nivo/axes';
import { BarExtendedDatum, Data, TooltipProp as TooltipPropBar } from '@nivo/bar';
import { LineSvgProps, Point, Serie, SliceTooltipProps } from '@nivo/line';

import { ResponsiveBarChart, ResponsiveLineChart } from '@app/common/components';
import { ANALYTICS_BENCHMARK_LABELS, CHART_TYPE, MATH_OPERATIONS } from '@app/common/constants';
import { formatDate, normalizeGraph } from '@app/common/helpers';
import { IChartKeys, ITempData, ITotalCount } from '@app/common/interfaces';

export function xAxisLabelFormatter(startDate: Date, endDate: Date): string {
  // if either date picker is being updated via keyboard inputs then make sure the
  // page doesn't break while edits are being made
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return '';
  }

  let formattedStartDate: string = formatDate(startDate.toISOString().split('T')[0]);
  let formattedEndDate: string = formatDate(endDate.toISOString().split('T')[0]);

  return `${formattedStartDate} - ${formattedEndDate}`;
}

export function yAxisLabelFormatter(value: number): string {
  // suffixes is the standard abbreviations for large numbers
  let suffixes: string[] = ['', 'k', 'm', 'b', 't'];

  // we take the absolute value because when the actual value of the data points is 0
  // the default y-axis labels seem to be negative, which throws off the rest of our calculations
  let absoluteValue: number = Math.abs(value);

  // just return with the proper precision if the absoluteValue is a float less than 1
  if (absoluteValue < 1) return `${parseFloat(absoluteValue.toPrecision(2))}`;

  // suffixNum is the power of 1000 and also the index number in suffixes
  // relating to that power (eg. 1000 is 1, 1000000 is 2, etc)
  let suffixNum: number = Math.floor((`${Math.abs(absoluteValue)}`.length - 1) / 3);

  // the shortValue is a float derived from the first two digits of the
  // large number (eg. 1000 is 1.0, 4300000 is 4.3, etc)
  let shortValue: number = parseFloat(
    (suffixNum !== 0 ? absoluteValue / Math.pow(1000, suffixNum) : absoluteValue).toPrecision(2)
  );

  // this combines the shortValue with the appropriate suffix (eg. 4300000 is 4.3m)
  return `${shortValue}${suffixes[suffixNum]}`;
}

/** returns the function used to render a custom tooltip
 * @params { (value: number | string) => string } formatter - function called on the yFormatted value to format
 * the data on the tooltip, the formatter will be: formatCurrency | formatWholeNumber | formatWholeNumberFromDecimal |
 * formatPercentage
 * @returns { JSX.Element } - returns the formatted tooltip
 */
export const renderLineChartTooltip = (formatter: (value: number | string) => string) => {
  return ({ slice }: SliceTooltipProps): JSX.Element => {
    return (
      <div
        style={{
          background: 'white',
          padding: '9px 12px',
          border: '1px solid #ccc',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <span style={{ marginBottom: '5px', fontWeight: '500' }}>
          {formatDate(slice.points[0].data.xFormatted as string)}
        </span>
        {slice.points.map((point: Point) => (
          <div
            id={`point-${point.serieId}-${formatter(point.data.yFormatted)}`}
            key={`point-${point.serieId}-${formatter(point.data.yFormatted)}`}
            style={{
              color: point.serieColor,
              padding: '3px 0',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <span style={{ marginRight: '10px' }}>{point.serieId}</span>
            <span style={{ fontWeight: '500' }}>{formatter(point.data.yFormatted)}</span>
          </div>
        ))}
      </div>
    );
  };
};

/** returns the function used to render a custom tooltip
 * @params { (value: number) => string } formatter - function called on the indexValue
 * the data on the tooltip, the formatter will be: formatCurrency | formatWholeNumber | formatWholeNumberFromDecimal |
 * formatPercentage
 * @returns { React.ReactNode } - returns the formatted tooltip
 */
export function renderBarChartTooltip(
  formatter: (value: number) => string
): (data: BarExtendedDatum) => React.ReactNode {
  return ({ id, value, color, indexValue }: BarExtendedDatum): React.ReactNode => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        <span style={{ marginBottom: '5px', fontWeight: '500' }}>
          {formatDate(indexValue as string)}
        </span>
        <div
          id={`point-${id}-${formatter(value)}`}
          key={`point-${id}-${formatter(value)}`}
          style={{
            color,
            padding: '3px 0',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ marginRight: '10px' }}>{id}</span>
          <span style={{ fontWeight: '500' }}>{formatter(value)}</span>
        </div>
      </div>
    );
  };
}

export function renderBarChart(
  keysObj: IChartKeys,
  colors: string[],
  startDate: Date,
  endDate: Date,
  filteredData: ITempData<ITotalCount>,
  axisLeft?: AxisProps | null,
  formatter?: (value: number) => string
): JSX.Element {
  let xAxisDates: string = xAxisLabelFormatter(startDate, endDate);
  let barKeys: string[] = Object.keys(keysObj);

  if (formatter) {
    return (
      <ResponsiveBarChart
        data={
          normalizeGraph(CHART_TYPE.BAR, filteredData, keysObj, MATH_OPERATIONS.SUM) as Data['data']
        }
        keys={barKeys}
        colors={colors}
        xAxisDates={xAxisDates}
        axisLeft={axisLeft}
        tooltip={renderBarChartTooltip(formatter) as TooltipPropBar}
      />
    );
  }

  return (
    <ResponsiveBarChart
      data={
        normalizeGraph(CHART_TYPE.BAR, filteredData, keysObj, MATH_OPERATIONS.SUM) as Data['data']
      }
      keys={barKeys}
      colors={colors}
      xAxisDates={xAxisDates}
      axisLeft={axisLeft}
    />
  );
}

interface ILineChartProps {
  keysObj: IChartKeys;
  colors: string[];
  mathOperation: MATH_OPERATIONS;
  dateRangeArray: string[];
  startDate: Date;
  endDate: Date;
  filteredData: ITempData<ITotalCount>;
  axisLeft?: AxisProps;
  formatter?: (value: number) => string;
  benchmarkValue?: number;
  benchmarkLabel?: ANALYTICS_BENCHMARK_LABELS;
  propOverrides?: Partial<LineSvgProps>;
}

export function renderLineChart({
  keysObj,
  colors,
  mathOperation,
  dateRangeArray,
  startDate,
  endDate,
  filteredData,
  axisLeft,
  formatter,
  benchmarkValue,
  benchmarkLabel,
  propOverrides,
}: ILineChartProps): JSX.Element {
  let xAxisDates: string = xAxisLabelFormatter(startDate, endDate);
  let lineGraphData: Serie[] = normalizeGraph(
    CHART_TYPE.LINE,
    filteredData,
    keysObj,
    mathOperation,
    dateRangeArray
  ) as Serie[];

  if (!!benchmarkValue && !!benchmarkLabel) {
    lineGraphData.push(addBenchmarkLine(benchmarkValue, dateRangeArray, benchmarkLabel));
  }

  return (
    <ResponsiveLineChart
      data={lineGraphData}
      colors={colors}
      xAxisDates={xAxisDates}
      axisLeft={axisLeft}
      sliceTooltip={renderLineChartTooltip(formatter as any)}
      propOverrides={propOverrides}
    />
  );
}

export function addBenchmarkLine(
  value: number,
  dateRangeArray: string[],
  benchmarkLabel: ANALYTICS_BENCHMARK_LABELS
): Serie {
  let benchMarkLine: Serie = {
    id: benchmarkLabel,
    data: [],
  };

  for (let date of dateRangeArray) {
    benchMarkLine.data.push({ x: date, y: value });
  }

  return benchMarkLine;
}

export function isEstimatedRevenueEnabled(): boolean {
  return window.appConfig.featureFlags.estimatedRevenueChart;
}
