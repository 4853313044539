import { VIRTUAL_CURRENCY_OPTIONS } from '@app/common/constants';
import { IFeature, IGameInfo } from '@app/common/interfaces';
import { IGamePerformanceStatus, TGameState } from '@app/modules/GameManagement/common/interfaces';
import {
  hasFewerDAU,
  isGameFair,
  nextStepSelectorActionRequired,
  nextStepSelectorGameState,
  postPerformanceGameStateSelector,
} from '@appGameHome/common/helpers/nextStepGameStateHelpers';
import { determineNextStepUrl } from '@appGameHome/common/helpers/nextStepUrlHelpers';

import { isPrizeEnablementRevamp } from '../../modules/Overview/modules/PrizeEnablement/modules/PrizeEnablementWidget/helpers';
import {
  NEXT_STEP_ACTION_REQUIRED_CONSTANTS,
  NEXT_STEP_GAME_STATE_CONSTANTS,
  NEXT_STEP_POST_PERFORMANCE_CONSTANTS,
  stepFairPlay,
  stepHundredDAU,
  stepAnalytics,
  stepPrizeEnablement,
  TNextStepActionRequiredSteps,
  TNextStepOptimizeSteps,
  TNextStepPostPerformanceSteps,
  stepSeventyFiveDAU,
} from '../constants';
import { INextStepConstantProps, INextStepRenderProps, TNextStepGameState } from '../interfaces';

export function determineNextStep(
  gameInfo: IGameInfo,
  isActionRequired: TNextStepGameState,
  isNextGameState: TNextStepGameState,
  gamePerformanceStatus: IGamePerformanceStatus | null,
  isPostPerformanceGameState: TNextStepGameState
): INextStepConstantProps {
  let isCashLive = !!gameInfo.cashLive;
  let isVcOnly = gameInfo.vcOnly === VIRTUAL_CURRENCY_OPTIONS.VIRTUAL_CURRENCY_ONLY;

  if (isActionRequired !== null && isActionRequired in NEXT_STEP_ACTION_REQUIRED_CONSTANTS) {
    return NEXT_STEP_ACTION_REQUIRED_CONSTANTS[isActionRequired as TNextStepActionRequiredSteps];
  }

  if (isNextGameState !== null && isNextGameState in NEXT_STEP_GAME_STATE_CONSTANTS) {
    return NEXT_STEP_GAME_STATE_CONSTANTS[isNextGameState as TNextStepOptimizeSteps];
  }

  if (gamePerformanceStatus === null || hasFewerDAU(gamePerformanceStatus, isCashLive)) {
    return isPrizeEnablementRevamp() ? stepSeventyFiveDAU : stepHundredDAU;
  }

  if (isGameFair(gamePerformanceStatus, isCashLive)) {
    return stepFairPlay;
  }

  if (
    isPostPerformanceGameState !== null &&
    isPostPerformanceGameState in NEXT_STEP_POST_PERFORMANCE_CONSTANTS
  ) {
    return NEXT_STEP_POST_PERFORMANCE_CONSTANTS[
      isPostPerformanceGameState as TNextStepPostPerformanceSteps
    ];
  }

  if (!isCashLive && !isVcOnly) {
    return stepPrizeEnablement;
  }

  return stepAnalytics;
}

// functions to determine what the next step props is
export function determineNextStepProps(
  gameState: TGameState,
  gameInfo: IGameInfo,
  gamePerformanceStatus: IGamePerformanceStatus | null,
  feature: IFeature
): INextStepRenderProps {
  const isActionRequired: TNextStepGameState = nextStepSelectorActionRequired(
    gameState,
    gameInfo.latestSdk.status
  );

  const isNextGameState: TNextStepGameState = nextStepSelectorGameState(gameState);

  const shouldShowAnalytics: boolean =
    !!gameInfo.cashLive || hasFewerDAU(gamePerformanceStatus, gameInfo.cashLive);

  const shouldShowFairPlay: boolean =
    !!gameInfo.cashLive || isGameFair(gamePerformanceStatus, gameInfo.cashLive);

  const isPerformanceRequirementAchieved = !shouldShowAnalytics && !shouldShowFairPlay;

  const isPostPerformanceGameState: TNextStepGameState = postPerformanceGameStateSelector(
    isPerformanceRequirementAchieved,
    gameState,
    feature
  );

  const nextStep = determineNextStep(
    gameInfo,
    isActionRequired,
    isNextGameState,
    gamePerformanceStatus,
    isPostPerformanceGameState
  );

  const url = determineNextStepUrl(
    gameInfo.id,
    isActionRequired,
    isNextGameState,
    isPostPerformanceGameState
  );

  return { ...nextStep, url };
}
