import { LOCALE } from '@app/common/constants';

export const LOCALE_MAPPING = {
  [LOCALE.DE]: 'German',
  [LOCALE.EN]: 'English',
  [LOCALE.ES]: 'Spanish',
  [LOCALE.FR]: 'French',
  [LOCALE.IT]: 'Italian',
  [LOCALE.JA]: 'Japanese',
  [LOCALE.PT]: 'Portuguese',
  [LOCALE.RU]: 'Russian',
  [LOCALE.ZH]: 'Chinese',
};

export const localeDropdown = Object.values(LOCALE).map((locale) => {
  let component = LOCALE_MAPPING[locale];

  if (locale === LOCALE.EN) {
    component = 'English (required)';
  }

  return { value: locale, component };
});
