import { PLATFORM } from '@app/common/constants';
import { ONE_GB_IN_BYTES, ONE_MB_IN_BYTES } from '@app/common/helpers';
import {
  IProgressBarSegments,
  ISnackbarData,
  TErrorGeneral,
  TProgressBarSegments,
} from '@app/common/interfaces';
import { fileNameCleanUp } from '@appGameHome/common/helpers/fileUploadHelpers';
import { platformValues } from '@appOptimize/modules/GameInfo/constants';

import { FILE_TYPE, GAME_BUILD_STATUS } from './constants';
import { IGameBuild, TNameGameVersionAndFileType } from './interfaces';
import { bundleMismatchError } from './subcomponents/errorComponents';

export function findGameBuildIndex(gameBuilds: IGameBuild[], gameBuildId: number): number {
  return Object.values(gameBuilds).findIndex(
    (gameBuild: IGameBuild) => gameBuild.id === gameBuildId
  );
}

export function defaultGameBuildsErrorSnackBar(errorContext: string | JSX.Element): ISnackbarData {
  return {
    text: errorContext,
    autoHideDuration: 20000,
    anchorOriginVertical: 'bottom',
    anchorOriginHorizontal: 'left',
  };
}

// TODO [GameBuild][FP1][WS-8711] remove after binaries passed back, filters out all failure binary
export function countIncompleteGameBuild(gameBuilds: IGameBuild[]): number {
  let count: number = 0;

  gameBuilds.forEach((gameBuild: IGameBuild) => {
    if (gameBuild.uploadStatus !== GAME_BUILD_STATUS.COMPLETE) {
      count += 1;
    }
  });

  return count;
}

export function parseNewGameVersion(mostRecentGameBuild: IGameBuild | null): string {
  if (!mostRecentGameBuild) return '1.0';

  let currentVersion: string | null = mostRecentGameBuild.gameVersion;
  if (!currentVersion) return '1.0';

  let lastIndexOfPeriod: number = currentVersion.lastIndexOf('.');

  if (lastIndexOfPeriod === -1) {
    return `${parseInt(currentVersion, 10) + 1}`;
  }

  let patchNumber: string = currentVersion.slice(lastIndexOfPeriod + 1);

  return `${currentVersion.slice(0, lastIndexOfPeriod + 1)}${parseInt(patchNumber, 10) + 1}`;
}

export function extractNameGameVersionAndFileType(
  file: File,
  gameBuild: IGameBuild | null,
  mostRecentGameBuild: IGameBuild | null
): TNameGameVersionAndFileType {
  // name must be shorter than 42 characters not including the period and file type
  let name: string = file.name.length > 46 ? file.name.slice(0, 42) : file.name.slice(0, -4);
  name = fileNameCleanUp(name);
  const fileType: FILE_TYPE = file.name.slice(-4) as FILE_TYPE;

  return {
    name,
    fileType,
    gameVersion: gameBuild?.gameVersion ?? parseNewGameVersion(mostRecentGameBuild),
  };
}

export function extractVersion(
  gameBuild: IGameBuild | null,
  mostRecentGameBuild: IGameBuild | null
): string {
  return gameBuild?.gameVersion ?? parseNewGameVersion(mostRecentGameBuild);
}

export function determineUploadFileTypes(platform: PLATFORM): FILE_TYPE[] {
  let fileTypes: FILE_TYPE[] = [];

  if (platform !== PLATFORM.ANDROID) {
    fileTypes.push(FILE_TYPE.IPA);
  }

  if (platform !== PLATFORM.IOS) {
    fileTypes.push(FILE_TYPE.APK);
  }

  return fileTypes;
}

export function isFirstOtherPlatformGameBuild(
  platform: PLATFORM,
  gameBinariesStatus: PLATFORM | null,
  uploadBinaryPlatform: PLATFORM
): boolean {
  if (platform !== PLATFORM.CROSS_PLATFORM) {
    return false;
  }

  return (
    // if this is the first binary for iOS for cross_platform game with android binary
    (uploadBinaryPlatform === PLATFORM.ANDROID && gameBinariesStatus === PLATFORM.IOS) ||
    // if this is the first binary for Android for cross_platform gamewith ios binary
    (uploadBinaryPlatform === PLATFORM.IOS && gameBinariesStatus === PLATFORM.ANDROID)
  );
}

// TODO [WS-9512] match all error text as spec and check default text with product/design
export function editGameBuildProcessErrorMessage(
  message: TErrorGeneral,
  gameBuildPlatform: PLATFORM
): string | JSX.Element {
  let platform = gameBuildPlatform === PLATFORM.IOS ? platformValues.ios : platformValues.android;

  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  let constantMessage: string = message.errors ? message.errors : message;
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
  /* eslint-enable @typescript-eslint/no-unsafe-assignment */

  if (constantMessage.includes('Mysql2::Error')) {
    return 'Internal server error';
  }

  // fingerprint validation
  if (constantMessage.includes('Fingerprint Duplicate')) {
    let cleanedErrorMessage = constantMessage.slice(
      constantMessage.lastIndexOf('Duplicate fingerprint')
    );

    return cleanedErrorMessage;
  }

  if (constantMessage.includes('Fingerprint Fingerprint')) {
    let cleanedErrorMessage = constantMessage
      .slice(constantMessage.lastIndexOf('Fingerprint Fingerprint'))
      .replace('/apps/portal-developer', '');

    return cleanedErrorMessage;
  }

  // this should hide file path or details about apk_validation
  if (constantMessage.includes('Android')) {
    if (constantMessage.includes('game_id')) {
      return `The gameID in your ${platform} game build does not match this game`;
    } else {
      // matching-production or no environment found
      return `Your ${platform} game build is not pointed at Skillz production servers`;
    }
  }

  // invalid file
  if (constantMessage.includes('/tmp/game_binaries')) {
    // invalid ipa file
    if (constantMessage.includes('Open archive failed -')) {
      return `Invalid ${platform} file uploaded`;
    }

    // invalid ipa file from model level
    return constantMessage.split(':')[0] + ` for ${platform}`;
  }

  // bundle name and bundle executable mismatch
  if (constantMessage.includes('CFBundleName')) {
    // invalid ipa file
    return bundleMismatchError;
  }

  // default error message for failed process uploaded
  return `Error processing upload of your ${platform} game build`;
}

export function calculateProgressSegment(
  currentProgress: TProgressBarSegments,
  increment: number,
  maxProgress: number
): IProgressBarSegments {
  if (!currentProgress) {
    return {
      previousSegment: 0,
      currentSegment: Math.min(increment, maxProgress),
    };
  }

  let newSegmentState: number = currentProgress.currentSegment + increment;

  if (newSegmentState >= maxProgress) {
    newSegmentState = maxProgress;
  }

  return {
    previousSegment: currentProgress.currentSegment,
    currentSegment: newSegmentState,
  };
}

export function calculateChunkProgressFactor(numberOfChunks: number, maxProgress: number): number {
  if (numberOfChunks >= maxProgress * 2) {
    return 0.5;
  }

  if (numberOfChunks <= 5) {
    return 4;
  }

  if (numberOfChunks <= maxProgress / 2) {
    return 2;
  }

  if (numberOfChunks <= maxProgress) {
    return 1;
  }

  return 0;
}

export function convertByteToMbGb(byteSize: number): string {
  let sizeInByte: string = (byteSize / ONE_MB_IN_BYTES).toFixed(2) + 'MB';
  if (byteSize >= ONE_GB_IN_BYTES) {
    sizeInByte = (byteSize / ONE_GB_IN_BYTES).toFixed(2) + 'GB';
  }

  return sizeInByte;
}

export function isProgressAtMax(
  uploadProgressBarSegments: TProgressBarSegments,
  maxProgress: number
): boolean {
  return !!uploadProgressBarSegments && uploadProgressBarSegments.currentSegment < maxProgress;
}

// this is function to mock uniqueness validation for game version
// new version should be a version greater than most recent game build
// not applying this validation upon product request - 6/2/2020
export function validateGameVersion(
  newVersion: string,
  mostRecentGameVersion: string | null
): boolean {
  if (!mostRecentGameVersion) return true;

  return newVersion.toLocaleLowerCase() > mostRecentGameVersion.toLocaleLowerCase();
}

export function determineFileUploadErrorMessage(platform: PLATFORM): string {
  if (platform === PLATFORM.IOS) {
    return 'Please upload a .ipa file.';
  } else if (platform === PLATFORM.ANDROID) {
    return 'Please upload a .apk file.';
  }

  return 'Please upload a .ipa or .apk file';
}
