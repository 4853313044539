/* eslint-disable complexity */
// TODO [WS-21024] REFACTOR TO USE GENERIC FUNCTION TO OPEN AND CLOSE MODAL
import merge from 'lodash/merge';

import { MODAL_MANAGEMENT } from '@app/common/actions';
import { IAction, IActionOptionalData, IModalManagement } from '@app/common/interfaces';

import {
  IS_PROFILE_MODAL_OPEN,
  IS_GAME_INFO_MODAL_OPEN,
  IS_PROGRESSION_ROOM_EDIT_MODAL_OPEN,
  IS_AUDIT_NOTE_MODAL_OPEN,
  IS_ENTRY_POINT_CONFIRM_MODAL_OPEN,
  IS_ENTRY_POINT_SYNC_MODAL_OPEN,
} from './constants';

export const initialModalState: IModalManagement = {
  [IS_PROFILE_MODAL_OPEN]: false,
  [IS_GAME_INFO_MODAL_OPEN]: false,
  isArchiveModalOpen: false,
  isFairnessModalOpen: false,
  isPrizeEnablementModalOpen: false,
  isAccelerateModalOpen: false,
  isFeedbackModalOpen: false,
  isStudioModalOpen: false,
  isUserModalOpen: false,
  isRemoveUserModalOpen: false,
  isDeactivateSkillzSupportModalOpen: false,
  isInviteUserModalOpen: false,
  isTransferOwnershipModalOpen: false,
  isEditUserModalOpen: false,
  isDisableThirdPartyAdsModalOpen: false,
  isProgressionDeleteItemModalOpen: false,
  isPlayerDataModalOpen: false,
  isProgressionPublishModalOpen: false,
  isProgressionChangelogModalOpen: false,
  isProgressionPlayerLookupModalOpen: false,
  isInGameItemsModalOpen: false,
  isProgressionRoomSaveModalOpen: false,
  isProgressionRoomCreateModalOpen: false,
  [IS_PROGRESSION_ROOM_EDIT_MODAL_OPEN]: false,
  isProgressionRoomDeleteModalOpen: false,
  isEntryPointAssignAndSyncModalOpen: false,
  [IS_AUDIT_NOTE_MODAL_OPEN]: false,
  [IS_ENTRY_POINT_CONFIRM_MODAL_OPEN]: false,
  [IS_ENTRY_POINT_SYNC_MODAL_OPEN]: false,
};

function modalManagementReducer<T>(
  state: IModalManagement = initialModalState,
  action: IActionOptionalData<null> | IAction<T>
): IModalManagement {
  let newState: IModalManagement;
  Object.freeze(state);

  switch (action.type) {
    case MODAL_MANAGEMENT.OPEN_PROFILE_MODAL:
      newState = merge({}, state, { isProfileModalOpen: true });

      return newState;
    case MODAL_MANAGEMENT.CLOSE_PROFILE_MODAL:
      newState = merge({}, state, { isProfileModalOpen: false });

      return newState;
    case MODAL_MANAGEMENT.OPEN_GAME_INFO_MODAL:
      newState = merge({}, state, { isGameInfoModalOpen: true });

      return newState;
    case MODAL_MANAGEMENT.CLOSE_GAME_INFO_MODAL:
      newState = merge({}, state, { isGameInfoModalOpen: false });

      return newState;
    case MODAL_MANAGEMENT.OPEN_ARCHIVE_MODAL:
      newState = merge({}, state, { isArchiveModalOpen: true });

      return newState;
    case MODAL_MANAGEMENT.CLOSE_ARCHIVE_MODAL:
      newState = merge({}, state, { isArchiveModalOpen: false });

      return newState;
    case MODAL_MANAGEMENT.OPEN_FAIRNESS_MODAL:
      newState = merge({}, state, { isFairnessModalOpen: true });

      return newState;
    case MODAL_MANAGEMENT.CLOSE_FAIRNESS_MODAL:
      newState = merge({}, state, { isFairnessModalOpen: false });

      return newState;
    case MODAL_MANAGEMENT.OPEN_PRIZE_ENABLEMENT_MODAL:
      newState = merge({}, state, { isPrizeEnablementModalOpen: true });

      return newState;
    case MODAL_MANAGEMENT.CLOSE_PRIZE_ENABLEMENT_MODAL:
      newState = merge({}, state, { isPrizeEnablementModalOpen: false });

      return newState;
    case MODAL_MANAGEMENT.OPEN_ACCELERATE_MODAL:
      newState = merge({}, state, { isAccelerateModalOpen: true });

      return newState;
    case MODAL_MANAGEMENT.CLOSE_ACCELERATE_MODAL:
      newState = merge({}, state, { isAccelerateModalOpen: false });

      return newState;
    case MODAL_MANAGEMENT.OPEN_FEEDBACK_MODAL:
      newState = merge({}, state, { isFeedbackModalOpen: true });

      return newState;
    case MODAL_MANAGEMENT.CLOSE_FEEDBACK_MODAL:
      newState = merge({}, state, { isFeedbackModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_STUDIO_MODAL:
      newState = merge({}, state, { isStudioModalOpen: true });

      return newState;
    case MODAL_MANAGEMENT.CLOSE_STUDIO_MODAL:
      newState = merge({}, state, { isStudioModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_USER_MODAL:
      newState = merge({}, state, { isUserModalOpen: true });

      return newState;
    case MODAL_MANAGEMENT.CLOSE_USER_MODAL:
      newState = merge({}, state, { isUserModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_REMOVE_USER_MODAL:
      newState = merge({}, state, { isRemoveUserModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_REMOVE_USER_MODAL:
      newState = merge({}, state, { isRemoveUserModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_DEACTIVATE_SKILLZ_SUPPORT_MODAL:
      newState = merge({}, state, { isDeactivateSkillzSupportModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_DEACTIVATE_SKILLZ_SUPPORT_MODAL:
      newState = merge({}, state, { isDeactivateSkillzSupportModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_INVITE_USER_MODAL:
      newState = merge({}, state, { isInviteUserModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_INVITE_USER_MODAL:
      newState = merge({}, state, { isInviteUserModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_TRANSFER_OWNERSHIP_MODAL:
      newState = merge({}, state, { isTransferOwnershipModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_TRANSFER_OWNERSHIP_MODAL:
      newState = merge({}, state, { isTransferOwnershipModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_EDIT_USER_MODAL:
      newState = merge({}, state, { isEditUserModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_EDIT_USER_MODAL:
      newState = merge({}, state, { isEditUserModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_DISABLE_THIRD_PARTY_ADS_MODAL:
      newState = merge({}, state, { isDisableThirdPartyAdsModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_DISABLE_THIRD_PARTY_ADS_MODAL:
      newState = merge({}, state, { isDisableThirdPartyAdsModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_PROGRESSION_DELETE_ITEM_MODAL:
      newState = merge({}, state, { isProgressionDeleteItemModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_PROGRESSION_DELETE_ITEM_MODAL:
      newState = merge({}, state, { isProgressionDeleteItemModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_PLAYER_DATA_MODAL:
      newState = merge({}, state, { isPlayerDataModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_PLAYER_DATA_MODAL:
      newState = merge({}, state, { isPlayerDataModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_PROGRESSION_PUBLISH_MODAL:
      newState = merge({}, state, { isProgressionPublishModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_PROGRESSION_PUBLISH_MODAL:
      newState = merge({}, state, { isProgressionPublishModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_PROGRESSION_CHANGELOG_MODAL:
      newState = merge({}, state, { isProgressionChangelogModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_PROGRESSION_CHANGELOG_MODAL:
      newState = merge({}, state, { isProgressionChangelogModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_PROGRESSION_PLAYER_LOOKUP_MODAL:
      newState = merge({}, state, { isProgressionPlayerLookupModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_PROGRESSION_PLAYER_LOOKUP_MODAL:
      newState = merge({}, state, { isProgressionPlayerLookupModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_IN_GAME_ITEMS_MODAL:
      newState = merge({}, state, { isInGameItemsModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_IN_GAME_ITEMS_MODAL:
      newState = merge({}, state, { isInGameItemsModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_PROGRESSION_ROOM_SAVE_MODAL:
      newState = merge({}, state, { isProgressionRoomSaveModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_PROGRESSION_ROOM_SAVE_MODAL:
      newState = merge({}, state, {
        isProgressionRoomSaveModalOpen: false,
      });

      return newState;

    case MODAL_MANAGEMENT.OPEN_PROGRESSION_ROOM_CREATE_MODAL:
      newState = merge({}, state, { isProgressionRoomCreateModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_PROGRESSION_ROOM_CREATE_MODAL:
      newState = merge({}, state, {
        isProgressionRoomCreateModalOpen: false,
      });

      return newState;

    case MODAL_MANAGEMENT.OPEN_PROGRESSION_ROOM_DELETE_MODAL:
      newState = merge({}, state, { isProgressionRoomDeleteModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_PROGRESSION_ROOM_DELETE_MODAL:
      newState = merge({}, state, {
        isProgressionRoomDeleteModalOpen: false,
      });

      return newState;

    case MODAL_MANAGEMENT.OPEN_ENTRY_POINT_ASSIGN_AND_SYNC_MODAL:
      newState = merge({}, state, { isEntryPointAssignAndSyncModalOpen: true });

      return newState;

    case MODAL_MANAGEMENT.CLOSE_ENTRY_POINT_ASSIGN_AND_SYNC_MODAL:
      newState = merge({}, state, { isEntryPointAssignAndSyncModalOpen: false });

      return newState;

    case MODAL_MANAGEMENT.OPEN_MODAL:
      newState = merge({}, state, action.data);

      return newState;
    case MODAL_MANAGEMENT.CLOSE_MODAL:
      newState = merge({}, state, action.data);

      return newState;

    default:
      return state;
  }
}

export default modalManagementReducer;
