import * as React from 'react';

import { INextStepConstantProps } from '@appGameHome/common/interfaces';

import * as Icons from '../components/nextStepIcons';
import * as Copy from './nextStepCopyConstants';

function renderDescription(description: string): JSX.Element {
  return <p>{description}</p>;
}

// action required game state steps
export const stepFixApplePay: INextStepConstantProps = {
  icon: Icons.fixItIcon,
  title: Copy.NEXT_STEP_FIX_APPLE_PAY_COPY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_FIX_APPLE_PAY_COPY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-fix-apple-pay',
    buttonText: Copy.NEXT_STEP_FIX_APPLE_PAY_COPY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepFixPushNotification: INextStepConstantProps = {
  icon: Icons.fixItIcon,
  title: Copy.NEXT_STEP_FIX_PUSH_NOTIFICATION_COPY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_FIX_PUSH_NOTIFICATION_COPY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-fix-push-notification',
    buttonText: Copy.NEXT_STEP_FIX_PUSH_NOTIFICATION_COPY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepUploadSdk: INextStepConstantProps = {
  icon: Icons.UploadSdkIcon,
  title: Copy.NEXT_STEP_UPLOAD_SDK_COPY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_UPLOAD_SDK_COPY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-upload-sdk',
    buttonText: Copy.NEXT_STEP_UPLOAD_SDK_COPY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

// game state steps
export const stepTournament: INextStepConstantProps = {
  icon: Icons.tournamentIcon,
  title: Copy.NEXT_STEP_TOURNAMENT_COPY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_TOURNAMENT_COPY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-tournament',
    buttonText: Copy.NEXT_STEP_TOURNAMENT_COPY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepInAppPurchase: INextStepConstantProps = {
  icon: Icons.inAppPurchaseIcon,
  title: Copy.NEXT_STEP_IN_APP_PURCHASE_COPY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_IN_APP_PURCHASE_COPY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-in-app-purchase',
    buttonText: Copy.NEXT_STEP_IN_APP_PURCHASE_COPY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepGameBuilds: INextStepConstantProps = {
  icon: Icons.gameBuildIcon,
  title: Copy.NEXT_STEP_GAME_BUILDS_COPY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_GAME_BUILDS_COPY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-game-builds',
    buttonText: Copy.NEXT_STEP_GAME_BUILDS_COPY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepAppStoreUrl: INextStepConstantProps = {
  icon: Icons.appStoreUrlIcon,
  title: Copy.NEXT_STEP_APP_STORE_URL_COPY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_APP_STORE_URL_COPY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-app-store-url',
    buttonText: Copy.NEXT_STEP_APP_STORE_URL_COPY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepTheme: INextStepConstantProps = {
  icon: Icons.themeIcon,
  title: Copy.NEXT_STEP_THEME_COPY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_THEME_COPY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-theme',
    buttonText: Copy.NEXT_STEP_THEME_COPY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepPushNotification: INextStepConstantProps = {
  icon: Icons.pushNotificationIcon,
  title: Copy.NEXT_STEP_PUSH_NOTIFICATION_COPY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_PUSH_NOTIFICATION_COPY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-push-notification',
    buttonText: Copy.NEXT_STEP_PUSH_NOTIFICATION_COPY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepApplePay: INextStepConstantProps = {
  icon: Icons.applePayIcon,
  title: Copy.NEXT_STEP_APPLE_PAY_COPY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_APPLE_PAY_COPY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-apple-pay',
    buttonText: Copy.NEXT_STEP_APPLE_PAY_COPY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepDeepLinking: INextStepConstantProps = {
  icon: Icons.deepLinkingIcon,
  title: Copy.NEXT_STEP_DEEP_LINKING_COPY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_DEEP_LINKING_COPY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-deep-linking',
    buttonText: Copy.NEXT_STEP_DEEP_LINKING_COPY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepSingleSignOn: INextStepConstantProps = {
  icon: Icons.singleSignOnIcon,
  title: Copy.NEXT_STEP_SINGLE_SIGN_ON_COPY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_SINGLE_SIGN_ON_COPY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-single-sign-on',
    buttonText: Copy.NEXT_STEP_SINGLE_SIGN_ON_COPY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

// after game state steps
export const stepHundredDAU: INextStepConstantProps = {
  icon: Icons.hundredDAUIcon,
  title: Copy.NEXT_STEP_HUNDRED_DAU.TITLE,
  description: renderDescription(Copy.NEXT_STEP_HUNDRED_DAU.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-hundred-dau',
    buttonText: Copy.NEXT_STEP_HUNDRED_DAU.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepSeventyFiveDAU: INextStepConstantProps = {
  icon: Icons.hundredDAUIcon,
  title: Copy.NEXT_STEP_SEVENTY_FIVE_DAU.TITLE,
  description: renderDescription(Copy.NEXT_STEP_SEVENTY_FIVE_DAU.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-seventy-five-dau',
    buttonText: Copy.NEXT_STEP_SEVENTY_FIVE_DAU.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepFairPlay: INextStepConstantProps = {
  icon: Icons.fairPlayIcon,
  title: Copy.NEXT_STEP_FAIR_PLAY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_FAIR_PLAY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-fair-play',
    buttonText: Copy.NEXT_STEP_FAIR_PLAY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

// prize enablement
export const stepPrizeEnablement: INextStepConstantProps = {
  icon: Icons.prizeEnablementIcon,
  title: Copy.NEXT_STEP_PRIZE_ENABLEMENT.TITLE,
  description: renderDescription(Copy.NEXT_STEP_PRIZE_ENABLEMENT.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-prize-enablement',
    buttonText: Copy.NEXT_STEP_PRIZE_ENABLEMENT.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepMonetizationChannel: INextStepConstantProps = {
  icon: Icons.expertMoneyGradient,
  title: Copy.NEXT_STEP_MONETIZATION_CHANNEL.TITLE,
  description: renderDescription(Copy.NEXT_STEP_MONETIZATION_CHANNEL.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-monetization-channel',
    buttonText: Copy.NEXT_STEP_MONETIZATION_CHANNEL.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepAnalytics: INextStepConstantProps = {
  icon: Icons.hundredDAUIcon,
  title: Copy.NEXT_STEP_ANALYTICS_COPY.TITLE,
  description: renderDescription(Copy.NEXT_STEP_ANALYTICS_COPY.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-analytics',
    buttonText: Copy.NEXT_STEP_ANALYTICS_COPY.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepPlayerData: INextStepConstantProps = {
  icon: Icons.playerDataIcon,
  title: Copy.NEXT_STEP_PLAYER_DATA.TITLE,
  description: renderDescription(Copy.NEXT_STEP_PLAYER_DATA.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-player-data',
    buttonText: Copy.NEXT_STEP_PLAYER_DATA.BUTTON_TEXT,
    buttonType: 'primary',
  },
};

export const stepInGameItems: INextStepConstantProps = {
  icon: Icons.inGameItemsIcon,
  title: Copy.NEXT_STEP_IN_GAME_ITEMS.TITLE,
  description: renderDescription(Copy.NEXT_STEP_IN_GAME_ITEMS.DESCRIPTION),
  buttonProps: {
    id: 'overview-next-step__button-in-game-items',
    buttonText: Copy.NEXT_STEP_IN_GAME_ITEMS.BUTTON_TEXT,
    buttonType: 'primary',
  },
};
