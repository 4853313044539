import { createAsyncThunk } from '@reduxjs/toolkit';

import { AxiosError, AxiosResponse } from 'axios';

import { IErrors, IPermissions } from '@app/common/interfaces';
import axios from '@app/common/setup/axiosWithHeader';

export const fetchPermissions = createAsyncThunk('permissions/fetch', (params?: string) => {
  return axios
    .get(`/api_internal/v1/permissions${params ? `?${params}` : ''}`)
    .then((response: AxiosResponse<IPermissions>) => {
      return response.data;
    })
    .catch((e) => {
      const error = e as AxiosError<IErrors>;

      throw new Error(error.response?.data.errors);
    });
});
