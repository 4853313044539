// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import { Dispatch } from 'redux';

import { snakeCaseConverter } from '@app/common/helpers';
import {
  IAction,
  IActionOptionalData,
  IErrors,
  ISnackbarData,
  TAxiosPromise,
  TDispatch,
  TThunkAction,
} from '@app/common/interfaces';
import axios, { AxiosError, AxiosResponse } from '@app/common/setup/axiosWithHeader';
import { TAwsUploadResponse } from '@appGameHome/common/interfaces';

import { GAME_BUILD_PROCESS_END_STATE } from '../constants';
import { IGameBuild, TGameBuildInitial } from '../interfaces';

type TActionGameBuildUpload<T> = IAction<T> | IActionOptionalData<ISnackbarData>;

export enum GAME_BUILDS_UPLOAD {
  RECEIVE_NEW_GAME_BUILD = 'gameBuildsUpload/RECEIVE_NEW_GAME_BUILD',
  CLEAR_FINALIZE_GAME_BUILD = 'gameBuildsUpload/CLEAR_FINALIZE_GAME_BUILD',
}

export function receiveNewGameBuild(data: IGameBuild): IAction<IGameBuild> {
  return { type: GAME_BUILDS_UPLOAD.RECEIVE_NEW_GAME_BUILD, data };
}

export function clearFinalizeGameBuild(data: IGameBuild): IAction<IGameBuild> {
  return { type: GAME_BUILDS_UPLOAD.CLEAR_FINALIZE_GAME_BUILD, data };
}

export const createGameBuild: TThunkAction = (
  gameId: number,
  gameBuildInfo: TGameBuildInitial
): TDispatch => {
  return (dispatch: Dispatch<TActionGameBuildUpload<IGameBuild>>): TAxiosPromise<number> => {
    return (
      axios
        .post(
          `/api/v1/games/${gameId}/game_binaries`,
          snakeCaseConverter({ gameBinary: gameBuildInfo })
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: AxiosResponse<IGameBuild>): TAxiosPromise<IGameBuild> => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          dispatch(receiveNewGameBuild(camelize(response.data)));

          return response.data.id as unknown as TAxiosPromise<number>;
        })
        .catch((error: AxiosError<IErrors>): never => {
          throw error.response ? error.response.data : error;
        })
    );
  };
};

export function completeMultipartUpload(
  gameId: number,
  uploadResponse: TAwsUploadResponse[],
  gameBuild: IGameBuild
): Promise<IGameBuild> {
  return axios
    .patch(
      `/api/v1/games/${gameId}/game_binaries/${gameBuild.id}/complete_multipart_upload`,
      snakeCaseConverter({
        gameBinary: { parts: uploadResponse, uploadId: gameBuild.uploadId },
      })
    )
    .then((response: AxiosResponse<IGameBuild>): IGameBuild => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      return camelize(response.data) as IGameBuild;
    })
    .catch((error: AxiosError<IErrors>): never => {
      throw error.response ? error.response.data : error;
    });
}

export const processUpload: TThunkAction = (gameId: number, gameBuild: IGameBuild): TDispatch => {
  return (dispatch: Dispatch<TActionGameBuildUpload<IGameBuild>>): TAxiosPromise<IGameBuild> => {
    return axios
      .patch(
        `/api/v1/games/${gameId}/game_binaries/${gameBuild.id}/process_upload`,
        snakeCaseConverter({
          gameBinary: { uploadId: gameBuild.uploadId },
        })
      )
      .then((response: AxiosResponse<IGameBuild>): TAxiosPromise<IGameBuild> => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        return camelize(response.data) as unknown as TAxiosPromise<IGameBuild>;
      })
      .catch((error: AxiosError<IErrors>): never => {
        dispatch(clearFinalizeGameBuild(gameBuild));
        throw error.response ? error.response.data : error;
      });
  };
};

export const fetchProcessingGameBuild: TThunkAction = (
  gameId: number,
  gameBuildId: number
): TDispatch => {
  return (dispatch: Dispatch<TActionGameBuildUpload<IGameBuild>>): TAxiosPromise<IGameBuild> => {
    return axios
      .get(`/api/v1/games/${gameId}/game_binaries/${gameBuildId}`)
      .then((response: AxiosResponse<IGameBuild>): TAxiosPromise<IGameBuild> => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const camelizeResponse: IGameBuild = camelize(response.data) as IGameBuild;

        const hasUploadComplete =
          !!camelizeResponse.uploadStatus &&
          GAME_BUILD_PROCESS_END_STATE.includes(camelizeResponse.uploadStatus);

        if (hasUploadComplete) {
          dispatch(clearFinalizeGameBuild(camelizeResponse));
        }

        return camelizeResponse as unknown as TAxiosPromise<IGameBuild>;
      });
  };
};
