import { IErrors, TVoidFunction } from '@app/common/interfaces';
import axios, { AxiosError, AxiosResponse } from '@app/common/setup/axiosWithHeader';
import { calculateFileChunkStartEnd } from '@appGameHome/common/helpers/fileUploadHelpers';
import {
  IAwsSignedUrl,
  IAwsUploadError,
  IAwsUploadManager,
  IAwsUploadResponse,
  IFileChunkStartEnd,
  TAwsUploadResponse,
} from '@appGameHome/common/interfaces';

export function chunkFileUpload(
  file: File,
  fileType: string,
  numberOfChunks: number,
  fileChunkSize: number,
  signedUrls: IAwsSignedUrl[],
  progressFunction: TVoidFunction<number, ProgressEvent>
): IAwsUploadManager {
  let awsResponsePromises: Promise<TAwsUploadResponse>[] = [];
  let startEndOfChunk: IFileChunkStartEnd;
  let fileChunk: Blob;

  const controller = new AbortController();

  signedUrls.forEach((signedUrl: IAwsSignedUrl) => {
    let partNumber: number = signedUrl.partNumber;
    startEndOfChunk = calculateFileChunkStartEnd(partNumber, numberOfChunks, fileChunkSize);
    fileChunk = file.slice(startEndOfChunk.start, startEndOfChunk.end);

    let uploadResp: Promise<TAwsUploadResponse> = uploadAwsChunk(
      fileChunk,
      signedUrl.url,
      fileType,
      partNumber,
      progressFunction,
      controller
    );

    awsResponsePromises.push(uploadResp);
  });

  return {
    uploadChunks: awsResponsePromises,
    cancelUpload: () => controller.abort(),
    signal: controller.signal,
  };
}

export function uploadAwsChunk(
  chunk: Blob,
  signedUrl: string,
  fileType: string,
  partNumber: number,
  progressFunction: TVoidFunction<number, ProgressEvent>,
  controller: AbortController
): Promise<TAwsUploadResponse> {
  const config = {
    headers: { 'Content-Type': fileType },
    onUploadProgress: (progressEvent: ProgressEvent) => {
      progressFunction(partNumber, progressEvent);
    },
    signal: controller.signal,
  };

  return axios
    .put(signedUrl, chunk, config)
    .then((response: AxiosResponse<unknown>): IAwsUploadResponse => {
      const headers = response.headers as Record<string, string>;

      return { partNumber, etag: headers.etag.replace(/"/g, '') };
    })
    .catch((error: AxiosError<IErrors>): IAwsUploadError => {
      return { partNumber, error: error.message };
    });
}
