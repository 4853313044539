import { Component } from 'react';

import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';

import { IWithSnackbarProp } from '@app/common/helpers/snackbarHookHelper';
import { IRootState } from '@app/core/rootReducer';

import { fetchStatements } from './actions';

const Statements = loadable(
  () => import(/* webpackChunkName: "statements" */ './Statements')
) as typeof Component;

const mapStateToProps = (state: IRootState) => {
  return { statements: state.statements };
};

const mapDispatchToProps = {
  fetchStatements,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TStatementsConnectedProps = ConnectedProps<typeof connector> & IWithSnackbarProp;

export default connector(Statements);
