import { createAsyncThunk } from '@reduxjs/toolkit';

import axios, { AxiosResponse } from 'axios';
// @ts-ignore: camelize is by definition an un-typeable Any => Any
import camelize from 'camelize';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import snakeCase from 'lodash/snakeCase';
import { IIndexResponse } from 'portal-common-library/interfaces/redux';
import qs from 'qs';

import { handleError, snakeCaseConverter } from '@app/common/helpers';

import { NOTIFICATION_ACTIONS } from './constants';
import {
  ICmsNotification,
  ICmsNotificationsParams,
  INotification,
  INotificationIndexParams,
} from './interfaces';

const CMS_NOTIFICATIONS_ACTIONS: Record<NOTIFICATION_ACTIONS, string> = {
  dismissed: 'dismiss',
  viewed: 'snooze',
  interacted: 'acknowledge',
};

export const fetchNotificationItems = createAsyncThunk(
  'notificationItem/fetch',
  async (params: INotificationIndexParams) => {
    // params.offset = params.page + 1;
    let urlParams = mapKeys(omit(params, ['page']), (_, k) => snakeCase(k));
    const response: AxiosResponse<IIndexResponse<INotification>> = await axios
      .get(`/api_internal/v1/notifications`, {
        params: urlParams,
      })
      .then(camelize);

    return response.data;
  }
);

/* eslint-disable camelcase */
export const logNotificationUserAction = (
  notificationId: number,
  action: NOTIFICATION_ACTIONS,
  userId: number
): Promise<void> => {
  return window.appConfig.featureFlags.cmsNotification
    ? axios.post('/publisher/v1/notification_responses/cms', {
        notification: notificationId,
        response_type: CMS_NOTIFICATIONS_ACTIONS[action],
        user_id: userId,
      })
    : axios.post(`/api_internal/v1/notifications/${notificationId}/logs`, {
        log: { action },
      });
};
/* eslint-enable camelcase */

export const contentManagementSystemInstance = axios.create();
delete contentManagementSystemInstance.defaults.headers.common['X-CSRF-TOKEN'];

export async function fetchCmsNotifications(
  params: ICmsNotificationsParams = {},
  isFtue: boolean,
  userRole: string
): Promise<ICmsNotification[]> {
  const today = new Date().toISOString().split('T')[0];
  /* eslint-disable camelcase */
  const filters = qs.stringify({
    _where: [
      { _or: [{ end_date_gte: today }, { end_date_null: true }] },
      { _or: [{ is_ftue: isFtue }, { is_ftue_null: true }] },
      { _or: [{ user_role: 'everyone' }, { user_role: userRole }] },
      { start_date_lte: today },
    ],
    _sort: 'id:ASC',
    ...snakeCaseConverter(params),
  });
  /* eslint-enable camelcase */

  return contentManagementSystemInstance
    .get(`${window.appConfig.environment.cmsBaseUrl}/publisher/v1/cms/notifications?${filters}`)
    .then(camelize)
    .then(({ data }: AxiosResponse<ICmsNotification[]>) => data)
    .catch(handleError);
}

export async function fetchNotifications(): Promise<INotification[]> {
  const response: IIndexResponse<INotification> = await axios
    .get(`/api_internal/v1/notifications`)
    .then(camelize)
    .then(({ data }: AxiosResponse<IIndexResponse<INotification>>) => data)
    .catch(handleError);

  return response?.items;
}
