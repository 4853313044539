import React from 'react';

import { useQuery } from 'react-query';

import { TIME_IN_MILLISECONDS } from '@app/common/constants';
import { IUser } from '@app/common/interfaces';
import { getGames } from '@app/modules/LearningCenter/modules/ManagedLearningCenter/actions';

import DialogSurvey from './DialogSurvey';
import PopupSurvey from './PopupSurvey';
import { useSurveyContext } from './SurveyContext';
import { TOAST_SURVEYS } from './constants';
import {
  determineUserType,
  mapUserTypeToSurveyTarget,
  checkHasUserRespondedInLast24Hours,
} from './helpers';
import { ISurvey, ISurveyResponse, TSurveyType } from './interfaces';
import { useSurvey, useLatestSurveyResponse } from './queries';

interface ISurveyProps {
  user: IUser;
}

const shouldRenderSurveyBasedOnLastResponse = (
  survey: ISurvey,
  lastResponse?: ISurveyResponse
): TSurveyType | undefined => {
  const { surveyType } = survey;
  if (!lastResponse) return surveyType;

  const lastResponseDate = new Date(lastResponse.createdAt);
  const nowInMilliseconds = new Date().getTime();
  const dismissalThreshold = new Date(
    nowInMilliseconds - survey.dismissalCadence * TIME_IN_MILLISECONDS.DAY
  );
  const completionThreshold = new Date(
    nowInMilliseconds - survey.completionCadence * TIME_IN_MILLISECONDS.DAY
  );

  if (lastResponse.isDismissal && lastResponseDate > dismissalThreshold) {
    return;
  }

  if (lastResponseDate > completionThreshold) {
    return;
  }

  return survey.surveyType;
};

export default function Surveys({ user }: ISurveyProps) {
  const { surveyTrigger, closeSurvey } = useSurveyContext();

  const userId = user.id;

  const gamesQuery = useQuery(['getGames'], () => getGames()).data;
  const userType = determineUserType(gamesQuery?.items ?? []);

  const triggeredSurvey = useSurvey(surveyTrigger, mapUserTypeToSurveyTarget(userType)).data;

  const previousResponse = useLatestSurveyResponse(userId, triggeredSurvey?.id).data;

  if (!surveyTrigger || !triggeredSurvey || checkHasUserRespondedInLast24Hours()) {
    return <></>;
  }

  const surveyType = shouldRenderSurveyBasedOnLastResponse(triggeredSurvey, previousResponse);

  if (!surveyType) {
    return <></>;
  }

  if (TOAST_SURVEYS.includes(surveyType)) {
    return <PopupSurvey survey={triggeredSurvey} userType={userType} userId={user.id} />;
  }

  return (
    <DialogSurvey
      survey={triggeredSurvey}
      onClose={closeSurvey}
      userType={userType}
      userId={user.id}
    />
  );
}
