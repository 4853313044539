import React from 'react';

import { AxisProps, TickFormatter } from '@nivo/axes';
import { LegendProps } from '@nivo/legends';

import cloneDeep from 'lodash/cloneDeep';

import { ANALYTICS_ATTRIBUTES, ANALYTICS_DB_ATTRIBUTES } from '@app/common/constants';
import { yAxisLabelFormatter } from '@app/common/helpers';
import {
  ICardProps,
  IChartContentLoaderDefaultProps,
  IChartKeys,
  TColorKey,
} from '@app/common/interfaces';
import variables from '@app/core/variables.scss';

export enum CHART_TYPE {
  LINE = 'line',
  BAR = 'bar',
}

export enum ANALYTICS_CHART_TITLES {
  REVENUE = 'Estimated Revenue',
  INSTALLS = 'Installs',
  DAU = 'Daily Active Users',
  RETENTION = 'Retention',
  PAYING_RETENTION = 'Paying User Retention',
  DAU_OF_MAU = 'DAU/MAU',
  GAMES_PER_USER = 'Games Per DAU',
  GAMES_PLAYED = 'Games Played',
  ARPPU_ARPDAU = 'Revenue Per User',
  NEW_PAYING_USERS = 'New Paying Users',
  Z_COMPLETION = 'Z Game Completion',
  DAY_1_Z_GAMES_PER_INSTALL = 'Day 1 Z Games per Install',
  ABORT_EVENTS = 'Abort Type Distribution',
  IN_BAND_WIN_RATE = 'In-Band Win Rate',
  ENTRY_FEE = 'Entry Fee',
  ENTRY_FEE_PER_PRO_DAU = 'Entry Fee per Pro DAU',
  FIRST_TIME_PAYERS_TO_SKILLZ_NETWORK = 'New Paying Users',
  INSTALLS_TO_DEPOSIT = 'Installs to Deposit',
  NEW_PRO_USERS = 'New Pro Playing Users',
  INSTALLS_TO_Z = 'Installs to Z',
  INSTALLS_TO_2Z = 'Installs to 2Z',
  RETENTION_D1 = 'D1 Retention',
  RETENTION_D7 = 'D7 Retention',
  COHORT_PERFORMANCE = 'Entry Fee by Install Cohort',
}

export const ANALYTICS_CHART_TOOLTIPS = {
  REVENUE: (
    <>
      An estimate of your daily game revenue calculated from actual daily Entry Fees and your
      game&apos;s revenue share percentage at the time of calculation. Estimated Revenue is only
      available from May 1st, 2023 under the new revenue model.&nbsp;
      <strong style={{ fontWeight: 500 }}>
        You will see $0 for any date prior to May 1st, 2023
      </strong>
    </>
  ),
  SKILLZ_INSTALLS: 'Count of unique players who have signed into Skillz through your game',
  INSTALLS_TO_D: 'The percentage of installs that have made at least one deposit',
  ABORT_RATE: 'The percentage of your games that end in an abort',
  ABORT_EVENTS: (
    <>
      Intentional: user manually quit the game <br />
      Unintentional: the game crashed due to functional issues <br />
      Backgrounded: user put the game to the background which is then force closed <br />
      Terminated: user manually force quit the game when frozen <br />
      Timeout: user started the game but did not report a score within 60 minutes
    </>
  ),
  IN_BAND_WIN_RATE: (
    <>
      Percentage of users with a win rate between 40% and 60% in their last 25 games <br />
      Note: 40%-60% is the win rate band that indicates optimal user experience. Successful games at
      Skillz typically has a in-band rate over 65%
    </>
  ),
  DAU: 'Count of unique players each day who have played a tournament in your game',
  DAU_OF_MAU:
    'The ratio of Daily Active Users to Monthly Active Users. Useful to indicate how engaged your user are',
  GAMES_PER_USER: 'Average number of tournaments played in your game per daily active user',
  GAMES_PLAYED: 'Number of tournaments played in your game',
  ARPPU_ARPDAU:
    'An estimate of your gross revenue divided by the unique number of\
  users who played a tournament each day',
  Z_COMPLETION: 'The % of users who completed at least X games after installing',
  DAY_1_Z_GAMES_PER_INSTALL:
    'The average number of Z games a new install plays in their first 24 hours on the system',
  ENTRY_FEE: 'Total amount paid by pro users to enter and play pro tournaments in your game',
  ENTRY_FEE_PER_PRO_DAU:
    'Total amount of entry fees divided by the unique number of users who played a pro tournament on that day',
  FIRST_TIME_PAYERS_TO_SKILLZ_NETWORK:
    'Count of unique users who made their first ever deposit with Skillz in any game, and then played their first pro tournament through your game',
  NEW_PRO_USERS:
    "Count of unique users who have played their first pro tournament in your game. Note: If viewing all games, a user's first pro tournament will be counted for each of your games they’ve played pro in",
  PAYING_USER_RETENTION:
    'Of the users who first deposited in Skillz’s system on a particular day, the percentage that play a pro tournament in your game N days after making their first deposit. Note that users first deposited on 1/1 will not have D7 paying retention data until 1/8.',
  INSTALLS_TO_DEPOSIT:
    "The percentage of installs that made their first deposit in Skillz's system through your game",
  INSTALLS:
    'Count of unique users who installed your game on their system and signed into Skillz through your game',
  INSTALLS_TO_Z: 'The percentage of installs that enter their Nth Z tournament',
  USER_RETENTION:
    'Of the cohort of users who installed on a particular day, the percentage that play a tournament in your game N days after installing. Note that users installed on 1/1 will not have D7 retention data until 1/8',
  COHORT_PERFORMANCE:
    'Total entry fees paid by the cohort of users who installed your game on a particular day, within N days after installing ',
};

export const ANALYTICS_CHART_TOOLTIPS_OLD = {
  INSTALLS_TO_Z: 'The percentage of installs that enter their nth Z match',
  RETENTION: 'The percentage of users that play a tournament in your game N days after installing',
  PAYING_RETENTION:
    'The percentage of paying users that play a cash tournament in your game N days after their first cash tournament',
  NEW_PAYING_USERS:
    "Count of unique players who have played their first cash tournament in your game. Note: If viewing all games, a player's first cash game will be counted for each of your games they've played cash in",
};

export const ANALYTICS_CHART_TOOLTIPS_PRE_PRIZE = {
  INSTALLS_TO_Z: 'The % of users who entered at least X games after installing',
  RETENTION:
    'Among the players who installed your game on a particular day, the percentage that play a tournament N days after the install date. For example, for the player cohort installed on 1/1, D7 retention is the percentage of players play a tournament on 1/8.',
  PAYING_RETENTION:
    'Among the players who first deposited in your game on a particular day, the percentage that play a cash tournament N days after the install date. For example, for the player cohort first deposited on 1/1, D7 cash retention is the percentage of players play a cash tournament on 1/8.',
};

export const legends: LegendProps = {
  anchor: 'top-right',
  direction: 'column',
  justify: false,
  translateY: -44,
  translateX: 0,
  itemWidth: 58,
  itemHeight: 10,
  itemsSpacing: 8,
  symbolSize: 12,
  symbolShape: 'circle',
  itemDirection: 'right-to-left',
  effects: [
    {
      on: 'hover',
      style: {
        itemBackground: 'rgba(0, 0, 0, .03)',
        itemOpacity: 1,
      },
    },
  ],
};

export const barLegends: { dataFrom: 'indexes' | 'keys' } & LegendProps = {
  ...cloneDeep(legends),
  ...{ dataFrom: 'keys' },
};

export const lineLegends: LegendProps = cloneDeep(legends);

export const horizontalLegends: LegendProps = {
  ...cloneDeep(legends),
  ...{ direction: 'row', itemWidth: 100, itemDirection: 'left-to-right' },
};

export const Z_COMPLETION_Y_AXIS_PROPERTIES: AxisProps = {
  orient: 'left',
  legendOffset: 29,
  legendPosition: 'middle',
  format: ((value: string): string => `${Math.abs(parseFloat(value))}%`) as TickFormatter,
};

export const DAY_1_Z_GAMES_PER_INSTALL_PROPERTIES: AxisProps = {
  orient: 'left',
  legendOffset: 29,
  legendPosition: 'middle',
  format: ((value: number): string => `${yAxisLabelFormatter(value)}`) as TickFormatter,
};

export const GENERIC_Y_AXIS_PROPERTIES: AxisProps = {
  format: ((value: number): string => yAxisLabelFormatter(value)) as TickFormatter,
};

export const WHOLE_NUMBER_Y_AXIS_PROPERTIES: AxisProps = {
  format: ((value: number) => Math.floor(value) === value && value) as TickFormatter,
};

export const USD_Y_AXIS_PROPERTIES: AxisProps = {
  orient: 'left',
  legendOffset: 29,
  legendPosition: 'middle',
  format: ((value: number): string => `$${yAxisLabelFormatter(value)}`) as TickFormatter,
};

export const PERCENTAGE_Y_AXIS_PROPERTIES: AxisProps = {
  orient: 'left',
  legendOffset: 29,
  legendPosition: 'middle',
  format: ((value: string): string =>
    Math.abs(parseFloat(value)) <= 100 ? `${Math.abs(parseFloat(value))}%` : '') as TickFormatter,
};

export const chartDefaultProps: IChartContentLoaderDefaultProps = {
  secondaryColor: '#b3b3b3',
  speed: 2,
};

export const CHART_COLORS: TColorKey = {
  PRIMARY: [
    variables.colorFirst,
    variables.colorSecond,
    variables.colorThird,
    variables.colorFourth,
    variables.colorFifth,
    variables.colorSixth,
  ],
  LIGHT: [
    variables.colorFirstEstimated,
    variables.colorSecondEstimated,
    variables.colorThirdEstimated,
    variables.colorFourthEstimated,
    variables.colorFifthEstimated,
    variables.colorSixthEstimated,
  ],
};

export const RETENTION_WITH_BENCHMARK: TColorKey = {
  PRIMARY: [variables.colorBrandBlue, variables.colorSecond, variables.colorThird, '#b6bdf3'],
  LIGHT: [],
};

export const SINGLE_LINE_WITH_BENCHMARK: TColorKey = {
  PRIMARY: [variables.colorBrandBlue, '#b6bdf3'],
  LIGHT: [],
};

export const DOUBLE_LINE_WITH_BENCHMARK: TColorKey = {
  PRIMARY: [variables.colorBrandBlue, variables.colorSecond, '#b6bdf3'],
  LIGHT: [],
};

export const CARD_PROPS: ICardProps = {
  raised: true,
  elevation: 1,
  style: {
    marginTop: 0,
    marginBottom: 0,
    padding: 32,
    overflow: 'visible',
  },
};

export const RETENTION_KEYS: IChartKeys = {
  [ANALYTICS_ATTRIBUTES.D1]: ANALYTICS_DB_ATTRIBUTES.D1,
  [ANALYTICS_ATTRIBUTES.D7]: ANALYTICS_DB_ATTRIBUTES.D7,
  [ANALYTICS_ATTRIBUTES.D30]: ANALYTICS_DB_ATTRIBUTES.D30,
};

export const PAYING_RETENTION_KEYS: IChartKeys = {
  [ANALYTICS_ATTRIBUTES.P1]: ANALYTICS_DB_ATTRIBUTES.P1,
  [ANALYTICS_ATTRIBUTES.P7]: ANALYTICS_DB_ATTRIBUTES.P7,
  [ANALYTICS_ATTRIBUTES.P30]: ANALYTICS_DB_ATTRIBUTES.P30,
};

export const ABORT_RATE_KEYS: IChartKeys = {
  [ANALYTICS_ATTRIBUTES.ABORT_RATE]: ANALYTICS_DB_ATTRIBUTES.ABORT_RATE,
};

export const ABORT_EVENTS_KEYS: IChartKeys = {
  [ANALYTICS_ATTRIBUTES.ABORT_EVENTS_UNKNOWN]: ANALYTICS_DB_ATTRIBUTES.ABORT_EVENTS_UNKNOWN,
  [ANALYTICS_ATTRIBUTES.ABORT_EVENTS_TIMEOUT]: ANALYTICS_DB_ATTRIBUTES.ABORT_EVENTS_TIMEOUT,
  [ANALYTICS_ATTRIBUTES.ABORT_EVENTS_TERMINATED]: ANALYTICS_DB_ATTRIBUTES.ABORT_EVENTS_TERMINATED,
  [ANALYTICS_ATTRIBUTES.ABORT_EVENTS_BACKGROUNDED]:
    ANALYTICS_DB_ATTRIBUTES.ABORT_EVENTS_BACKGROUNDED,
  [ANALYTICS_ATTRIBUTES.ABORT_EVENTS_UNINTENTIONAL]:
    ANALYTICS_DB_ATTRIBUTES.ABORT_EVENTS_UNINTENTIONAL,
  [ANALYTICS_ATTRIBUTES.ABORT_EVENTS_INTENTIONAL]: ANALYTICS_DB_ATTRIBUTES.ABORT_EVENTS_INTENTIONAL,
};

export const IN_BAND_WIN_RATE_KEYS: IChartKeys = {
  [ANALYTICS_ATTRIBUTES.IN_BAND_WIN_RATE]: ANALYTICS_DB_ATTRIBUTES.IN_BAND_WIN_RATE,
};

export const Z_COMPLETION_KEYS: IChartKeys = {
  [ANALYTICS_ATTRIBUTES.COMPLETION_1Z]: ANALYTICS_DB_ATTRIBUTES.COMPLETION_1Z,
  [ANALYTICS_ATTRIBUTES.COMPLETION_2Z]: ANALYTICS_DB_ATTRIBUTES.COMPLETION_2Z,
  [ANALYTICS_ATTRIBUTES.COMPLETION_3Z]: ANALYTICS_DB_ATTRIBUTES.COMPLETION_3Z,
};

export const DAY_1_Z_GAMES_PER_INSTALL_KEYS: IChartKeys = {
  [ANALYTICS_ATTRIBUTES.DAY_1_Z_GAMES_PER_INSTALL]:
    ANALYTICS_DB_ATTRIBUTES.DAY_1_Z_GAMES_PER_INSTALL,
};
