/**
 * function taken from the progression-room-service used to parse out developer keys
 * from a given text
 * @param text value to parse out the developer key from
 * @returns a list of the developer keys present in the text
 */
export const extractDeveloperKeyFromInput = (text: string | undefined | null): string[] => {
  const keys: string[] = [];

  // this regex is parsing for values wrapped in ${<>} where <> is a string of N number of characters where N >= 1
  const regex = /\$\{([^}{]+)\}/g;

  if (text === undefined || text === null) {
    return keys;
  }

  let parsed = regex.exec(text);

  while (parsed !== null) {
    keys.push(parsed[1]);

    parsed = regex.exec(text);
  }

  return keys;
};
