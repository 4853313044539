export enum FORM_ERRORS {
  INVALID_EMAIL = 'Please enter a valid email address',
  INVALID_URL = 'Please enter a valid URL',
  INVALID_LENGTH = 'Must be less than 255 characters',
  FIELD_EMPTY = 'Must be at least one character',
  INVALID_TELEPHONE = 'Please enter a valid phone number',
  COMPANY_NAME_NOT_UNIQUE = 'Company name is taken, enter a different name',
  INVALID_VERSION = 'Please enter a valid version number',
  INVALID_SPECIAL_CHARACTERS = 'Only special characters ._- are allowed',
  HTTPS_REQUIRED = 'https:// url required',
  INVALID_OPTION = 'Please select one of the options',
  INVALID_SPACES = 'Must not include spaces',
  IS_MANDATORY = 'is mandatory',
  INVALID_HEX_CODE = 'Must be a 6 character hex code starting with # (ie: #FFFFFF)',
}

// this support urls beginning with both http:// and https://
export const properUrlFormat: RegExp = /^(?:http(s)?:\/\/)?[\w]+(?:\.[\w\.-]+)+[\w\-_~:/?#[\]@!\$&'\(\)\*\+,;=]+$/;

// this only supports urls beginning with https://
// also allows '.' to be in the extension of url
// eslint-disable-next-line max-len
export const properSecuredUrlFormat: RegExp = /^(https:\/\/)[\w]+(?:\.[\w\.-]+)+[\w\-_~:/?#[\]@!\$&'\(\)\*\+\.,;=]+$/;

// this is to test that only allowed characters are in the phone number: 0-9 () , . + - and space
export const allowedPhoneNumberCharacters: RegExp = /[^\d\(\)\,\.\+\-\s]/;

export enum RESPONSE_TYPES {
  ERROR = 'error',
  SUCCESS = 'success',
}

export const FEEDBACK_MAX_LENGTH = 5000;

export const ONE_MB_IN_KB = 1024;
