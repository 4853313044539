import { IFileChunkStartEnd } from '@appGameHome/common/interfaces';

export const FILE_CHUNK_SIZE: number = 10 * 1024 * 1024; // 10MB

export function calculateNumberOfChunks(fileSize: number, fileChunkSize: number): number {
  return Math.ceil(fileSize / fileChunkSize);
}

export function fileNameCleanUp(filename: string): string {
  return filename.replace(/[^\w._\-]/g, '');
}

/** returns the array of the start and end value for file chunk
 * @params { partNumber } number - the part number of the chunk
 * @params { numberOfChunks } number - number of chunks in the file upload
 * @params { fileChunkSize } number - the standard chunk size
 * @returns { IFileChunkStartEnd } - returns the object of the start and end value for file chunk
 */
export function calculateFileChunkStartEnd(
  partNumber: number,
  numberOfChunks: number,
  fileChunkSize: number
): IFileChunkStartEnd {
  // eslint-disable-next-line id-blacklist
  let startEnd: IFileChunkStartEnd = { start: (partNumber - 1) * fileChunkSize, end: undefined };

  if (partNumber < numberOfChunks) {
    startEnd.end = partNumber * fileChunkSize;
  }

  return startEnd;
}
