/* eslint-disable @typescript-eslint/no-unsafe-call */
// TODO UPDATE [WS-22306] properly type this file
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import { Dispatch } from 'redux';

import * as ApiUtils from './utils';
import { IJsonBody, IUploadedImages } from './utils';

export const BASE_THEME = {
  COLOR: 'baseTheme/COLOR',
};

export const CUSTOM_THEME = {
  SAVED_THEMES: 'customTheme/SAVED_THEMES',
};

export const ADVANCED_THEME = {
  UPDATE: 'advancedTheme/THEME',
  GRADIENT: 'advancedTheme/GRADIENT',
  GRADIENT_REPLACE: 'advancedTheme/GRADIENT_REPLACE',
};

export interface IBaseTheme {
  id: number;
  jsonBody: {
    viewPrimaryColor: unknown;
    opacityLevels: { opacityPrimary: { value: string } };
    sheetStyles: {
      viewPrimaryColor: { color: string; backgroundColor: string };
      textColorPrimary: { color: string };
      textColorSecondary: { color: string };
    };
    gradients: {
      backgroundViewGradient: {
        gradient: unknown[];
      };
    };
  };
}

export interface IBaseThemeResponse extends IBaseTheme {
  status?: string;
  id: number;
  errors?: string[];
}

export const storeTheme = (theme: { id: number }) => {
  // create a themeData object that holds the themeId and the theme payload
  // and adds it to the store to prevent refetching the theme each time
  let themeData: Record<number, unknown> = {};
  themeData[theme.id] = theme;

  return {
    type: BASE_THEME.COLOR,
    theme: themeData,
  };
};

export const getBaseTheme = (themeId: number, gameId: number) => (dispatch: Dispatch) => {
  return ApiUtils.getBaseTheme(themeId, gameId)
    .then(camelize)
    .then((response) => {
      dispatch(storeTheme(response as unknown as { id: number }));

      // TODO UPDATE [WS-22306] properly type this response
      return response as unknown as IBaseTheme;
    });
};

// schema for action arguments
// state[selected].jsonBody[mainField][subField][keyField] = data;

export const receiveTheme = (
  mainField: string,
  subField: string,
  keyField: string,
  data: string | number | File,
  selected: number
) => {
  return {
    type: ADVANCED_THEME.UPDATE,
    mainField,
    subField,
    keyField,
    data,
    selected,
  };
};

// schema for action arguments
// state[selected].jsonBody.gradients[subField].gradient[index] = data;

export const receiveGradientTheme = (
  subField: string,
  data: string,
  selected: number,
  index: number
) => {
  return {
    type: ADVANCED_THEME.GRADIENT,
    subField,
    data,
    selected,
    index,
  };
};

// schema for action arguments
// state[selected].jsonBody.gradients[subField] = data;
export const receiveThemeGradientToReplace = (
  subField: string,
  data: string[],
  selected: number
) => {
  return {
    type: ADVANCED_THEME.GRADIENT_REPLACE,
    subField,
    data,
    selected,
  };
};

// unable to dispatch to store since the api returns only 1 of many themes that are saved.
// if the themes are required in the future, it will be necessary to create an action that actually
// fetches the themes related to the game.

export const saveBaseTheme =
  (
    gameId: string,
    data: IJsonBody,
    themeName: string,
    themeId: string,
    uploadedImages: IUploadedImages
  ) =>
  (dispatch: Dispatch) => {
    return ApiUtils.saveBaseTheme(gameId, data, themeName, themeId, uploadedImages).then(
      (response: IBaseThemeResponse) => {
        if (response.status !== 'unprocessable_entity') {
          dispatch(storeTheme(camelize(response)));
        }

        return response;
      }
    );
  };

export const updateTheme =
  (
    gameId: string,
    data: IJsonBody,
    baseThemeId: string,
    themeId: string,
    uploadedImages: IUploadedImages
  ) =>
  (dispatch: Dispatch) => {
    return ApiUtils.updateTheme(gameId, data, baseThemeId, themeId, uploadedImages).then(
      (response: IBaseThemeResponse) => {
        if (response.status !== 'unprocessable_entity') {
          dispatch(storeTheme(camelize(response)));
        }

        return response;
      }
    );
  };

export const emailCustomTheme = (id: string) => {
  return ApiUtils.emailCustomTheme(id).then((response: IBaseThemeResponse) => {
    return response;
  });
};

export const requestSync = (gameId: string) => (dispatch: Dispatch) => {
  return ApiUtils.requestSync(gameId).then((response: IBaseThemeResponse) => {
    dispatch(storeTheme(camelize(response)));

    return response;
  });
};

export const syncThemeToProd = (id: string) => (dispatch: Dispatch) => {
  return ApiUtils.syncThemeToProd(id).then((response: IBaseThemeResponse) => {
    dispatch(storeTheme(camelize(response)));

    return response;
  });
};
