import { createSlice } from '@reduxjs/toolkit';

import {
  fetchGameChallengeProposal,
  saveGameChallengeProposal,
  updateGameChallengeProposal,
  uploadGameChallengeImages,
} from '@app/common/actions/gameChallengeProposalActions';
import { replaceNullsWith } from '@app/common/helpers';
import {
  IGameChallengeProposal,
  TGameChallengeProposalResponse,
} from '@app/modules/NflChallenge/ProposalPage/interfaces';

export const initialState: IGameChallengeProposal = {
  form: {
    artStyle: '',
    competitiveFormat: '',
    gameSummary: '',
    gameplayOverview: '',
    postWorkAndPlanning: '',
    scoring: '',
    skillFactor: '',
    useOfNflBranding: '',
    userInterface: '',
    workingTitle: '',
  },
  images: {
    userInterfaceImages: [],
    artStyleImages: [],
    postWorkAndPlanningImages: [],
  },
  isSubmitted: false,
  updatedAt: '',
};

const replaceNullsWithEmptyStr = replaceNullsWith('');

const extractProposal = (
  proposalResponse: TGameChallengeProposalResponse
): IGameChallengeProposal => {
  const {
    isSubmitted,
    updatedAt,
    userInterfaceImages,
    artStyleImages,
    postWorkAndPlanningImages,
    ...form
  } = proposalResponse;

  return {
    images: {
      userInterfaceImages,
      artStyleImages,
      postWorkAndPlanningImages,
    },
    form: replaceNullsWithEmptyStr(form),
    isSubmitted,
    updatedAt,
  };
};

const nflChallengeProposal = createSlice({
  name: 'nflChallengeProposal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGameChallengeProposal.fulfilled, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { form, isSubmitted, images, updatedAt } = extractProposal(action.payload);

      return {
        ...state,
        form,
        isSubmitted,
        images,
        updatedAt,
      };
    });
    builder.addCase(fetchGameChallengeProposal.rejected, (_, action) => {
      throw action.error;
    });

    builder.addCase(saveGameChallengeProposal.fulfilled, (state, action) => {
      const { form, isSubmitted, updatedAt } = extractProposal(action.payload);

      return {
        ...state,
        form,
        isSubmitted,
        updatedAt,
      };
    });
    builder.addCase(saveGameChallengeProposal.rejected, (_, action) => {
      throw action.error;
    });

    builder.addCase(updateGameChallengeProposal.fulfilled, (state, action) => {
      const { form, isSubmitted, updatedAt } = extractProposal(action.payload);

      return {
        ...state,
        form,
        isSubmitted,
        updatedAt,
      };
    });
    builder.addCase(updateGameChallengeProposal.rejected, (_, action) => {
      throw action.error;
    });

    builder.addCase(uploadGameChallengeImages.fulfilled, (state, action) => {
      const { updatedAt, ...images } = action.payload;

      return {
        ...state,
        images,
        updatedAt,
      };
    });
    builder.addCase(uploadGameChallengeImages.rejected, (_, action) => {
      throw action.error;
    });
  },
});

export default nflChallengeProposal.reducer;
