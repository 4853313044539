import * as React from 'react';

import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import { Styles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';

import { IBaseProps } from '../../interfaces/componentBase';
import variables from '../../styles/core/variables.scss';
import './styles.scss';

export interface IAtomButtonProps extends IBaseProps {
  buttonContent: string | JSX.Element;
  // matches our design guide for button
  buttonType: TButtonType;
  isErrorMode: boolean;
  onClick: () => void;
  isDisabled: boolean;
  size: TButtonSize;
  // this is needed for the Mui withStyle classes to be added
  classes?: {
    [className: string]: string;
  };
  // className prop will not be wired-up for atomButton, add constructor to class if this prop needs to be added
  // className?: string
}

export type TAtomButtonOptionalProps = 'isDisabled' | 'size' | 'isErrorMode';

type TDefaultProps = Required<Pick<IAtomButtonProps, TAtomButtonOptionalProps>>;

// buttonType props matches our design guide
export type TButtonType = 'primary' | 'secondary' | 'tertiary';
type TButtonSize = 'small' | 'medium' | 'large';

// mocks buttonColor and buttonVariant props for Material UI Button Props
interface IColorVariantProps {
  buttonColor: 'inherit' | 'primary' | 'secondary';
  buttonVariant: 'text' | 'outlined' | 'contained';
}

// simplify logic for out dev to TButtonType
// this is match of our buttonType to material UI button's prop of color and variant
// primary => containedPrimary
// in MaterialUIButton definition: buttonType='primary' && buttonVariant='contained'
// secondary => outlinedPrimary
// in MaterialUIButton definition: buttonType='primary' && buttonVariant='outlined'
// tertiary => textPrimary
// in MaterialUIButton definition: buttonType='primary' && buttonVariant='text'
// if button is error mode, then buttonType='secondary' variant remains the same
function determineColorVariantProps(buttonType: string, isErrorMode?: boolean): IColorVariantProps {
  let exportProps: IColorVariantProps = {
    buttonColor: isErrorMode ? 'secondary' : 'primary',
    buttonVariant: 'contained',
  };

  switch (buttonType) {
    case 'primary':
      break;
    case 'secondary':
      exportProps.buttonVariant = 'outlined';
      break;
    case 'tertiary':
      exportProps.buttonVariant = 'text';
      break;
    default:
      break;
  }

  return exportProps;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styles: Styles<Theme, Record<string, any>, string> = () => ({
  // basic style that is applied to all buttons
  root: {
    fontSize: '16px',
    lineHeight: '21px',
    fontFamily: 'Roboto',
    borderRadius: '4px',
    textTransform: 'none',
    flexShrink: 0,
  },
  sizeLarge: {
    fontWeight: 500,
    padding: '8px 24px',
    height: '40px',
  },
  // primary
  contained: {
    color: 'white',
    '&:disabled': {
      color: `${variables.colorGray200DisabledState}`,
      backgroundColor: `${variables.colorGray25ContentBackground}`,
      borderColor: `${variables.colorGray25ContentBackground}`,
    },
  },
  containedPrimary: {
    backgroundColor: `${variables.colorBrandBlue}`,
    '&:hover': {
      backgroundColor: `${variables.colorBlueHoverDark}`,
    },
  },
  containedSecondary: {
    backgroundColor: `${variables.colorRed}`,
    '&:hover': {
      backgroundColor: `${variables.colorRedHover}`,
    },
  },
  // secondary
  outlined: {
    backgroundColor: `${variables.colorWhite}`,
    '&:disabled': {
      color: `${variables.colorGray200DisabledState}`,
      borderColor: `${variables.colorGray100Borders}`,
    },
  },
  outlinedPrimary: {
    color: `${variables.colorBrandBlue}`,
    borderColor: `${variables.colorBrandBlue}`,
    '&:hover': {
      color: `${variables.colorBlueHoverDark}`,
      backgroundColor: `${variables.colorBlueHoverLight}`,
      borderColor: `${variables.colorBlueHoverDark}`,
    },
  },
  outlinedSecondary: {
    color: `${variables.colorRed}`,
    borderColor: `${variables.colorRed}`,
    '&:hover': {
      color: `${variables.colorRedHover}`,
      backgroundColor: `${variables.colorRedBackground}`,
      borderColor: `${variables.colorRedHover}`,
    },
  },
  // tertiary
  text: {
    border: `1px solid transparent`,
    '&:disabled': {
      color: `${variables.colorGray200DisabledState}`,
    },
  },
  textPrimary: {
    color: `${variables.colorBrandBlue}`,
    '&:hover': {
      color: `${variables.colorBlueHoverDark}`,
      backgroundColor: `${variables.colorBlueHoverLight}`,
    },
  },
  textSecondary: {
    color: `${variables.colorRed}`,
    '&:hover': {
      color: `${variables.colorRedHover}`,
      backgroundColor: `${variables.colorRedBackground}`,
    },
  },
});

class AtomButton extends React.PureComponent<IAtomButtonProps> {
  public static defaultProps: TDefaultProps = {
    isDisabled: false,
    size: 'large',
    isErrorMode: false,
  };

  public render(): JSX.Element {
    let {
      onClick,
      buttonType,
      classes,
      id,
      isDisabled,
      size,
      buttonContent,
      isErrorMode,
      dataAttributes,
    } = this.props;
    let colorVariantProps: IColorVariantProps = determineColorVariantProps(buttonType, isErrorMode);

    return (
      <Button
        id={id}
        size={size}
        variant={colorVariantProps.buttonVariant}
        color={colorVariantProps.buttonColor}
        onClick={onClick}
        classes={classes}
        disabled={isDisabled}
        className={`atom-component__button ${buttonType}`}
        {...dataAttributes}
      >
        {buttonContent}
      </Button>
    );
  }
}

export default withStyles(styles)(AtomButton);
