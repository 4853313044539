import * as React from 'react';

import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import OutlinedInput from '@mui/material/OutlinedInput';
import { lightBlue } from '@mui/material/colors';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';

import { IDataAttributes } from 'portal-common-library/interfaces/dataAttributes';

import { MATERIAL_UI_VARIANTS } from '../constants';
import { TSelectChangeEvent } from '../interfaces';
import './CommonComponent.css';
import './styles/materialSingleSelector.scss';

interface IProps {
  id: string;
  dropdownLabel?: string; // the label for the dropdown menu
  attributeName: string; // the name of the attribute we want to change with this dropdown.
  selectionOptions: DropdownOption[];
  handleChange: (event: TSelectChangeEvent) => void;
  // can be any type just as DropdownOption
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentValue: any;
  hasError?: boolean;
  errorText?: string;
  disabledText?: string;
  isDisabled?: boolean;
  shouldDisableUnderline?: boolean;
  variant?: MATERIAL_UI_VARIANTS;
  isFullWidth?: boolean;
  // optional because still needs to be added to the rest of the selectors
  dataAttributes?: IDataAttributes;
}

type TDefaultKeys =
  | 'shouldDisableUnderline'
  | 'dropdownLabel'
  | 'variant'
  | 'isDisabled'
  | 'isFullWidth'
  | 'hasError'
  | 'errorText';

type TDefaultProps = Pick<IProps, TDefaultKeys>;

export interface DropdownOption {
  // can be any type: string | number | boolean | null | undefined | any enum
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  component: string | number;
}

const theme: Theme = createTheme({
  palette: {
    primary: lightBlue,
  },
});

class MaterialSingleSelector extends React.PureComponent<IProps> {
  public static defaultProps: TDefaultProps = {
    shouldDisableUnderline: false,
    dropdownLabel: '',
    variant: 'standard' as MATERIAL_UI_VARIANTS,
    isDisabled: false,
    isFullWidth: true,
    hasError: false,
    errorText: '',
  };

  public renderDropdownLabel(): JSX.Element | null {
    let { dropdownLabel } = this.props;

    if (dropdownLabel !== '') {
      return <>{dropdownLabel}</>;
    }

    return null;
  }

  public render(): JSX.Element {
    let {
      id,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      currentValue,
      handleChange,
      attributeName,
      hasError,
      errorText,
      selectionOptions,
      disabledText,
      dropdownLabel,
      isDisabled,
      shouldDisableUnderline,
      variant,
      isFullWidth,
      dataAttributes,
    } = this.props;

    const labelStyle: React.CSSProperties = {
      fontSize: '16px',
      fontWeight: 'normal',
      height: '40px',
      top: '13px',
    };

    let inputElement: JSX.Element = <Input disableUnderline={shouldDisableUnderline} />;
    const selectStyle: React.CSSProperties = {
      width: '100%',
      fontSize: '16px',
      textAlign: 'left',
    };
    const errorDataAttribute = !!dataAttributes
      ? { 'data-qa': `${dataAttributes['data-qa']}-error` }
      : {};

    switch (variant) {
      case MATERIAL_UI_VARIANTS.OUTLINED:
        inputElement = <OutlinedInput label={dropdownLabel} />;
        labelStyle.top = '0px';
        break;
      case MATERIAL_UI_VARIANTS.FILLED:
        inputElement = <FilledInput />;
        labelStyle.top = '-5px';
        break;
      // case standard
      default:
        selectStyle.paddingTop = '11px';
        break;
    }

    // really making sure &ZeroWidthSpace; does not cause weird width white padding
    const selectorInputLabel = !dropdownLabel ? { label: '\u200B' } : {};

    return (
      <ThemeProvider theme={theme}>
        <FormControl
          className={`single-selector${!dropdownLabel ? ' no-label' : ''}`}
          variant={variant}
          id={id}
          fullWidth={isFullWidth}
          disabled={isDisabled}
          margin="dense"
        >
          <InputLabel
            id={`${id}-label`}
            style={labelStyle}
            variant={variant}
            htmlFor={`${id}-dropdown`}
          >
            {this.renderDropdownLabel()}
          </InputLabel>
          <NativeSelect
            margin="dense"
            style={selectStyle}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value={currentValue}
            onChange={handleChange}
            input={inputElement}
            error={hasError}
            inputProps={{
              ...selectorInputLabel,
              name: attributeName,
              id: `${id}-dropdown`,
            }}
            {...dataAttributes}
          >
            {selectionOptions.map((option: DropdownOption, index: number) => {
              return (
                <option
                  className="single-selector-menuitem"
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  value={option.value}
                  key={index}
                  disabled={disabledText === option.component}
                >
                  {option.component}
                </option>
              );
            })}
          </NativeSelect>
          {hasError && (
            <FormHelperText {...errorDataAttribute} error={hasError}>
              {errorText}
            </FormHelperText>
          )}
        </FormControl>
      </ThemeProvider>
    );
  }
}

export { MaterialSingleSelector };
