import capitalize from 'lodash/capitalize';

import {
  allowedPhoneNumberCharacters,
  FORM_ERRORS,
  properSecuredUrlFormat,
  properUrlFormat,
} from '@app/common/constants';
import { PASSWORD_ERRORS } from '@app/modules/Account/modules/User/constants';
import { UserFlowErrors } from '@app/modules/SignUpFlow/subcomponents';

import PasswordWrapper from '../components/PasswordWrapper/PasswordWrapper';
import { ICurrentUser, IPublisher2, IUser, TSkillzSupport } from '../interfaces';
import { VALID_EMAIL_REGEX } from '../regex';

export interface IValidation {
  status: boolean;
  error: string;
}

type TPasswordValidation = (password: string) => IValidation;
type TEmailValidation = (password: string) => IValidation;
type TPhoneNumberValidation = (phoneNumber: string) => IValidation;

export const DEFAULT_VALID_RESPONSE: IValidation = { status: true, error: '' };

export const passwordValidation: TPasswordValidation = (password: string): IValidation => {
  if (password.length < 8) {
    return { status: false, error: UserFlowErrors.PasswordMinimum };
  }
  if (password.length > 128) {
    return { status: false, error: UserFlowErrors.PasswordMaximum };
  }

  return DEFAULT_VALID_RESPONSE;
};

export const emailValidation: TEmailValidation = (email: string): IValidation => {
  let formatCheck: RegExp = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  if (!formatCheck.exec(email)) {
    return { status: false, error: FORM_ERRORS.INVALID_EMAIL };
  }

  return DEFAULT_VALID_RESPONSE;
};

export function lengthValidation(
  attributeName: string,
  attributeValue: string,
  maximumLength: number = 250
): IValidation {
  if (attributeValue && attributeValue.length > maximumLength) {
    if (attributeName === '') {
      return {
        status: false,
        error: `Must be fewer than ${maximumLength} characters`,
      };
    }

    return {
      status: false,
      error: `${capitalize(attributeName)} must be fewer than ${maximumLength} characters`,
    };
  }

  return DEFAULT_VALID_RESPONSE;
}

export const phoneNumberValidation: TPhoneNumberValidation = (phoneNumber: string): IValidation => {
  let hasInvalidCharacters: boolean = allowedPhoneNumberCharacters.test(phoneNumber);
  // this is replacing all non-digit characters with an empty string thus removing them
  let digitsFromPhoneNumber: string = phoneNumber.replace(/\D/gi, '');
  let hasInvalidNumberOfDigits: boolean =
    digitsFromPhoneNumber.length < 7 || digitsFromPhoneNumber.length > 12;
  if (hasInvalidCharacters || hasInvalidNumberOfDigits) {
    return { status: false, error: FORM_ERRORS.INVALID_TELEPHONE };
  }

  return DEFAULT_VALID_RESPONSE;
};

export const urlValidation = (website: string): IValidation => {
  if (!properUrlFormat.test(website)) {
    return { status: false, error: FORM_ERRORS.INVALID_URL };
  }

  return DEFAULT_VALID_RESPONSE;
};

export const securedUrlValidation = (website: string): IValidation => {
  let httpsPattern: RegExp = /^(https:\/\/)/;

  if (!properSecuredUrlFormat.test(website)) {
    if (!httpsPattern.test(website)) {
      return { status: false, error: FORM_ERRORS.HTTPS_REQUIRED };
    }

    return { status: false, error: FORM_ERRORS.INVALID_URL };
  }

  return DEFAULT_VALID_RESPONSE;
};

export const emptyValidation = (value: string | undefined): IValidation => {
  if (value === '' || value === undefined) {
    return { status: false, error: FORM_ERRORS.FIELD_EMPTY };
  }

  return DEFAULT_VALID_RESPONSE;
};

export function semverValidation(version: string): IValidation {
  let formatCheck: RegExp = RegExp(/^([\w.]+)$/i);
  if (!formatCheck.exec(version)) {
    return { status: false, error: FORM_ERRORS.INVALID_VERSION };
  }

  return DEFAULT_VALID_RESPONSE;
}

export function specialCharacterValidation(value: string): IValidation {
  let formatCheck: RegExp = RegExp(/^([\w._\-\s]+)$/i);
  if (!formatCheck.exec(value)) {
    return { status: false, error: FORM_ERRORS.INVALID_SPECIAL_CHARACTERS };
  }

  return DEFAULT_VALID_RESPONSE;
}

export const isSkillzSupport = (user: IUser | ICurrentUser, publisher: IPublisher2): boolean => {
  return publisher.id === 1 && user.skillzSupport === ('full_access' as TSkillzSupport);
};

export const validateStrongPassword = (
  password: string,
  email: string = '',
  currentPassword: string = ''
): UserFlowErrors | PASSWORD_ERRORS | string => {
  if (password === '') {
    return UserFlowErrors.Blank;
  }

  if (password.length > 128) {
    return UserFlowErrors.PasswordMaximum;
  }

  // needed for the accounts page and password comparison of password vs currentPassword
  if (!!currentPassword && password === currentPassword) {
    return PASSWORD_ERRORS.DIFFERENT_PASSWORD;
  }

  let passwordExtraError: string = PasswordWrapper.extraValidation(password, email);
  if (!!passwordExtraError) {
    return passwordExtraError;
  }

  return '';
};

export const validateEmail = (email: string): UserFlowErrors | '' => {
  if (email === '') {
    return UserFlowErrors.Blank;
  }

  if (!VALID_EMAIL_REGEX.test(email)) {
    return UserFlowErrors.InvalidEmail;
  }

  return '';
};

export const validateInputForEmptyField = (input: string): UserFlowErrors | '' => {
  if (input === '') {
    return UserFlowErrors.Blank;
  }

  return '';
};

export const emptyFileNameValidation = (name: string, value: string | undefined): IValidation => {
  if (!value) {
    return { status: false, error: `${name} ${FORM_ERRORS.IS_MANDATORY}` };
  }

  return DEFAULT_VALID_RESPONSE;
};
