import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchProgressionRoomMappingsRequest, fetchProgressionRoomsRequest } from '../helpers';

export const fetchProgressionRooms = createAsyncThunk(
  'progressionRooms/fetchProgressionRooms',
  fetchProgressionRoomsRequest
);

export const fetchProgressionRoomMappings = createAsyncThunk(
  'progressionRooms/fetchProgressionRoomMappings',
  fetchProgressionRoomMappingsRequest
);
