import * as React from 'react';

import kebabCase from 'lodash/kebabCase';

import { IActionNoData, IGameInfo } from '@app/common/interfaces';
import alertIcon from '@app/imageAssets/Overview/error.svg';
import { META_TAGS_ATTRIBUTES } from '@app/modules/GameManagement/common/constants';
import { IGamePerformanceStatus } from '@app/modules/GameManagement/common/interfaces';
import {
  DAY_7_RETENTION_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL,
  GAMES_PER_DAU_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL,
  INSTALLS_TO_2Z_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL,
  SKILLZ_DAU_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL,
  SKILLZ_DAU_7_DAY_AVERAGE_SYNC_PRIZE_ENABLEMENT_GOAL,
} from '@appOverview/constants/gamePerformanceStatus';

import {
  PRIZE_ENABLEMENT_STEPS_COPY,
  PRIZE_ENABLEMENT_STEPS_STATE,
  SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE,
} from './constants';
import { ISortedGamePerformanceStatus, TSORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE } from './interfaces';
import { PathIcon } from './subcomponents';

export const sortGamePerformanceStatusAttributes = (
  status: IGamePerformanceStatus | null
): ISortedGamePerformanceStatus => {
  let complete: TSORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE[] = [];
  let incomplete: TSORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE[] = [];

  if (status === null) {
    return { complete, incomplete };
  }

  if (
    status.skillzDau7DayAverage &&
    status.skillzDau7DayAverage >= SKILLZ_DAU_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL
  ) {
    complete.push(SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.SKILLZ_DAU_7_DAY_AVERAGE);
  } else {
    incomplete.push(SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.SKILLZ_DAU_7_DAY_AVERAGE);
  }

  if (
    status.gamesPerDau7DayAverage &&
    status.gamesPerDau7DayAverage >= GAMES_PER_DAU_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL
  ) {
    complete.push(SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.GAMES_PER_DAU_7_DAY_AVERAGE);
  } else {
    incomplete.push(SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.GAMES_PER_DAU_7_DAY_AVERAGE);
  }

  if (
    status.day7Retention7DayAverage &&
    status.day7Retention7DayAverage >= DAY_7_RETENTION_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL
  ) {
    complete.push(SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.DAY_7_RETENTION_7_DAY_AVERAGE);
  } else {
    incomplete.push(SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.DAY_7_RETENTION_7_DAY_AVERAGE);
  }

  if (
    status.installsTo1z7DayAverage &&
    status.installsTo1z7DayAverage >= INSTALLS_TO_2Z_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL
  ) {
    complete.push(SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.INSTALLS_TO_1Z_7_DAY_AVERAGE);
  } else {
    incomplete.push(SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.INSTALLS_TO_1Z_7_DAY_AVERAGE);
  }

  if (status.isFair) {
    complete.push(SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.IS_FAIR);
  } else {
    incomplete.push(SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.IS_FAIR);
  }

  return {
    complete,
    incomplete,
  };
};

export const determineStepState = (
  stepValue: number | null | undefined,
  goal: number
): PRIZE_ENABLEMENT_STEPS_STATE => {
  return stepValue && stepValue >= goal
    ? PRIZE_ENABLEMENT_STEPS_STATE.PASSED
    : PRIZE_ENABLEMENT_STEPS_STATE.PENDING;
};

// gamePerformance - DAU

export const renderSkillzDauCopy = (
  skillzDau7DayAverage: number | null | undefined,
  game: IGameInfo
): JSX.Element => {
  const dauGoal =
    game.gameplayFormat === META_TAGS_ATTRIBUTES.REAL_TIME_ONLY
      ? SKILLZ_DAU_7_DAY_AVERAGE_SYNC_PRIZE_ENABLEMENT_GOAL
      : SKILLZ_DAU_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL;

  return (
    <p id={'skillz-dau-copy'} className={determineStepState(skillzDau7DayAverage, dauGoal)}>
      {dauGoal}&nbsp;{PRIZE_ENABLEMENT_STEPS_COPY.DAU}
    </p>
  );
};

export const renderSkillzDauPathIcon = (
  skillzDau7DayAverage: number | null | undefined
): JSX.Element => {
  return (
    <PathIcon
      state={determineStepState(
        skillzDau7DayAverage,
        SKILLZ_DAU_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL
      )}
    />
  );
};

// gamePerformance - Games per DAU

export const renderGamesPerDauPathIcon = (
  gamesPerDau7DayAverage: number | null | undefined
): JSX.Element => {
  return (
    <PathIcon
      state={determineStepState(
        gamesPerDau7DayAverage,
        GAMES_PER_DAU_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL
      )}
    />
  );
};

export const renderGamesPerDauCopy = (
  gamesPerDau7DayAverage: number | null | undefined
): JSX.Element => {
  return (
    <p
      id={'games-per-dau-copy'}
      className={determineStepState(
        gamesPerDau7DayAverage,
        GAMES_PER_DAU_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL
      )}
    >
      {PRIZE_ENABLEMENT_STEPS_COPY.GAMES_PER_DAU}
    </p>
  );
};

// gamePerformance - Day 7 Retention

export const renderDay7RetentionPathIcon = (
  day7Retention7DayAverage: null | number | undefined
): JSX.Element => {
  return (
    <PathIcon
      state={determineStepState(
        day7Retention7DayAverage,
        DAY_7_RETENTION_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL
      )}
    />
  );
};

export const renderDay7RetentionCopy = (
  day7Retention7DayAverage: null | number | undefined
): JSX.Element => {
  return (
    <p
      id={'day-7-retention-copy'}
      className={determineStepState(
        day7Retention7DayAverage,
        DAY_7_RETENTION_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL
      )}
    >
      {PRIZE_ENABLEMENT_STEPS_COPY.DAY_7_RETENTION}
    </p>
  );
};

// gamePerformance - Installs to 1z

export const renderInstallsTo1ZPathIcon = (
  installsTo1z7DayAverage: null | number | undefined
): JSX.Element => {
  return (
    <PathIcon
      state={determineStepState(
        installsTo1z7DayAverage,
        INSTALLS_TO_2Z_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL
      )}
    />
  );
};

export const renderInstallsTo1ZCopy = (
  installsTo1z7DayAverage: null | number | undefined
): JSX.Element => {
  return (
    <p
      id={'installs-to-1z-copy'}
      className={determineStepState(
        installsTo1z7DayAverage,
        INSTALLS_TO_2Z_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL
      )}
    >
      {PRIZE_ENABLEMENT_STEPS_COPY.INSTALLS_TO_2_Z}
    </p>
  );
};

// gamePerformance - FairPlay
const fairPlayState = (isFair: null | boolean | undefined): PRIZE_ENABLEMENT_STEPS_STATE => {
  let state: PRIZE_ENABLEMENT_STEPS_STATE;

  if (isFair === null) {
    state = PRIZE_ENABLEMENT_STEPS_STATE.PENDING;
  } else if (isFair) {
    state = PRIZE_ENABLEMENT_STEPS_STATE.PASSED;
  } else {
    state = PRIZE_ENABLEMENT_STEPS_STATE.ACTION_REQUIRED;
  }

  return state;
};

export const renderFairPlayPathIcon = (isFair: null | boolean | undefined): JSX.Element => {
  return <PathIcon state={fairPlayState(isFair)} />;
};

export const renderFairPlayCopy = (isFair: null | boolean | undefined): JSX.Element => {
  return (
    <p id={'fair-play-copy'} className={kebabCase(fairPlayState(isFair))}>
      {PRIZE_ENABLEMENT_STEPS_COPY.FAIR_PLAY}
    </p>
  );
};

export const renderPrizeEnablementAttributeCopy = (
  gamePerformanceStatus: IGamePerformanceStatus | null,
  attribute: TSORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE,
  game: IGameInfo,
  openFairnessModal?: () => IActionNoData
): JSX.Element => {
  switch (attribute) {
    case SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.SKILLZ_DAU_7_DAY_AVERAGE:
      return renderSkillzDauCopy(gamePerformanceStatus?.skillzDau7DayAverage, game);

    case SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.GAMES_PER_DAU_7_DAY_AVERAGE:
      return renderGamesPerDauCopy(gamePerformanceStatus?.gamesPerDau7DayAverage);

    case SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.DAY_7_RETENTION_7_DAY_AVERAGE:
      return renderDay7RetentionCopy(gamePerformanceStatus?.day7Retention7DayAverage);

    case SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.INSTALLS_TO_1Z_7_DAY_AVERAGE:
      return renderInstallsTo1ZCopy(gamePerformanceStatus?.installsTo1z7DayAverage);

    default:
      // styled in `desktopViewStyles` && `mobileViewStyles` .step-copy > div
      return (
        <div onClick={openFairnessModal}>
          {renderFairPlayCopy(gamePerformanceStatus?.isFair)}
          <img src={alertIcon} />
        </div>
      );
  }
};

export const renderPrizeEnablementAttributeIcon = (
  gamePerformanceStatus: IGamePerformanceStatus | null,
  attribute: TSORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE
): JSX.Element => {
  switch (attribute) {
    case SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.SKILLZ_DAU_7_DAY_AVERAGE:
      return renderSkillzDauPathIcon(gamePerformanceStatus?.skillzDau7DayAverage);

    case SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.GAMES_PER_DAU_7_DAY_AVERAGE:
      return renderGamesPerDauPathIcon(gamePerformanceStatus?.gamesPerDau7DayAverage);

    case SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.DAY_7_RETENTION_7_DAY_AVERAGE:
      return renderDay7RetentionPathIcon(gamePerformanceStatus?.day7Retention7DayAverage);

    case SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE.INSTALLS_TO_1Z_7_DAY_AVERAGE:
      return renderInstallsTo1ZPathIcon(gamePerformanceStatus?.installsTo1z7DayAverage);

    default:
      return renderFairPlayPathIcon(gamePerformanceStatus?.isFair);
  }
};

export function isPrizeEnablementRevamp(): boolean {
  return window.appConfig.featureFlags.prizeEnablementRevamp;
}
