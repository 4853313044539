import * as React from 'react';

import { IDashboardGame } from '@app/common/interfaces';
import {
  BaseAutocomplete,
  IBaseAutocompleteProps,
  IRequiredKey,
} from '@app/common/molecules/BaseAutocomplete';

import { TGameAccess } from '../helpers';

export type TGamesAutocompleteOption = IDashboardGame & IRequiredKey;

type IGamesAutocompleteProps = Omit<
  IBaseAutocompleteProps<TGamesAutocompleteOption>,
  'getOptionDetails'
> & {
  userRolesU6GameRolesAndPermissions: boolean;
  gameAccess?: TGameAccess;
};

class GamesAutocomplete extends React.Component<IGamesAutocompleteProps> {
  private readonly isDisabled = (game: IDashboardGame) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { gameAccess, userRolesU6GameRolesAndPermissions } = this.props;

    return (
      userRolesU6GameRolesAndPermissions &&
      (gameAccess?.every(({ productionId }) => productionId !== game.productionId) ?? false)
    );
  };

  private readonly getOptionDetails = (game: TGamesAutocompleteOption) => {
    return {
      iconUrl: game.iconUrl,
      label: game.title,
      isDisabled: this.isDisabled(game),
    };
  };

  public render(): JSX.Element {
    return (
      <BaseAutocomplete<TGamesAutocompleteOption>
        placeholder="Games"
        getOptionDetails={this.getOptionDetails}
        {...this.props}
        getOptionDisabled={this.isDisabled}
      />
    );
  }
}

export { GamesAutocomplete };
