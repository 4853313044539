import * as React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import SyncIcon from '@mui/icons-material/Sync';
import SyncAlt from '@mui/icons-material/SyncAlt';

import { IMenuItem } from 'portal-common-library/interfaces/menu';

import './styles/menuItemStyles.scss';

export enum PROGRESSION_ROOM_MENU_ITEM_ATTRIBUTES {
  DELETE = 'delete',
  EDIT_ENTRY_POINT = 'editEntryPoint',
  VIEW_IN_SANDBOX = 'viewInSandbox',
  ASSIGN_TO_BUILDS = 'assignToBuilds',
  STOP_IN_SANDBOX = 'stopInSandbox',
  CHANGE_NAME = 'changeName',
  ACTIVATE_IN_PRODUCTION = 'activateInProduction',
  DEACTIVATE_FROM_PRODUCTION = 'deactivateFromProduction',
  SYNC_TO_PRODUCTION = 'syncToProduction',
}

export enum PROGRESSION_ROOM_MENU_ITEM_COPY {
  DELETE = 'Delete',
  EDIT_ENTRY_POINT = 'Edit Entry Point',
  VIEW_IN_SANDBOX = 'Activate in Sandbox',
  ASSIGN_TO_BUILDS = 'Assign to Build(s)',
  STOP_IN_SANDBOX = 'Deactivate from Sandbox',
  CHANGE_NAME = 'Change Name',
  ACTIVATE_IN_PRODUCTION = 'Activate in Production',
  DEACTIVATE_FROM_PRODUCTION = 'Deactivate from Production',
  SYNC_TO_PRODUCTION = 'Sync To Production',
}

export const deleteProgressionRoom: IMenuItem = {
  attributeName: PROGRESSION_ROOM_MENU_ITEM_ATTRIBUTES.DELETE,
  itemBody: (
    <span className={'game-progression-room__menu-item-container'}>
      <DeleteIcon className={'menu-item'} />
      {PROGRESSION_ROOM_MENU_ITEM_COPY.DELETE}
    </span>
  ),
};

export const viewProgressionRoomInSandbox: IMenuItem = {
  attributeName: PROGRESSION_ROOM_MENU_ITEM_ATTRIBUTES.VIEW_IN_SANDBOX,
  itemBody: (
    <span className={'game-progression-room__menu-item-container'}>
      <CheckCircleRoundedIcon className={'menu-item'} />
      {PROGRESSION_ROOM_MENU_ITEM_COPY.VIEW_IN_SANDBOX}
    </span>
  ),
};

export const stopProgressionRoomInSandbox: IMenuItem = {
  attributeName: PROGRESSION_ROOM_MENU_ITEM_ATTRIBUTES.STOP_IN_SANDBOX,
  itemBody: (
    <span className={'game-progression-room__menu-item-container'}>
      <CancelIcon className={'menu-item'} />
      {PROGRESSION_ROOM_MENU_ITEM_COPY.STOP_IN_SANDBOX}
    </span>
  ),
};

export const assignToBuilds: IMenuItem = {
  attributeName: PROGRESSION_ROOM_MENU_ITEM_ATTRIBUTES.ASSIGN_TO_BUILDS,
  itemBody: (
    <span className={'game-progression-room__menu-item-container'}>
      <LibraryAddIcon className={'menu-item'} />
      {PROGRESSION_ROOM_MENU_ITEM_COPY.ASSIGN_TO_BUILDS}
    </span>
  ),
};

export const editEntryPoint: IMenuItem = {
  attributeName: PROGRESSION_ROOM_MENU_ITEM_ATTRIBUTES.EDIT_ENTRY_POINT,
  itemBody: (
    <span className={'game-progression-room__menu-item-container'}>
      <CreateIcon className={'menu-item'} />
      {PROGRESSION_ROOM_MENU_ITEM_COPY.EDIT_ENTRY_POINT}
    </span>
  ),
};

export const changeEntryPointName: IMenuItem = {
  attributeName: PROGRESSION_ROOM_MENU_ITEM_ATTRIBUTES.CHANGE_NAME,
  itemBody: (
    <span className={'game-progression-room__menu-item-container'}>
      <SyncAlt className={'menu-item'} />
      {PROGRESSION_ROOM_MENU_ITEM_COPY.CHANGE_NAME}
    </span>
  ),
};

export const activateProgressionRoomInProduction: IMenuItem = {
  attributeName: PROGRESSION_ROOM_MENU_ITEM_ATTRIBUTES.ACTIVATE_IN_PRODUCTION,
  itemBody: (
    <span className={'game-progression-room__menu-item-container'}>
      <CheckCircleRoundedIcon className={'menu-item'} />
      {PROGRESSION_ROOM_MENU_ITEM_COPY.ACTIVATE_IN_PRODUCTION}
    </span>
  ),
};

export const deactivateProgressionRoomFromProduction: IMenuItem = {
  attributeName: PROGRESSION_ROOM_MENU_ITEM_ATTRIBUTES.DEACTIVATE_FROM_PRODUCTION,
  itemBody: (
    <span className={'game-progression-room__menu-item-container'}>
      <CancelIcon className={'menu-item'} />
      {PROGRESSION_ROOM_MENU_ITEM_COPY.DEACTIVATE_FROM_PRODUCTION}
    </span>
  ),
};

export const syncToProgressionRoomToProduction: IMenuItem = {
  attributeName: PROGRESSION_ROOM_MENU_ITEM_ATTRIBUTES.SYNC_TO_PRODUCTION,
  itemBody: (
    <span className={'game-progression-room__menu-item-container'}>
      <SyncIcon className={'menu-item'} />
      {PROGRESSION_ROOM_MENU_ITEM_COPY.SYNC_TO_PRODUCTION}
    </span>
  ),
};
