import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { useSnackbarContext } from '@skillzet/components';

import { renderFooterComponents } from '@app/modules/ContentManagementSystem/helpers/renderFooterComponents';
import { IHelpBitResponse } from '@app/modules/ContentManagementSystem/interfaces';

import { fetchHelpBit } from '../../utils';
import Content from '../Content/modules/Content';
import Footer from '../Content/modules/Footer';
import Header from '../Content/modules/Header';
import LoadingIndicator from '../LoadingIndicator';
import WizardSidebar from './subcomponents/WizardSidebar';
import './wizardView.scss';

export interface IWizardView {
  helpBitId: number;
  closeModal: () => void;
}

const iconStyle = {
  width: '14px',
  height: '14px',
};

const MODAL_ANIMATION_TIMEOUT = 100;

export const componentClassName = 'wizard-view-container';

const WizardView = ({ helpBitId, closeModal }: IWizardView): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [isInView, setIsInView] = useState(true);
  const [helpBitsList, setHelpBitsList] = useState([] as IHelpBitResponse[]);
  const { show: showSnackbar } = useSnackbarContext();
  const [currentHelpBit, setCurrentHelpBit] = useState({} as IHelpBitResponse);

  const handleToggleClose = () => {
    setIsInView((prev) => !prev);

    // give some time for animation to complete
    setTimeout(() => {
      closeModal();
    }, MODAL_ANIMATION_TIMEOUT);
  };

  const fetchHelpBitsChainRecursively = (
    id: number = helpBitId,
    fetchedHelpBits: IHelpBitResponse[] = []
  ): void => {
    void fetchHelpBit({ id }).then((response: { data: IHelpBitResponse } | void) => {
      if (!response) {
        setIsLoading(false);

        return;
      }

      const data = response.data;

      fetchedHelpBits.push(data);
      if (data.next) {
        fetchHelpBitsChainRecursively(data.next.id, fetchedHelpBits);
      } else {
        setHelpBitsList(fetchedHelpBits);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    try {
      fetchHelpBitsChainRecursively();
    } catch {
      showSnackbar('Could not fetch help bits');
    }
  }, []);

  useEffect(() => {
    if (helpBitsList.length) {
      setCurrentHelpBit(helpBitsList[0]);
    }
  }, [isLoading]);

  const handleChangeHelpBit = (targetHelpBitId: number) => {
    return (): void => {
      if (helpBitsList.length) {
        const targetHelpBit = helpBitsList.find(
          (helpBit: IHelpBitResponse) => helpBit.id === targetHelpBitId
        );
        if (targetHelpBit) {
          setCurrentHelpBit(targetHelpBit);
        }
      }
    };
  };

  return (
    <Fade in={isInView} mountOnEnter unmountOnExit>
      <Backdrop open={true} style={{ zIndex: 1000000 }}>
        {/* wizard modal's zIndex is 1000001 */}
        <div data-qa={componentClassName} className={componentClassName}>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <WizardSidebar
                helpBits={helpBitsList}
                currentHelpBit={currentHelpBit}
                handleChangeHelpBit={handleChangeHelpBit}
              />
              <div className={`${componentClassName}__main-content`}>
                <Header
                  components={[
                    <CloseIcon
                      data-qa={`helpbit-close-button`}
                      style={iconStyle}
                      key={'icon-1'}
                      onClick={handleToggleClose}
                    />,
                  ]}
                />
                <div className={`${componentClassName}__content-box`}>
                  <Content
                    id={helpBitId.toString()}
                    title={currentHelpBit.title}
                    content={currentHelpBit.content}
                  />
                  <Footer
                    components={renderFooterComponents(currentHelpBit, handleChangeHelpBit)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Backdrop>
    </Fade>
  );
};

export default WizardView;
