import * as React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import kebabCase from 'lodash/kebabCase';

import { AtomTooltip } from '@app/common/atoms';
import { IChartContentLoaderProps } from '@app/common/interfaces';

import './styles/chartStyles.scss';

export interface IChartProps {
  loading: boolean;
  chartComponent: JSX.Element;
  loadingComponent: React.ComponentType<IChartContentLoaderProps>;
  tooltip?: string | JSX.Element;
  title: string;
  id: string;
  idPrefix: string;
  shouldHaveSingleGame?: boolean;
}

type TDefaultProps = Required<Pick<IChartProps, 'tooltip'>>;
export interface IChartState {
  containerHeight: number;
  containerWidth: number;
  resizing: boolean;
}

function RequiresSingleGamePlaceholder({ chartTitle }: { chartTitle: string }) {
  return (
    <Stack sx={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <InfoIcon color="info" sx={{ fontSize: '40px', marginBottom: '22px' }} />
      <Typography variant={'body1'}>
        To view {chartTitle}, please select the game you want to analyze.
      </Typography>
    </Stack>
  );
}

// TODO: WS-8055 refactor Chart component to have all the calculation & rendering logic here
class Chart extends React.Component<IChartProps, IChartState> {
  public static defaultProps: TDefaultProps = {
    tooltip: '',
  };
  private interval: NodeJS.Timer | null;

  constructor(props: IChartProps) {
    super(props);
    this.state = {
      containerWidth: 0,
      containerHeight: 0,
      resizing: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.interval = null;
  }

  public componentDidMount(): void {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    clearInterval(this.interval!);
  }

  // this updates the state's containerHeight and containerWidth elements to
  // allow the ContentLoaderContainer know what the div size is
  public updateWindowDimensions(): void {
    let { idPrefix, title } = this.props;
    let chartContainer: HTMLElement | null = document.getElementById(
      `${idPrefix}__chart-outer-container-${kebabCase(title)}`
    );

    if (chartContainer) {
      // this is to prevent the slow shrinking, after resizing it waits
      // then it displays the chart
      this.interval = setTimeout(() => {
        this.setState({ resizing: false });
      }, 1000);

      // when the chart is being resized it sets the resizing value in the chart
      // in order to prevent the slow resize
      this.setState({
        containerWidth: chartContainer.offsetWidth,
        containerHeight: chartContainer.offsetHeight,
        resizing: true,
      });
    }
  }

  public renderLoader(Loading: React.ComponentType<IChartContentLoaderProps>): JSX.Element {
    let { containerHeight, containerWidth } = this.state;

    return <Loading height={containerHeight} width={containerWidth} speed={2} />;
  }

  public render(): JSX.Element {
    let { resizing: isResizing } = this.state;
    let {
      loading: isLoading,
      loadingComponent,
      chartComponent,
      tooltip,
      title,
      idPrefix,
      shouldHaveSingleGame,
    } = this.props;
    let kebabCaseTitle: string = kebabCase(title);

    const renderMainArea = () => {
      if (shouldHaveSingleGame) {
        return <RequiresSingleGamePlaceholder chartTitle={title} />;
      }

      if (isLoading || isResizing) {
        return this.renderLoader(loadingComponent);
      }

      return chartComponent;
    };

    return (
      <div
        className="common-component__chart-container"
        id={`${idPrefix}__chart-outer-container-${kebabCase(title)}`}
      >
        <div
          className="common-component__chart-inner-container"
          id={`${idPrefix}__chart-inner-container-${kebabCaseTitle}`}
        >
          <span
            className="common-component__chart-header-title"
            id={`${idPrefix}__chart-header-title-${kebabCaseTitle}`}
          >
            <h2>{title}</h2>
            <div
              className="common-component__chart-header-tooltip"
              id={`${idPrefix}__chart-tooltip-${kebabCaseTitle}`}
            >
              {tooltip !== '' && tooltip !== undefined && (
                <AtomTooltip copy={tooltip} placement="top" />
              )}
            </div>
          </span>
          {renderMainArea()}
        </div>
      </div>
    );
  }
}

export { Chart };
