import { IProgressionRoomVersion } from '@appEntryPoints/common/interfaces';

export const determineNextSandboxVersionNumber = (
  latestSandboxVersion: IProgressionRoomVersion | null,
  latestProdVersion: IProgressionRoomVersion | null
) => {
  // case 1: 0.1 for first sandbox version number
  if (!latestSandboxVersion) {
    return '0.1';
  }

  const sandboxVersionNumbers = latestSandboxVersion.versionNumber.split('.');

  // case 2: increase major version when sandbox version major version = prod major version
  //         if sandbox major version is < prod major then start off clean, since that's corrupt data
  if (
    !!latestProdVersion &&
    sandboxVersionNumbers[0] <= latestProdVersion.versionNumber.split('.')[0]
  ) {
    return `${Number(latestProdVersion.versionNumber.split('.')[0]) + 1}.0`;
  }

  // case 3: increase minor version when sandbox version major version > prod major version
  return `${sandboxVersionNumbers[0]}.${Number(sandboxVersionNumbers[1]) + 1}`;
};
