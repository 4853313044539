/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from 'redux';

import { TGameAccess } from '@app/common/helpers';
import {
  IAnalyticReducer,
  IConfig,
  ICurrentUser,
  IFeature,
  IGameInfo,
  IModalManagement,
  IPermissions,
  ISdkDownloads,
  ISnackbar,
  ISurveyTool,
  TGamesReducerState,
  IUser,
  ITournamentData,
} from '@app/common/interfaces';
import { IGameChallenge } from '@app/common/interfaces/gameChallenge';
import config from '@app/common/reducers/config';
import feature from '@app/common/reducers/features';
import analytics from '@app/modules/Analytics/reducer';
import { IContactUs } from '@app/modules/ContactUs/interfaces';
import contactUs from '@app/modules/ContactUs/reducer';
import currentUser from '@app/modules/CurrentUser/reducer';
import gameAccess from '@app/modules/GameAccess/reducer';
import { IGameReducer } from '@app/modules/GameManagement/interfaces';
import game from '@app/modules/GameManagement/reducer';
import games from '@app/modules/Games/reducer';
import gameUrl from '@app/modules/IntegrationGameUrl/reducer';
import verifyConfig from '@app/modules/IntegrationVerifyConfig/reducer';
import modalManagement from '@app/modules/ModalManagement/reducer';
import { IGameChallengeProposal } from '@app/modules/NflChallenge/ProposalPage/interfaces';
import gameChallengeProposal from '@app/modules/NflChallenge/ProposalPage/reducer';
import gameChallenge from '@app/modules/NflChallenge/reducer';
import { TNotificationReducerState } from '@app/modules/Notifications/interfaces';
import notifications from '@app/modules/Notifications/reducer';
import permissions from '@app/modules/Permissions/reducer';
import { IPublisherReducer } from '@app/modules/Publisher/interfaces';
import publisher from '@app/modules/Publisher/reducer';
import sdkDownloads from '@app/modules/SdkDownloads/reducer';
import snackbar from '@app/modules/Snackbar/reducer';
import { IStatement } from '@app/modules/Statements/interfaces';
import statements from '@app/modules/Statements/reducer';
import survey from '@app/modules/Survey/reducer';
import user from '@app/modules/User/reducer';
import { IGameProgression } from '@appGameProgression/common/interfaces';
import progression from '@appGameProgression/reducer';
import gameInfo from '@appOptimize/modules/GameInfo/reducer';
import customTheme from '@appOptimize/modules/Theme/reducer';
import tournament from '@appOptimize/modules/Tournament/reducer';

// TODO Add types to the remaining reducers and clean up duplicate reducers
export interface IRootState {
  gameInfo: IGameInfo;
  customTheme: any;
  gameUrl: any;
  publisher: IPublisherReducer;
  tournament: ITournamentData;
  verifyConfig: any;
  config: IConfig;
  user: IUser;
  currentUser: ICurrentUser;
  feature: IFeature;
  sdkDownloads: ISdkDownloads;
  survey: ISurveyTool;
  contactUs: IContactUs;
  games: TGamesReducerState;
  analytics: IAnalyticReducer<Date>;
  statements: IStatement[];
  modalManagement: IModalManagement;
  snackbar: ISnackbar;
  game: IGameReducer;
  notifications: TNotificationReducerState;
  permissions: IPermissions;
  progression: IGameProgression;
  gameChallenge: IGameChallenge;
  gameChallengeProposal: IGameChallengeProposal;
  gameAccess: TGameAccess;
}

const appReducer = combineReducers({
  gameInfo,
  customTheme,
  gameUrl,
  publisher,
  tournament,
  verifyConfig,
  config,
  user,
  currentUser,
  feature,
  sdkDownloads,
  survey,
  contactUs,
  games,
  analytics,
  statements,
  modalManagement,
  snackbar,
  game,
  notifications,
  permissions,
  progression,
  gameChallenge,
  gameChallengeProposal,
  gameAccess,
});

interface IRootReducerResetAction {
  type: 'RESET';
}

type ReducerParams = Parameters<typeof appReducer>;
const rootReducer = (state: ReducerParams[0], action: IRootReducerResetAction) => {
  if (action.type === 'RESET') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
