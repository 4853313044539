import * as React from 'react';

import Button from '@mui/material/Button';

import './styles/atomSingleSelectButtonStyles.scss';

interface IProps {
  isSelected: boolean;
  icon?: JSX.Element;
  buttonText: string;
  onClick: () => void;
  id: string;
  isDisabled?: boolean;
  isLargeFormat?: boolean;
}

type TDefaultProps = Required<Pick<IProps, 'isDisabled' | 'isLargeFormat'>>;

class AtomSingleSelectButton extends React.PureComponent<IProps> {
  public static defaultProps: TDefaultProps = {
    isDisabled: false,
    isLargeFormat: false,
  };

  public render(): JSX.Element {
    const { buttonText, icon, id, isSelected, onClick, isDisabled, isLargeFormat } = this.props;

    const selected = isSelected ? ' selected' : '';
    const largeFormat = isLargeFormat ? ' large-format' : '';
    const baseClass = 'atom-component__single-select-button';

    return (
      <Button
        id={`${id}-container`}
        disabled={isDisabled}
        className={`${baseClass}-border${selected} ${baseClass}-styles${largeFormat}`}
        onClick={onClick}
      >
        <div className={`${baseClass}-align-items${largeFormat}`}>
          {icon ? icon : <span />}
          <span>{buttonText}</span>
        </div>
      </Button>
    );
  }
}

export { AtomSingleSelectButton };
