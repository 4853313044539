import * as React from 'react';

import { ISnackBar, useSnackbarContext } from '@skillzet/components';

// utility function to pass the snackbar hook into a class component

export function WithSnackbar(Component: typeof React.Component) {
  return function WrappedComponent(props: Record<string, unknown>) {
    const snackbar = useSnackbarContext();

    return <Component {...props} snackbar={snackbar} />;
  };
}

export interface IWithSnackbarProp {
  snackbar: ISnackBar;
}

export function snackbarErrorMessage(error: Error) {
  return `An error occurred: ${error?.message || error.toString()}`;
}
