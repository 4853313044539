import { TSeasonStatus, ISeason } from './interfaces';

export const seasonStatusNames: Record<TSeasonStatus, string> = {
  active: 'Active',
  upcoming: 'Upcoming Seasons',
  past: 'Past Seasons',
  draft: 'Drafts',
  deleted: 'Deleted',
  // TODO [WS-23372] remove if we decide not to have published/unpublished in the future
  // unpublished: 'Unpublished',
};

// endpoint will be used to determine active, upcoming, and past seasons
const now = new Date('2022-02-01T00:00:00Z');
const tomorrow = new Date('2022-02-02T00:00:00Z');
const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
const yesterday = new Date('2022-01-31T00:00:00Z');
const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);

export const dummySeasons: ISeason[] = [
  {
    id: '0',
    color: '#CC48E8',
    syncedAt: now.toISOString(),
    seasonName: `Chelsea's Summer`,
    seasonIndex: 0,
    startTime: now.toISOString(),
    endTime: nextMonth.toISOString(),
    createdAt: now.toISOString(),
    updatedAt: now.toISOString(),
    deletedAt: now.toISOString(),
    pausedAt: now.toISOString(),
    gameId: '1',
    description: 'Description',
    seasonParams: {},
    isActive: true,
  },
  {
    id: '1',
    color: '#F5992C',
    syncedAt: now.toISOString(),
    seasonName: `Chelsea's Halloween`,
    seasonIndex: 1,
    startTime: tomorrow.toISOString(),
    endTime: nextMonth.toISOString(),
    createdAt: now.toISOString(),
    updatedAt: now.toISOString(),
    deletedAt: now.toISOString(),
    pausedAt: now.toISOString(),
    gameId: '1',
    description: 'Description',
    seasonParams: {},
    isActive: false,
  },
  {
    id: '2',
    color: '#DD6952',
    syncedAt: now.toISOString(),
    seasonName: `Chelsea's Thanksgiving`,
    seasonIndex: 2,
    startTime: tomorrow.toISOString(),
    endTime: nextMonth.toISOString(),
    createdAt: now.toISOString(),
    updatedAt: now.toISOString(),
    deletedAt: now.toISOString(),
    pausedAt: now.toISOString(),
    gameId: '1',
    description: 'Description',
    seasonParams: {},
    isActive: false,
  },
  {
    id: '3',
    color: '#7DB534',
    syncedAt: now.toISOString(),
    seasonName: `St.Patricks Day`,
    seasonIndex: -1,
    startTime: now.toISOString(),
    endTime: nextMonth.toISOString(),
    createdAt: now.toISOString(),
    updatedAt: now.toISOString(),
    deletedAt: now.toISOString(),
    pausedAt: now.toISOString(),
    gameId: '1',
    description: 'Description',
    seasonParams: {},
    isActive: false,
  },
  {
    id: '4',
    color: '#8CBAFF',
    syncedAt: null,
    seasonName: `Chelsea Season Idea`,
    seasonIndex: -2,
    startTime: now.toISOString(),
    endTime: nextMonth.toISOString(),
    createdAt: now.toISOString(),
    updatedAt: now.toISOString(),
    deletedAt: now.toISOString(),
    pausedAt: now.toISOString(),
    gameId: '1',
    description: 'Description',
    seasonParams: {},
    isActive: false,
  },
];

// not part of dummySeasons because SeasonHome test takes dummySeasons as a whole
export const pastSeason = {
  id: '5',
  color: '#FF3939',
  syncedAt: yesterday.toISOString(),
  seasonName: `Chelsea's Summer`,
  seasonIndex: 0,
  startTime: lastMonth.toISOString(),
  endTime: yesterday.toISOString(),
  createdAt: lastMonth.toISOString(),
  updatedAt: lastMonth.toISOString(),
  deletedAt: lastMonth.toISOString(),
  pausedAt: null,
  gameId: '1',
  description: 'Description',
  seasonParams: {},
  isActive: false,
};

export const COMPONENT_CLASS_NAME = 'game-home-seasons-tab';
