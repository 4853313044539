import React, { Dispatch, SetStateAction } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { TIME_IN_MILLISECONDS } from '@app/common/constants';
import { IDashboardGame } from '@app/common/interfaces';
import { hasLaunchedToStore, hasFirstGame } from '@app/modules/Dashboard/helpers';

import NumericSelector from './NumericSelector';
import SurveyTextInput from './SurveyTextInput';
import { SURVEY_24H_COOKIE } from './constants';
import { TSurveyQuestion, ISurveyAnswer, TUserType, TSurveyTarget } from './interfaces';

export const renderQuestionWithAppropriateComponent = (
  question: TSurveyQuestion,
  onChangeFunc: (newAnswer: ISurveyAnswer) => void
): JSX.Element => {
  if (question.questionType === 'numeric') {
    return (
      <Stack key={question.id} mb={2} spacing={1}>
        <Typography variant={'body1'}>{question.body}</Typography>
        <Box>
          <NumericSelector
            question={question}
            // all current numeric options use this label, hardcoding for now, but can change in the future
            startLabel={'Not at all satisfied'}
            endLabel={'Extremely satisfied'}
            onChange={onChangeFunc}
          />
        </Box>
      </Stack>
    );
  }

  return (
    <Stack key={question.id} mb={2} spacing={1}>
      <Typography variant={'body1'}>{question.body}</Typography>
      <SurveyTextInput key={question.id} onChange={onChangeFunc} surveyQuestionId={question.id} />
    </Stack>
  );
};

export const addAnswer =
  (answers: ISurveyAnswer[], setStateFunction: Dispatch<SetStateAction<ISurveyAnswer[]>>) =>
  (newAnswer: ISurveyAnswer) => {
    const existingAnswerIndex = answers.findIndex(
      (recordedAnswer) => recordedAnswer.surveyQuestionId === newAnswer.surveyQuestionId
    );

    if (existingAnswerIndex < 0) {
      setStateFunction((prev) => {
        return [...prev, newAnswer];
      });

      return;
    }

    setStateFunction((prev) => {
      const existingAnswers = [...prev];
      existingAnswers[existingAnswerIndex] = newAnswer;

      return existingAnswers;
    });
  };

export const validateAnswers = (
  allQuestions: TSurveyQuestion[],
  answers: ISurveyAnswer[]
): { errors?: string[] } => {
  const errorMessages: string[] = [];

  allQuestions.forEach((question) => {
    if (!question.isRequired) return;

    if (!answers.find((answer) => answer.surveyQuestionId === question.id)) {
      errorMessages.push(`Question "${question.body}" is required`);
    }
  });

  if (errorMessages.length === 0) {
    return { errors: undefined };
  }

  return { errors: errorMessages };
};

export const determineUserType = (games: IDashboardGame[]): TUserType => {
  if (hasLaunchedToStore(games)) {
    return 'activeDeveloper';
  }

  if (hasFirstGame(games)) {
    return 'developer';
  }

  return 'player';
};

export const mapUserTypeToSurveyTarget = (userType: TUserType): TSurveyTarget => {
  return `${userType}s` as unknown as TSurveyTarget;
};

export const checkHasUserRespondedInLast24Hours = (): boolean => {
  const lastResponse = localStorage.getItem(SURVEY_24H_COOKIE);
  if (!lastResponse) {
    return false;
  }

  const lastResponseDate = new Date(Number(lastResponse));
  const date24hAgo = new Date(new Date().getTime() - TIME_IN_MILLISECONDS.DAY);

  if (lastResponseDate > date24hAgo) {
    return true;
  }

  return false;
};
