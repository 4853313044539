import { combineReducers } from 'redux';

import { itemsReducer as items, totalCountReducer as totalCount } from './reducers';

const gamesReducer = combineReducers({
  totalCount,
  items
});

export default gamesReducer;
