import { TGameProgressionRooms } from '@appEntryPoints/common/interfaces';

export const extractGameBinaryMappedRoom = (
  gameBinaryId: number,
  reduxProgressionRooms: TGameProgressionRooms
) => {
  const result = Object.entries(reduxProgressionRooms).map(([roomIdentifier, { production }]) => {
    const versionMappedToBinary = production.versions.find(({ gameBinaryIds }) => {
      return gameBinaryIds.includes(gameBinaryId.toString());
    });

    return versionMappedToBinary ? { roomIdentifier, versionMappedToBinary } : null;
  });

  return result.find((item) => !!item);
};
