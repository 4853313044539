import { DropdownOption } from '@app/common/components';
// TODO REPLACE [WS-10403] remove the below disable and fix error
/* eslint-disable @typescript-eslint/no-explicit-any */
type TFormattedOptions = (array: any[], value: string, component: string) => DropdownOption[];
type TOptions = (value: string[]) => DropdownOption[];

export const dropdownOptionsConverter: TOptions = (options: string[]): DropdownOption[] => {
  return options.map((option: string) => {
    return {
      value: option,
      component: option
    };
  });
};

// TODO: replace formatDropdownOptions and formatDropdownOptionsGame with this function
export const formatToDropDownOptions: TFormattedOptions = (
  array: any[],
  value: string,
  component: string
): DropdownOption[] => {
  return array.map((object: any) => {
    return { value: object[value.toString()], component: object[component.toString()] };
  });
};
/* eslint-enable @typescript-eslint/no-explicit-any */
