import { PUSH_NOTIFICATIONS } from './actions';
import { IAction } from '@app/common/interfaces';
import { ICertificate } from './interfaces';

let initialState: ICertificate[] = [];

const notificationCertificates = (
  state: ICertificate[] = initialState,
  action: IAction<ICertificate[]>
): ICertificate[] => {
  Object.freeze(state);

  switch (action.type) {
    case PUSH_NOTIFICATIONS.RECEIVE_CERTIFICATES:
      return action.data;
    default:
      return state;
  }
};

export default notificationCertificates;
