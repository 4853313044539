import merge from 'lodash/merge';

import { IAction } from '@app/common/interfaces';

import { DEEP_LINKING } from './actions';
import { IVerificationDeepLink } from './constants';

let initialState: IVerificationDeepLink = {
  sendStatus: null,
  deepLinkOption: 'matchStream',
};

const deepLinkingReducer = (state = initialState, action: IAction<IVerificationDeepLink>) => {
  let newState: IVerificationDeepLink;
  Object.freeze(state);
  switch (action.type) {
    case DEEP_LINKING.RECEIVE_VERIFICATION:
      newState = merge({}, state, action.data);

      return newState;
    default:
      return state;
  }
};

export default deepLinkingReducer;
