import { Dispatch } from 'redux';
import axios, { AxiosResponse, AxiosError } from 'axios';
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';

import { snakeCaseConverter } from '@app/common/helpers';
import { GAME_STATES, STATUS } from '../constants';
import { TDispatch, IAction, TAxiosPromise, IErrors, TErrorGeneral } from '@app/common/interfaces';
import { TGameState, IGameStateRequest } from '../interfaces';

export enum GAME_STATE_ACTIONS {
  RECEIVE = 'gameState/RECEIVE'
}

export function receiveGameState(data: TGameState): IAction<TGameState> {
  return {
    type: GAME_STATE_ACTIONS.RECEIVE,
    data
  };
}

export function fetchGameStateByGameId(gameId: number): TDispatch {
  return (dispatch: Dispatch<IAction<TGameState>>): TAxiosPromise<void> => {
    return axios
      .get(`/api/v1/games/${gameId}/game_state`)
      .then(camelize)
      .then((response: AxiosResponse<TGameState>) => {
        dispatch(receiveGameState(response.data));
      })
      .catch(
        (error: AxiosError<IErrors>): TErrorGeneral => {
          return error.response ? error.response.data : error;
        }
      );
  };
}

function updateGameState(
  requestObject: IGameStateRequest,
  gameId: number,
  dispatch: Dispatch<IAction<TGameState>>
): TAxiosPromise<TGameState> {
  return axios
    .patch(`/api/v1/games/${gameId}/game_state`, snakeCaseConverter(requestObject))
    .then(camelize)
    .then((response: AxiosResponse<TGameState>) => {
      dispatch(receiveGameState(response.data));
    })
    .catch(
      (error: AxiosError<IErrors>): TErrorGeneral => {
        return error.response ? error.response.data : error;
      }
    );
}

export function updateInProgressGameState(gameId: number, stateName: GAME_STATES): TDispatch {
  return (dispatch: Dispatch<IAction<TGameState>>): TAxiosPromise<TGameState> => {
    let requestObject: IGameStateRequest = {
      gameState: { [stateName]: STATUS.IN_PROGRESS }
    };

    return updateGameState(requestObject, gameId, dispatch);
  };
}

export function updateCompleteGameState(gameId: number, stateName: GAME_STATES): TDispatch {
  return (dispatch: Dispatch<IAction<TGameState>>): TAxiosPromise<TGameState> => {
    let requestObject: IGameStateRequest = {
      gameState: { [stateName]: STATUS.COMPLETED }
    };

    return updateGameState(requestObject, gameId, dispatch);
  };
}
