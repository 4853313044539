/* eslint-disable @typescript-eslint/no-unsafe-call */
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { generateModalGameFormData, snakeCaseConverter } from '@app/common/helpers';
import {
  IAction,
  IActionNoData,
  IErrors,
  IGameConfig,
  IGameEnabled,
  IGameGenreTagline,
  IGameIndexParams,
  IGameInfo,
  IGameInfoModalState,
  IGameStoreUrls,
  IPublicGame,
  TErrorGeneral,
  TGameIndexResponses,
  TGamesPerPage,
  TReceiveAction,
} from '@app/common/interfaces';
import axios, { AxiosError, AxiosResponse } from '@app/common/setup/axiosWithHeader';
import { IRootState } from '@app/core/rootReducer';
import { ISsoData, IGameDeepLinkUpdate } from '@app/modules/GameManagement/common/interfaces';

import { GAMES_PAGE_PAGE } from '../constants';
import { clearGamesTotalCount, receiveGamesTotalCount } from './countActions';

// TODO[WS-9306]: Refactor the RECEIVE_GAMES section in the GAME enum
export enum GAME {
  GAME_INFO = 'game/GAME_INFO',
  APPLE_PAY_UPDATE = 'game/APPLE_PAY_UPDATE',
  RECEIVE_GAME = 'game/RECEIVE_GAME',
  CLEAR_GAME = 'game/CLEAR_GAME',
}

export enum GAMES {
  RECEIVE_TOTAL_COUNT = 'games/RECEIVE_TOTAL_COUNT',
  RECEIVE_GAMES = 'games/RECEIVE_GAMES',
  CLEAR_GAMES = 'games/CLEAR_GAMES',
  RECEIVE_UPDATED_GAME = 'games/RECEIVE_UPDATED_GAME',
}

export type TGameUpdate =
  | IGameInfoModalState
  | IGameConfig
  | ISsoData
  | IGameDeepLinkUpdate
  | IGameGenreTagline
  | IGameStoreUrls
  | IGameEnabled;

export const getGameInfo = (data: IGameInfo): IAction<IGameInfo> => {
  return {
    type: GAME.GAME_INFO,
    data,
  };
};

export const clearGames = (): IActionNoData => {
  return { type: GAMES.CLEAR_GAMES };
};

export const clearGameInfo = (): IActionNoData => {
  return { type: GAME.CLEAR_GAME };
};

export const receiveGames = (data: TGamesPerPage): IAction<TGamesPerPage> => {
  return {
    type: GAMES.RECEIVE_GAMES,
    data,
  };
};

export const receiveUpdatedGame: TReceiveAction<TGamesPerPage> = (
  data: TGamesPerPage
): IAction<TGamesPerPage> => {
  return {
    type: GAMES.RECEIVE_UPDATED_GAME,
    data,
  };
};

type TNewGame = (data: IGameInfo) => IAction<IGameInfo>;

export const receiveModalGameInfo: TNewGame = (data: IGameInfo): IAction<IGameInfo> => {
  return {
    type: GAME.RECEIVE_GAME,
    data,
  };
};

export const fetchGamesApiCall = (
  indexParams: IGameIndexParams
): Promise<AxiosResponse<TGameIndexResponses>> => {
  let urlParams = new URLSearchParams(snakeCaseConverter(indexParams)).toString();

  return axios.get(`/api_internal/v1/games?${urlParams}`);
};

export const fetchPublicGameApiCall = (
  gameProductionId: number
): Promise<AxiosResponse<IPublicGame>> => {
  return axios.get(`/public_games/${gameProductionId}`);
};

export const findGame = (
  id: number
): ThunkAction<Promise<void>, IRootState, unknown, IAction<IGameInfo>> => {
  return (dispatch: Dispatch<IAction<IGameInfo>>): Promise<void> | TErrorGeneral => {
    return axios
      .get(`/games/${id}.json?request_source=devconsole`)
      .then((response: AxiosResponse<IGameInfo>) => {
        dispatch(getGameInfo(camelize(response.data)));
      })
      .catch((error: AxiosError<IErrors>): TErrorGeneral => {
        window.location.assign('/dashboard'); // ie do not have permissions

        return error.response ? error.response.data : error;
      });
  };
};

export const updateGame = (
  game: TGameUpdate
): ThunkAction<Promise<void>, IRootState, unknown, IAction<IGameInfo>> => {
  return (dispatch: Dispatch<IAction<IGameInfo>>): Promise<void> | TErrorGeneral => {
    let data: FormData | IGameConfig;

    if (game.hasOwnProperty('platform')) {
      data = generateModalGameFormData(game as IGameInfoModalState);
    } else {
      data = snakeCaseConverter({ game }) as IGameConfig;
    }

    if (game.id) {
      return axios({ method: 'patch', url: `/api_internal/v1/games/${game.id}`, data })
        .then((response: AxiosResponse<IGameInfo>) => {
          dispatch(receiveModalGameInfo(camelize(response.data)));
        })
        .catch((error: AxiosError<IErrors>): TErrorGeneral => {
          return error.response ? error.response.data : error;
        });
    }
  };
};

export const updateGameEnabled = (
  game: IGameEnabled,
  pageNumber: number = 0
): ThunkAction<Promise<void>, IRootState, unknown, IAction<TGamesPerPage>> => {
  return (dispatch: Dispatch<IAction<TGamesPerPage>>): Promise<void> | TErrorGeneral => {
    return axios({
      method: 'patch',
      url: `/api_internal/v1/games/${game.id}`,
      data: { ...snakeCaseConverter({ game: { ...game, id: undefined } }) },
    })
      .then(() => {
        dispatch(receiveUpdatedGame({ [pageNumber]: [game] } as TGamesPerPage));
      })
      .catch((error: AxiosError<IErrors>): TErrorGeneral => {
        return error.response ? error.response.data : error;
      });
  };
};

export const fetchGameByProductionId = (
  productionId: number
): ThunkAction<Promise<void>, IRootState, unknown, IAction<IGameInfo>> => {
  return (dispatch: Dispatch<IAction<IGameInfo>>): Promise<void> | TErrorGeneral => {
    return axios
      .get(`/api_internal/v1/games/${productionId}`)
      .then((response: AxiosResponse<IGameInfo>) => {
        dispatch(getGameInfo(response.data));
      })
      .catch((error: AxiosError<IErrors>): TErrorGeneral => {
        return error.response ? error.response.data : error;
      });
  };
};

export const fetchGames = (
  indexParams: IGameIndexParams,
  page: number = 0
): ThunkAction<
  Promise<void>,
  IRootState,
  unknown,
  IAction<TGamesPerPage> | IAction<number> | IActionNoData
> => {
  indexParams.limit = GAMES_PAGE_PAGE;
  indexParams.offset = page * indexParams.limit;

  return (
    dispatch: Dispatch<IAction<TGamesPerPage> | IAction<number> | IActionNoData>
  ): Promise<void> | TErrorGeneral => {
    return fetchGamesApiCall(indexParams)
      .then(camelize)
      .then((response: AxiosResponse<TGameIndexResponses>) => {
        let resp: TGameIndexResponses = response.data;
        let responseInPage: TGamesPerPage = { [page]: resp.items };

        if (page === 0) {
          dispatch(clearGames());
          dispatch(clearGamesTotalCount());
        }
        dispatch(receiveGames(responseInPage));
        dispatch(receiveGamesTotalCount(resp.totalCount));
      })
      .catch((error: AxiosError<IErrors>): TErrorGeneral => {
        return error.response ? error.response.data : error;
      });
  };
};
