import * as React from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import './styles/analyticsSummaryCardContainer.scss';

const classNamePrefix: string = 'common-component__analytics-summary-card-container';

interface ISummaryCardProps {
  children: React.ReactNode;
}

class AnalyticsSummaryCardContainer extends React.PureComponent<ISummaryCardProps> {
  public render(): JSX.Element {
    return (
      <Card className={`${classNamePrefix} box-shadow`}>
        <Grid className={`${classNamePrefix}-grid-component`} container={true}>
          {/* children must be grid items to render! */}
          {this.props.children}
        </Grid>
      </Card>
    );
  }
}

export { AnalyticsSummaryCardContainer };
