import React, { useState, ChangeEvent } from 'react';

import TextField from '@mui/material/TextField';

import { FREEFORM_TEXT_PLACEHOLDER } from './constants';
import { ISurveyAnswer } from './interfaces';
import './surveyTextInputMuiCssFix.scss';

interface ISurveyTextInputProps {
  surveyQuestionId: number;
  onChange: (newAnswer: ISurveyAnswer) => void;
}

export default function SurveyTextInput({ onChange, surveyQuestionId }: ISurveyTextInputProps) {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    onChange({ surveyQuestionId, body: e.target.value });
  };

  return (
    <TextField
      multiline
      placeholder={FREEFORM_TEXT_PLACEHOLDER}
      label={'\u200B'}
      fullWidth
      onChange={handleChange}
      value={inputValue}
      // fix tiny gap on top of input outline
      className={'no-label'}
      data-qa={'survey-text-input'}
    />
  );
}
