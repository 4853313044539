import React, { useState } from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { default as ReactGA4 } from 'react-ga4';

import { GOOGLE_ANALYTICS_4 } from '@app/common/constants/googleAnalytics4';
import WizardView from '@app/modules/ContentManagementSystem/modules/WizardView/WizardView';
import { renderVideoModal } from '@appGameProgression/modules/SeasonsTab/subcomponents/VideoModal';

import {
  learningCenterCardStyles,
  LINEAR_BACKGROUND,
  learningCenterCardAspectRatio,
} from './constants';
import { ILearningCenterCardProps } from './interfaces';

interface IWizardProps extends ILearningCenterCardProps {
  helpBitId: number;
}

interface IVideoProps extends ILearningCenterCardProps {
  videoUrl: string;
}

type TInteractiveCardProps = IWizardProps | IVideoProps;

export default function InteractiveCard(props: TInteractiveCardProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const muiTheme = useTheme();

  const handleClick = () => {
    ReactGA4.event({
      category: GOOGLE_ANALYTICS_4.USER.CATEGORY,
      action: GOOGLE_ANALYTICS_4.USER.ACTIONS.LEARNING_CENTER_CARD_CLICK(
        props.category,
        props.children
      ),
    });

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const cardStyle = {
    ...learningCenterCardStyles,
    backgroundImage: `${LINEAR_BACKGROUND}, url(${props.backgroundImage})`,
  };

  const isLessThanMd = useMediaQuery(muiTheme.breakpoints.down('md'));

  // maintain aspect ratio in learning center, when there's no profile widget, or when less than md width
  if (props.shouldMaintainAspectRatio || isLessThanMd) {
    Object.assign(cardStyle, { aspectRatio: learningCenterCardAspectRatio });
  }

  const renderCard = () => (
    <Card sx={cardStyle} onClick={handleClick} data-qa={'learning-center-interactive-card'}>
      <Grid
        container
        alignItems={'center'}
        data-qa={'learning-center-card-content'}
        wrap={'nowrap'}
      >
        <Grid item pr={1}>
          {props.icon}
        </Grid>
        <Grid item>{props.children}</Grid>
      </Grid>
    </Card>
  );

  if ('helpBitId' in props && !!props.helpBitId) {
    return (
      <>
        {renderCard()}
        {isModalOpen && <WizardView helpBitId={props.helpBitId} closeModal={closeModal} />}
      </>
    );
  }

  if ('videoUrl' in props && !!props.videoUrl) {
    const handleToggle = () => {
      setIsModalOpen((prev) => !prev);
    };

    return (
      <>
        {renderCard()}
        {renderVideoModal(isModalOpen, props.children, handleToggle, props.videoUrl)}
      </>
    );
  }

  return <></>;
}
