import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import useTheme from '@mui/styles/useTheme';
import { useSnackbarContext } from '@skillzet/components';

import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

import { useSurveyContext } from './SurveyContext';
import { postSurveyResponse } from './actions';
import { SURVEY_24H_COOKIE } from './constants';
import { renderQuestionWithAppropriateComponent, addAnswer, validateAnswers } from './helpers';
import { ISurvey, ISurveyAnswer, TUserType } from './interfaces';

export interface IPopupSurveyProps {
  survey: ISurvey;
  userType: TUserType;
  userId: number;
}

export default function PopupSurvey({ survey, userType, userId }: IPopupSurveyProps): JSX.Element {
  const isSurveyOpen = !!survey;

  const muiTheme = useTheme();
  const { closeSurvey } = useSurveyContext();
  const [answers, setAnswers] = useState<ISurveyAnswer[]>([]);
  const { show: showSnackbar } = useSnackbarContext();
  const questions = survey.surveyQuestions;
  const pageLocation = useLocation().pathname;

  const submitResponse = useMutation(
    (isDismissal: boolean) => {
      return postSurveyResponse({
        userId,
        userType,
        isDismissal,
        pageLocation,
        surveyId: survey.id,
        answers,
      });
    },
    {
      onSuccess: (_, isDismissal) => {
        if (!isDismissal) {
          showSnackbar('Response submitted, thank you.');
        }
        localStorage.setItem(SURVEY_24H_COOKIE, `${new Date().getTime()}`);
        closeSurvey();
      },
      onError: (error: Error) => {
        showSnackbar(`An error occurred: ${error.message}. Please try again.`, 'error');
      },
    }
  );

  const handleClose = () => {
    submitResponse.mutate(true);
    closeSurvey();
  };

  if (!isSurveyOpen) return <></>;

  return (
    <Box
      sx={{
        width: '100vw',
        position: 'fixed',
        bottom: 0,
        zIndex: 1200,
        display: 'flex',
        justifyContent: 'space-around',
      }}
      data-qa={'popup-survey-container'}
    >
      <Slide direction={'up'} in={isSurveyOpen}>
        <Card
          sx={{
            border: `1px solid ${muiTheme.palette.primary.main}`,
            // TODO get this color into palette
            backgroundColor: '#EDF4FD',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            padding: 2,
            width: '100%',
            maxWidth: '450px',
            marginBottom: 0,
            position: 'relative',
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', right: 0, top: 0 }}
            data-qa={'popup-survey-close-button'}
          >
            <CloseIcon />
          </IconButton>
          {questions.map((question) => {
            return renderQuestionWithAppropriateComponent(question, addAnswer(answers, setAnswers));
          })}
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', padding: 0 }}>
            <Button
              variant={'contained'}
              data-qa={'popup-survey-submit-button'}
              onClick={() => {
                const { errors } = validateAnswers(questions, answers);

                if (!errors) {
                  submitResponse.mutate(false);

                  return;
                }

                showSnackbar(errors.join(', '), 'error');
              }}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </Slide>
    </Box>
  );
}
