import { useQuery } from 'react-query';

import { getSurvey, getLatestSurveyResponse } from './actions';
import { TSurveyTarget } from './interfaces';

export const useSurvey = (surveyTrigger?: string, targetType?: TSurveyTarget) =>
  useQuery(
    ['survey', surveyTrigger],
    () => getSurvey(surveyTrigger as string, targetType as TSurveyTarget),
    {
      // another query for userType happens before this, which gets the userType needed for this query
      enabled: !!targetType && !!surveyTrigger,
    }
  );

export const useLatestSurveyResponse = (userId: number, surveyId?: number) =>
  useQuery(
    ['surveyResponses', userId, surveyId],
    () => getLatestSurveyResponse(userId, surveyId as number),
    {
      // useSurvey happens before this, which gets the surveyId needed for this query
      enabled: !!surveyId,
    }
  );
