import { LOCALE } from '@app/common/constants';
import {
  TStateProgressionRoomVersionLocales,
  TStateProgressionRoomVersionLocale,
  TProgressionRoomVersionPublish,
} from '@appEntryPoints/common/interfaces';

import { convertConditionsIntoFormData } from '.';

export function generateProductionProgressionRoomVersionFormData(
  progressionRoomVersion: TProgressionRoomVersionPublish
): FormData {
  const form = new FormData();

  form.append(`progression_room[version_number]`, progressionRoomVersion.versionNumber);

  progressionRoomVersion.locales.forEach((locale, localeIdx) => {
    form.append(`progression_room[locales][${localeIdx}][locale]`, locale.locale);

    // name & titles
    form.append(`progression_room[locales][${localeIdx}][room_name]`, locale.roomName);
    form.append(`progression_room[locales][${localeIdx}][header_title]`, locale.headerTitle);
    form.append(
      `progression_room[locales][${localeIdx}][header_subtitle1]`,
      locale.headerSubtitle1
    );

    if (locale.headerSubtitle2) {
      form.append(
        `progression_room[locales][${localeIdx}][header_subtitle2]`,
        locale.headerSubtitle2
      );
    }

    // colors
    form.append(
      `progression_room[locales][${localeIdx}][header_font_color1]`,
      locale.headerFontColor1
    );
    form.append(
      `progression_room[locales][${localeIdx}][header_font_color2]`,
      locale.headerFontColor2
    );

    form.append(
      `progression_room[locales][${localeIdx}][header_color][render_type]`,
      locale.headerColor.renderType
    );

    form.append(
      `progression_room[locales][${localeIdx}][header_color][colors][0]`,
      locale.headerColor.colors[0]
    );
    form.append(
      `progression_room[locales][${localeIdx}][header_color][colors][1]`,
      locale.headerColor.colors[1]
    );

    // images
    form.append(`progression_room[locales][${localeIdx}][room_icon]`, locale.roomIcon);

    if (locale.headerBadge) {
      form.append(`progression_room[locales][${localeIdx}][header_badge]`, locale.headerBadge);
    }

    if (locale.headerImage) {
      form.append(`progression_room[locales][${localeIdx}][header_image]`, locale.headerImage);
    }

    // body images
    const lastIndex = locale.bodyImages.length - 1;
    locale.bodyImages.forEach((bodyImage, bodyImageIdx) => {
      form.append(
        `progression_room[locales][${localeIdx}][body_images][${bodyImageIdx}][url]`,
        bodyImage.url
      );

      if (bodyImageIdx < lastIndex && !!bodyImage.condition) {
        const conditions = convertConditionsIntoFormData(bodyImage.condition);
        const bodyImageKey = `progression_room[locales][${localeIdx}][body_images][${bodyImageIdx}][condition]`;

        conditions.forEach(({ formDataStr, value }) => {
          form.append(`${bodyImageKey}${formDataStr}`, value);
        });
      }
    });
  });

  return form;
}

export function generateSandboxProgressionRoomVersionFormData(
  progressionRoomVersionState: TStateProgressionRoomVersionLocales,
  versionNumber: string
): FormData {
  let form: FormData = new FormData();
  form.append(`progression_room[version_number]`, versionNumber);
  (Object.keys(progressionRoomVersionState) as LOCALE[]).forEach((locale, localeIndex) => {
    const versionLocale = progressionRoomVersionState[locale] as TStateProgressionRoomVersionLocale;
    form.append(`progression_room[locales][${localeIndex}][locale]`, versionLocale.locale);
    form.append(`progression_room[locales][${localeIndex}][room_name]`, versionLocale.roomName);
    form.append(
      `progression_room[locales][${localeIndex}][room_icon]`,
      versionLocale.roomIconFile ? versionLocale.roomIconFile : versionLocale.roomIcon
    );
    form.append(
      `progression_room[locales][${localeIndex}][header_title]`,
      versionLocale.headerTitle
    );
    form.append(
      `progression_room[locales][${localeIndex}][header_subtitle1]`,
      versionLocale.headerSubtitle1
    );
    if (versionLocale.headerSubtitle2) {
      form.append(
        `progression_room[locales][${localeIndex}][header_subtitle2]`,
        versionLocale.headerSubtitle2
      );
    }
    form.append(
      `progression_room[locales][${localeIndex}][header_font_color1]`,
      versionLocale.headerFontColor1
    );
    form.append(
      `progression_room[locales][${localeIndex}][header_font_color2]`,
      versionLocale.headerFontColor2
    );
    if (versionLocale.headerBadge || versionLocale.headerBadgeFile) {
      form.append(
        `progression_room[locales][${localeIndex}][header_badge]`,
        versionLocale.headerBadgeFile ? versionLocale.headerBadgeFile : versionLocale.headerBadge
      );
    }
    if (versionLocale.headerImage || versionLocale.headerImageFile) {
      form.append(
        `progression_room[locales][${localeIndex}][header_image]`,
        versionLocale.headerImageFile ? versionLocale.headerImageFile : versionLocale.headerImage
      );
    }
    form.append(
      `progression_room[locales][${localeIndex}][header_color][render_type]`,
      versionLocale.headerColor.renderType
    );
    form.append(
      `progression_room[locales][${localeIndex}][header_color][colors][0]`,
      versionLocale.headerColor.colors[0]
    );
    form.append(
      `progression_room[locales][${localeIndex}][header_color][colors][1]`,
      versionLocale.headerColor.colors[1]
    );

    if (!!versionLocale.defaultBodyImage.url) {
      const fullBodyImages = versionLocale.bodyImagesWithCondition.concat([
        versionLocale.defaultBodyImage,
      ]);
      const lastIndex = fullBodyImages.length - 1;

      fullBodyImages.forEach((bodyImage, bodyImageIndex) => {
        if (!!bodyImage.file) {
          form.append(
            `progression_room[locales][${localeIndex}][body_images][${bodyImageIndex}][file]`,
            bodyImage.file
          );
        } else {
          form.append(
            `progression_room[locales][${localeIndex}][body_images][${bodyImageIndex}][url]`,
            bodyImage.url
          );
          form.append(
            `progression_room[locales][${localeIndex}][body_images][${bodyImageIndex}][mime_type]`,
            bodyImage.mimeType
          );
        }

        if (bodyImageIndex < lastIndex && !!bodyImage.condition) {
          const conditions = convertConditionsIntoFormData(bodyImage.condition);
          const bodyImageKey = `progression_room[locales][${localeIndex}][body_images][${bodyImageIndex}][condition]`;

          conditions.forEach(({ formDataStr, value }) => {
            form.append(`${bodyImageKey}${formDataStr}`, value);
          });
        }
      });
    }
  });

  return form;
}
