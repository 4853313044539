import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { chartDefaultProps } from '@app/common/constants';
import { IChartContentLoaderProps } from '@app/common/interfaces';

import variables from '@app/core/variables.scss';

const BarChartContentLoader: React.SFC<IChartContentLoaderProps> = (
  props: IChartContentLoaderProps
): JSX.Element => {
  let { width, speed } = props;

  return (
    <div id="chart__content-loader">
      <ContentLoader
        height={360}
        width={width}
        speed={speed}
        primaryColor={variables.colorGray50Background}
        secondaryColor={variables.colorGray100Borders}
      >
        <rect x="8" y="267" rx="0" ry="0" width="38" height="113" />
        <rect x="63" y="233" rx="0" ry="0" width="52" height="147" />
        <rect x="130" y="175" rx="0" ry="0" width="42" height="204" />
        <rect x="189" y="198" rx="0" ry="0" width="39" height="183" />
        <rect x="253" y="221" rx="0" ry="0" width="37" height="158" />
        <rect x="304" y="295" rx="0" ry="0" width="44" height="86" />

        <rect x="368" y="267" rx="0" ry="0" width="38" height="113" />
        <rect x="423" y="233" rx="0" ry="0" width="52" height="147" />
        <rect x="490" y="175" rx="0" ry="0" width="42" height="204" />
        <rect x="549" y="198" rx="0" ry="0" width="39" height="183" />
        <rect x="613" y="221" rx="0" ry="0" width="37" height="158" />
        <rect x="664" y="295" rx="0" ry="0" width="44" height="86" />

        <rect x="730" y="268" rx="0" ry="0" width="38" height="113" />
        <rect x="785" y="234" rx="0" ry="0" width="52" height="147" />
        <rect x="852" y="176" rx="0" ry="0" width="42" height="204" />
        <rect x="911" y="199" rx="0" ry="0" width="39" height="183" />
        <rect x="975" y="222" rx="0" ry="0" width="37" height="158" />
        <rect x="1026" y="296" rx="0" ry="0" width="44" height="86" />

        <rect x="1089" y="266" rx="0" ry="0" width="38" height="113" />
        <rect x="1144" y="232" rx="0" ry="0" width="52" height="147" />
        <rect x="1211" y="174" rx="0" ry="0" width="42" height="204" />
        <rect x="1270" y="197" rx="0" ry="0" width="39" height="183" />
        <rect x="1334" y="220" rx="0" ry="0" width="37" height="158" />
        <rect x="1385" y="294" rx="0" ry="0" width="44" height="86" />

        <rect x="1449" y="266" rx="0" ry="0" width="38" height="113" />
        <rect x="1504" y="232" rx="0" ry="0" width="52" height="147" />
        <rect x="1571" y="174" rx="0" ry="0" width="42" height="204" />
        <rect x="1630" y="197" rx="0" ry="0" width="39" height="183" />
        <rect x="1694" y="220" rx="0" ry="0" width="37" height="158" />
        <rect x="1745" y="294" rx="0" ry="0" width="44" height="86" />
      </ContentLoader>
    </div>
  );
};

BarChartContentLoader.defaultProps = chartDefaultProps;

export { BarChartContentLoader };
