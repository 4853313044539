import axios, { AxiosResponse } from 'axios';
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import { useQuery } from 'react-query';

import { handleError } from '@app/common/helpers';
import { IPublisher2 } from '@app/common/interfaces';

const fetchPublisher = () =>
  axios
    .get(`/api_internal/v1/publisher`)
    .then(camelize)
    .then(({ data }: AxiosResponse<IPublisher2>) => data)
    .catch(handleError);

export const updatePublisherRevenueModelAgreedOn = (publisherId: number) =>
  axios
    .patch(`/api_internal/v1/publishers/${publisherId}/agreed_to_revenue_model`)
    .then(camelize)
    .catch(handleError);

export const usePublisherQuery = (options = {}) => {
  const fetcher = () => fetchPublisher();

  return useQuery(['publisher'], fetcher, options);
};
