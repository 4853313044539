import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ENVIRONMENT } from '@app/common/constants';
import { fetchProgressionRoomVersions } from '@appGameHome/common/actions';

import {
  TGameProgressionRoomVersionMappings,
  IProgressionRoomVersion,
} from '../../common/interfaces';

const defaultSingleRoomState = {
  sandbox: { roomVersionIds: [] as string[] },
  production: { roomVersionIds: [] as string[] },
};

interface IReducerPayload<T> {
  env: ENVIRONMENT;
  items: T[];
  totalCount: number;
  currentRoomIdentifier?: string;
}

type TProgressionRoomReducer = IProgressionRoomVersion;

interface IReducerGenerator {
  <T extends TProgressionRoomReducer>(): CaseReducer<
    TGameProgressionRoomVersionMappings,
    PayloadAction<IReducerPayload<T>>
  >;
}

const createProgressionRoomVersionMappingReducer: IReducerGenerator = <
  T extends TProgressionRoomReducer
>() =>
  function fulfilledProgressionRoomVersionFetchActionReducer(
    state: TGameProgressionRoomVersionMappings,
    action: PayloadAction<IReducerPayload<T>>
  ) {
    const { env } = action.payload;
    const currentRoomIdentifier = action.payload.currentRoomIdentifier;

    switch (action.type) {
      case fetchProgressionRoomVersions.fulfilled.type:
        const roomVersionIds = (action.payload.items as IProgressionRoomVersion[]).map(
          ({ id }) => id
        );

        if (!!currentRoomIdentifier) {
          if (!state[currentRoomIdentifier]) {
            state[currentRoomIdentifier] = {
              ...defaultSingleRoomState,
              [env]: { roomVersionIds },
            };
          } else {
            state[currentRoomIdentifier][env].roomVersionIds = roomVersionIds;
          }
        }

        break;
      default:
        state = {} as TGameProgressionRoomVersionMappings;
        break;
    }
  };

const initialState = {} as TGameProgressionRoomVersionMappings;

interface IMappingDelete {
  payload: string;
  type: string;
}

const progressionRoomVersionMappings = createSlice({
  name: 'progressionRoomVersionMappings',
  initialState,
  reducers: {
    reset: () => initialState,
    deleteMappings: (state, { payload }: IMappingDelete) => {
      delete state[payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchProgressionRoomVersions.fulfilled,
      createProgressionRoomVersionMappingReducer<IProgressionRoomVersion>()
    );

    builder.addCase(fetchProgressionRoomVersions.rejected, (_, action) => {
      throw action.error;
    });
  },
});

export const { deleteMappings: deleteProgressionRoomVersionMappings } =
  progressionRoomVersionMappings.actions;
export default progressionRoomVersionMappings.reducer;
