import { createAsyncThunk } from '@reduxjs/toolkit';

import axios, { AxiosError, AxiosResponse } from 'axios';
// @ts-ignore: camelize is by definition an un-typeable Any => Any
import camelize from 'camelize';
import merge from 'lodash/merge';

import { ENVIRONMENT } from '@app/common/constants';
import { IErrors } from '@app/common/interfaces';
import { KEY_NAMESPACE } from '@appGameProgression/common/constants';
import {
  IPlayerDataSchema,
  IPlayerDataSchemaChangelog,
  IProgressionSchemaChangelogsResponse,
  IProgressionSchemaResponse,
} from '@appGameProgression/common/interfaces';

interface IFetchPlayerDataSchemaProps {
  searchParams: string;
  env: ENVIRONMENT;
}

interface IFetchPlayerDataSchemaPropsWithEnv extends IFetchPlayerDataSchemaProps {
  env: ENVIRONMENT;
}

type AxiosResponsePlayerDataChangelogs = AxiosResponse<
  IProgressionSchemaChangelogsResponse<IPlayerDataSchemaChangelog>
>;

export const fetchPlayerDataSchema = createAsyncThunk(
  'progression/fetchPlayerDataSchema',
  async ({ searchParams, env }: IFetchPlayerDataSchemaPropsWithEnv) => {
    return axios
      .get(`/api/v1/progression_schemas/${KEY_NAMESPACE.PLAYER_DATA}/${env}${searchParams}`)
      .then(camelize)
      .then((response: AxiosResponse<IProgressionSchemaResponse<IPlayerDataSchema>>) => {
        return merge(response.data, { env });
      })
      .catch((e) => {
        const error = e as AxiosError<IErrors>;

        throw new Error(error.response?.data.errors);
      });
  }
);

export const fetchPlayerDataSchemaChangelogs = createAsyncThunk(
  'progression/fetchPlayerDataSchemaChangelogs',
  async ({ searchParams }: IFetchPlayerDataSchemaProps) => {
    return axios
      .get(
        `/api/v1/progression_schemas/${KEY_NAMESPACE.PLAYER_DATA}/${ENVIRONMENT.PRODUCTION}/changelogs${searchParams}`
      )
      .then(camelize)
      .then((response: AxiosResponsePlayerDataChangelogs) => {
        return merge(response.data, { env: ENVIRONMENT.PRODUCTION });
      })
      .catch((e) => {
        const error = e as AxiosError<IErrors>;

        throw new Error(error.response?.data.errors);
      });
  }
);
