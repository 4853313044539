import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';

import { IRootState } from '@app/core/rootReducer';

const NoAccessPage = loadable(
  () => import(/* webpackChunkName: "no-access-page" */ './NoAccessPage')
);

const mapStateToProps = (state: IRootState) => ({
  publisherId: state.publisher.publisherInfo.id.toString(),
});

const connector = connect(mapStateToProps, {});

export type TNoAccessPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(NoAccessPage);
