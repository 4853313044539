import { IAction } from '@app/common/interfaces';
import { GAME_STATE_ACTIONS } from '@app/modules/GameManagement/common/actions';
import { TGameState } from '@app/modules/GameManagement/common/interfaces';

export type TReducerState = TGameState | {};
let initialState: TReducerState = {};

const gameStateReducer = (
  state: TReducerState = initialState,
  action: IAction<TGameState>
): TReducerState => {
  Object.freeze(state);

  switch (action.type) {
    case GAME_STATE_ACTIONS.RECEIVE:
      return action.data;
    default:
      return state;
  }
};

export default gameStateReducer;
