import loadable from '@loadable/component';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IRootState } from '@app/core/rootReducer';
import { ISignInProps } from '@app/modules/SignUpFlow/modules/SignIn/SignIn';

const SignInComponent = loadable(() => import(/* webpackChunkName: "sign-in" */ './SignIn'));

const mapStateToProps = (state: IRootState) => {
  let { feature } = state;

  return {
    feature
  };
};

const connector = connect(mapStateToProps);

export type TSignInProps = ConnectedProps<typeof connector> & ISignInProps & RouteComponentProps;

export const SignIn = connector(withRouter(SignInComponent));
