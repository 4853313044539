import { parseISO, startOfDay } from 'date-fns';
import { calculateTotalAndCountByDate } from './normalizeData';
import { ANALYTICS_DB_ATTRIBUTES } from '@app/common/constants';
import {
  IAnalyticInfo,
  ITempData,
  ITotalCount,
  IAnalyticReducer,
  IDashboardGame
} from '@app/common/interfaces';

export function filterData(
  data: IAnalyticInfo[],
  gameProductionId: number,
  startDate: Date,
  endDate: Date
): IAnalyticInfo[] {
  return data.filter((point: IAnalyticInfo) => {
    let tempDate: Date = startOfDay(parseISO(point.startTime));

    return (
      tempDate <= endDate &&
      tempDate >= startDate &&
      (point.gameProductionId === gameProductionId || gameProductionId === -1)
    );
  });
}

export function createTotalCountDataObject(
  selectedGameProductionId: number,
  analytics: IAnalyticReducer<Date>,
  keyAttributes: { [ANALYTICS_ATTRIBUTES: string]: ANALYTICS_DB_ATTRIBUTES },
  startDate: Date,
  endDate: Date
): ITempData<ITotalCount> {
  let filteredDate: IAnalyticInfo[] = filterData(
    analytics.rawData,
    selectedGameProductionId,
    startDate,
    endDate
  );

  return calculateTotalAndCountByDate(filteredDate, keyAttributes);
}

export function findNestedIndex(array: IDashboardGame[], selectedId: number): number {
  return array.findIndex((element: IDashboardGame) => element.id === selectedId);
}
