// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import { Dispatch } from 'redux';

import { IAction } from '@app/common/interfaces';
import axios, { AxiosResponse } from '@app/common/setup/axiosWithHeader';

import { IStatement } from './interfaces';

export enum STATEMENTS {
  RECEIVE = 'statements/RECEIVE',
}

export function receiveStatements(statements: IStatement[]): IAction<IStatement[]> {
  return {
    type: STATEMENTS.RECEIVE,
    data: statements,
  };
}

export function fetchStatements(year: number, month: number) {
  const paddedMonth = String(month).padStart(2, '0');

  return async (dispatch: Dispatch<IAction<IStatement[]>>): Promise<void> => {
    const response: AxiosResponse<IStatement[]> = await axios.get(`/api/v1/statements`, {
      params: { month: paddedMonth, year },
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(receiveStatements(camelize(response.data)));
  };
}
