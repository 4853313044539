import { IPasswordChecklistOrder } from './interfaces';

export enum PASSWORD_CHECKLIST {
  HAS_BOTH_UPPER_LOWER_CHARS = 'hasBothUpperAndLowerCaseLetters',
  HAS_AT_LEAST_ONE_NUMBER = 'hasAtLeastOneNumber',
  HAS_MIN_EIGHT_CHARS = 'hasMinimumEightCharacters',
  HAS_AT_LEAST_ONE_SPECIAL_CHARS = 'hasAtLeastOneSpecialCharacter'
}

export const PASSWORD_CHECKLIST_ORDER: IPasswordChecklistOrder[] = [
  {
    checklistItem: PASSWORD_CHECKLIST.HAS_MIN_EIGHT_CHARS,
    text: 'Minimum 8 characters'
  },

  {
    checklistItem: PASSWORD_CHECKLIST.HAS_AT_LEAST_ONE_NUMBER,
    text: 'At least 1 number'
  },

  {
    checklistItem: PASSWORD_CHECKLIST.HAS_AT_LEAST_ONE_SPECIAL_CHARS,
    text: 'At least 1 special character (eg. !@#$)'
  },
  {
    checklistItem: PASSWORD_CHECKLIST.HAS_BOTH_UPPER_LOWER_CHARS,
    text: 'Includes both upper and lower case letters'
  }
];

export const passwordChecklistClassName = 'password-checklist';
