export enum UserFlowErrors {
  Blank = 'Please fill out this field.',
  InvalidEmail = 'Please enter a valid email address',
  PasswordMinimum = 'Password must be minimum of 8 characters.',
  PasswordMaximum = 'Password is too long (maximum is 128 characters)'
}

export enum EMAIL_PASSWORD_ERROR {
  PASSWORD = 'passwordError',
  EMAIL = 'emailError'
}
