export enum CRUD {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DESTROY = 'destroy',
}

export enum RESOURCES {
  PUBLISHER = 'Publisher',
  USER = 'User',
  GAME_CHALLENGE_PROPOSAL = 'GameChallengeProposal',
  GAME = 'Game',
  ANALYTICS = 'DeveloperAnalytic',
  AUDIT = 'CustomAudit',
}

export const PUBLISHER_PERMISSIONS = {
  CREATE: `${RESOURCES.PUBLISHER}::${CRUD.CREATE}`,
  READ: `${RESOURCES.PUBLISHER}::${CRUD.READ}`,
  UPDATE: `${RESOURCES.PUBLISHER}::${CRUD.UPDATE}`,
  DESTROY: `${RESOURCES.PUBLISHER}::${CRUD.DESTROY}`,
};

export const USER_PERMISSIONS = {
  CREATE: `${RESOURCES.USER}::${CRUD.CREATE}`,
  READ: `${RESOURCES.USER}::${CRUD.READ}`,
  UPDATE: `${RESOURCES.USER}::${CRUD.UPDATE}`,
  DESTROY: `${RESOURCES.USER}::${CRUD.DESTROY}`,
};

export const GAME_CHALLENGE_PROPOSAL_PERMISSIONS = {
  CREATE: `${RESOURCES.GAME_CHALLENGE_PROPOSAL}::${CRUD.CREATE}`,
  READ: `${RESOURCES.GAME_CHALLENGE_PROPOSAL}::${CRUD.READ}`,
  UPDATE: `${RESOURCES.GAME_CHALLENGE_PROPOSAL}::${CRUD.UPDATE}`,
};

export const GAME_PERMISSIONS = {
  CREATE: `${RESOURCES.GAME}::${CRUD.CREATE}`,
  READ: `${RESOURCES.GAME}::${CRUD.READ}`,
  UPDATE: `${RESOURCES.GAME}::${CRUD.UPDATE}`,
  DESTROY: `${RESOURCES.GAME}::${CRUD.DESTROY}`,
  PUBLISH: `${RESOURCES.GAME}::publish`,
};

export const ANALYTICS_PERMISSIONS = {
  READ: `${RESOURCES.ANALYTICS}::${CRUD.READ}`,
};

export const BILLING_PERMISSIONS = {
  DOWNLOAD_STATEMENTS: `${RESOURCES.GAME}::download_statements`,
};

export const AUDIT_PERMISSIONS = {
  READ: `${RESOURCES.AUDIT}::${CRUD.READ}`,
};
