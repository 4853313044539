import * as React from 'react';

import Grid from '@mui/material/Grid';

import './styles/modalHeadersStyles.scss';

interface ITitle {
  className: string;
  title: string | JSX.Element;
}

export const ModalHeader = ({ className, title }: ITitle): JSX.Element => {
  return (
    <Grid item={true} xs={12} className={`${className}__header modal__header`}>
      <h2>{title}</h2>
    </Grid>
  );
};

export const ModalSubHeader = ({ className, title }: ITitle): JSX.Element => {
  return (
    <Grid item={true} xs={12} className={`${className}__subheader modal__subheader`}>
      <h4>{title}</h4>
    </Grid>
  );
};
