import { Component } from 'react';

import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { fetchSdkDownloads } from '@app/common/actions';
import { IWithSnackbarProp } from '@app/common/helpers/snackbarHookHelper';
import { IRootState } from '@app/core/rootReducer';

const SdkDownloads = loadable(
  () => import(/* webpackChunkName: "sdk-downloads" */ './SdkDownloads')
) as typeof Component;

const mapStateToProps = (state: IRootState) => {
  let { sdkDownloads } = state;

  return { sdkDownloads };
};

const mapDispatchToProps = {
  fetchSdkDownloads,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TSdkDownloadsConnectedProps = ConnectedProps<typeof connector> &
  RouteComponentProps &
  IWithSnackbarProp;

export default connector(withRouter(SdkDownloads));
