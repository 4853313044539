import { IGameProgressionRoom } from '@appEntryPoints/common/interfaces';

export const gameBuildsMappingCount = (room: IGameProgressionRoom | undefined): number => {
  if (!room) return 0;

  let totalCount = 0;

  room.production.versions.forEach(({ gameBinaryIds }) => (totalCount += gameBinaryIds.length));

  return Math.floor(totalCount / 2);
};
