import { Dispatch } from 'redux';
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import cloneDeep from 'lodash/cloneDeep';
import axios, { AxiosResponse, AxiosError } from '@app/common/setup/axiosWithHeader';
import { snakeCaseConverter } from '@app/common/helpers';
import { clearFinalizeGameBuild } from './uploadActions';
import { GAME_BUILD_STATUS } from '../constants';

import {
  TThunkAction,
  TDispatch,
  IAction,
  TAxiosPromise,
  IErrors,
  IZendeskTicket,
  TErrorGeneral
} from '@app/common/interfaces';
import { IGameBuild, TGameBuildUpdate, TGameBuildsPerPage } from '../interfaces';

export enum GAME_BUILDS_UPDATE {
  RECEIVE_GAME_BUILD_UPDATE = 'gameBuildsUpload/RECEIVE_GAME_BUILD_UPDATE'
}

export function receiveUpdatedGameBuild(data: TGameBuildsPerPage): IAction<TGameBuildsPerPage> {
  return { type: GAME_BUILDS_UPDATE.RECEIVE_GAME_BUILD_UPDATE, data };
}

export function updateGameBuild(
  gameId: number,
  gameBuild: TGameBuildUpdate
): Promise<IGameBuild | never> {
  return axios
    .patch(
      `/api/v1/games/${gameId}/game_binaries/${gameBuild.id}`,
      snakeCaseConverter({ gameBinary: gameBuild })
    )
    .then(
      (response: AxiosResponse<IGameBuild>): IGameBuild => {
        return camelize(response.data);
      }
    )
    .catch((error: AxiosError<IErrors>): never => {
      let errorText: TErrorGeneral = error.response ? error.response.data.errors : error;

      throw errorText;
    });
}

export const updateGameBuildNotComplete: TThunkAction = (
  gameId: number,
  gameBuildId: number,
  newUploadStatus: GAME_BUILD_STATUS
): TDispatch => {
  return (dispatch: Dispatch<IAction<IGameBuild>>): TAxiosPromise<IGameBuild> => {
    let gameBuildUpdateParams: TGameBuildUpdate = {
      id: gameBuildId,
      uploadStatus: newUploadStatus
    };

    return (
      updateGameBuild(gameId, gameBuildUpdateParams)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: IGameBuild): any => {
          dispatch(clearFinalizeGameBuild(response));
        })
        .catch((error: AxiosError<IErrors>): never => {
          throw error;
        })
    );
  };
};

export const updateGameBuildInPage: TThunkAction = (
  gameId: number,
  page: number,
  gameBuildUpdateParams: TGameBuildUpdate
): TDispatch => {
  return (dispatch: Dispatch<IAction<TGameBuildsPerPage>>): TAxiosPromise<TGameBuildsPerPage> => {
    return (
      updateGameBuild(gameId, gameBuildUpdateParams)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: IGameBuild): any => {
          let responseInPage: TGameBuildsPerPage = { [page]: [response] };
          dispatch(receiveUpdatedGameBuild(responseInPage));

          return response;
        })
        .catch((error: AxiosError<IErrors>): never => {
          throw error;
        })
    );
  };
};

export const createGameBuildRequest: TThunkAction = (
  gameId: number,
  page: number,
  gameBuild: IGameBuild
): TDispatch => {
  return (dispatch: Dispatch<IAction<TGameBuildsPerPage>>): TAxiosPromise<TGameBuildsPerPage> => {
    let updatedGameBuild: IGameBuild = cloneDeep(gameBuild);

    return (
      axios
        .post(`/api/v1/games/${gameId}/game_binaries/${gameBuild.id}/zendesk_tickets`)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: AxiosResponse<IZendeskTicket>): any => {
          let ticketCreated: IZendeskTicket = camelize(response.data);
          updatedGameBuild.activeTicketId = ticketCreated.id;

          let responseInPage: TGameBuildsPerPage = { [page]: [updatedGameBuild] };
          dispatch(receiveUpdatedGameBuild(responseInPage));
        })
        .catch((error: AxiosError<IErrors>): never => {
          let errorText: TErrorGeneral = error.response ? error.response.data : error;

          throw errorText;
        })
    );
  };
};

export const updateGameBuildRequest: TThunkAction = (
  gameId: number,
  page: number,
  gameBuild: IGameBuild
): TDispatch => {
  return (dispatch: Dispatch<IAction<TGameBuildsPerPage>>): TAxiosPromise<TGameBuildsPerPage> => {
    let updatedGameBuild: IGameBuild = cloneDeep(gameBuild);

    return (
      axios
        .patch(
          `/api/v1/games/${gameId}/game_binaries/${gameBuild.id}/zendesk_tickets/${gameBuild.activeTicketId}`
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((): any => {
          updatedGameBuild.activeTicketId = null;
          let responseInPage: TGameBuildsPerPage = { [page]: [updatedGameBuild] };

          dispatch(receiveUpdatedGameBuild(responseInPage));
        })
        .catch((error: AxiosError<IErrors>): never => {
          let errorText: TErrorGeneral = error.response ? error.response.data : error;

          throw errorText;
        })
    );
  };
};
