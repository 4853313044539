import Loadable from 'react-loadable';
import { withRouter } from 'react-router-dom';

import { Loading } from '@app/common/components';
import { ILoadableComponent } from '@app/common/interfaces';

const SurveyLandingPage: ILoadableComponent['component'] = Loadable({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loader: (): Promise<any> =>
    import(/* webpackChunkName: "survey-landing-page" */ './SurveyLandingPage'),
  loading: Loading,
});

export default withRouter(SurveyLandingPage);
