import { combineReducers } from 'redux';

import {
  itemsReducer as items, totalCountReducer as totalCount, uploadsReducer as uploads
} from './reducers';

const gameBuildsReducer = combineReducers({
  totalCount,
  items,
  uploads
});

export default gameBuildsReducer;
