import merge from 'lodash/merge';

import { IAction, IQuestionSet } from '@app/common/interfaces';

import { SURVEY_TOOL } from './actions';

export interface IReducerState {
  showModal: boolean;
  fromLocation: string;
  questionSet: IQuestionSet;
}

let initialState: IReducerState = {
  showModal: false,
  fromLocation: '',
  questionSet: {}
};

type TAction = IAction<any>;

const surveyToolReducer = (state: IReducerState = initialState, action: TAction): IReducerState => {
  Object.freeze(state);

  let newState: IReducerState = merge({}, state);

  switch (action.type) {
    case SURVEY_TOOL.SHOW_SURVEY:
      // only set showModal to true if the question for that page exists
      // and then set the fromLocation, else clear them both
      if (state.questionSet[action.data]) {
        newState.showModal = true;
        newState.fromLocation = action.data;
      } else {
        newState.showModal = false;
        newState.fromLocation = '';
      }

      return newState;
    case SURVEY_TOOL.HIDE_SURVEY:
      // removing the question for the page in order to not show the question twice
      delete newState.questionSet[action.data];
      newState.showModal = false;
      newState.fromLocation = '';

      return newState;
    case SURVEY_TOOL.RECEIVE_QUESTIONS:
      newState.questionSet = action.data;

      return newState;
    default:
      return state;
  }
};

export default surveyToolReducer;
