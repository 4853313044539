import { IAtomTableHeaderColumn } from 'portal-common-library/interfaces/table';

import { TABLE_SORT_ATTRIBUTES } from '@appGameProgression/modules/PlayerData/constants';

import { IDefaultPlayerDataSchemaTable } from '../../modules/PlayerData/interfaces';

export * from './validationConstants';

export const GAME_PROGRESSION_CLASS = 'game-progression';

export const SANDBOX_TOOLTIP_COPY = 'Turn on Sandbox to start creating custom';

export enum SANDBOX_SCHEMA_STATUS {
  NEW = 'NEW',
  EDITED = 'EDITED',
  EMPTY = '',
}

export enum MENU_TYPES {
  TABLE_ITEM = 'tableItem',
  SUBHEADER = 'subheader',
}

export const KEY_NAME_MUST_BE_UNIQUE = 'Key name must be unique';

export const INVALID_GAME_ID = 'Invalid GameID';

export enum PROGRESSION_SCHEMA_ERRORS {
  DEFAULT_QUANTITY_MUST_BE_INTEGER = 'Default quantity must be an integer and must not contain any leading zeros',
  GAME_IDS_MUST_BE_INTEGERS = 'Game IDs must be integers',
}

export enum BULK_ERRORS {
  KEYS_UNIQUE = 'All keys must be unique',
  PLAYER_DATA_NON_NULL = 'All keys and data types must be non-null',
  IN_GAME_ITEMS_NON_NULL = 'All keys, display names, data types, and default quantities must be non-null',
  COMPLY_TO_VALIDATIONS = 'All fields must comply with their validations',
  UNSUPPORTED_COLUMN = 'Unsupported column',
  MISSING_COLUMN = 'Missing required column',
  CREATE = 'Error creating key(s)',
}

export enum KEY_NAMESPACE {
  PLAYER_DATA = 'PlayerData',
  IN_GAME_ITEMS = 'InGameItems',
}

export enum DEFAULT_NAMESPACE {
  DEFAULT_PLAYER_DATA = 'DefaultPlayerData',
}

export type TLookupKeyNamespace = KEY_NAMESPACE | DEFAULT_NAMESPACE;

export enum UPLOAD_PROGRESS_MAX {
  VALIDATION = 100,
}

export enum ACTION_TYPE {
  CREATE = 'create',
  EDIT = 'edit',
}

export const SCHEMA_KEY_CAP = 100;

export const SCHEMA_KEY_CAP_EXCEEDED_ERROR = `Cannot create more than ${SCHEMA_KEY_CAP} keys`;

export const DEFAULT_PLAYER_DATA = [
  {
    id: 'GamesPlayed',
    key: 'games_played',
    type: 'Integer',
    description: 'A count of the games a player has entered',
  },
  {
    id: 'CashGamesPlayed',
    key: 'cash_games_played',
    type: 'Integer',
    description: 'A count of the cash games a player has entered',
  },
  {
    id: 'GamesWon',
    key: 'games_won',
    type: 'Integer',
    description: 'A count of the total games a player has won',
  },
  {
    id: 'CashGamesWon',
    key: 'cash_games_won',
    type: 'Integer',
    description: 'A count of the total cash games a player has won',
  },
  {
    id: 'BestScoreLifetime',
    key: 'best_score_lifetime',
    type: 'Float',
    description: 'The best score achieved by this player',
  },
  {
    id: 'AverageScore',
    key: 'average_score',
    type: 'Float',
    description: 'The average of all scores by this player',
  },
  {
    id: 'PlayerLevel',
    key: 'player_level',
    type: 'Integer',
    description: 'The player’s level for this game',
  },
  {
    id: 'SkillzLevel',
    key: 'skillz_level',
    type: 'Integer',
    description: 'The player’s global Skillz level',
  },
  {
    id: 'InstallDate',
    key: 'install_date',
    type: 'Date',
    description: 'The UTC date and timestamp the user installed the game',
  },
];

export const DEFAULT_PLAYER_DATA_HEAD_ROWS: IAtomTableHeaderColumn<TABLE_SORT_ATTRIBUTES>[] = [
  {
    id: 'default-player-data__table-header-table-key',
    cellContent: 'Key',
  },
  {
    id: 'default-player-data__table-header-table-type',
    cellContent: 'Type',
  },
  {
    id: 'default-player-data__table-header-table-description',
    cellContent: 'Description',
  },
];

export const DEFAULT_PLAYER_DATA_ORDER: (keyof IDefaultPlayerDataSchemaTable)[] = [
  'key',
  'type',
  'description',
];

export enum SNACKBAR_MESSAGES {
  ERROR_PROCESSING_TRY_AGAIN = 'Error processing: Please try again. If the issue persists, contact us',
}

export const MIN_SCREEN_WIDTH_TO_ANIMATE = 960;

export const DEFAULT_PROGRESSION_SCHEMA_PAGE_SIZE = 20;

export const IGI_EDIT_AUTHORIZATION_ERROR_MESSAGE =
  'You can only modify an In-Game Item if you have access to all the games assigned to it.';

export const IGI_PUBLISH_AUTHORIZATION_ERROR_MESSAGE =
  'You can only publish an In-Game Item if you have access to all the games assigned to it.';
