/** prepares a filename will '...' appended to the filename if it is too long
 * @params { fileName } string - the filename of the file being uploaded
 * @return { string } - the modified filename ready to be rendered
 */
export function fileNameHelper(filename: string): string {
  if (filename.length > 11) {
    return filename.substring(0, 11) + '...';
  }

  return filename;
}
/**
 * calculates the percentage difference from A -> B
 * @param valueA
 * @param valueB
 */
export function calculatePercentageDifference(valueA: number, valueB: number): number {
  return ((valueB - valueA) / valueA) * 100;
}
