import loadable from '@loadable/component';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  fetchGames,
  openSnackbar
} from '@app/common/actions';
import { IRootState } from '@app/core/rootReducer';

import { fetchSdkVersions } from './actions';

const ContactUs = loadable(() => import(/* webpackChunkName: "contact-us" */ './ContactUs'));

const mapStateToProps = (state: IRootState) => {
  const { user, contactUs, games } = state;

  return { user, contactUs, games };
};

const mapDispatchToProps = {
  fetchGames,
  fetchSdkVersions,
  openSnackbar
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TContactUsConnectedProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactUs));
