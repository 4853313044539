import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IIndexResponse } from 'portal-common-library/interfaces/redux';

import { fetchNotificationItems } from './actions';
import { INotification, INotificationIndexParams, TNotificationReducerState } from './interfaces';

const initialState: TNotificationReducerState = {
  totalCount: 0,
  items: {}
};

type TActionPayload = PayloadAction<
  IIndexResponse<INotification>,
  string,
  { arg: INotificationIndexParams }
>;
const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchNotificationItems.fulfilled,
      (state: TNotificationReducerState, action: TActionPayload) => {
        state.totalCount = action.payload.totalCount;
        if (action.meta.arg.page === 0) {
          state.items = {};
        }
        state.items[action.meta.arg.page] = action.payload.items;
      }
    );
  }
});

export default itemsSlice.reducer;
