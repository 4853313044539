import * as React from 'react';

import defaultProfile from '@app/imageAssets/Dashboard/defaultProfileIcon.svg';

export const profileIconWithInitials = (
  userFirstName: string,
  userLastName: string
): JSX.Element => {
  let renderFirstInitialOrLast: string = userFirstName.slice(0, 1)
    ? userFirstName.slice(0, 1).toUpperCase()
    : userLastName.slice(0, 1).toUpperCase();

  return (
    <div className="profile-widget__profile-icon">
      <h4 className="profile-widget__profile-icon-initials">{renderFirstInitialOrLast}</h4>
    </div>
  );
};

export const defaultProfileIcon: JSX.Element = (
  <img className="profile-widget__default-profile-icon" src={defaultProfile} />
);
