import { createSlice } from '@reduxjs/toolkit';

import { fetchPermissions } from '@app/common/actions';
import { IPermissions } from '@app/common/interfaces';

const permissions = createSlice({
  name: 'permissions',
  initialState: {} as IPermissions,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPermissions.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });

    builder.addCase(fetchPermissions.rejected, (_, action) => {
      throw action.error;
    });
  },
});

export default permissions.reducer;
