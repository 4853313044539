import { useQuery } from 'react-query';

import { getLearningCenterCategories, getLearningCenterCards, getGames } from './actions';

export const useLearningCenterCategories = () =>
  useQuery(['learningCenterCategories'], () => getLearningCenterCategories());

export const useLearningCenterCards = () =>
  useQuery(['learningCenterCards'], () => getLearningCenterCards());

export const useGames = () => useQuery(['games'], () => getGames());
