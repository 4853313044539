/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-return */
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import { Dispatch } from 'redux';

import { PLATFORM } from '@app/common/constants';
import { snakeCaseConverter } from '@app/common/helpers';
import {
  IAction,
  IErrors,
  TDispatch,
  TThunkAction,
  TErrorGeneral,
  TAxiosPromise,
  IActionNoData,
} from '@app/common/interfaces';
import axios, { AxiosResponse, AxiosError } from '@app/common/setup/axiosWithHeader';

import { GAME_BUILDS_PER_PAGE, GAME_BUILD_STATUS } from '../constants';
import {
  IGameBuildIndexParams,
  IGameBuild,
  TGameBuildsIndexResponses,
  TGameBuildsPerPage,
  IGameBuildsFirstPage,
} from '../interfaces';
import { receiveGameBuildsCount } from './countActions';

export enum GAME_BUILDS_FETCH {
  RECEIVE_GAME_BUILDS = 'gameBuildsFetch/RECEIVE_GAME_BUILDS',
  RESET_AND_RECEIVE_GAME_BUILDS = 'gameBuildsFetch/RESET_AND_RECEIVE_GAME_BUILDS',
  CLEAR_GAME_BUILDS = 'gameBuildsFetch/CLEAR_GAME_BUILDS',
}

export function receiveGameBuilds(data: TGameBuildsPerPage): IAction<TGameBuildsPerPage> {
  return { type: GAME_BUILDS_FETCH.RECEIVE_GAME_BUILDS, data };
}

export function resetAndReceiveGameBuilds(
  data: IGameBuildsFirstPage
): IAction<IGameBuildsFirstPage> {
  return { type: GAME_BUILDS_FETCH.RESET_AND_RECEIVE_GAME_BUILDS, data };
}

export function clearGameBuilds(): IActionNoData {
  return { type: GAME_BUILDS_FETCH.CLEAR_GAME_BUILDS };
}

export function fetchGameBuildsApiCall(
  gameId: number,
  indexParams: IGameBuildIndexParams
): Promise<AxiosResponse<TGameBuildsIndexResponses> | never> {
  let urlParams = new URLSearchParams(snakeCaseConverter(indexParams)).toString();

  return axios.get(`/api/v1/games/${gameId}/game_binaries?${urlParams}`);
}

export type TActiveAndroidAxiosPromise = IGameBuild[] | never;
export function fetchActiveAndroidGameBuilds(
  gameId: number,
  limit: number = 20000
): Promise<TActiveAndroidAxiosPromise> {
  let activeAndroidParams: IGameBuildIndexParams = {
    uploadStatus: GAME_BUILD_STATUS.COMPLETE,
    platform: PLATFORM.ANDROID,
    active: true,
    limit,
  };

  return fetchGameBuildsApiCall(gameId, activeAndroidParams)
    .then((response: AxiosResponse<TGameBuildsIndexResponses>): IGameBuild[] => {
      return camelize(response.data.items);
    })
    .catch((error: AxiosError<IErrors>): never => {
      let errorText: TErrorGeneral = error.response ? error.response.data : error;
      throw errorText;
    });
}

export const fetchGameBuilds: TThunkAction = (
  gameId: number,
  indexParams: IGameBuildIndexParams,
  page: number = 0
): TDispatch => {
  // in the new assign and sync modal we pass the offset so it doesn't fall into this if block
  if (indexParams.offset === undefined && page !== 0) {
    indexParams.offset = page * GAME_BUILDS_PER_PAGE;
  }

  return (
    dispatch: Dispatch<IAction<TGameBuildsPerPage | number> | IActionNoData>
  ): TAxiosPromise<TGameBuildsPerPage> => {
    return (
      fetchGameBuildsApiCall(gameId, indexParams)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: AxiosResponse<TGameBuildsIndexResponses>): any => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          let resp: TGameBuildsIndexResponses = camelize(response.data);

          if (page === 0) {
            dispatch(resetAndReceiveGameBuilds({ 0: resp.items }));
          } else {
            dispatch(receiveGameBuilds({ [page]: resp.items }));
          }

          dispatch(receiveGameBuildsCount(resp.totalCount));
        })
        .catch((error: AxiosError<IErrors>): never => {
          let errorText: TErrorGeneral = error.response ? error.response.data : error;
          throw errorText;
        })
    );
  };
};
