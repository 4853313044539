export enum ANALYTICS_ATTRIBUTES {
  REVENUE = 'Revenue',
  ESTIMATED_REVENUE = 'Estimated Revenue',
  DAU = 'DAU',
  PAYING_DAU = 'Paying DAU',
  SKILLZ_INSTALLS = 'Skillz Installs',
  INSTALLS = 'Installs',
  D1_RETENTION = 'D1 Retention',
  D7_RETENTION = 'D7 Retention',
  D30_RETENTION = 'D30 Retention',
  P1_RETENTION = 'P1 Retention',
  P7_RETENTION = 'P7 Retention',
  P30_RETENTION = 'P30 Retention',
  INSTALLS_TO_D = 'I to D',
  INSTALLS_TO_DEPOSIT = 'Installs to Deposit',
  ABORT_RATE = 'Abort Rate',
  ABORT_EVENTS_INTENTIONAL = 'Intentional',
  ABORT_EVENTS_UNINTENTIONAL = 'Unintentional',
  ABORT_EVENTS_BACKGROUNDED = 'Backgrounded',
  ABORT_EVENTS_TERMINATED = 'Terminated',
  ABORT_EVENTS_TIMEOUT = 'Timeout',
  ABORT_EVENTS_UNKNOWN = 'Unknown',
  ACCELERATE_INSTALLS = 'Accelerate Installs',
  DAU_OF_MAU = 'DAU/MAU',
  GAMES_PER_USER = 'Games Per DAU',
  CASH_GAMES_PER_USER = 'Paid Games per Paying DAU',
  GAMES_PLAYED = 'Games Played',
  PRO_GAMES_PLAYED = 'Pro Games Played',
  Z_GAMES_PLAYED = 'Z Games Played',
  D1 = 'D1',
  D7 = 'D7',
  D30 = 'D30',
  P1 = 'p1',
  P7 = 'p7',
  P30 = 'p30',
  INSTALLS_TO_1Z = 'I to 1Z',
  INSTALLS_TO_2Z = 'I to 2Z',
  INSTALLS_TO_3Z = 'I to 3Z',
  ARPPU = 'Avg Rev/Paying DAU',
  ARPDAU = 'Avg Rev/DAU',
  EST_ARPPU = 'Est Avg Rev/Paying DAU',
  EST_ARPDAU = 'Est Avg Rev/DAU',
  NEW_PAYING_USERS = 'NPU',
  COMPLETION_1Z = 'Completion 1Z',
  COMPLETION_2Z = 'Completion 2Z',
  COMPLETION_3Z = 'Completion 3Z',
  DAY_1_Z_GAMES_PER_INSTALL = 'Day 1 Z Games per Install',
  IN_BAND_WIN_RATE = 'In-Band Win Rate',
  ENTRY_FEE = 'Entry Fee',
  ENTRY_FEE_PER_PRO_DAU = 'Entry Fee per Pro DAU',
  PRO_DAU = 'Pro DAU',
  FIRST_TIME_PAYERS_TO_SKILLZ_NETWORK = 'New Paying Users',
  PRO_GAMES_PER_PRO_DAU = 'Pro Games Per Pro DAU',
  NEW_PRO_USERS = 'New Pro Playing Users',
  PAYER_PRO_RETENTION_D1 = 'Paying User Retention D1',
  PAYER_PRO_RETENTION_D7 = 'Paying User Retention D7',
  PAYER_PRO_RETENTION_D30 = 'Paying User Retention D30',
  RETENTION_D1 = 'Retention D1',
  RETENTION_D7 = 'Retention D7',
  RETENTION_D30 = 'Retention D30',
  INSTALLS_Z1 = 'Installs to 1Z',
  INSTALLS_Z2 = 'Installs to 2Z',
  INSTALLS_Z3 = 'Installs to 3Z',
  ENTRY_FEE_D1 = 'Entry Fee D1',
  ENTRY_FEE_D7 = 'Entry Fee D7',
  ENTRY_FEE_D30 = 'Entry Fee D30',
  ENTRY_FEE_LIFETIME = 'Entry Fee Lifetime',
  ARPPU_DEV_ANALYTICS = 'Avg Rev/Pro DAU',
  EST_ARPPU_DEV_ANALYTICS = 'Est Avg Rev/Pro DAU',
}

export enum MATH_OPERATIONS {
  SUM = 'sum',
  AVERAGE = 'average',
  AVG_OVER_TOTAL_DAYS = 'averageOverDays',
}

export enum ANALYTICS_DB_ATTRIBUTES {
  GROSS_REVENUE = 'grossRevenue',
  ESTIMATED_GROSS_REVENUE = 'estimatedGrossRevenue',
  D1 = 'd1',
  D7 = 'd7',
  D30 = 'd30',
  P1 = 'p1',
  P7 = 'p7',
  P30 = 'p30',
  SKILLZ_DAU = 'skillzDau',
  SKILLZ_PDAU = 'skillzPayingDau',
  INSTALLS_TO_1Z = 'installsTo1z',
  INSTALLS_TO_2Z = 'installsTo2z',
  INSTALLS_TO_3Z = 'installsTo3z',
  INSTALLS_TO_D = 'installsToDeposit',
  ABORT_RATE = 'abortRate',
  ABORT_EVENTS_INTENTIONAL = 'intentionalAbortRate',
  ABORT_EVENTS_UNINTENTIONAL = 'unintentionalAbortRate',
  ABORT_EVENTS_BACKGROUNDED = 'backgroundedAbortRate',
  ABORT_EVENTS_TERMINATED = 'terminatedAbortRate',
  ABORT_EVENTS_TIMEOUT = 'timeoutAbortRate',
  ABORT_EVENTS_UNKNOWN = 'unknownAbortRate',
  ACCELERATE_INSTALLS = 'accelerateInstalls',
  SKILLZ_INSTALLS = 'skillzInstalls',
  DAU_OF_MAU = 'dauOfMau',
  GAMES_PER_USER = 'gamesPerPlayer',
  CASH_GAMES_PER_USER = 'proGamesPerPlayer',
  GAMES_PLAYED = 'gamesPlayed',
  ARPPU = 'skillzArppu',
  ARPDAU = 'skillzArpdau',
  EST_ARPPU = 'estSkillzArppu',
  EST_ARPDAU = 'estSkillzArpdau',
  PRO_GAMES_PLAYED = 'proGamesPlayed',
  Z_GAMES_PLAYED = 'zGamesPlayed',
  NEW_PAYING_USERS = 'newProPlayers',
  COMPLETION_1Z = 'mmp1zCompletion',
  COMPLETION_2Z = 'mmp2zCompletion',
  COMPLETION_3Z = 'mmp3zCompletion',
  DAY_1_Z_GAMES_PER_INSTALL = 'd1ZMmpTournamentsPerMmpInstalls',
  IN_BAND_WIN_RATE = 'inBandWinRatePerc',
  ENTRY_FEE = 'entryFee',
  ENTRY_FEE_PER_PRO_DAU = 'entryFeePerProDau',
  PRO_DAU = 'proDau',
  FIRST_TIME_PAYERS_TO_SKILLZ_NETWORK = 'firstTimePayersToSkillzNetwork',
  PRO_GAMES_PER_PRO_DAU = 'proGamesPerProDau',
  NEW_PRO_USERS = 'newProUsers',
  PAYER_PRO_RETENTION_D1 = 'payerProRetentionD1',
  PAYER_PRO_RETENTION_D7 = 'payerProRetentionD7',
  PAYER_PRO_RETENTION_D30 = 'payerProRetentionD30',
  INSTALLS_TO_DEPOSIT = 'kpiInstallsToDeposit',
  INSTALLS = 'installs',
  INSTALLS_Z1 = 'installsZ1',
  INSTALLS_Z2 = 'installsZ2',
  INSTALLS_Z3 = 'installsZ3',
  RETENTION_D1 = 'retentionD1',
  RETENTION_D7 = 'retentionD7',
  RETENTION_D30 = 'retentionD30',
  ENTRY_FEE_D1 = 'entryFeeD1',
  ENTRY_FEE_D7 = 'entryFeeD7',
  ENTRY_FEE_D30 = 'entryFeeD30',
  ENTRY_FEE_LIFETIME = 'entryFeeLifetime',
  ARPPU_DEV_ANALYTICS = 'skillzArppu',
  EST_ARPPU_DEV_ANALYTICS = 'estSkillzArppu',
  ESTIMATED_REVENUE = 'estimatedRevenue',
}

export const ANALYTICS_KEY_ATTRIBUTES: {
  [ANALYTICS_ATTRIBUTES: string]: ANALYTICS_DB_ATTRIBUTES;
} = {
  [ANALYTICS_ATTRIBUTES.SKILLZ_INSTALLS]: ANALYTICS_DB_ATTRIBUTES.SKILLZ_INSTALLS,
  [ANALYTICS_ATTRIBUTES.DAU]: ANALYTICS_DB_ATTRIBUTES.SKILLZ_DAU,
  [ANALYTICS_ATTRIBUTES.PAYING_DAU]: ANALYTICS_DB_ATTRIBUTES.SKILLZ_PDAU,
  [ANALYTICS_ATTRIBUTES.DAU_OF_MAU]: ANALYTICS_DB_ATTRIBUTES.DAU_OF_MAU,
  [ANALYTICS_ATTRIBUTES.D1]: ANALYTICS_DB_ATTRIBUTES.D1,
  [ANALYTICS_ATTRIBUTES.D7]: ANALYTICS_DB_ATTRIBUTES.D7,
  [ANALYTICS_ATTRIBUTES.D30]: ANALYTICS_DB_ATTRIBUTES.D30,
  [ANALYTICS_ATTRIBUTES.INSTALLS_TO_1Z]: ANALYTICS_DB_ATTRIBUTES.INSTALLS_TO_1Z,
  [ANALYTICS_ATTRIBUTES.INSTALLS_TO_2Z]: ANALYTICS_DB_ATTRIBUTES.INSTALLS_TO_2Z,
  [ANALYTICS_ATTRIBUTES.INSTALLS_TO_3Z]: ANALYTICS_DB_ATTRIBUTES.INSTALLS_TO_3Z,
  [ANALYTICS_ATTRIBUTES.INSTALLS_TO_D]: ANALYTICS_DB_ATTRIBUTES.INSTALLS_TO_D,
  [ANALYTICS_ATTRIBUTES.ABORT_RATE]: ANALYTICS_DB_ATTRIBUTES.ABORT_RATE,
  [ANALYTICS_ATTRIBUTES.ABORT_EVENTS_UNKNOWN]: ANALYTICS_DB_ATTRIBUTES.ABORT_EVENTS_UNKNOWN,
  [ANALYTICS_ATTRIBUTES.ABORT_EVENTS_TIMEOUT]: ANALYTICS_DB_ATTRIBUTES.ABORT_EVENTS_TIMEOUT,
  [ANALYTICS_ATTRIBUTES.ABORT_EVENTS_TERMINATED]: ANALYTICS_DB_ATTRIBUTES.ABORT_EVENTS_TERMINATED,
  [ANALYTICS_ATTRIBUTES.ABORT_EVENTS_BACKGROUNDED]:
    ANALYTICS_DB_ATTRIBUTES.ABORT_EVENTS_BACKGROUNDED,
  [ANALYTICS_ATTRIBUTES.ABORT_EVENTS_UNINTENTIONAL]:
    ANALYTICS_DB_ATTRIBUTES.ABORT_EVENTS_UNINTENTIONAL,
  [ANALYTICS_ATTRIBUTES.ABORT_EVENTS_INTENTIONAL]: ANALYTICS_DB_ATTRIBUTES.ABORT_EVENTS_INTENTIONAL,
  [ANALYTICS_ATTRIBUTES.ACCELERATE_INSTALLS]: ANALYTICS_DB_ATTRIBUTES.ACCELERATE_INSTALLS,
  [ANALYTICS_ATTRIBUTES.P1]: ANALYTICS_DB_ATTRIBUTES.P1,
  [ANALYTICS_ATTRIBUTES.P7]: ANALYTICS_DB_ATTRIBUTES.P7,
  [ANALYTICS_ATTRIBUTES.P30]: ANALYTICS_DB_ATTRIBUTES.P30,
  [ANALYTICS_ATTRIBUTES.REVENUE]: ANALYTICS_DB_ATTRIBUTES.GROSS_REVENUE,
  [ANALYTICS_ATTRIBUTES.ESTIMATED_REVENUE]: ANALYTICS_DB_ATTRIBUTES.ESTIMATED_REVENUE,
  [ANALYTICS_ATTRIBUTES.GAMES_PER_USER]: ANALYTICS_DB_ATTRIBUTES.GAMES_PER_USER,
  [ANALYTICS_ATTRIBUTES.CASH_GAMES_PER_USER]: ANALYTICS_DB_ATTRIBUTES.CASH_GAMES_PER_USER,
  [ANALYTICS_ATTRIBUTES.GAMES_PLAYED]: ANALYTICS_DB_ATTRIBUTES.GAMES_PLAYED,
  [ANALYTICS_ATTRIBUTES.PRO_GAMES_PLAYED]: ANALYTICS_DB_ATTRIBUTES.PRO_GAMES_PLAYED,
  [ANALYTICS_ATTRIBUTES.Z_GAMES_PLAYED]: ANALYTICS_DB_ATTRIBUTES.Z_GAMES_PLAYED,
  [ANALYTICS_ATTRIBUTES.ARPPU]: ANALYTICS_DB_ATTRIBUTES.ARPPU,
  [ANALYTICS_ATTRIBUTES.ARPDAU]: ANALYTICS_DB_ATTRIBUTES.ARPDAU,
  [ANALYTICS_ATTRIBUTES.EST_ARPPU]: ANALYTICS_DB_ATTRIBUTES.EST_ARPPU,
  [ANALYTICS_ATTRIBUTES.EST_ARPDAU]: ANALYTICS_DB_ATTRIBUTES.EST_ARPDAU,
  [ANALYTICS_ATTRIBUTES.NEW_PAYING_USERS]: ANALYTICS_DB_ATTRIBUTES.NEW_PAYING_USERS,
  [ANALYTICS_ATTRIBUTES.COMPLETION_1Z]: ANALYTICS_DB_ATTRIBUTES.COMPLETION_1Z,
  [ANALYTICS_ATTRIBUTES.COMPLETION_2Z]: ANALYTICS_DB_ATTRIBUTES.COMPLETION_2Z,
  [ANALYTICS_ATTRIBUTES.COMPLETION_3Z]: ANALYTICS_DB_ATTRIBUTES.COMPLETION_3Z,
  [ANALYTICS_ATTRIBUTES.DAY_1_Z_GAMES_PER_INSTALL]:
    ANALYTICS_DB_ATTRIBUTES.DAY_1_Z_GAMES_PER_INSTALL,
  [ANALYTICS_ATTRIBUTES.IN_BAND_WIN_RATE]: ANALYTICS_DB_ATTRIBUTES.IN_BAND_WIN_RATE,
  [ANALYTICS_ATTRIBUTES.ENTRY_FEE]: ANALYTICS_DB_ATTRIBUTES.ENTRY_FEE,
  [ANALYTICS_ATTRIBUTES.ENTRY_FEE_PER_PRO_DAU]: ANALYTICS_DB_ATTRIBUTES.ENTRY_FEE_PER_PRO_DAU,
  [ANALYTICS_ATTRIBUTES.PRO_DAU]: ANALYTICS_DB_ATTRIBUTES.PRO_DAU,
  [ANALYTICS_ATTRIBUTES.FIRST_TIME_PAYERS_TO_SKILLZ_NETWORK]:
    ANALYTICS_DB_ATTRIBUTES.FIRST_TIME_PAYERS_TO_SKILLZ_NETWORK,
  [ANALYTICS_ATTRIBUTES.NEW_PRO_USERS]: ANALYTICS_DB_ATTRIBUTES.NEW_PRO_USERS,
  [ANALYTICS_ATTRIBUTES.PRO_GAMES_PER_PRO_DAU]: ANALYTICS_DB_ATTRIBUTES.PRO_GAMES_PER_PRO_DAU,
  [ANALYTICS_ATTRIBUTES.PAYER_PRO_RETENTION_D1]: ANALYTICS_DB_ATTRIBUTES.PAYER_PRO_RETENTION_D1,
  [ANALYTICS_ATTRIBUTES.PAYER_PRO_RETENTION_D7]: ANALYTICS_DB_ATTRIBUTES.PAYER_PRO_RETENTION_D7,
  [ANALYTICS_ATTRIBUTES.PAYER_PRO_RETENTION_D30]: ANALYTICS_DB_ATTRIBUTES.PAYER_PRO_RETENTION_D30,
  [ANALYTICS_ATTRIBUTES.INSTALLS_TO_DEPOSIT]: ANALYTICS_DB_ATTRIBUTES.INSTALLS_TO_DEPOSIT,
  [ANALYTICS_ATTRIBUTES.INSTALLS]: ANALYTICS_DB_ATTRIBUTES.INSTALLS,
  [ANALYTICS_ATTRIBUTES.INSTALLS_Z1]: ANALYTICS_DB_ATTRIBUTES.INSTALLS_Z1,
  [ANALYTICS_ATTRIBUTES.INSTALLS_Z2]: ANALYTICS_DB_ATTRIBUTES.INSTALLS_Z2,
  [ANALYTICS_ATTRIBUTES.INSTALLS_Z3]: ANALYTICS_DB_ATTRIBUTES.INSTALLS_Z3,
  [ANALYTICS_ATTRIBUTES.RETENTION_D1]: ANALYTICS_DB_ATTRIBUTES.RETENTION_D1,
  [ANALYTICS_ATTRIBUTES.RETENTION_D7]: ANALYTICS_DB_ATTRIBUTES.RETENTION_D7,
  [ANALYTICS_ATTRIBUTES.RETENTION_D30]: ANALYTICS_DB_ATTRIBUTES.RETENTION_D30,
  [ANALYTICS_ATTRIBUTES.ENTRY_FEE_D1]: ANALYTICS_DB_ATTRIBUTES.ENTRY_FEE_D1,
  [ANALYTICS_ATTRIBUTES.ENTRY_FEE_D7]: ANALYTICS_DB_ATTRIBUTES.ENTRY_FEE_D7,
  [ANALYTICS_ATTRIBUTES.ENTRY_FEE_D30]: ANALYTICS_DB_ATTRIBUTES.ENTRY_FEE_D30,
  [ANALYTICS_ATTRIBUTES.ENTRY_FEE_LIFETIME]: ANALYTICS_DB_ATTRIBUTES.ENTRY_FEE_LIFETIME,
  [ANALYTICS_ATTRIBUTES.EST_ARPPU_DEV_ANALYTICS]: ANALYTICS_DB_ATTRIBUTES.EST_ARPPU_DEV_ANALYTICS,
  [ANALYTICS_ATTRIBUTES.ARPPU_DEV_ANALYTICS]: ANALYTICS_DB_ATTRIBUTES.ARPPU_DEV_ANALYTICS,
};

export enum ANALYTIC_DATA_FORMAT_TYPES {
  CURRENCY = 'currency',
  WHOLE_NUMBER = 'wholeNumber',
  WHOLE_NUMBER_FROM_DECIMAL = 'wholeNumberFromDecimal',
  DECIMAL = 'decimal',
  PERCENTAGE = 'percentage',
}

export enum ANALYTIC_DATA_BENCHMARKS {
  GAMES_PER_DAU = 10,
  INSTALLS_TO_1Z = 90,
}

export enum ANALYTICS_BENCHMARK_LABELS {
  BENCHMARK = 'Benchmark',
}
