import { DropdownOption } from '@app/common/components';

export function formatDropdownOptions(options: string[]): DropdownOption[] {
  return options.map((option: string) => {
    return {
      value: option,
      component: option
    };
  });
}
