import {
  GAMES_PER_DAU_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL,
  DAY_7_RETENTION_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL,
  INSTALLS_TO_2Z_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL,
} from '@appGameHome/modules/Overview/constants/gamePerformanceStatus';

export const PRIZE_ENABLEMENT_STEPS_COPY = {
  DAU: 'Daily Active Users',
  GAMES_PER_DAU: `${GAMES_PER_DAU_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL} Games per DAU`,
  DAY_7_RETENTION: `${DAY_7_RETENTION_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL}% Day 7 Retention`,
  INSTALLS_TO_2_Z: `${INSTALLS_TO_2Z_7_DAY_AVERAGE_PRIZE_ENABLEMENT_GOAL}% Installs to 2Z`,
  FAIR_PLAY: 'Fair Play',
};

export enum PRIZE_ENABLEMENT_STEPS_STATE {
  PENDING = 'pending',
  PASSED = 'passed',
  ACTION_REQUIRED = 'actionRequired',
  ICON_GAME = 'iconGame',
  ICON_GIFT_COLOR = 'iconGiftColor',
  ICON_GIFT_SHADE = 'iconGiftShade',
}

export enum PRIZE_ENABLEMENT_LABEL_POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum SORTABLE_PRIZE_ENABLEMENT_ATTRIBUTE {
  SKILLZ_DAU_7_DAY_AVERAGE = 'skillzDau7DayAverage',
  GAMES_PER_DAU_7_DAY_AVERAGE = 'gamesPerDau7DayAverage',
  DAY_7_RETENTION_7_DAY_AVERAGE = 'day7Retention7DayAverage',
  INSTALLS_TO_1Z_7_DAY_AVERAGE = 'installsTo1z7DayAverage',
  IS_FAIR = 'isFair',
}

export enum VIEW {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}
