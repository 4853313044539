import merge from 'lodash/merge';

import { FEATURE } from '@app/common/actions';
import { IAction, IFeature } from '@app/common/interfaces';

let initialState: IFeature = {};

const featureReducer = (state: IFeature = initialState, action: IAction<IFeature>): IFeature => {
  let newState: IFeature;
  Object.freeze(state);
  switch (action.type) {
    case FEATURE.FLAGS:
      newState = merge({}, state, action.data);

      return newState;
    default:
      return state;
  }
};

export default featureReducer;
