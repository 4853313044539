import * as React from 'react';

import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';

import variables from '@app/core/variables.scss';

const SidebarTooltipWithStyles = withStyles({
  tooltip: {
    padding: '12px 16px',
    minWidth: '140px',
    backgroundColor: 'black',
    color: variables.colorGrayArrowDisabledState,
  },
  arrow: {
    color: 'black',
  },
  popper: {
    zIndex: 1500, // z-index for tooltips according to mui
  },
})(Tooltip);

const SidebarTooltip = ({
  id,
  title,
  children,
  shouldShowTooltip,
}: {
  id: number;
  title: string;
  children: JSX.Element;
  shouldShowTooltip: boolean;
}) => {
  const tooltipContent = (
    <div className={`sidebar-tooltip`} data-qa={`sidebar-tooltip-${id}`}>
      <div className={`sidebar-tooltip__text`}>{title}</div>
    </div>
  );

  if (!shouldShowTooltip) {
    return children;
  }

  return (
    <SidebarTooltipWithStyles
      id={`sidebar-tooltip-${id}`}
      title={tooltipContent}
      arrow={true}
      enterDelay={500}
      placement={'right'}
    >
      <div data-qa={`sidebar-tooltip-${id}-ready`}>{children}</div>
    </SidebarTooltipWithStyles>
  );
};

export default SidebarTooltip;
