import * as React from 'react';

import capitalize from 'lodash/capitalize';

import { AtomLinkButton, AtomProgressBar } from '@app/common/atoms';
import { isDefaultCompany, isDefaultUserName } from '@app/common/helpers';

import { defaultProfileIcon, profileIconWithInitials } from '.';
import { IProfileWidgetProps } from '../interfaces';
import './styles/profileWidgetStyles.scss';

class ProfileWidget extends React.PureComponent<IProfileWidgetProps> {
  public renderProgressComplete = (): JSX.Element => {
    let {
      segments: { current: currentSegment },
    } = this.props;

    return (
      <div
        className="profile-widget__percent-complete-text"
        data-qa="profile-widget__percent-complete"
      >{`${currentSegment}% complete`}</div>
    );
  };

  public renderProfileIcon = (hasDefaultUserName: boolean): JSX.Element => {
    let { user } = this.props;

    return hasDefaultUserName
      ? defaultProfileIcon
      : profileIconWithInitials(user.firstName ?? '', user.lastName ?? '');
  };

  public renderUserName = (hasDefaultUserName: boolean): string => {
    let { user } = this.props;

    return hasDefaultUserName
      ? 'Your name'
      : `${capitalize(user.firstName ?? '')} ${capitalize(user.lastName ?? '')}`;
  };

  public renderStudioName = (): string => {
    let { publisher } = this.props;

    return isDefaultCompany(publisher.name, publisher.id)
      ? 'Your studio profile'
      : capitalize(publisher.name ?? '');
  };

  public render = (): JSX.Element => {
    let { user, segments } = this.props;

    let hasDefaultUserName: boolean = isDefaultUserName(user.firstName, user.lastName);
    let userNameItalics: string = isDefaultUserName(user.firstName, user.lastName)
      ? 'user-name-italics'
      : '';

    return (
      <div
        className="profile-widget__container"
        id="profile-widget__clickable-container"
        onClick={this.props.openProfileModal}
        data-qa="profile-widget__container"
      >
        <h3 className="profile-widget__studio-name" data-qa="profile-widget__studio-name">
          {this.renderStudioName()}
        </h3>
        <div className="profile-widget__icon-name-container">
          {this.renderProfileIcon(hasDefaultUserName)}
          <h4
            className={`profile-widget__first-last-name ${userNameItalics}`}
            data-qa="profile-widget__user-name"
          >
            {this.renderUserName(hasDefaultUserName)}
          </h4>
        </div>
        <span className="profile-widget__progress-bar-edit-container">
          <AtomProgressBar
            id="profile-widget__atom-progress-bar"
            currentSegment={segments.current}
            previousSegment={segments.previous}
          />
          <AtomLinkButton
            id="profile-widget___edit-button"
            onClick={this.props.openProfileModal}
            text="Edit"
            dataAttributes={{ 'data-qa': 'profile-widget__edit-button' }}
          />
        </span>
        {this.renderProgressComplete()}
      </div>
    );
  };
}

export { ProfileWidget };
