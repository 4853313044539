import { PUBLIC_FLOWS, PUBLIC_ROUTES, PUBLIC_PRIVATE_ROUTES } from '../constants';

// TODO: Remove flow; keeping it now to maintain original logic
export function isPublicRoute(flow: string, pathName: string): boolean {
  return PUBLIC_FLOWS.includes(flow) || PUBLIC_ROUTES.includes(pathName);
}

export function isPublicOrPrivateRoute(pathName: string): boolean {
  return PUBLIC_PRIVATE_ROUTES.includes(pathName);
}
