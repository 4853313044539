import React from 'react';

import Box from '@mui/material/Box';

interface IProps {
  children: React.ReactNode;
}

export const StickyHeader = ({ children }: IProps): JSX.Element => {
  return (
    <Box
      sx={{
        position: 'sticky',
        zIndex: 7,
      }}
      className={'peek-header-offset'}
    >
      {children}
    </Box>
  );
};
