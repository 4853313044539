import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
// @ts-ignore: camelize is by definition an un-typeable Any => Any
import camelize from 'camelize';

import { IGameChallenge, IGameChallengeParams } from '@app/common/interfaces/gameChallenge';

export const fetchGameChallenge = createAsyncThunk(
  'gameChallenge/fetch',
  async (params: IGameChallengeParams) => {
    const response: AxiosResponse<IGameChallenge> = await axios
      .get(`/api/v1/game_challenge/${params.challengeName}`)
      .then(camelize);

    return response.data;
  }
);

export const setRegistrationStatus = createAsyncThunk(
  'gameChallenge/register',
  async (params: IGameChallengeParams) => {
    const response: AxiosResponse<IGameChallenge> = await axios
      .post(`/api/v1/game_challenge/${params.challengeName}`, { registered: params.registered })
      .then(camelize);

    return response.data;
  }
);

export const updateRegistrationStatus = createAsyncThunk(
  'gameChallenge/register',
  async (params: IGameChallengeParams) => {
    const response: AxiosResponse<IGameChallenge> = await axios
      .patch(`/api/v1/game_challenge/${params.challengeName}`, { registered: params.registered })
      .then(camelize);

    return response.data;
  }
);

export const updateNudgeStatus = createAsyncThunk(
  'gameChallenge/nudge',
  async (params: IGameChallengeParams) => {
    const response: AxiosResponse<IGameChallenge> = await axios
      .patch(`/api/v1/game_challenge/${params.challengeName}`, {
        nudgeDismissed: params.nudgeDismissed
      })
      .then(camelize);

    return response.data;
  }
);
