import { createSlice } from '@reduxjs/toolkit';

import { fetchGameAccess } from '@app/common/actions';

import { TGameAccess } from '../../common/helpers';

const gameAccess = createSlice({
  name: 'gameAccess',
  initialState: [] as TGameAccess,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGameAccess.fulfilled, (state, action) => {
      return action.payload;
    });

    builder.addCase(fetchGameAccess.rejected, (_, action) => {
      throw action.error;
    });
  },
});

export default gameAccess.reducer;
