import merge from 'lodash/merge';
import { GAME } from '@app/common/actions';
import camelize from 'camelize';

let initialState = {
  androidStoreUrl: '',
  iosStoreUrl: '',
  samsungStoreUrl: ''
};

const gameUrlReducer = (state = initialState, action) => {
  let newState;
  Object.freeze(state);
  switch (action.type) {
    case GAME.GAME_INFO:
      newState = merge({}, state, camelize(action.data));
      return newState;
    default:
      return state;
  }
};

export default gameUrlReducer;
