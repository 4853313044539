import React, { useState } from 'react';

import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { useSnackbarContext } from '@skillzet/components';

import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

import { DialogTitleWithCloseButton } from '@app/modules/GameManagement/modules/GameHome/modules/GameProgression/modules/SeasonsTab/subcomponents/SeasonsList/subcomponents/DialogTitleWithCloseButton';

import { useSurveyContext } from './SurveyContext';
import { postSurveyResponse } from './actions';
import {
  DEFAULT_SURVEY_TITLE,
  SURVEY_24H_COOKIE,
  SUCCESS_SUBMISSION_SNACKBAR_MESSAGE,
} from './constants';
import { renderQuestionWithAppropriateComponent, addAnswer, validateAnswers } from './helpers';
import { ISurvey, ISurveyAnswer, TUserType } from './interfaces';

export interface IDialogSurveyProps {
  onClose: () => void;
  survey: ISurvey;
  userType: TUserType;
  userId: number;
}

export default function DialogSurvey({ onClose, survey, userType, userId }: IDialogSurveyProps) {
  const isSurveyOpen = !!survey;

  const [answers, setAnswers] = useState<ISurveyAnswer[]>([]);
  const { show: showSnackbar } = useSnackbarContext();
  const { closeSurvey } = useSurveyContext();
  const questions = survey.surveyQuestions;
  const pageLocation = useLocation().pathname;

  const submitResponse = useMutation(
    (isDismissal: boolean) => {
      return postSurveyResponse({
        userId,
        userType,
        isDismissal,
        pageLocation,
        surveyId: survey.id,
        answers,
      });
    },
    {
      onSuccess: (_, isDismissal) => {
        if (!isDismissal) {
          showSnackbar(SUCCESS_SUBMISSION_SNACKBAR_MESSAGE);
        }
        localStorage.setItem(SURVEY_24H_COOKIE, `${new Date().getTime()}`);
        closeSurvey();
      },
      onError: (error: Error) => {
        showSnackbar(`An error occurred: ${error.message}. Please try again.`, 'error');
      },
    }
  );

  const handleClose = () => {
    submitResponse.mutate(true);
    onClose();
  };

  if (!isSurveyOpen) return <></>;

  return (
    <Dialog
      open={isSurveyOpen}
      onClose={handleClose}
      fullWidth
      maxWidth={'md'}
      data-qa={'dialog-survey-container'}
    >
      <DialogTitleWithCloseButton onClose={handleClose} id={'dialog-survey-title'}>
        {survey.surveyTitle ?? DEFAULT_SURVEY_TITLE}
      </DialogTitleWithCloseButton>
      <DialogContent>
        {survey.surveyQuestions.map((question) => {
          return renderQuestionWithAppropriateComponent(question, addAnswer(answers, setAnswers));
        })}
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} spacing={1}>
          <Button
            variant={'outlined'}
            onClick={handleClose}
            data-qa={'dialog-survey-cancel-button'}
          >
            Cancel
          </Button>
          <Button
            variant={'contained'}
            data-qa={'dialog-survey-submit-button'}
            onClick={() => {
              const { errors } = validateAnswers(questions, answers);

              if (!errors) {
                submitResponse.mutate(false);

                return;
              }

              showSnackbar(errors.join(', '), 'error');
            }}
          >
            Submit
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
