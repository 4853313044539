import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import withStyles from '@mui/styles/withStyles';

// accordion without margins
export const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
    },
    display: 'flex',
    flexDirection: 'column',
  },
  expanded: {
    flexGrow: 1,
    overflow: 'hidden',
  },
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    fontSize: '16px',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 41,
    '&$expanded': {
      minHeight: 41,
    },
  },
  content: {
    margin: '8px 0',
    '&$expanded': {
      margin: '8px 0',
    },
  },
  expandIcon: {
    padding: 8,
  },
  expanded: {},
})(MuiAccordionSummary);
