import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IRootState } from '@app/core/rootReducer';

const HomePage = loadable(() => import(/* webpackChunkName: "home-page" */ './HomePage'));

const mapStateToProps = (state: IRootState) => {
  let { feature } = state;

  return {
    feature,
  };
};

const connector = connect(mapStateToProps);

export type THomePageProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(withRouter(HomePage));
