import merge from 'lodash/merge';

import { IAction } from '@app/common/interfaces';
import { ISdkDownloadInfo } from '@app/modules/GameManagement/common/interfaces';

import { SDK_DOWNLOAD_INFO } from './actions';

type TReducerState = ISdkDownloadInfo | {};

let initialState: {} = {};

type TAction = IAction<ISdkDownloadInfo>;

const sdkDownloadInfoReducer = (
  state: TReducerState = initialState,
  action: TAction
): TReducerState | {} => {
  Object.freeze(state);
  let newState: TReducerState;
  switch (action.type) {
    case SDK_DOWNLOAD_INFO.RECEIVE:
      newState = merge({}, state, action.data);

      return newState;
    default:
      return state;
  }
};

export default sdkDownloadInfoReducer;
