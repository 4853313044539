import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import './styles/atomTableRow.scss';

export type TCellAlign = 'inherit' | 'left' | 'center' | 'right' | 'justify';

interface IRequiredAtomTableRow {
  id: number;
}

export type TAtomTableRowData<T> = IRequiredAtomTableRow & T;

export interface IAtomTableRowProps<T = null> {
  id: string;
  data: TAtomTableRowData<T>;
  order: (keyof T)[];
  rowIndex: number;
  hasHover?: boolean;
  isActive?: boolean;
  align?: TCellAlign;
}
type TOptionalAtomTableRowProps = Pick<IAtomTableRowProps, 'align' | 'hasHover' | 'isActive'>;
type TAtomTableRowsDefaultProps = Required<TOptionalAtomTableRowProps>;

class AtomTableRow<T> extends React.PureComponent<IAtomTableRowProps<T>> {
  public static defaultProps: TAtomTableRowsDefaultProps = {
    align: 'left',
    hasHover: false,
    isActive: true
  };

  public render(): JSX.Element {
    let { order, hasHover, data, align, id, isActive, rowIndex } = this.props;

    return (
      <TableRow
        className={'atom-component__table-row'}
        id={`${id}__table-row-${rowIndex}`}
        hover={hasHover}
      >
        {order.map((key: keyof T, cellIndex: number) => {
          return (
            <TableCell
              align={align}
              id={`${id}__table-row-${rowIndex}-cell-${cellIndex}`}
              key={`atom-component__table-row-${rowIndex}-cell-${cellIndex}`}
              className={`atom-component__table-row-cell ${isActive ? 'enabled' : 'disabled'}`}
            >
              {data[key]}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }
}

export { AtomTableRow };
