import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { openGameInfoModal } from '@app/common/actions';
import { IRootState } from '@app/core/rootReducer';

export const Introduction = loadable(
  () => import(/* webpackChunkName: "integration-introduction" */ './Introduction')
);

const mapStateToProps = (state: IRootState) => {
  let { gameInfo } = state;

  return {
    gameInfo,
  };
};

const mapDispatchToProps = { openGameInfoModal };

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TIntroductionConnectedProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(withRouter(Introduction));
