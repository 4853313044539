// @ts-ignore: camelize package is not typed
import camelize from 'camelize';
import { Dispatch } from 'redux';

import { snakeCaseConverter } from '@app/common/helpers';
import { IAction, IErrors, IZendeskTicket, TErrorGeneral } from '@app/common/interfaces';
import axios, { AxiosError, AxiosResponse } from '@app/common/setup/axiosWithHeader';
import { IAccelerateApplication, TAccelerateRequest } from '@appOverview/interfaces';

export enum ACCELERATE_PROGRAM {
  RECEIVE_ACCELERATE_APPLICATION = 'accelerateProgram/RECEIVE_ACCELERATE_APPLICATION',
}

export function receiveAccelerateApplication(
  data: IAccelerateApplication
): IAction<IAccelerateApplication> {
  return { type: ACCELERATE_PROGRAM.RECEIVE_ACCELERATE_APPLICATION, data };
}

export const fetchAccelerateApplication = (gameId: number) => {
  return (dispatch: Dispatch<IAction<IAccelerateApplication>>): Promise<void> | TErrorGeneral => {
    return axios
      .get(`/api_internal/v1/games/${gameId}/accelerate_application`)
      .then(camelize)
      .then((response: AxiosResponse<IAccelerateApplication>) => {
        dispatch(receiveAccelerateApplication(response.data));
      })
      .catch((error: AxiosError<IErrors>): TErrorGeneral => {
        return error.response ? error.response.data : error;
      });
  };
};

export const updateAccelerateApplication = (gameId: number) => {
  return (dispatch: Dispatch<IAction<IAccelerateApplication>>): Promise<void> | TErrorGeneral => {
    return axios
      .patch(
        `/api_internal/v1/games/${gameId}/accelerate_application`,
        snakeCaseConverter({ accelerateApplication: { isWidgetClosed: true } })
      )
      .then(camelize)
      .then((response: AxiosResponse<IAccelerateApplication>) => {
        dispatch(receiveAccelerateApplication(response.data));
      })
      .catch((error: AxiosError<IErrors>): TErrorGeneral => {
        return error.response ? error.response.data : error;
      });
  };
};

export const createAccelerateApplication = (
  gameId: number,
  applicationRequest: TAccelerateRequest = null
) => {
  return (dispatch: Dispatch<IAction<IAccelerateApplication>>): Promise<void> | TErrorGeneral => {
    return axios
      .post(
        `/api_internal/v1/games/${gameId}/accelerate_application`,
        applicationRequest && snakeCaseConverter({ accelerateApplication: applicationRequest })
      )
      .then(camelize)
      .then((response: AxiosResponse<IAccelerateApplication>) => {
        dispatch(receiveAccelerateApplication(response.data));
      })
      .catch((error: AxiosError<IErrors>): TErrorGeneral => {
        return error.response ? error.response.data : error;
      });
  };
};

export const createAccelerateApplicationZendeskTicket = (
  gameId: number,
  accelerateApplicationId: number
): Promise<IZendeskTicket> | TErrorGeneral => {
  return axios
    .post(
      `/api/v1/games/${gameId}/accelerate_application/${accelerateApplicationId}/zendesk_tickets`
    )
    .then(camelize)
    .then((response: AxiosResponse<IZendeskTicket>) => {
      return response.data;
    })
    .catch((error: AxiosError<IErrors>): TErrorGeneral => {
      return error.response ? error.response.data : error;
    });
};
