// TODO [WS-24677] Implement a proper way to cancel in-flight requests. eg. AbortController
export const makeCancelable = <T>(promise: Promise<T>) => {
  let hasCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel(this: void) {
      hasCanceled = true;
    },
  };
};
