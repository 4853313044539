import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosResponse } from 'axios';
// @ts-ignore: camelize is by definition an un-typeable Any => Any
import camelize from 'camelize';

import { snakeCaseConverter } from '@app/common/helpers';
import { ICurrentUser, IErrors } from '@app/common/interfaces';

export const fetchCurrentUser = createAsyncThunk('currentUser/fetch', async () => {
  const response: AxiosResponse<ICurrentUser> = await axios
    .get('/api_internal/v1/user')
    .then(camelize);

  return response.data;
});

export const updateCurrentUser = createAsyncThunk(
  'currentUser/update',
  (user: Partial<ICurrentUser>) => {
    return axios
      .patch('/api_internal/v1/user', snakeCaseConverter({ user }))
      .then(camelize)
      .then((response: AxiosResponse<ICurrentUser>) => {
        return response.data;
      })
      .catch((e) => {
        const error = e as AxiosError<IErrors>;

        throw new Error(error.response?.data.errors);
      });
  }
);
