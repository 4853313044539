import * as React from 'react';

import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';

import { IBaseProps } from 'portal-common-library/interfaces/componentBase';
import { IDataAttributes } from 'portal-common-library/interfaces/dataAttributes';

import { TOpenSnackbarFunc } from '@app/common/interfaces';
import variables from '@app/core/variables.scss';

import {
  commonTooltipStyles,
  commonTooltipTextStyles,
  CommonTooltipActionWithStyle,
} from '../constants/tooltipConstants';

interface IBlueTooltipText extends IBaseProps {
  text: string;
  toggleTooltip: () => void;
  classNamePrefix: string;
}

interface IShepherdToolTipText extends IBlueTooltipText {
  buttonText?: 'GOT IT' | 'NEXT' | 'CLOSE';
}

export const BlueTooltipWithStyle = withStyles({
  tooltip: {
    backgroundColor: `${variables.colorInfoIcon}`,
    top: '-15px',
    padding: '8px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 'normal',
    boxShadow:
      '0 1px 10px 0 rgba(0, 0, 0, 0.18), 0 4px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 4px 0 rgba(0, 0, 0, 0.14)',
  },
  arrow: {
    color: `${variables.colorInfoIcon}`,
  },
  popper: {
    zIndex: 100,
  },
})(Tooltip);

export const PlayerDataTooltipWithStyle = withStyles({
  tooltip: {
    width: '270px',
    backgroundColor: `${variables.colorInfoIcon}`,
    top: '15px',
    padding: '8px',
    color: 'white',
    fontWeight: 'normal',
    boxShadow:
      '0 1px 10px 0 rgba(0, 0, 0, 0.18), 0 4px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 4px 0 rgba(0, 0, 0, 0.14)',
    animation: '$bounce 0.8s ease-in-out infinite',
  },
  '@keyframes bounce': {
    '0%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(-2px)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
  arrow: {
    fontSize: '25px',
    color: `${variables.colorInfoIcon}`,
    filter: 'drop-shadow(0px 3px 2px rgba(0,0,0,0.3))',
  },
  popper: {
    zIndex: 111,
  },
})(Tooltip);

export const BlueTooltipText = ({
  id,
  text,
  toggleTooltip,
  dataAttributes,
  classNamePrefix,
}: IBlueTooltipText): JSX.Element => {
  return (
    <div {...dataAttributes} id={id} className={`${classNamePrefix}-tooltip__text`}>
      {text}
      <div className={`${classNamePrefix}-tooltip__button-section`}>
        <span
          className={`${classNamePrefix}-tooltip__button`}
          onClick={toggleTooltip}
          data-qa={`${dataAttributes['data-qa']}-button`}
        >
          GOT IT
        </span>
      </div>
    </div>
  );
};

export const ShepherdToolTipWithStyle = withStyles({
  tooltip: {
    ...commonTooltipStyles.tooltip,
    width: '209px',
    backgroundColor: `${variables.colorInfoIcon}`,
    top: '-1px',
    color: `#F7F7F8`,
  },
  arrow: {
    ...commonTooltipStyles.arrow,
    color: `${variables.colorInfoIcon}`,
  },
  popper: {
    zIndex: 1100,
  },
})(Tooltip);

export const ShepherdToolTipText = ({
  id,
  text,
  toggleTooltip,
  dataAttributes,
  buttonText,
}: IShepherdToolTipText): JSX.Element => {
  return (
    <div
      {...dataAttributes}
      id={id}
      className={`shepherd-tooltip__text`}
      style={commonTooltipTextStyles}
    >
      {text}
      <CommonTooltipActionWithStyle>
        <span
          className={`shepherd-tooltip__button`}
          onClick={toggleTooltip}
          data-qa={`${dataAttributes['data-qa']}-button`}
        >
          {buttonText ?? 'GOT IT'}
        </span>
      </CommonTooltipActionWithStyle>
    </div>
  );
};

export const WhiteTooltipWithStyle = withStyles({
  tooltip: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    color: `${variables.colorGray500SecondaryText}`,
    backgroundColor: `${variables.colorWhite}`,
    borderColor: `${variables.colorWhite}`,
    boxShadow: `0 4px 8px 0 ${variables.colorGray100Borders}`,
  },
  arrow: {
    color: `${variables.colorWhite}`,
  },
})(Tooltip);

export const OverflowTip = (props: {
  id: string;
  text: string;
  maxWidth: string;
  dataAttributes: IDataAttributes;
  classNamePrefix: string;
  arrow: {
    hasArrow: boolean;
    placement: TooltipProps['placement'];
  } | null;
}): JSX.Element => {
  const textElementRef = React.useRef<HTMLInputElement>(null);

  const compareSize = () => {
    if (textElementRef.current) {
      const shouldResize = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(shouldResize);
    }
  };

  // compare once and add then remove resize listener
  React.useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, [textElementRef, props.text]);

  // define state and function to update the value
  const [hasHover, setHover] = React.useState(false);
  const styleProps = {
    '--maxwidth': props.maxWidth,
  } as React.CSSProperties;

  return (
    <WhiteTooltipWithStyle
      title={props.text}
      arrow={props.arrow?.hasArrow}
      placement={props.arrow?.placement}
      disableHoverListener={!hasHover}
    >
      <div
        {...props.dataAttributes}
        id={props.id}
        ref={textElementRef}
        className={`${props.classNamePrefix}-tooltip__overflow`}
        style={styleProps}
      >
        {props.text}
      </div>
    </WhiteTooltipWithStyle>
  );
};

export const GrayTooltipWithStyle = withStyles({
  tooltip: {
    backgroundColor: `${variables.colorGray50Background}`,
    padding: '8px',
    color: `${variables.colorGray900PrimaryText}`,
    fontSize: '14px',
    fontWeight: 'normal',
    boxShadow:
      '0 1px 10px 0 rgba(0, 0, 0, 0.18), 0 4px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 4px 0 rgba(0, 0, 0, 0.14)',
  },
  arrow: {
    fontSize: '25px',
    color: `${variables.colorGray50Background}`,
    filter: 'drop-shadow(0px 3px 2px rgba(0,0,0,0.3))',
  },
  popper: {
    zIndex: 1301,
  },
})(Tooltip);

export const LearnMoreTooltipWithStyle = withStyles({
  ...commonTooltipStyles,
  tooltip: {
    ...commonTooltipStyles.tooltip,
    backgroundColor: `${variables.colorGray50Background}`,
    color: `${variables.colorGray900PrimaryText}`,
    width: '229px',
  },
  arrow: {
    ...commonTooltipStyles.arrow,
    color: `${variables.colorGray50Background}`,
  },
})(Tooltip);

interface ILearnMoreTooltipText extends IBaseProps {
  text: string;
  helpBitId?: number;
  userId: number;
  // openSnackbar function from redux is deprecated
  openSnackbar?: TOpenSnackbarFunc;
  toggleHelpBit?: () => void;
}

export const LearnMoreTooltipText = ({
  id,
  text,
  dataAttributes,
  helpBitId,
  toggleHelpBit,
}: ILearnMoreTooltipText): JSX.Element => {
  return (
    <div
      id={id}
      data-qa={dataAttributes['data-qa']}
      className={`learn-more-tooltip__text`}
      style={commonTooltipTextStyles}
    >
      {text}
      {!!helpBitId ? (
        <CommonTooltipActionWithStyle>
          <span
            className={`learn-more-tooltip__button`}
            data-qa={`learn-more-button-${helpBitId}`}
            style={{ color: variables.colorBrandBlue, marginTop: '16px', cursor: 'pointer' }}
            onClick={toggleHelpBit}
          >
            Learn More
          </span>
        </CommonTooltipActionWithStyle>
      ) : null}
    </div>
  );
};
