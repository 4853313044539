import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import variables from '@app/core/variables.scss';

import './loadingIndicator.scss';

export const componentClassName = 'wizard-view-container';

const LoadingIndicator = ({
  color = variables.colorBrandBlue,
  size = 50,
  thickness = 5,
  testId = 'loading-indicator',
}: {
  color?: string;
  size?: number;
  thickness?: number;
  testId?: string;
}): JSX.Element => {
  return (
    <div className={'loading-indicator-container'} data-qa={testId}>
      <CircularProgress size={size} thickness={thickness} style={{ color }} />
    </div>
  );
};

export default LoadingIndicator;
