// documentation for nivo rocks: https://nivo.rocks/line/
import React from 'react';

import { AxisProps } from '@nivo/axes';
import { OrdinalColorsInstruction } from '@nivo/colors';
import { Datum, LineSvgProps, ResponsiveLine, Serie, SliceTooltip } from '@nivo/line';

import { lineLegends } from '@app/common/constants';

interface IDataProps {
  data: Serie[];
  colors?: OrdinalColorsInstruction; // string[] or {scheme: 'nivo'}
  xAxisDates: string;
  axisLeft?: AxisProps;
  sliceTooltip?: SliceTooltip;
  propOverrides?: Partial<LineSvgProps>;
}

/*
 * finds the max value of all of the lines in the chart and returns that value multiplied by 1.2
 * in order to create a buffer for the max value of the chart
 * @param { Serie[] } data - line chart data
 * @return { number } - returns the max value of all lines multiplied by 1.2
 */
export function findMaxValueFromLineChartData(data: Serie[]): number {
  let max: number = -1;
  data.forEach((line: Serie) => {
    line.data.forEach((point: Datum) => {
      if (point.y && point.y > max) {
        max = point.y as number;
      }
    });
  });

  return max * 1.2;
}

export const ResponsiveLineChart = ({
  data,
  colors = { scheme: 'nivo' }, // default set to nivo,
  xAxisDates,
  axisLeft,
  sliceTooltip,
  propOverrides,
}: IDataProps): JSX.Element => {
  return (
    <ResponsiveLine
      data={data}
      enableGridX={false}
      margin={{ top: 48, right: 8, bottom: 48, left: 48 }}
      yScale={{ type: 'linear', stacked: false, min: 0, max: findMaxValueFromLineChartData(data) }}
      colors={colors}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      animate={false}
      axisLeft={axisLeft}
      axisBottom={{
        tickSize: 0,
        legend: xAxisDates,
        legendPosition: 'middle',
        legendOffset: 29,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        format: (label = '') => '',
      }}
      useMesh={true}
      legends={[lineLegends]}
      enableSlices="x"
      sliceTooltip={sliceTooltip}
      {...(propOverrides ?? {})}
    />
  );
};
