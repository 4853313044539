import { GAME_STATES } from '@app/modules/GameManagement/common/constants';
import { GAME_HOME_PAGE_URL, TGameHomePageUrlKey } from '@appGameHome/common/constants';
import { TNextStepGameState } from '@appGameHome/common/interfaces';

// function to find url for next step
export function getGameStatePageUrl(gameId: number, nextStepState: GAME_STATES): string {
  // placeholder for upload sdk
  if (nextStepState === GAME_STATES.SKILLZ_SDK) {
    return '/downloads';
  }

  let pageUrl: string = `/games/${gameId}/home`;
  const pageWithTab = [GAME_STATES.UPLOAD_BINARY];
  const progressionPages = [GAME_STATES.PLAYER_DATA, GAME_STATES.IN_GAME_ITEMS];

  const nonOptimizePages = pageWithTab.concat(progressionPages);

  if (!nonOptimizePages.includes(nextStepState)) {
    pageUrl += '/optimize';
  }

  if (progressionPages.includes(nextStepState)) {
    pageUrl += '/progression';
  }

  return pageUrl + `/${GAME_HOME_PAGE_URL[nextStepState as TGameHomePageUrlKey]}`;
}

export function determineNextStepUrl(
  gameId: number,
  isActionRequired: TNextStepGameState,
  isNextGameState: TNextStepGameState,
  isPostPerformanceGameState: TNextStepGameState
): string {
  if (isActionRequired) {
    return getGameStatePageUrl(gameId, isActionRequired);
  }

  if (isNextGameState) {
    return getGameStatePageUrl(gameId, isNextGameState);
  }

  if (isPostPerformanceGameState) {
    return getGameStatePageUrl(gameId, isPostPerformanceGameState);
  }

  return '';
}
