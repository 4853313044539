import parseISO from 'date-fns/parseISO';

/** returns a string formatted as US currency
 * @params { number } value - the number to format
 * @returns { string } - returns the formatted string
 */
export function formatCurrency(value: number): string {
  return `${value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })}`;
}

/** returns a string formatted as a whole number (with commas where necessary)
 * @params { number } value - the number to format
 * @returns { string } - returns the formatted string
 */
export function formatWholeNumber(value: number): string {
  return `${value.toLocaleString()}`;
}

/** returns a string formatted as a whole number (with commas where necessary)
 * @params { number } value - the number to format (decimal removed)
 * @returns { string } - returns the formatted string
 */
export function formatWholeNumberFromDecimal(value: number): string {
  let wholeNumber: number = Math.round(value);

  return `${wholeNumber.toLocaleString()}`;
}

/** returns a string formatted as a decimal (with commas where necessary)
 * @params { number } value - the number to format
 * @returns { string } - returns the formatted string
 */
export function formatDecimal(value: number): string {
  return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

/** returns a string formatted as a percentage
 * @params { number } value - the number to format
 * @returns { string } - returns the formatted string
 */
export function formatPercentage(
  value: number,
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2
): string {
  return `${value.toLocaleString('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  })}%`;
}

/**
 * returns an ISO string of the passed in date with all of the 'time' stripped out
 * @param date the date to be formatted
 */
export function formatDateToIsoString(date: Date): string {
  return date.toISOString().split('T')[0];
}

/**
 * returns an ISO string of the passed in date with all of the 'time' stripped out
 * example: '2021-12-31T21:26:22.000Z' -> '12-31-2021'
 * @param string the date to be formatted
 */
export const formatStringDateToIsoString = (date: string): string => {
  const cleanDate: Date = parseISO(date.split('T')[0]);

  const prefixedMonth: string = (cleanDate.getUTCMonth() + 1).toString().padStart(2, '0');
  const prefixedDate: string = cleanDate.getUTCDate().toString().padStart(2, '0');

  return `${prefixedMonth}-${prefixedDate}-${cleanDate.getUTCFullYear()}`;
};
