import { Component } from 'react';

import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { fetchAnalytics, fetchAnalyticsDateRangeLimit, fetchPublisher } from '@app/common/actions';
import { IWithSnackbarProp } from '@app/common/helpers/snackbarHookHelper';
import { IAction, IAnalyticDataResponse, IAnalyticsQuery } from '@app/common/interfaces';
import { IRootState } from '@app/core/rootReducer';

import { IGameSnapshotOwnProps } from './interfaces';

const GameSnapshotComponent = loadable(
  () => import(/* webpackChunkName: "common-component-game-snapshot" */ './GameSnapshot')
) as typeof Component;

const mapStateToProps = (state: IRootState, ownProps: IGameSnapshotOwnProps) => {
  return {
    analytics: state.analytics,
    ...ownProps,
    publisher: state.publisher?.publisherInfo,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, null, IAction<string> | IAction<IAnalyticDataResponse>>
) => {
  return {
    fetchAnalytics: (analyticsQuery: IAnalyticsQuery): Promise<void> =>
      dispatch(fetchAnalytics(analyticsQuery)),
    fetchAnalyticsDateRangeLimit: (): Promise<void> => dispatch(fetchAnalyticsDateRangeLimit()),
    fetchPublisher,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TGameSnapshotConnectedProps = ConnectedProps<typeof connector> & IWithSnackbarProp;

export const GameSnapshot = connector(GameSnapshotComponent);
