import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ENVIRONMENT } from '@app/common/constants';
import { fetchProgressionRoomMappings, fetchProgressionRooms } from '@appGameHome/common/actions';

import {
  TGameProgressionRooms,
  IResponseProgressionRoom,
  IProgressionRoom,
  IResponseProgressionRoomVersionBinaryMapping,
} from './common/interfaces';

const defaultSingleRoomState = {
  sandbox: {} as IProgressionRoom,
  production: {} as IProgressionRoom,
};

interface IReducerPayload<T> {
  env: ENVIRONMENT;
  items: T[];
  totalCount: number;
  currentRoomIdentifier?: string;
}

type TProgressionRoomReducer =
  | IResponseProgressionRoom
  | IResponseProgressionRoomVersionBinaryMapping;

interface IReducerGenerator {
  <T extends TProgressionRoomReducer>(): CaseReducer<
    TGameProgressionRooms,
    PayloadAction<IReducerPayload<T>>
  >;
}

const createProgressionRoomReducer: IReducerGenerator = <T extends TProgressionRoomReducer>() =>
  function fulfilledProgressionRoomFetchActionReducer(
    state: TGameProgressionRooms,
    action: PayloadAction<IReducerPayload<T>>
  ) {
    const { env } = action.payload;
    const currentRoomIdentifier = action.payload.currentRoomIdentifier;

    switch (action.type) {
      case fetchProgressionRooms.fulfilled.type:
        (action.payload.items as IResponseProgressionRoom[]).forEach((room) => {
          const roomIdentifier = room.roomIdentifier;
          const formattedRoom = { ...room };
          if (!state[roomIdentifier]) {
            state[roomIdentifier] = {
              ...defaultSingleRoomState,
              [env]: formattedRoom,
            };
          } else {
            state[roomIdentifier][env] = formattedRoom;
          }
        });
        break;

      case fetchProgressionRoomMappings.fulfilled.type:
        const roomVersionBinaryMappings = action.payload
          .items as IResponseProgressionRoomVersionBinaryMapping[];

        if (!!currentRoomIdentifier && state[currentRoomIdentifier]) {
          // [env] will always be production
          state[currentRoomIdentifier][env].versions = roomVersionBinaryMappings;
        }

        break;
      default:
        state = {} as TGameProgressionRooms;
        break;
    }
  };

const initialState = {} as TGameProgressionRooms;

const progressionRooms = createSlice({
  name: 'progressionRooms',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchProgressionRooms.fulfilled,
      createProgressionRoomReducer<IResponseProgressionRoom>()
    );

    builder.addCase(fetchProgressionRooms.rejected, (_, action) => {
      throw action.error;
    });

    builder.addCase(
      fetchProgressionRoomMappings.fulfilled,
      createProgressionRoomReducer<IResponseProgressionRoomVersionBinaryMapping>()
    );

    builder.addCase(fetchProgressionRoomMappings.rejected, (_, action) => {
      throw action.error;
    });
  },
});

export const { reset: resetProgressionRooms } = progressionRooms.actions;
export default progressionRooms.reducer;
