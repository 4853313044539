import { STATUS } from '@app/modules/GameManagement/common/constants';
import { TGameState } from '@app/modules/GameManagement/common/interfaces';
import { IPrizeEnablementRequest } from '@appOverview/interfaces';

export const shouldRenderPrizeEnablementSuccessModal = (
  prizeEnablementRequest: IPrizeEnablementRequest | null,
  gameState: TGameState
): boolean => {
  return (
    !!prizeEnablementRequest &&
    prizeEnablementRequest.status === 'approved' &&
    gameState.hasSeenPrizeEnablementSuccess !== STATUS.COMPLETED
  );
};
