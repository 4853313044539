/* eslint-disable */
import * as React from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { lightBlue } from '@mui/material/colors';
import { ThemeProvider, createTheme, Theme } from '@mui/material/styles';

import warningIcon from '@app/imageAssets/warningIcon.svg';

interface IProps {
  id: string;
  label: string; // label for the text field
  placeholder: string; // placeholder when field is empty
  attributeName: string; // name of attribute
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
  fullWidth?: boolean;
  errorText?: string;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export interface IPasswordState {
  passwordIsMasked: boolean;
}

interface IDefaultProps {
  fullWidth: boolean;
  error: boolean;
  required: boolean;
}

const theme: Theme = createTheme({
  palette: {
    primary: lightBlue,
  },
  typography: {
    fontSize: 22,
    fontFamily: 'Roboto',
  },
});

// TODO DELETE [WS-10321] replace with AtomTextField
class MaterialPasswordField extends React.Component<IProps, IPasswordState> {
  public static defaultProps: IDefaultProps = {
    fullWidth: true,
    error: false,
    required: true,
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      passwordIsMasked: true,
    };
  }

  public renderShowOrHide(): JSX.Element {
    let { value } = this.props;
    let { passwordIsMasked } = this.state;

    let label: string = passwordIsMasked ? 'Show' : 'Hide';

    return (
      <InputAdornment position="end" style={{ marginLeft: '0' }}>
        <a className="show-hide-label" onClick={this.togglePasswordMask}>
          {label}
        </a>
      </InputAdornment>
    );
  }

  public renderErrorIcon(): JSX.Element {
    return <img src={warningIcon} className="error-icon" />;
  }

  public togglePasswordMask = (): void => {
    let { passwordIsMasked } = this.state;
    this.setState({ passwordIsMasked: !passwordIsMasked });
  };

  public render(): JSX.Element {
    let {
      id,
      label,
      placeholder,
      attributeName,
      onChange,
      fullWidth,
      error,
      errorText,
      value,
      onKeyPress,
    } = this.props;
    let { passwordIsMasked } = this.state;
    let type: string = passwordIsMasked ? 'password' : 'text';

    return (
      <div className="signin-signup-textfield">
        <ThemeProvider theme={theme}>
          <TextField
            id={id}
            className={'material-password'}
            label={<p id={`${id}-title`}>{label}</p>}
            name={attributeName}
            placeholder={placeholder}
            onChange={onChange}
            fullWidth={fullWidth}
            error={error}
            helperText={error ? errorText : ''}
            type={type}
            value={value}
            InputProps={{
              endAdornment: (error && this.renderErrorIcon()) || (value && this.renderShowOrHide()),
            }}
            onKeyPress={onKeyPress}
          />
        </ThemeProvider>
      </div>
    );
  }
}

export { MaterialPasswordField };
