import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  closeSnackbar,
  fetchPermissions,
  fetchPublisher,
  findFeatureFlags,
  findUser,
  openSnackbar,
} from '@app/common/actions';
import { IRootState } from '@app/core/rootReducer';

import App from './app';

const mapStateToProps = (state: IRootState) => {
  let {
    config,
    feature,
    user,
    snackbar,
    publisher: { publisherInfo },
    permissions,
  } = state;

  return {
    config,
    feature,
    user,
    publisher: publisherInfo,
    snackbar,
    permissions,
  };
};

const mapDispatchToProps = {
  fetchPublisher,
  findUser,
  findFeatureFlags,
  closeSnackbar,
  openSnackbar,
  fetchPermissions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TAppConnectedProps = ConnectedProps<typeof connector>;

export default connector(withRouter(App));
