export const NUMBER_OF_DAYS_IN_YEAR: number = 365;

export enum MONTH_NAMES_MMM {
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
}

export enum MONTH_NAMES_FULL {
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
}

export enum TIME_IN_MILLISECONDS {
  DAY = 24 * 60 * 60 * 1000,
  HOUR = 60 * 60 * 1000,
  MINUTE = 60 * 1000,
  SECOND = 1000
}

export enum TIME_UNIT {
  WEEK = 'week',
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second'
}
