import { IAction } from '@app/common/interfaces';
import { STATEMENTS } from './actions';
import { IStatement } from './interfaces';

let initialState: IStatement[] = [];

type TAction = IAction<IStatement[]>;

function statementsReducer(state: IStatement[] = initialState, action: TAction): IStatement[] {
  Object.freeze(state);

  switch (action.type) {
    case STATEMENTS.RECEIVE:
      return action.data;
    default:
      return state;
  }
}

export default statementsReducer;
