// redux snackbar is deprecated, use  @skillzet/components/SnackbarContainer
import merge from 'lodash/merge';

import { SNACK_BAR } from '@app/common/actions';
import { IActionOptionalData, ISnackbarData, ISnackbar } from '@app/common/interfaces';

const initialState: ISnackbar = {
  isSnackbarOpen: false,
  text: '',
  autoHideDuration: 10000,
  anchorOriginVertical: 'bottom',
  anchorOriginHorizontal: 'left',
};

function snackbar(
  state: ISnackbar = initialState,
  action: IActionOptionalData<ISnackbarData>
): ISnackbar {
  Object.freeze(state);

  switch (action.type) {
    case SNACK_BAR.OPEN_SNACKBAR:
      return merge({}, state, action.data, { isSnackbarOpen: true });
    case SNACK_BAR.CLOSE_SNACKBAR:
      return merge({}, state, { isSnackbarOpen: false, text: '' });
    default:
      return state;
  }
}

export default snackbar;
