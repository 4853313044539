export function isDefaultCompany(name: string | null, id: number): boolean {
  return name === null || name === '' || `Game Creators ${id}` === name;
}

export function isDefaultFirstName(firstName: null | string): boolean {
  return firstName === null || firstName === 'Game' || firstName === '';
}

export function isDefaultLastName(lastName: null | string): boolean {
  return lastName === null || lastName === 'Creator' || lastName === '';
}

export function isDefaultUserName(firstName: string | null, lastName: string | null): boolean {
  // both first name and last name need to be empty or the default name is Game Creator
  return (!firstName && !lastName) || /Game\s*Creator/.test(`${firstName ?? ''} ${lastName ?? ''}`);
}
