import { createAsyncThunk } from '@reduxjs/toolkit';

import axios, { AxiosResponse } from 'axios';
// @ts-ignore: camelize is by definition an un-typeable Any => Any
import camelize from 'camelize';
import { serialize } from 'object-to-formdata';

import { snakeCaseConverter } from '@app/common/helpers';
import {
  TGameChallengeProposalFormPartialParams,
  TGameChallengeProposalResponse,
  TImageField,
  TGameChallengeProposalImages,
  TGameChallengeProposalUploadedImages,
  IUploadedImage,
} from '@app/modules/NflChallenge/ProposalPage/interfaces';

export const fetchGameChallengeProposal = createAsyncThunk(
  'gameChallengeProposal/fetch',
  async ({ challengeName }: { challengeName: string }) => {
    const response: AxiosResponse<TGameChallengeProposalResponse> = await axios
      .get(`/api/v1/game_challenge_proposal/${challengeName}`)
      .then(camelize);

    return response.data;
  }
);

export const saveGameChallengeProposal = createAsyncThunk(
  'gameChallengeProposal/save',
  async (params: TGameChallengeProposalFormPartialParams) => {
    const { challengeName, ...payload } = params;
    const response: AxiosResponse<TGameChallengeProposalResponse> = await axios
      .post(
        `/api/v1/game_challenge_proposal/${challengeName}`,
        snakeCaseConverter({
          gameChallengeProposal: { ...payload },
        })
      )
      .then(camelize);

    return response.data;
  }
);

export const updateGameChallengeProposal = createAsyncThunk(
  'gameChallengeProposal/update',
  async (params: TGameChallengeProposalFormPartialParams) => {
    const { challengeName, ...payload } = params;
    const response: AxiosResponse<TGameChallengeProposalResponse> = await axios
      .patch(
        `/api/v1/game_challenge_proposal/${challengeName}`,
        snakeCaseConverter({
          gameChallengeProposal: { ...payload },
        })
      )
      .then(camelize);

    return response.data;
  }
);

interface IImageSaveResultPartial extends Partial<TGameChallengeProposalUploadedImages> {
  updatedAt: string;
}

interface IImageSaveResultComplete extends TGameChallengeProposalUploadedImages {
  updatedAt: string;
}

export const uploadGameChallengeImages = createAsyncThunk(
  'gameChallengeProposal/uploadImages',
  async ({
    images,
    challengeName,
  }: {
    images: TGameChallengeProposalImages;
    challengeName: string;
  }) => {
    return Promise.all(
      // send separate update request for every image field
      (Object.entries(images) as [TImageField, (File | IUploadedImage)[]][]).map(
        ([key, value]): Promise<IImageSaveResultPartial> => {
          // send filename of existing files to server to preserve them
          const files = value.map((file: File | IUploadedImage): string | File =>
            file.hasOwnProperty('filename') ? (file as IUploadedImage).filename : (file as File)
          );

          return axios
            .patch<TGameChallengeProposalResponse>(
              `/api/v1/game_challenge_proposal/${challengeName}`,
              serialize(
                snakeCaseConverter({
                  gameChallengeProposal: { [key]: files },
                }),
                { allowEmptyArrays: true }
              )
            )
            .then(camelize)
            .then(({ data }) => ({
              [key]: data[key],
              updatedAt: data.updatedAt,
            }));
        }
      )
    ).then(
      // glue result image fields back together
      (updatedImages: IImageSaveResultPartial[]): IImageSaveResultComplete =>
        updatedImages.reduce(
          (
            acc: IImageSaveResultComplete,
            curr: IImageSaveResultPartial
          ): IImageSaveResultComplete => ({
            ...acc,
            ...curr,
            updatedAt:
              // include latest updatedAt
              acc.updatedAt && new Date(acc.updatedAt) > new Date(curr.updatedAt)
                ? acc.updatedAt
                : curr.updatedAt,
          }),
          {} as IImageSaveResultComplete
        )
    );
  }
);
