import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const CaseStudyChat = loadable(
  () => import(/* webpackChunkName: "case-study-chat" */ './CaseStudyChat')
);

const mapStateToProps = () => {
  return {} as Record<string, unknown>;
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TCaseStudyChatConnectedProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(withRouter(CaseStudyChat));
