import * as React from 'react';

import appStoreUrlIconSvg from '@app/imageAssets/Overview/appStoresIcon.svg';
import applePayIconSvg from '@app/imageAssets/Overview/applePayIcon.svg';
import deepLinkingIconSvg from '@app/imageAssets/Overview/deepLinkingIcon.svg';
import expertMoneyGradientSvg from '@app/imageAssets/Overview/expertMoneyGradient.svg';
import fairPlayIconSvg from '@app/imageAssets/Overview/fairPlayIcon.svg';
import fixItIconSvg from '@app/imageAssets/Overview/fixItIcon.svg';
import gameBuildIIconSvg from '@app/imageAssets/Overview/gameBuildIcon.svg';
import hundredDAUIconSvg from '@app/imageAssets/Overview/hundredDAU.svg';
import inAppPurchaseIconSvg from '@app/imageAssets/Overview/inAppPurchaseIcon.svg';
import inGameItemsIconSvg from '@app/imageAssets/Overview/inGameItems.svg';
import playerDataIconSvg from '@app/imageAssets/Overview/playerData.svg';
import prizeEnablementIconSvg from '@app/imageAssets/Overview/prizeEnablementIcon.svg';
import pushNotificationIconSvg from '@app/imageAssets/Overview/pushNotificationIcon.svg';
import singleSignOnIconSvg from '@app/imageAssets/Overview/ssoIcon.svg';
import themeIconSvg from '@app/imageAssets/Overview/themeIcon.svg';
import tournamentIconSvg from '@app/imageAssets/Overview/tournamentIcon.svg';
import UploadSdkIconSvg from '@app/imageAssets/Overview/uploadSdkIcon.svg';

export const tournamentIcon: JSX.Element = (
  <img
    src={tournamentIconSvg}
    id="overview-next-step__icon-tournament"
    className="overview__non-draggable-icon"
  />
);

export const inAppPurchaseIcon: JSX.Element = (
  <img
    src={inAppPurchaseIconSvg}
    id="overview-next-step__icon-in-app-purchase"
    className="overview__non-draggable-icon"
  />
);

export const gameBuildIcon: JSX.Element = (
  <img
    src={gameBuildIIconSvg}
    id="overview-next-step__icon-game-build"
    className="overview__non-draggable-icon"
  />
);

export const appStoreUrlIcon: JSX.Element = (
  <img
    src={appStoreUrlIconSvg}
    id="overview-next-step__icon-app-store-url"
    className="overview__non-draggable-icon"
  />
);
export const themeIcon: JSX.Element = (
  <img
    src={themeIconSvg}
    id="overview-next-step__icon-theme"
    className="overview__non-draggable-icon"
  />
);

export const pushNotificationIcon: JSX.Element = (
  <img
    src={pushNotificationIconSvg}
    id="overview-next-step__icon-push-notification"
    className="overview__non-draggable-icon"
  />
);
export const applePayIcon: JSX.Element = (
  <img
    src={applePayIconSvg}
    id="overview-next-step__icon-apple-pay"
    className="overview__non-draggable-icon"
  />
);

export const deepLinkingIcon: JSX.Element = (
  <img
    src={deepLinkingIconSvg}
    id="overview-next-step__icon-deep-linking"
    className="overview__non-draggable-icon"
  />
);

export const singleSignOnIcon: JSX.Element = (
  <img
    src={singleSignOnIconSvg}
    id="overview-next-step__icon-single-sign-on"
    className="overview__non-draggable-icon"
  />
);

export const prizeEnablementIcon: JSX.Element = (
  <img
    src={prizeEnablementIconSvg}
    id="overview-next-step__icon-prize-enablement"
    className="overview__non-draggable-icon"
  />
);

export const fixItIcon: JSX.Element = (
  <img
    src={fixItIconSvg}
    id="overview-next-step__icon-fix-it"
    className="overview__non-draggable-icon"
  />
);

export const UploadSdkIcon: JSX.Element = (
  <img
    src={UploadSdkIconSvg}
    id="overview-next-step__icon-upload-sdk"
    className="overview__non-draggable-icon"
  />
);

export const fairPlayIcon: JSX.Element = (
  <img
    src={fairPlayIconSvg}
    id="overview-next-step__icon-fair-play"
    className="overview__non-draggable-icon"
  />
);

export const hundredDAUIcon: JSX.Element = (
  <img
    src={hundredDAUIconSvg}
    id="overview-next-step__icon-hundred-dau"
    className="overview__non-draggable-icon"
  />
);

export const expertMoneyGradient: JSX.Element = (
  <img
    src={expertMoneyGradientSvg}
    id="overview-next-step__icon-expert-money-gradient"
    className="overview__non-draggable-icon"
  />
);

export const playerDataIcon: JSX.Element = (
  <img
    src={playerDataIconSvg}
    id="overview-next-step__icon-player-data"
    className="overview__non-draggable-icon"
  />
);

export const inGameItemsIcon: JSX.Element = (
  <img
    src={inGameItemsIconSvg}
    id="overview-next-step__icon-in-game-items"
    className="overview__non-draggable-icon"
  />
);
