import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { LogoutOptions } from '@auth0/auth0-react';

import ReactGA from 'react-ga';
import { default as ReactGA4 } from 'react-ga4';
import { Store } from 'redux';

import { fetchEnvironmentConfig, IAppConfig, initialAppConfig } from './core/appConfig';
import Root from './core/root';
import { IRootState } from './core/rootReducer';
import configureStore from './core/store';

declare global {
  interface Window {
    appConfig: IAppConfig;
    s: () => IRootState;
    store: Store;
    logout: (options: LogoutOptions | undefined) => void;
    getAccessTokenSilently: () => Promise<string>;
  }
}

export const store: Store = configureStore();

document.addEventListener('DOMContentLoaded', () => {
  const root: HTMLElement = document.getElementById('root') as HTMLElement;

  window.appConfig = initialAppConfig; // needed to bind FF to the window

  void fetchEnvironmentConfig().then(({ data }) => {
    window.appConfig.environment = data;

    // google analytics 4 is only setup for staging and production - production id
    let GA4_ID = 'G-9LMYNJEETP';
    if (window.location.host !== 'developers.skillz.com') {
      // for testing, allow access to store in chrome console
      window.store = store;
      window.s = store.getState.bind(store);
      // google analytics 4 is only setup for staging and production - staging id
      GA4_ID = 'G-DRBRWBZWH3';
    }

    ReactDOM.render(<Root store={store} />, root);

    ReactGA.initialize('UA-40410914-4');

    ReactGA4.initialize(GA4_ID);
  });
});
