import * as React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

import AtomTextField from 'portal-common-library/atoms/AtomTextField';
import { IBaseProps } from 'portal-common-library/interfaces/componentBase';

import { AtomInteractiveIcon } from '@app/common/atoms';
import { TInputChangeEvent } from '@app/common/interfaces';

import './styles/iconToSearchBar.scss';

interface IIconToSearchBarState {
  isExpanded: boolean;
  shouldAnimate: boolean;
  timer?: NodeJS.Timeout;
}

export interface IIconToSearchBarProps extends IBaseProps {
  search: string;
  handleChange: (event: TInputChangeEvent) => void;
  growDirection: 'right' | 'left';
  minScreenWidth: number;
}

type TDefaultProps = Pick<IIconToSearchBarProps, 'minScreenWidth'>;
class IconToSearchBar extends React.Component<IIconToSearchBarProps, IIconToSearchBarState> {
  public static defaultProps: TDefaultProps = {
    minScreenWidth: 0,
  };

  constructor(props: IIconToSearchBarProps) {
    super(props);

    this.state = {
      isExpanded: false,
      shouldAnimate: this.shouldAnimate(),
      timer: undefined,
    };
  }

  public setTimer = () => {
    const timer = setTimeout(() => {
      this.setState({ isExpanded: false });
    }, 500); // the transition animation is 0.4s

    this.setState({ timer });
  };

  public componentDidMount(): void {
    window.addEventListener('resize', this.updateShouldAnimate);
  }

  public componentWillUnmount(): void {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
    window.removeEventListener('resize', this.updateShouldAnimate);
  }

  public updateShouldAnimate = () => {
    const { search } = this.props;
    const isScreenWideEnough = this.shouldAnimate();
    this.setState({
      shouldAnimate: isScreenWideEnough,
      isExpanded: !isScreenWideEnough || search !== '',
    });
  };

  public shouldAnimate = () => {
    return this.getWindowWidth() >= (this.props.minScreenWidth ?? 0);
  };

  public getWindowWidth = (): number => {
    return window.innerWidth;
  };

  public handleSearchExpand = () => {
    this.setState({ isExpanded: true });
  };

  public handleSearchCompress = () => {
    if (!this.props.search) {
      this.setTimer();
    }
  };

  public render(): JSX.Element {
    const { id, search, handleChange, dataAttributes, growDirection } = this.props;
    const { isExpanded, shouldAnimate } = this.state;

    const searchIcon = (
      <div className={`fuzzy-finder__search-icon ${growDirection}`}>
        <SearchIcon />
      </div>
    );

    const shouldShowIcon = !isExpanded && shouldAnimate;

    return (
      <div className={`fuzzy-finder__section ${growDirection}`}>
        {shouldShowIcon && (
          <AtomInteractiveIcon
            id={`${id}__fuzzy-finder-search-icon`}
            icon={searchIcon}
            clickFunction={this.handleSearchExpand}
            isActive={false}
            dataAttributes={{ 'data-qa': `${dataAttributes['data-qa']}__fuzzy-finder-search-icon` }}
          />
        )}
        {!shouldShowIcon && (
          <AtomTextField
            id={`${id}__fuzzy-finder-input`}
            dataAttributes={{ 'data-qa': `${dataAttributes['data-qa']}__fuzzy-finder-input` }}
            label=""
            placeholder="Search"
            attributeName="search"
            onChange={handleChange}
            onBlur={this.handleSearchCompress}
            value={search}
            variant="outlined"
            isAutoFocused={shouldAnimate}
            hasAnimation={shouldAnimate}
            isFullWidth={!shouldAnimate}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className="fuzzy-finder__inner-search-icon">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      </div>
    );
  }
}

export { IconToSearchBar };
