import axios from 'axios';
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';

import { GAMES_PAGE_PAGE } from '@app/common/constants';
import { handleError } from '@app/common/helpers';
import { IDashboardGame, IIndexResponse } from '@app/common/interfaces';

// a separate instance of `axios` which removes the `x-csrf-token` that keeps
// getting injected on each request to prevent CORS errors
export const contentManagementSystemInstance = axios.create();

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
delete contentManagementSystemInstance.defaults.headers.common['X-CSRF-TOKEN'];

export const getLearningCenterCategories = () =>
  contentManagementSystemInstance
    .get(`${window.appConfig.environment.cmsBaseUrl}/publisher/v1/cms/learning-center-categories`)
    .then(camelize)
    .catch(handleError);

export const getLearningCenterCards = () =>
  contentManagementSystemInstance
    .get(`${window.appConfig.environment.cmsBaseUrl}/publisher/v1/cms/learning-center-cards`)
    .then(camelize)
    .catch(handleError);

// used for determining whether user is ftue or not
export const getGames = (limit = GAMES_PAGE_PAGE, offset = 0) =>
  // use regular axios, need x-csrf-token
  axios
    .get<IIndexResponse<IDashboardGame>>('/api_internal/v1/games', {
      params: { limit, offset },
    })
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
