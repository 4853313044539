import merge from 'lodash/merge';

import { GAME } from '@app/common/actions';
import { IAction } from '@app/common/interfaces';

import { VERIFY_CONFIG } from './action';

interface IState {
  androidPushVerification: boolean;
  iosPushVerification: boolean;
}

let initialState: IState = {
  androidPushVerification: false,
  iosPushVerification: false
};

const verifyConfigurationReducer = (
  state: IState = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: IAction<any>
): IState => {
  Object.freeze(state);
  switch (action.type) {
    case GAME.GAME_INFO:
      return merge({}, state, action.data.verification);
    case VERIFY_CONFIG.PUSH:
      return merge({}, state, action.data);
    default:
      return state;
  }
};

export default verifyConfigurationReducer;
