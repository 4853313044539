/**
 * !TRANSITIONAL COMPONENT
 * This component does not render anything, it exists to pass
 * data from old redux snackbar actions to the new snackbar function.
 * No longer required when we fully move away from redux
 */
import React, { useEffect } from 'react';

import { useSnackbarContext } from '@skillzet/components';

import { ISnackbar } from '../interfaces';

export interface IProps extends ISnackbar {
  closeSnackbar: () => void;
}

function ReduxSnackbarAdaptor({ isSnackbarOpen, text, autoHideDuration, closeSnackbar }: IProps) {
  const { show } = useSnackbarContext();

  useEffect(() => {
    if (isSnackbarOpen) {
      // updated that redux snackbar will be info snackbar with no icon and with close icon
      show(
        text,
        'success',
        { duration: autoHideDuration as number, icon: false },
        { alertProps: { style: { backgroundColor: '#ffffff' } } }
      );

      /**
       *  calling `closeSnackbar` immediately here to update the old snackbar's state and set it to closed
       *  this does not close the new snackbar
       */
      closeSnackbar();
    }
  }, [isSnackbarOpen]);

  return <></>;
}

export default ReduxSnackbarAdaptor;
