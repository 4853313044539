// @ts-ignore: camelize package is not typed
import camelize from 'camelize';

import { snakeCaseConverter } from '@app/common/helpers';
import {
  IAction,
  TAxiosDispatch,
  TMiddlewareDispatch,
  TAxiosResponse,
} from '@app/common/interfaces';
import axios, { AxiosResponse } from '@app/common/setup/axiosWithHeader';

import { IPushNotificationsState, ICertificate, TAndroidRequest } from './interfaces';

function formAndroidRequest(state: IPushNotificationsState): TAndroidRequest {
  return {
    key: state.fcm,
    platform: 'android',
    overwrite: state.hasOverwriteAndroid.toString(),
  };
}

export enum PUSH_NOTIFICATIONS {
  RECEIVE_CERTIFICATES = 'pushNotifications/RECEIVE_CERTIFICATES',
}

export function receiveCertificates(data: ICertificate[]): IAction<ICertificate[]> {
  return {
    type: PUSH_NOTIFICATIONS.RECEIVE_CERTIFICATES,
    data,
  };
}

export function fetchNotificationCertificates(gameId: number): TAxiosDispatch<ICertificate[]> {
  return (dispatch: TMiddlewareDispatch<ICertificate[]>): TAxiosResponse<ICertificate[]> => {
    return axios({
      url: `/api/v1/games/${gameId}/notification_certificates`,
      method: 'GET',
    }).then((response: AxiosResponse<ICertificate[]>) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(receiveCertificates(camelize(response.data)));

      return response;
    });
  };
}

export function updateIosCertificate(
  gameId: number,
  state: IPushNotificationsState
): TAxiosDispatch<ICertificate[]> {
  return (dispatch: TMiddlewareDispatch<ICertificate[]>): TAxiosResponse<ICertificate[]> => {
    let form: FormData = new FormData();

    form.append('notification_certificate[game_id]', gameId.toString());
    if (state.p12) {
      // for ios certificates, there will always be a file
      form.append('notification_certificate[file]', state.p12);
    }
    form.append('notification_certificate[platform]', 'ios');
    form.append('notification_certificate[password]', state.password);
    form.append('notification_certificate[overwrite]', state.hasOverwriteIos.toString());

    return axios({
      url: `/api/v1/games/${gameId}/notification_certificates`,
      method: 'POST',
      data: form,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then((response: AxiosResponse<ICertificate[]>) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(receiveCertificates(camelize(response.data)));

      return response;
    });
  };
}

export function updateAndroidCertificate(
  gameId: number,
  state: IPushNotificationsState
): TAxiosDispatch<ICertificate[]> {
  return (dispatch: TMiddlewareDispatch<ICertificate[]>): TAxiosResponse<ICertificate[]> => {
    let androidRequest: TAndroidRequest = formAndroidRequest(state);

    return axios({
      url: `/api/v1/games/${gameId}/notification_certificates`,
      method: 'POST',
      data: snakeCaseConverter({ notificationCertificate: androidRequest }),
    }).then((response: AxiosResponse<ICertificate[]>) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(receiveCertificates(camelize(response.data)));

      return response;
    });
  };
}
