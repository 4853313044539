import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { openSnackbar } from '@app/common/actions';
import { IRootState } from '@app/core/rootReducer';

import { IAnalyticsSummaryCardOwnProps } from './interfaces';

const AnalyticsSummaryCardContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "common-component-analytics-summary-card" */ './AnalyticsSummaryCard'
    )
);

const mapStateToProps = (state: IRootState, ownProps: IAnalyticsSummaryCardOwnProps) => {
  let { analytics } = state;
  let {
    id,
    title,
    mathOperation,
    attributeKeys,
    format,
    startDate,
    endDate,
    selectedGameProductionId,
    isLoading,
    mdOverride,
  } = ownProps;

  return {
    analytics,
    id,
    title,
    mathOperation,
    attributeKeys,
    format,
    startDate,
    endDate,
    selectedGameProductionId,
    isLoading,
    mdOverride,
  };
};

const mapDispatchToProps = {
  openSnackbar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TAnalyticsSummaryCardConnectedProps = ConnectedProps<typeof connector>;

export const AnalyticsSummaryCard = connector(withRouter(AnalyticsSummaryCardContainer));
