import { Component } from 'react';

import loadable from '@loadable/component';

import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { fetchAnalytics, fetchAnalyticsDateRangeLimit, openSnackbar } from '@app/common/actions';
import { IWithSnackbarProp } from '@app/common/helpers/snackbarHookHelper';
import { IRootState } from '@app/core/rootReducer';
import { showSurvey } from '@app/modules/Survey/actions';

const Analytics = loadable(
  () => import(/* webpackChunkName: "analytics" */ './Analytics')
) as typeof Component;

const mapStateToProps = (state: IRootState) => {
  let {
    publisher: { publisherInfo },
    analytics,
    feature,
  } = state;

  return {
    publisher: publisherInfo,
    analytics,
    feature,
  };
};

const mapDispatchToProps = {
  fetchAnalytics,
  fetchAnalyticsDateRangeLimit,
  showSurvey,
  openSnackbar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TAnalyticsConnectedProps = ConnectedProps<typeof connector> &
  RouteComponentProps &
  IWithSnackbarProp;

export default connector(withRouter(Analytics));
