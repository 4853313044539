import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
// @ts-ignore: camelize package is not typed
import camelize from 'camelize';

import axios from '@app/common/setup/axiosWithHeader';
import { generateModalGameFormData } from '@app/common/helpers';
import { receiveModalGameInfo } from '@app/common/actions';
import { IGameInfo, IAction, IModalGame } from '@app/common/interfaces';

export const createGame = (game: IModalGame) => {
  return (dispatch: Dispatch<IAction<IGameInfo>>): Promise<void> => {
    let data: FormData = generateModalGameFormData(game);

    return axios
      .post('/api_internal/v1/games/', data)
      .then(camelize)
      .then((response: AxiosResponse<IGameInfo>) => {
        dispatch(receiveModalGameInfo(response.data));
      });
  };
};
