import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import {
  IProgressionRoomVersionLocale,
  TStateProgressionRoomVersionLocale,
  IStateProgressionRoomVersionLocaleBodyImage,
} from '@appEntryPoints/common/interfaces';
// import {
//   initialBaseBodyImages,
//   initialBaseVersionLocaleFiles,
// } from '../modules/EntryPoint/constants';
import {
  initialBaseBodyImages,
  initialBaseVersionLocaleFiles,
} from '@appGameProgression/modules/EntryPoints/modules/EntryPoint/constants';

export const formatResponseLocaleToLocaleState = (
  responseVersionLocale: IProgressionRoomVersionLocale
): TStateProgressionRoomVersionLocale => {
  const { bodyImages, ...versionLocaleWithoutBodyImages } = responseVersionLocale;

  let bodyImagesState = initialBaseBodyImages;
  const bodyImagesCount = bodyImages.length;

  const formattedBodyImages = bodyImages.map((bodyImage) => {
    return { ...bodyImage, file: null };
  });

  if (bodyImagesCount === 1) {
    bodyImagesState = {
      defaultBodyImage: formattedBodyImages[0],
      bodyImagesWithCondition: initialBaseBodyImages.bodyImagesWithCondition,
    };
  }

  // default Image body is last element of bodyImages with no conditions
  if (bodyImagesCount > 1) {
    const bodyImagesCopy = cloneDeep(formattedBodyImages);
    const defaultBodyImage = bodyImagesCopy.pop() as IStateProgressionRoomVersionLocaleBodyImage;
    bodyImagesState = {
      defaultBodyImage,
      bodyImagesWithCondition: bodyImagesCopy,
    };
  }

  return merge(
    {} as TStateProgressionRoomVersionLocale,
    initialBaseVersionLocaleFiles,
    versionLocaleWithoutBodyImages,
    bodyImagesState
  );
};
