import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import AtomTextField from 'portal-common-library/atoms/AtomTextField';
import { IBaseProps } from 'portal-common-library/interfaces/componentBase';

import eyeClosed from '@app/imageAssets/eyeClosed.svg';
import eyeOpened from '@app/imageAssets/eyeOpened.svg';

import './styles/passwordTextFieldStyles.scss';

interface IPasswordTextFieldProps extends IBaseProps {
  attributeName: string;
  autoComplete?: string;
  errorText?: string;
  InputProps?: { [key: string]: JSX.Element };
  hasError?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isMultiline?: boolean;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
  isRequired?: boolean;
  rows?: number;
  value: string;
  variant?: 'outlined' | 'standard' | 'filled';
}

export interface IPasswordTextFieldState {
  shouldShowPassword: boolean;
}

class PasswordTextField extends React.Component<IPasswordTextFieldProps, IPasswordTextFieldState> {
  constructor(props: IPasswordTextFieldProps) {
    super(props);
    this.state = {
      shouldShowPassword: false
    };
  }

  public handleClickShowPassword = (): void => {
    this.setState({ shouldShowPassword: !this.state.shouldShowPassword });
  };

  public renderInputAdornment(): JSX.Element {
    let { shouldShowPassword } = this.state;

    // note eye is closed when showing password to indicate the eye means to HIDE
    return (
      <InputAdornment position="end" classes={{ positionEnd: 'password-textfield__position-end' }}>
        <IconButton
          aria-label="toggle password visibility"
          className="password-textfield__icon button"
          onClick={this.handleClickShowPassword}
          size="large">
          <>
            {shouldShowPassword ? (
              <img src={eyeClosed} className="password-textfield__closed-eye password-image" />
            ) : (
              <img src={eyeOpened} className="password-textfield__open-eye password-image" />
            )}
          </>
        </IconButton>
      </InputAdornment>
    );
  }

  public render(): JSX.Element {
    return (
      <AtomTextField
        {...this.props}
        InputProps={{
          endAdornment: this.renderInputAdornment()
        }}
        type={this.state.shouldShowPassword ? 'text' : 'password'}
      />
    );
  }
}

export { PasswordTextField };
