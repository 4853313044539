/* global FormData */

export const sendPush = (platform, name, gameInfo) => {
  let form = new FormData();

  form.append('notification_test[production]', true);
  form.append('notification_test[game_id]', gameInfo.productionId);
  form.append('notification_test[enabled]', true);
  form.append('notification_test[platform]', platform);
  form.append('notification_test[display_name]', name);

  form.append('commit', 'Send Test');
  form.append('id', gameInfo.id);

  return $.ajax({
    url: `/games/${gameInfo.id}/test_apns.json`,
    method: 'POST',
    data: form,
    processData: false,
    contentType: false
  });
};

export const verifyPush = (platform, productionId, pushCode, verifyId) => {
  let form = new FormData();

  form.append('verification[production]', true);
  form.append('verification[game_id]', productionId);
  form.append('verification[enabled]', true);
  form.append(`verification[${platform}_push_code]`, pushCode);

  form.append('id', verifyId);
  form.append('commit', 'Verify');

  return $.ajax({
    url: `/verifications/${verifyId}/${platform}_push_test.json`,
    method: 'POST',
    data: form,
    processData: false,
    contentType: false
  });
};
